import React from "react";
import { Button, Checkbox, Form, Input, InputNumber } from "antd";
import { ModalStyle, SubmitBtn } from "../styles";
import { Card, StyledCheckbox } from "./styles";
import { EditArea } from "./styles";
import { editUser, register } from "redux/action/appActions";
import { Fragment } from "react";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";

const RegisterModal = ({ isModalOpen, userPhoneNumber, callback }) => {
  const ipRef = useRef("");

  const dispatch = useDispatch();

  const getUserIP = async () => {
    await fetch("https://api.ipify.org?format=json").then(async (res) => {
      const data = await res.json();
      ipRef.current = data.ip;
    });
  };

  const onSubmit = async (value) => {
    dispatch(
      register(
        {
          ...value,
          last_name: "",
          ip: ipRef.current,
          channel: "website",
          ref: userPhoneNumber.ref,
          country: "India",
          mobile: userPhoneNumber.mobile,
          country_code: userPhoneNumber.country_code,
          firebase_key: "",
        },
        callback
      )
    );
  };

  useEffect(() => {
    getUserIP();
  }, []);

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={608}
      open={isModalOpen}
      closable={false}
      // onCancel={() => setIsModalOpen(false)}
    >
      <EditArea>
        <h4>Add your info</h4>
        <Form
          // form={form}
          name="info-form"
          layout="vertical"
          onFinish={onSubmit}
        >
          {(values, formInsatance) => (
            <Fragment>
              <Form.Item
                label={false}
                name="name"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z ]{3,}$/,
                    message: "Please enter valid name!",
                  },
                ]}
              >
                <Input placeholder="User Name" name="name" />
              </Form.Item>  
              <Form.Item
                label={false}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" name="email" />
              </Form.Item>
              <Card>
                <Form.Item
                  label={false}
                  name="hasRefer"
                  valuePropName="checked"
                >
                  <StyledCheckbox name="hasRefer">
                    I have an referral code
                  </StyledCheckbox>
                </Form.Item>
              </Card>
              <Form.Item label={false} name="referral">
                <Input
                  placeholder="Referral Code (optional)"
                  disabled={!values.hasRefer}
                  name="referral"
                />
              </Form.Item>
              <SubmitBtn>
                <Button type="primary" htmlType="submit">
                  Continue
                </Button>
              </SubmitBtn>
            </Fragment>
          )}
        </Form>
      </EditArea>
    </ModalStyle>
  );
};

export default RegisterModal;
