import styled from "styled-components";

export const VerifyArea = styled.div`
  font-weight: 600;
  color: var(--black);
  h4 {
    margin-bottom: 2em;
  }
  h5 {
    font-size: 1.43em;
    &:nth-child(2) {
      color: var(--gray-legendary);
      font-weight: 300;
      margin-bottom: 0.75em;
      text-align: center;
    }
    &:nth-child(3) {
      color: var(--black);
      text-align: center;
      margin-bottom: 1.625em;
      img {
        cursor: pointer;
      }
    }
  }
  .CodeInput-module_cellContainer__Tq-Q1 {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.286em;
    input {
      width: 60px;
      height: 60px;
      border: 1px solid var(--gray-legendary);
      border-radius: 0.4em;
      outline: 0;
      box-shadow: none;
      font-size: 1.75em;
      font-weight: 500;
      &:focus,
      &:focus-visible,
      &:focus-within {
        border-color: var(--black);
      }
    }
  }
  p {
    font-weight: 300;
    color: var(--gray-legendary);
  }
  & {
    /* @media (max-width: 576px) {
      h5 {
        &:nth-child(2) {
          font-size: 1.125rem;
        }
      }
    } */
  }
`;
export const ActionButtons = styled.div`
  button {
    height: auto;
    background-color: var(--grey-ordinary);
    border: 1px solid var(--primary) !important;
    border-radius: 0.36em;
    outline: 0;
    box-shadow: none;
    svg {
      margin-right: 0.375em;
      font-size: 1.15em;
      color: var(--black);
    }
    span {
      font-size: 0.86em;
      font-weight: 400;
      color: var(--black);
    }
    &:first-child {
      padding: 0.5em 1.15em;
      margin-right: 0.715em;
    }
    &:last-child {
      padding: 0.5em 0.86em;
    }
  }
`;
