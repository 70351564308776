import styled from "styled-components";
import { Button } from "antd";

export const SelectCard = styled.div`
  padding: 2.25em 2.5em;
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.36em;
  margin-bottom: 2em;
  h4 {
    margin-bottom: 1.25em;
  }
  &:last-child {
    height: calc(100% - 245px);
    margin-bottom: 0;
  }
  & {
    @media (max-width: 576px) {
      padding: 1.5em;
    }
  }
`;
export const NewAddressCard = styled.div`
  width: 50%;
  height: 178px;
  border: 1px solid var(--primary);
  border-radius: 0.36em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.5em;
  cursor: pointer;
  img {
    width: 12.5px;
    height: 16.6px;
    margin-right: 0.25em;
  }
  p {
    font-size: 1.125em;
    font-weight: 400;
    color: var(--primary);
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;
export const PaymentArea = styled.div`
  .box-heading {
    font-size: 1.575em;
  }
  .home-deliver {
    border: 1px solid var(--white-smoke-primary);
    border-radius: 0.36em;
    padding: 2.5em;
    margin-bottom: 5em;
    display: flex;
    justify-content: space-between;
    img {
      width: 29.5px;
      height: 23.5px;
      margin-right: 1.615em;
    }
    .homeInfo {
      h5 {
        font-size: 1.429em;
        font-weight: 400;
        color: var(--gray);
        strong {
          font-weight: 600;
        }
      }
      p {
        font-size: 1.22em;
        font-weight: 300;
        color: var(--gray);
        margin: 0;
      }
    }
    & > p:last-child {
      font-size: 1em;
      font-weight: 500;
      font-family: "Baloo 2", cursive;
      color: var(--primary);
      margin: 0;
      cursor: pointer;
    }
  }
  .delivery-type {
    border: 1px solid var(--white-smoke-primary);
    border-radius: 0.36em;
    padding: 2.5em;
    margin-bottom: 2em;
    h5 {
      font-weight: 400;
      color: var(--black);
      margin-bottom: 1.25em;
    }
  }
  .more-options {
    border: 1px solid var(--white-smoke-primary);
    border-radius: 0.36em;
    padding: 2.5em;
    .option {
      width: 100%;
      /* height: 86px; */
      border: 1px solid var(--white-smoke-primary);
      border-radius: 0.36em;
      padding: 2em 1.86em;
      margin-bottom: 2.15em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      h5 {
        color: var(--black);
        margin: 0;
      }
      svg {
        font-size: 2.86em;
      }
      .ant-radio-wrapper {
        .ant-radio-inner {
          width: 26px;
          height: 26px;
          border: 1px solid var(--grey-legendary);
          position: relative;
          &::after {
            padding: 1.8em;
            position: absolute;
            top: -50%;
            left: -50%;
            background: var(--primary);
            border-radius: 50%;
            margin-block-start: -1px;
            margin-inline-start: -1px;
          }
        }
        .ant-radio-checked {
          .ant-radio-inner {
            background-color: unset !important;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & {
    /* @media (min-width: 1400px) {
      .box-heading {
        font-size: 1.375rem;
      }
    } */
    @media (max-width: 576px) {
      .more-options {
        .option {
          padding: 1.5em 1em;
        }
      }
      .home-deliver {
        padding: 1em;
        margin-bottom: 2.5em;
        img {
          margin-right: 1em;
        }
        .homeInfo {
          h5 {
            font-size: 1.125em;
          }
          p {
            font-size: 1em;
          }
        }
      }
    }
  }
`;
export const ShippingCard = styled.div`
  width: 100%;
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.36em;
  padding: 1.75em 0px 0px 0px;
  .fixedScroll {
    height: 275px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & {
    @media (max-width: 576px) {
      .fixedScroll {
        height: 730px;
      }
    }
  }
`;
export const FreeDelivery = styled.div`
  background-color: var(--white-smoke-secondary);
  border-radius: 0.36em;
  padding: 1.145em 2em;
  margin-bottom: 1.15em;
  h5 {
    color: var(--primary);
    margin-bottom: 0.25em;
  }
  p {
    font-size: 0.86em;
    font-weight: 400;
    color: var(--black);
    margin: 0;
  }
`;
export const CouponBtn = styled.button`
  width: 100%;
  padding: 1.5em 1em 1.5em 1.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: unset;
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.36em;
  img {
    width: 32px;
    height: 32px;
    margin-right: 2.5em;
  }
  h6 {
    font-size: 1.286em;
    font-weight: 400;
    color: var(--black);
    margin: 0;
    text-align: start;
  }
  p {
    font-size: 0.65em;
    font-weight: 400;
    color: var(--black);
    margin: 0.45em 0 0;
  }
  svg {
    font-size: 2.5em;
    color: var(--black);
  }
  &.active {
    cursor: auto;
    h6 {
      font-weight: 600;
      span {
        color: var(--primary);
      }
    }
    svg {
      font-size: 1.75em;
      cursor: pointer;
      color: var(--gray);
    }
    img {
      filter: invert(30%) sepia(130%) saturate(230%) hue-rotate(118deg)
        brightness(97%) contrast(82%);
    }
  }
  @media (max-width: 576px) {
    padding: 1.25em 1em;
    img {
      width: 26px;
      height: 26px;
      margin-right: 1.25em;
    }
    h6 {
      font-size: 1em;
    }
    svg {
      font-size: 2em;
    }
    &.active {
      padding: 0.75em;
      img {
        margin-right: 0.75em;
      }
      h6 {
        font-size: 0.875em;
        margin-bottom: 0.125em;
      }
      svg {
        font-size: 1.25em;
      }
    }
  }
`;
export const TipArea = styled.div`
  margin: 2.25em 0;
  h6 {
    font-size: 1.286em;
    font-weight: 400;
    color: var(--black);
  }
  p {
    font-size: 1em;
    font-weight: 300;
    color: var(--gray);
  }
  .ant-radio-button-wrapper {
    height: auto;
    border: 1px solid var(--light-gray) !important;
    border-radius: 0.715em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: none;
    }
    &:hover {
      border-color: var(--primary) !important;
      background: unset !important;
      & > span:last-child {
        color: var(--primary) !important;
      }
    }
    &.ant-radio-button-wrapper-checked {
      border-color: var(--primary) !important;
      background-color: var(--grey-secondary);
    }
    & > span:last-child {
      font-size: 1.36em;
      font-weight: 600;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
      color: var(--black);
    }
    & {
      @media (min-width: 1400px) {
        margin: 0 0.5em 0 0;
      }
      @media (min-width: 1440px) {
        margin: 0 1em 0 0;
      }
      @media (max-width: 1200px) {
        margin: 0.5em auto;
      }
    }
  }
`;
export const BillDetails = styled.div`
  margin-bottom: 1em;
  h6 {
    font-size: 1.286em;
    font-weight: 400;
    color: var(--black);
    margin-bottom: 0.75em;
  }
`;
export const Bill = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  p {
    margin-bottom: 0.5em;
    &:first-child {
      font-size: 1em;
      font-weight: 300;
      color: var(--gray);
    }
    &:last-child {
      font-size: 1.15em;
      font-weight: 400;
      color: var(--black);
      &.special {
        color: var(--primary);
      }
    }
  }
`;
export const SubtotalCard = styled.div`
  background-color: var(--white-smoke-secondary);
  padding: 1em 1.15em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6,
  span,
  p {
    margin: 0;
  }
  h6 {
    font-size: 1.3em;
    font-weight: 400;
    color: var(--black);
  }
  span {
    font-size: 0.86em;
    font-weight: 400;
    color: var(--gray);
    text-decoration: line-through;
  }
  p {
    color: var(--primary);
  }
  & {
    @media (min-width: 1400px) {
      padding: 1em 2.43em;
    }
    @media (max-width: 576px) {
      display: block;
      & > div {
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          text-align: end;
          margin-top: 0.5em;
        }
      }
    }
  }
`;
export const AddNumber = styled.div`
  padding: 0 1.5em;
  margin: 0 0 1.75em 0;
  .logo {
    padding: 0.65em;
    border: 1px solid var(--grey-legendary);
    border-radius: 0.5em;
    margin-right: 1em;
    svg {
      font-size: 2em;
      color: var(--grey-legendary);
    }
  }
  h5 {
    font-size: 1.175em;
    color: var(--black);
    margin: 0.375em 0;
  }
  p {
    font-size: 0.915em;
    font-weight: 300;
    color: var(--gray);
    margin: 0;
  }
  & {
    @media (min-width: 1400px) {
      padding: 0 3em;
    }
    @media (max-width: 576px) {
      h5 {
        font-size: 1.15em;
      }
      p {
        font-size: 1em;
      }
    }
  }
`;
export const ConfirmDelivery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.125em;
  .imageBox {
    padding: 0.65em;
    border: 1px solid var(--grey-legendary);
    border-radius: 0.5em;
    margin-right: 1em;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .info {
    p {
      &:first-child {
        font-size: 0.96em;
        font-weight: 400;
        color: var(--gray);
        margin-bottom: 0.45em;
        strong {
          font-weight: 600;
          color: var(--black);
        }
      }
      &:last-child {
        font-size: 0.9em;
        font-weight: 300;
        color: var(--gray);
        span {
          color: var(--primary);
        }
      }
    }
  }
  & > p:last-child {
    font-size: 1.36em;
    font-weight: 500;
    font-family: "Baloo 2", cursive;
    color: var(--primary);
    cursor: pointer;
  }
  & {
    @media (max-width: 1400px) {
      .info {
        p {
          &:first-child {
            font-size: 1em;
          }
          &:last-child {
            font-size: 0.9em;
          }
        }
      }
      & > p:last-child {
        font-size: 1em;
      }
    }
  }
`;

export const AddNumberBtn = styled(Button)`
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 0.715em;
  box-shadow: none !important;
  padding: 0.8em 0;
  &:focus,
  &:focus-visible {
    outline: 0 !important;
  }
  div {
    display: none;
  }
  span {
    font-size: 1.15em;
    font-weight: 400;
  }
  & disable {
    background: var(--grey-secondary);
    cursor: not-allowed;
    span {
      color: var(--primary);
    }
  }
  &.active {
    background: var(--primary);
    span {
      color: var(--white);
    }
  }
  & {
    @media (min-width: 1400px) {
      padding: 0.815em 0;
      span {
        font-size: 1.2em;
      }
    }
  }
`;

export const MinOrderMessage = styled.div`
  display: flex;
  padding: 0.5rem;
  text-align:center;
  justify-content: space-center;
  align-items: center;
  p{
     color: var(--primary);
  },
  & @media (max-width: 576px) {
      display: block;
      & > div {
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          text-align: end;
          margin-top: 0.5em;
        }
      }
    }
  }
`;
