import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FooterIcon } from "./styles";
import { AppStore, PlayStore } from "assets/svg";
import "./footer.css";

const NewFooter = () => {
  const links = useSelector((state) => state.app.data?.DownloadApp);
  const data = useSelector((state) => state?.app?.data?.refType);

  return (
    <Container
      className="py-2 home-container"
      style={{
        backgroundColor: "#f3f9f8",
      }}
    >
      <div className="d-flex justify-content-between flex-nowrap">
        <div className="d-flex justify-content-between align-items-center flex-nowrap mobile-store-container">
          <span className="d-lg-flex d-xl-flex gap-2 d-sm-none d-md-none d-xs-none mobile-store-icons">
            <a href={links?.ios} target="_blank" rel="noreferrer">
              <AppStore className="footer-icons" />
            </a>
            <a href={links?.android} target="_blank" rel="noreferrer">
              <PlayStore className="footer-icons" />
            </a>
          </span>

          <h5 className="pt-2 font-xs-x-small font-sm-medium px-1">
            Download App
          </h5>

          <span className="d-lg-none d-xl-none gap-2 d-sm-flex d-md-flex d-xs-flex mobile-store-icons">
            <a href={links?.ios} target="_blank" rel="noreferrer">
              <AppStore className="footer-icons" />
            </a>
            <a href={links?.android} target="_blank" rel="noreferrer">
              <PlayStore className="footer-icons" />
            </a>
          </span>
        </div>
        {data.business_name && (
          <div className="d-lg-flex d-xs-none d-sm-none d-md-none">
            <h6 className="pt-2 px-3 font-xs-x-small font-sm-medium px-1">
              Copyright © {new Date().getFullYear()} {data.business_name}
            </h6>
          </div>
        )}

        {/* <div className="d-lg-flex d-xs-none d-sm-none d-md-none">
          <h5 className="pt-2 px-3 font-xs-x-small font-sm-medium px-1">
            Follow Us
          </h5>
          <FooterIcon className="ml-4">
            <a href="www.facebook.com" style={{ color: "white" }}>
              <FaFacebookF />
            </a>
          </FooterIcon>
          <FooterIcon>
            <a href="www.instagram.com" style={{ color: "white" }}>
              <FaInstagram />
            </a>
          </FooterIcon>
          <FooterIcon>
            <a href="www.twitter.com" style={{ color: "white" }}>
              <FaTwitter />
            </a>
          </FooterIcon>
        </div> */}
      </div>
    </Container>
  );
};

export default NewFooter;
