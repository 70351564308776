import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedCategory } from "redux/action/appActions";
import DefProd from "../../../assets/images/default_image.svg";
import "./CategoryCard.css";
import { fileURL } from "helpers/baseURL";

const CategoryCard = ({ image, heading, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({ type: "GET_SUB_CATEGORIES", payload: [] });
    dispatch({
      type: "GET_SUB_CATEGORIES_PRODUCTS",
      payload: {
        nestedSubCategoriesWithProducts: [],
      },
    });
    dispatch(setSelectedCategory({ title: heading, id: id }));
    dispatch({ type: "GET_PRODUCTS", payload: [] });
    dispatch({ type: "SET_MESSAGE", payload: "" });

    navigate(`/itemList/${id}`);
  };
  return (
    <div onClick={handleClick} className="category-card">
      <div className="category-card-image-container">
        <img
          src={`${fileURL}/${image}` ? `${fileURL}/${image}` : DefProd}
          alt="click here"
          onError={(e) => {
            e.target.src = DefProd;
            e.onerror = null;
          }}
        />
      </div>
      <p>{heading}</p>
    </div>
  );
};

export default CategoryCard;
