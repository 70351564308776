import { Button } from "antd";
import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.15em;
  .product-info {
    width: 116px;
    margin-left: 1em;
    h6 {
      font-size: 0.93em;
      font-weight: 500;
      color: var(--black);
    }
    p {
      font-size: 1em;
      font-weight: 400;
      color: var(--gray);
    }
  }
  svg {
    cursor: pointer;
  }
  & {
    @media (max-width: 1400px) {
      .product-info {
        h6 {
          font-size: 0.75em;
        }
        p {
          font-size: 0.86em;
        }
      }
    }
    @media (max-width: 576px) {
      display: block;
      border-bottom: 1px solid var(--white-smoke-primary);
      padding-bottom: 1em;
    }
  }
`;
export const ImageBox = styled.div`
  /* width: 76px;
  height: 76px; */
  border: 1px solid var(--gray-legendary);
  border-radius: 0.36em;
  padding: 0.645em 1em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  img {
    width: 45px;
    height: 58px;
  }
`;
export const ItemButton = styled(Button)`
  width: 110px;
  height: 40px;
  border: 1px solid var(--primary) !important;
  border-radius: 0.36em;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 0.65em;
    font-weight: 400;
    color: var(--gray);
  }
  & {
    @media (max-width: 1400px) {
      width: 99px;
      height: 28px;
      margin: 0 2.5em 0 1em;
      span {
        font-size: 0.575em;
      }
    }
    @media (max-width: 1280px) {
      margin-right: 1.5em;
    }
    @media (max-width: 1199px) {
      margin-right: 4em;
    }
    @media (max-width: 992px) {
      margin-right: 5em;
    }
    @media (max-width: 767px) {
      margin-right: 3em;
    }
  }
`;
export const QuantityBtn = styled.div`
  width: 110px;
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 0.36em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  span {
    font-size: 1.36em;
    font-weight: 500;
    color: var(--primary);
    &:first-child,
    &:last-child {
      cursor: pointer;
    }
  }
  & {
    @media (max-width: 1400px) {
      width: 99px;
      height: 28px;
      margin: 0 0.625em 0 0.375em;
      span {
        font-size: 1em;
      }
    }
    @media (min-width: 1200px) and (max-width: 1450px) {
      margin: 0;
    }
    @media (max-width: 992px) {
      margin: 0 1.75em 0 0;
    }
    @media (max-width: 576px) {
      margin-right: 2em;
    }
  }
`;
export const ProductPrice = styled.div`
  p {
    margin: 0;
    text-align: end;
    font-size: 1em;
    font-weight: 400;
    color: var(--black);
  }

  span {
    font-size: 0.715em;
    font-weight: 300;
    color: var(--gray);
    text-decoration: line-through;
  }
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
  flex-direction: column;
  p {
    margin: 0;
    text-align: end;
    font-size: 1em;
    font-weight: 400;
    color: var(--black);
  }

  span {
    font-size: 0.715em;
    font-weight: 300;
    color: var(--gray);
    text-decoration: line-through;
    alignitems: flex-end;
  }
`;

export const QuantityAndPriceContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  justify-content: space-between;
  gap: 1rem;
`;
