import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Col, Collapse, Row, Empty } from "antd";
import DashboardHeader from "components/DashboardHeader";
import { UserCard, UserCollapse, UserInfo, UserOrders } from "./index.styled";
import MemberShip from "./MemberShip";
import Loyalty from "./Loyalty";
import Wallet from "./Wallet";
import Address from "./Address";
import OrderDetail from "./OrderDetail";
import Orders from "./Orders";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "redux/action/appActions";
import Policy from "./Policy";
import TermsCondition from "./TermsCondition";
import About from "./About";
import { Outlet } from "react-router-dom";

const MyOrders = ({ username, userPhoneNumber, userEmail }) => {
  const user = useSelector((state) => state.app.data);
  const dispatch = useDispatch();
  const [step, setStep] = useState("orders");
  const [selectedOrder, setSelectedOrder] = useState();

  const handleConsole = () => {};

  useEffect(() => {
    dispatch(getCartItems(handleConsole));
  }, []);

  return (
    <>
      <DashboardHeader setStep={setStep} />
      <Container>
        <Row gutter={[{ lg: 32 }]} style={{ margin: "3em 0" }}>
          <Col
            lg={9}
            xl={9}
            className="d-lg-block d-xl-block d-sm-none d-md-none d-xs-none"
          >
            <Sidebar step={step} setStep={setStep} />
          </Col>
          <Col lg={15} xs={24} style={{ height: "auto" }}>
            <UserOrders>
              <Outlet />
              {/* {step === "orders" ? (
                <Orders
                  setStep={(id) => {
                    setSelectedOrder(id);
                    setStep("detail");
                  }}
                />
              ) : step === "detail" ? (
                <OrderDetail selectedOrder={selectedOrder} setStep={setStep} />
              ) : step === "address" ? (
                <Address />
              ) : step === "wallet" ? (
                <Wallet />
              ) : step === "loyalty" ? (
                <Loyalty />
              ) : step === "membership" ? (
                <MemberShip />
              ) : step === "policy" ? (
                <Policy />
              ) : step === "conditions" ? (
                <TermsCondition />
              ) : step === "about" ? (
                <About />
              ) : (
                <Empty />
              )} */}
            </UserOrders>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyOrders;
