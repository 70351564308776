import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function PaymentPending() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <img src="/images/payment_pending.svg" alt="" />
      <H2>Order Pending</H2>
      <H3>Please check back after some time as payment is pending.</H3>
      <H4>
        Page will be automatically redirected to the main page in <span>3</span>{" "}
        seconds or click button below
      </H4>
      <Button onClick={() => navigate("/dashboard/orders")}>
        View Order Detail's
      </Button>
    </Wrapper>
  );
}
const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const H2 = styled("h2")`
  font-size: 26px;
  color: var(--yellow);
  font-weight: 700;
  margin: 1em 0 1em 0;
`;
const H3 = styled("h3")`
  font-size: 24px;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 0.5em;
`;
const H4 = styled("h4")`
  font-size: 18px;
  font-weight: 400;
  color: var(--gray);
  width: 100%;
  max-width: 600px;
  text-align: center;
  span {
    color: var(--black);
  }
`;
const Button = styled("button")`
  font-size: 21px;
  border: none;
  outline: none;
  padding: 0.5em 1em;
  background: var(--primary);
  color: #fff;
  border-radius: 10px;
  margin-top: 1.5em;
`;
