import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Marker,
  DrawingManager,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
const Pointer = `<svg xmlns="http://www.w3.org/2000/svg" width="25.455" height="25.455" viewBox="0 0 25.455 25.455">
  <g id="Group_73354" data-name="Group 73354" transform="translate(-11446.25 -8820.282)">
    <g id="_000000ff" data-name="#000000ff" transform="translate(11446.25 8820.282)">
      <path id="Path_1465" data-name="Path 1465" d="M12.2,0h1.058a.588.588,0,0,1,.409.2.736.736,0,0,1,.124.492c0,.851,0,1.7,0,2.551a9.578,9.578,0,0,1,8.2,7.2,9.467,9.467,0,0,1,.222,1.219c.9.017,1.8,0,2.7.01a.545.545,0,0,1,.548.527v1.06a.589.589,0,0,1-.2.408.739.739,0,0,1-.493.124c-.85,0-1.7,0-2.549,0a9.58,9.58,0,0,1-7.794,8.325c-.21.032-.419.064-.628.1,0,.85,0,1.7,0,2.551a.736.736,0,0,1-.125.493.589.589,0,0,1-.408.2H12.2a.591.591,0,0,1-.406-.2.742.742,0,0,1-.126-.5c0-.849,0-1.7,0-2.548a9.576,9.576,0,0,1-8.214-7.273,11.359,11.359,0,0,1-.209-1.148q-1.273,0-2.547,0a.744.744,0,0,1-.5-.124.585.585,0,0,1-.2-.408V12.2a.589.589,0,0,1,.2-.406.738.738,0,0,1,.5-.126c.85,0,1.7,0,2.548,0a9.584,9.584,0,0,1,8.422-8.422c0-.85,0-1.7,0-2.548a.735.735,0,0,1,.126-.5A.589.589,0,0,1,12.2,0m-.244,5.346a7.617,7.617,0,0,0-1.041.191A7.431,7.431,0,0,0,5.8,10.082,7.328,7.328,0,0,0,5.4,13.9a7.407,7.407,0,1,0,6.551-8.55Z"/>
      <path id="Path_1466" data-name="Path 1466" d="M174.513,170.742a4.242,4.242,0,1,1-2.374,1.051A4.247,4.247,0,0,1,174.513,170.742Z" transform="translate(-162.23 -162.231)"/>
    </g>
  </g>
</svg>
`;

const libraries = ["places", "drawing"];

export default function Home({ center, onMarker }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPBOX_TOKEN,
    libraries: libraries,
  });

  const defaultLocation = useSelector(
    (state) => state.app.data.default_location
  );

  const [locCenter, setLocCenter] = useState({});
  const [markerPosition, setMarkerPosition] = useState(
    center || defaultLocation
  );

  function createCenterControl(map) {
    const controlButton = document.createElement("button");

    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontSize = "16px";
    controlButton.style.lineHeight = "38px";
    controlButton.style.margin = "8px 10px 22px";
    controlButton.style.textAlign = "center";
    controlButton.innerHTML = Pointer;
    controlButton.title = "Center";
    controlButton.type = "button";

    controlButton.addEventListener("click", async () => {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const newPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        // Set the marker position
        setMarkerPosition(newPosition);

        // Pan the map to the new position
        map.panTo(newPosition);
        onMarker(newPosition)
      });
    });

    return controlButton;
  }

  function onMapLoad(map) {
    const position = window.google.maps.ControlPosition.RIGHT_CENTER;
    const centerControlDiv = document.createElement("div");
    const centerControl = createCenterControl(map);
    centerControlDiv.appendChild(centerControl);
    map.controls[position].push(centerControlDiv);
  }

  useEffect(() => {
    setLocCenter(center || defaultLocation);
    setMarkerPosition(center || defaultLocation);
  }, [center, defaultLocation]);

  if (loadError) return <div>Error loading map: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Map
      center={center}
      onMarker={onMarker}
      onMapLoad={onMapLoad}
      markerPosition={markerPosition}
    />
  );
}

function Map({ center, onMarker, onMapLoad, markerPosition }) {
  return (
    <GoogleMap
      zoom={15}
      center={center}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
      onLoad={(map) => onMapLoad(map)}
      mapContainerClassName="map-container"
    >
      <DrawingManager
        options={{
          markerOptions: {},
          drawingControl: true,
          drawingControlOptions: {
            drawingModes: ["marker"],
          },
        }}
        libraries={libraries}
        onMarkerComplete={(markerEvent) => {
          onMarker(markerEvent);
          markerEvent.setMap(null);
        }}
      />
      <Marker position={markerPosition} />
    </GoogleMap>
  );
}
