import styled from "styled-components";

export const InfoArea = styled.div`
  h4 {
    font-size: 1.715em;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 1.46em;
  }
  .ant-form-item {
    margin-bottom: 2.5em;
    input {
      width: 100%;
      /* height: 100%;
      min-height: 73px; */
      padding: 1.25em 1.5em;
      background-color: var(--white-smoke-secondary);
      border: 0px;
      outline: 0px;
      box-shadow: none;
      border-radius: 0.635em;
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
      &::placeholder {
        color: var(--gray-primary);
      }
    }
    .ant-checkbox-wrapper {
      align-items: flex-end;
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 32px;
          height: 32px;
          border: 1px solid var(--primary);
          &::after {
            width: 9px;
            height: 19px;
            top: 45%;
          }
        }
        &::after {
          display: none;
        }
      }
      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          background: var(--primary) !important;
          border-color: var(--primary) !important;
        }
      }
      & > span:last-child {
        font-size: 1.15em;
        font-weight: 400;
        color: var(--black);
      }
    }
  }
`;
