import styled from "styled-components";

export const Rate = styled.div`
  margin: 2em 0;
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    svg {
      width: 42px;
      height: 42px;
    }
    &.on {
      color: var(--primary);
    }
    &.off {
      color: var(--white-smoke-legendary);
    }
  }
`;
