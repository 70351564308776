import React, { useEffect, useState, useCallback } from "react";
import { OrderItemCard, OrderView, ReorderBtn } from "./index.styled";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { getOrders, getSelectedOrder } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { cancelOrder, getCartItems } from "redux/action/appActions";
import { message } from "antd";
import { fileURL } from "helpers/baseURL";
import CancelOrderPopup from "components/Modals/CancelOrderPopupModal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function OrderDetail({ setStep, selectedOrder }) {
  const [data, setData] = useState();
  console.log("the data is ", data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getData = async () => {
    const res = await getSelectedOrder(params.id);
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [params]);

  const handleConsole = () => {};

  const handleCancel = async () => {
    const res = await cancelOrder({ order_id: params.id, status: "Cancelled" });
    if (res.status) {
      message.success("Order Cancelled Successfully");
      navigate(-1);
    } else {
      message.error("Failed to Cancel Order");
    }
  };

  const getDeliveryMode = useCallback((mode) => {
    const splitMode = mode?.split("_");
    if (splitMode?.length > 1) {
      const str = splitMode.reduce((acc, next) => `${acc} ${next}`, "");
      return str;
    }

    return mode;
  }, []);

  const getNumberToFixed = useCallback((num) => {
    const float = parseFloat(num);
    if (float) {
      return float.toFixed(2);
    }
    return num;
  }, []);

  const addressIcon =
    data?.customerAddress?.address_type === "Home"
      ? "/images/address-card-logo-3.svg"
      : data?.customerAddress?.address_type === "Work"
      ? "/images/address-card-logo-2.svg"
      : "/images/address-card-logo-1.svg";

  const orderDeliveryAddress = data?.orders[0]?.order_address[0];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 100,
      }}
      spin
    />
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          <OrderView>
            <div className="icon" onClick={() => navigate(-1)}>
              <MdKeyboardArrowLeft />
            </div>
            <div className="order-info">
              <div>
                <div>
                  <h2>{data?.orders[0]?.ordernumber}</h2>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="dot"
                    style={
                      data?.orders[0]?.order_status === "Received"
                        ? { backgroundColor: "var(--lime-green)" }
                        : data?.orders[0]?.order_status === "Out_For_Delivery"
                        ? { backgroundColor: "var(--dark-orange-primary)" }
                        : data?.orders[0]?.order_status === "Delivered"
                        ? { backgroundColor: "var(--sea-green)" }
                        : data?.orders[0]?.order_status === "Cancelled"
                        ? { backgroundColor: "var(--red-primary)" }
                        : data?.orders[0]?.order_status === "Pending"
                        ? { backgroundColor: "var(--lemon-yellow)" }
                        : data?.orders[0]?.order_status === "Accepted"
                        ? { backgroundColor: "var(--orange-primary)" }
                        : data?.orders[0]?.order_status === "Packed"
                        ? { backgroundColor: "var(--dark-blue-primary)" }
                        : data?.orders[0]?.order_status === "Assign_Delivery"
                        ? { backgroundColor: "var(--orange-primary)" }
                        : data?.orders[0]?.order_status ===
                          "Delivery_Partner_is_on_the_way_to_store"
                        ? { backgroundColor: "var(--orange-primary)" }
                        : data?.orders[0]?.order_status ===
                          "Waiting_for_Order_to_be_ready"
                        ? { backgroundColor: "var(--orange-primary)" }
                        : data?.orders[0]?.order_status === "Rescheduled"
                        ? { backgroundColor: "var(--dark-orange-secondary)" }
                        : {}
                    }
                  ></div>
                  <h5>{data?.orders[0]?.order_status_message}</h5>
                </div>
              </div>
              <div>
                <p>
                  {moment(data?.orders[0]?.created_at).format(
                    "DD MMM, YYYY h:mm A"
                  )}
                </p>

                <p>
                  {localStorage.getItem("ref") === "ORDRIO-1015" ? (
                    ["Delivered", "Cancelled"].includes(data?.orders[0]?.order_status) ? (
                      ""
                    ) : (
                      "Your order will be delivered soon"
                    )
                  ) : (
                    <p>
                      {data?.orders[0]?.delivery_date}
                      {data?.orders[0]?.delivery_time}
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className="order-mods">
              <div>
                <h5>Payment Mode</h5>
                <p className="uppercase">{data?.orders[0]?.payment_type}</p>
              </div>
              <div>
                <h5>Delivery mode</h5>
                <p className="capitalize">
                  {getDeliveryMode(data?.orders[0]?.delivery_type)}
                </p>
              </div>
            </div>
            <div className="order-items">
              <h5 style={{ marginBottom: "1.75em" }}>
                {data?.orders[0]?.order_details.length} Items
              </h5>
              {data?.orders[0]?.order_details?.map(
                ({ product_name, product_variations, quantity }, index) => (
                  <OrderItemCard key={index}>
                    <div className="d-flex">
                      <div className="image-box">
                        <img
                          src={`${fileURL}${product_variations?.product_images?.[0]?.path}`}
                          alt="click here"
                        />
                      </div>
                      <div className="product-info">
                        <p className="m-0">{product_name}</p>
                        <span className="d-inline-flex align-items-center gap-3">
                          <p className="m-0">
                            {product_variations.name +
                              product_variations.unit_of_measure}
                          </p>
                          <p
                            className="m-0"
                            style={{
                              fontSize: "small",
                            }}
                          >{`QTY: ${quantity}`}</p>
                        </span>
                      </div>
                    </div>
                    <span className="d-flex flex-column align-items-end ">
                      <h5>
                        {currency_symbol}
                        {product_variations?.product_batches?.[0]
                          ?.sale_price === 0
                          ? product_variations?.product_batches?.[0]?.mrp
                          : product_variations?.product_batches?.[0]
                              ?.sale_price}
                      </h5>
                      {product_variations?.product_batches?.[0]?.sale_price !=
                        0 && (
                        <p
                          className="m-0"
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <s>
                            {currency_symbol}
                            {product_variations?.product_batches?.[0]?.mrp}
                          </s>
                        </p>
                      )}
                    </span>
                  </OrderItemCard>
                )
              )}
              {/* <OrderItemCard>
            <div className="d-flex">
              <div className="image-box">
                <img src="/images/slider-image.png" alt="click here" />
              </div>
              <div className="product-info">
                <p>Lay's Sizzling Hot Potato Chips</p>
                <p>50g</p>
              </div>
            </div>
            <h5>₹19</h5>
          </OrderItemCard>
          <OrderItemCard>
            <div className="d-flex">
              <div className="image-box">
                <img src="/images/slider-image.png" alt="click here" />
              </div>
              <div className="product-info">
                <p>Coriander Bunch (Kothmir)</p>
                <p>200g</p>
              </div>
            </div>
            <h5>₹22</h5>
          </OrderItemCard> */}
            </div>
            {orderDeliveryAddress && (
              <div className="address">
                <h5>Address</h5>
                <div className="card-header">
                  <div className="address-info">
                    <img src={addressIcon} alt="click here" />
                    <h5>{orderDeliveryAddress?.address_type}</h5>
                  </div>
                </div>
                <div className="card-body">
                  {/* <p>{orderDeliveryAddress?.location_address}</p> */}
                  <p>{orderDeliveryAddress?.reach_address}</p>
                  <p>{orderDeliveryAddress?.address}</p>
                  <p>{`Ph : ${orderDeliveryAddress?.mobile}`}</p>
                </div>
              </div>
            )}
            <div className="payment-details">
              <h5>Bill details</h5>
              <div className="details-info">
                <div>
                  <h5>Item total</h5>
                  <h5>Discount</h5>
                  <h5>Delivery charges</h5>
                  <h5 className="dark">Total Amount</h5>
                </div>
                <div>
                  <span className="d-flex gap-2">
                    {data?.billDetails.salePrice !=
                      data?.billDetails.totalAmountInCart && (
                      <s
                        style={{ color: "var(--gray)" }}
                      >{`${currency_symbol} ${getNumberToFixed(
                        data?.billDetails.totalAmountInCart
                      )}`}</s>
                    )}
                    <h5
                      style={{
                        color: "black",
                      }}
                    >
                      {`${currency_symbol} ${getNumberToFixed(
                        data?.billDetails.salePrice
                      )}`}
                    </h5>
                  </span>
                  <h5 className="special">
                    -{currency_symbol}
                    {getNumberToFixed(data?.billDetails?.totalSavings)}
                  </h5>
                  <h5
                    style={{
                      color:
                        data?.billDetails.deliveryFee === 0
                          ? "var(--primary)"
                          : "var(--gray)",
                    }}
                  >
                    {data?.billDetails.deliveryFee == 0
                      ? "Free"
                      : `${currency_symbol} ${getNumberToFixed(
                          data?.billDetails.deliveryFee
                        )}`}
                  </h5>
                  <h5 className="dark">
                    {`${currency_symbol} ${getNumberToFixed(
                      data?.billDetails.topay
                    )}`}
                  </h5>
                </div>
              </div>
            </div>
            {data?.orders[0]?.order_status !== "Out_For_Delivery" &&
            data?.orders[0]?.order_status !== "Cancelled" &&
            data?.orders[0]?.order_status !== "Failed" &&
            data?.orders[0]?.order_status !== "Accepted" &&
            data?.orders[0]?.order_status !== "Assign_Delivery" &&
            data?.orders[0]?.order_status !== "Pending" ? (
              <ReorderBtn
                onClick={() => {
                  data?.orders[0]?.order_status === "Received" &&
                    setIsModalVisible(true);
                }}
              >
                {data?.orders[0]?.order_status === "Received"
                  ? "Cancel Order"
                  : data?.orders[0]?.order_status === "Delivered"
                  ? "Rate Order"
                  : ""}
              </ReorderBtn>
            ) : (
              ""
            )}
            <CancelOrderPopup
              orderNumber={data?.orders[0]?.ordernumber}
              onConfirm={handleCancel}
              isModalVisible={isModalVisible}
              setIsModalVisible={() => setIsModalVisible()}
            />
            {/* <ReorderBtn>Reorder</ReorderBtn> */}
          </OrderView>
        </>
      )}
    </>
  );
}
