import axios from "axios";

// const ENV = "SANDBOX";
const ENV = "PRODUCTION";

const URLDictionary = {
  SANDBOX: {
    dashboardService: "https://ddashboard.ordrio.com",
    locationService: "https://dlocation.ordrio.com",
    orderService: "https://dorder.ordrio.com",
    customerService: "https://dcustomer.ordrio.com",
    settingService: "https://dsetting.ordrio.com",
    storeService: "https://dstore.ordrio.com",
    cartService: "https://dcart.ordrio.com",
    productService: "https://dproduct.ordrio.com",
    homeService: "https://dhome.ordrio.com",
  },
  PRODUCTION: {
    dashboardService: "https://dashboard.ordrio.com",
    locationService: "https://location.ordrio.com",
    orderService: "https://order.ordrio.com",
    customerService: "https://customer.ordrio.com",
    settingService: "https://setting.ordrio.com",
    storeService: "https://store.ordrio.com",
    cartService: "https://cart.ordrio.com",
    productService: "https://product.ordrio.com",
    homeService: "https://home.ordrio.com",
  },
};

const fileURL =
  ENV === "SANDBOX"
    ? "https://dservice.ordrio.com/public"
    : "https://service.ordrio.com/public";

const dashboardService = axios.create({
  baseURL: URLDictionary[ENV].dashboardService,
});

const locationService = axios.create({
  baseURL: URLDictionary[ENV].locationService,
});

const orderService = axios.create({
  baseURL: URLDictionary[ENV].orderService,
});

const customerService = axios.create({
  baseURL: URLDictionary[ENV].customerService,
});

const settingService = axios.create({
  baseURL: URLDictionary[ENV].settingService,
});

const storeService = axios.create({
  baseURL: URLDictionary[ENV].storeService,
});

const cartService = axios.create({
  baseURL: URLDictionary[ENV].cartService,
});

const productService = axios.create({
  baseURL: URLDictionary[ENV].productService,
});

const homeService = axios.create({
  baseURL: URLDictionary[ENV].homeService,
});

export {
  fileURL,
  locationService,
  orderService,
  customerService,
  settingService,
  storeService,
  cartService,
  productService,
  homeService,
  dashboardService,
};
