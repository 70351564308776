import React from "react";
import { Card } from "./styles";

const TransactionCard = ({ image, loyalty, heading, subheading, balance }) => {
  return (
    <>
      <Card>
        <div className="d-flex align-items-center">
          <div className="cashback-logo">
            <img className={loyalty} src={image} alt="click here" />
          </div>
          <div>
            <h6>{heading}</h6>
            <p>{subheading}</p>
          </div>
        </div>
        <h4>{balance}</h4>
      </Card>
    </>
  );
};

export default TransactionCard;
