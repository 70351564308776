import styled from "styled-components";
import { Dropdown } from "antd";

export const QuantityBtn = styled.div`
  max-width: 9.5em;
  padding: 0.2em 0em;
  height: 55px;
  border: 1px solid var(--primary);
  background: var(--gray-secondary);
  border-radius: 0.3125em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2.286em;
  span {
    font-size: 1.435em;
    font-weight: 500;
    color: var(--primary);
    &:first-child,
    &:last-child {
      cursor: pointer;
    }
  }
`;
export const OptionDrop = styled(Dropdown)`
  // width: 111px;
  // height: 34px;
  padding: 0.5em;
  background: var(--primary);
  color: var(--white);
  border-radius: 0.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: 0;
  box-shadow: none;
  border: 0;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
  svg {
    font-size: 1.375em;
  }
`;
export const OptionButton = styled.button`
  // width: 111px;
  // height: 34px;
  padding: 0.5em;
  background: var(--primary);
  color: var(--white);
  border-radius: 0.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: 0;
  box-shadow: none;
  border: 0;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
  svg {
    font-size: 1.375em;
  }
`;

export const ProductArea = styled.div`
  // position: relative;
  margin-top: 1.15em;
  .fixedScroll {
    height: 760px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .product-location {
    font-size: 1.286em;
    font-weight: 400;
    display: flex;
    p {
      color: var(--black);
      cursor: pointer;
    }
    span {
      color: var(--gray-ordinary);
    }
  }
  & {
    @media (min-width: 992px) {
      & > .ant-row {
        border-bottom: 1px solid var(--grey-legendary);
        padding-bottom: 1.75em;
      }
    }
    @media (max-width: 992px) {
      & > .ant-row {
        .fixedScroll {
          border-bottom: 1px solid var(--grey-legendary);
          padding-bottom: 1.75em;
        }
      }
    }
    @media (max-width: 576px) {
      .product-location {
        font-size: 1em;
      }
    }
    @media (max-width: 1060px) {
      .product-location {
        flex-direction: column;

        & > p {
          margin-bottom: 0;
        }
      }
    }
  }
`;
export const ImageSection = styled.div`
  // position: absolute;

  display: flex;
  & {
    @media (max-width: 576px) {
      display: block;
      & > div {
        &:first-child {
          display: flex;
          align-items: baseline;
        }
      }
    }
  }
`;
export const ImageBox = styled.div`
  /* width: 104px;
  height: 95px; */
  padding: 0.7em 1em;
  border: 1px solid var(--white-smoke-primary);
  border-radius: 1.075em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-bottom: 1em;
  img {
    width: 70px;
    height: 70px;
  }
  & {
    @media (max-width: 576px) {
      padding: 0.6em 0.65em;
      /* width: 75px;
      height: 75px; */
      margin-right: 0.36em;
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
`;
export const More = styled.div`
  /* width: 32px;
  height: 32px; */
  width: min-content;
  border: 1px solid var(--black-secondary);
  border-radius: 50%;
  padding: 0.36em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 0 auto;
  cursor: pointer;
  svg {
    font-size: 1.5em;
  }
  & {
    @media (max-width: 576px) {
      /* width: 25px;
      min-width: 25px;
      height: 25px; */
      padding: 0.315em 0.415em;
      margin: 0 0.1em 0 auto;
      svg {
        font-size: 1.215em;
        transform: rotate(-90deg);
        vertical-align: -0.125em;
      }
    }
  }
`;
export const ShownImage = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--white-smoke-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  & {
    @media (max-width: 1400px) {
      img {
        width: 100%;
        height: 420px;
      }
    }
  }
`;
export const ProductDescription = styled.div`
  margin: 4em 0 1.15em;
  h4 {
    font-weight: 600;
    color: var(--black);
  }
  h5, p {
    font-weight: 300;
    line-height: 1.75em;
    color: var(--gray);
    margin-bottom: 1.5em;
  }

  li, ul {
    font-weight: 300;
    color: var(--gray);
  }
`;
export const ProductInfo = styled.div`
  // margin-top: 3.75em;
  .discount-label {
    background-color: var(--grey-primary);
    padding: 0.575em 1.15em;
    margin-bottom: 2em;
    width: max-content;
    p {
      font-size: 1.0715em;
      color: var(--primary);
      margin: 0;
    }
  }
  .product-price {
    display: flex;
    align-items: baseline;
    h3 {
      font-weight: 400;
      color: var(--black);
      margin-right: 0.375em;
    }
    h5 {
      font-weight: 300;
      color: var(--gray-primary);
      text-decoration: line-through;
    }
  }
  .membership-text {
    font-size: 1.15em;
    font-weight: 300;
    color: var(--primary);
    margin-bottom: 1.7395em;
  }
  .add-btn {
    /* width: 140px;
    height: 50px; */
    height: auto;
    background: var(--primary);
    border-radius: 0.36em;
    border: 0px;
    outline: 0px;
    box-shadow: none;
    margin-bottom: 2.286em;
    padding: 0.86em 3.6em;
    span {
      font-size: 1.435em;
      font-weight: 400;
      color: var(--white);
    }
  }
  .product-review {
    color: var(--gray);
    font-weight: 300;
    display: block;
    margin-bottom: 0.735em;
    svg {
      font-size: 1.375em;
      color: var(--primary);
    }
  }
  .product-rate {
    font-size: 1.286em;
    font-weight: 400;
    color: var(--primary) !important;
  }
  & {
    @media (min-width: 1200px) {
      .product-review {
        font-size: 1.575em;
      }
    }
  }
`;
export const RelatedProduct = styled.div`
  position: relative;
  h2 {
    font-size: 2.125em;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 3em;
  }
`;
export const ProductQuantity = styled.div`
  /* width: 115px;
  height: 47px; */
  padding: 0.715em 1em;
  border: 1px solid var(--grey-legendary);
  border-radius: 0.36em;
  transition: all 250ms ease-in-out 0s;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  .ant-radio-wrapper {
    margin: 0 !important;
    .ant-radio-inner {
      width: 26px;
      height: 26px;
      border: 1px solid var(--grey-legendary);
      position: relative;
      &::after {
        padding: 1.8em;
        position: absolute;
        top: -50%;
        left: -50%;
        background: var(--primary);
        border-radius: 50%;
        margin-block-start: -1px;
        margin-inline-start: -1px;
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        background-color: unset !important;
      }
    }
    & > span:last-child {
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
    }
  }
  &:first-child {
    margin-right: 1.0715em !important;
  }
`;
export const Ratting = styled.div`
  h4 {
    font-size: 1.715em;
    color: var(--black);
    margin-bottom: 0.835em;
  }
  div {
    display: flex;
    align-items: center;
    h1 {
      font-size: 2.575em;
      font-weight: 600;
      color: var(--black);
      margin: 0 0.28em 0.25em 0;
    }
    /* svg {
      font-size: 28px;
      color: var(--yellow);
      cursor: pointer;
      &:last-child {
        color: var(--grey-legendary);
      }
    } */
    .ant-rate {
      height: 100%;
      margin-bottom: 0.45em;
      li {
        color: var(--white-smoke-legendary);
        margin-right: 0.6em !important;
        svg {
          font-size: 1.5em;
        }
        &.ant-rate-star-full {
          color: var(--yellow) !important;
        }
      }
    }
  }
`;
export const StyledButton = styled.button`
  height: auto;
  background: var(--primary);
  border-radius: 0.36em;
  border: 0px;
  outline: 0px;
  box-shadow: none;
  margin-bottom: 2.286em;
  padding: 0.5em 2em;
  font-size: 1.435em;
  font-weight: 400;
  color: var(--white);

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    margin:0
  }
`;
