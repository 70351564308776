import { fileURL } from "helpers/baseURL";
import { useEffect, useState } from "react";
import DefImg from "../../assets/images/default_image.svg";

import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
} from "react-icons/md";

const styles = {
  horizontalScroll: {
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  },

  horizontalContainer: {
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "thin",
    justifyContent: "center",
    margin: "10px",
  },

  horizontalItem: {
    border: "1px solid #ddd",
    borderRadius: "5px",
    marginRight: "10px",
    padding: "5px",
    width: "100px",
    cursor: "pointer",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },

  horizontalImage: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    alignSelf: "center",
  },

  verticalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "hidden",
    padding: 0,
    margin: 0,
  },

  verticalItem: {
    border: "1px solid #ddd",
    borderRadius: "5px",
    marginBottom: "10px",
    padding: "5px",
    cursor: "pointer",
    width: "100px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },

  verticalImage: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    alignSelf: "center",
  },

  button: {
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid var(--black-secondary)",
    borderRadius: " 50%",
    padding: "0.2em",
    alignSelf: "center",
  },
  upperArrow: {
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid var(--black-secondary)",
    borderRadius: " 50%",
    padding: "0.2em",
    alignSelf: "center",
    marginBottom: "10px",
  },
  selectedImage: {
    border: "2px solid #28a745",
  },
};

const ProductList = ({ products, type, selectedImgPath }) => {
  const [startIdx, setStartIdx] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const itemsToShow = type === "horizontal" ? 4 : 3;
  const totalProducts = products?.length;

  useEffect(() => {
    if (products) {
      setSelectedImageIndex(0);
    }
  }, [products]);

  const handleShowMoreClick = () => {
    setStartIdx((prevStartIdx) =>
      Math.min(prevStartIdx + 1, totalProducts - itemsToShow)
    );
  };

  const handleShowLessClick = () => {
    setStartIdx((prevStartIdx) => Math.max(prevStartIdx - 1, 0));
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <div
      style={
        type === "horizontal"
          ? styles.horizontalScroll
          : styles.verticalContainer
      }
    >
      {startIdx > 0 && (
        <button onClick={handleShowLessClick} style={styles.upperArrow}>
          {type === "horizontal" ? (
            <MdKeyboardArrowLeft />
          ) : (
            <MdKeyboardArrowUp />
          )}
        </button>
      )}
      {type === "horizontal" ? (
        <div style={styles.horizontalContainer}>
          {products
            .slice(startIdx, startIdx + itemsToShow)
            .map((product, index) => (
              <div
                key={product.id}
                style={{
                  ...styles.horizontalItem,
                  ...(selectedImageIndex === index ? styles.selectedImage : {}),
                }}
                onClick={() => {
                  handleImageClick(index);
                  selectedImgPath(product?.path);
                }}
              >
                <img
                  src={`${fileURL}${product?.path}`}
                  alt={`Product ${product.id}`}
                  style={styles.horizontalImage}
                />
              </div>
            ))}
        </div>
      ) : (
        <div style={styles.verticalContainer}>
          {products
            .slice(startIdx, startIdx + itemsToShow)
            .map((product, index) => (
              <div
                key={product.id}
                style={{
                  ...styles.verticalItem,
                  ...(selectedImageIndex === index ? styles.selectedImage : {}),
                }}
                onClick={() => {
                  handleImageClick(index);
                  selectedImgPath(product?.path);
                }}
              >
                <img
                  src={`${fileURL}${product?.path}`}
                  alt={`Product ${product.id}`}
                  style={styles.verticalImage}
                  onError={(e) => {
                    e.target.src = DefImg;
                    e.onerror = null;
                  }}
                />
              </div>
            ))}
        </div>
      )}

      {startIdx + itemsToShow < totalProducts && (
        <button onClick={handleShowMoreClick} style={styles.button}>
          {type === "horizontal" ? (
            <MdKeyboardArrowRight />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </button>
      )}
    </div>
  );
};

export default ProductList;
