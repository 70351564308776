import React from "react";
import { Card } from "./styles";

const AddressCard = ({
  heading,
  subheading,
  text,
  image,
  showDeleteModal,
  showEditModal,
  reachAddress,
  address
}) => {
  return (
    <>
      <Card>
        <div className="card-header">
          <div>
            <p onClick={showEditModal}>Edit</p>
            <p onClick={showDeleteModal}>Delete</p>
          </div>
          <div>
            <img src={image} alt="click here" />
            <h5>{heading}</h5>
          </div>
        </div>
        <div className="card-body">
          {/* <p>{subheading}</p> */}
          <p>{reachAddress}</p>
          <p>{address}</p>
          <p>{text}</p>
        </div>
      </Card>
    </>
  );
};

export default AddressCard;
