import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Head } from "./styles";
import { useSelector } from "react-redux";

const utsavkartOrigin = "utsavkart.com";

const Header = () => {
  const { business_name } = useSelector((state) => state.app.data.refType);
  const links = useSelector((state) => state.app.data?.DownloadApp);
  const slogan = useSelector((state) => state.app.data?.slogan);
  return (
    <>
      {window.location.host !== "utsavkart.com" && (
        <Head
          style={{
            height: "max-height",
          }}
        >
          <Container>
            {!document.location.origin.includes(utsavkartOrigin) && (
              <Fragment>
                <p>{slogan}</p>
                <a href={links?.android} target="_blank">
                  Download {business_name} app now
                  <span style={{ marginLeft: "0.75em" }}>
                    <MdOutlineKeyboardArrowRight size={22} />
                  </span>
                </a>
              </Fragment>
            )}
          </Container>
        </Head>
      )}
    </>
  );
};

export default Header;
