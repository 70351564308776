import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Collapse } from "antd";
import { Container } from "react-bootstrap";
import DashboardHeader from "components/DashboardHeader";
import ProductLinks from "components/ProductLinks";
import ItemCard from "components/Cards/ItemCard";
import { ItemCardsData } from "data/ItemCardsData";
import { ItemListArea } from "./index.styled";
import DealCard from "components/Cards/DealCard";
import { BreadCardsData } from "data/BreadCardsData";
import DashboardFooter from "components/DashboardFooter";
import Footer from "components/Footer";

import NewDashboardFooter from "components/NewDashboardFooter";
import NewFooter from "components/NewFooter";

import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Empty from "components/empty";
import {
  getCategory,
  getAllCategory,
  getSubCategory,
  getProducts,
  getMoreProducts,
  featuredItems,
  setSelectedCategory,
} from "redux/action/appActions";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "shimmer-react";
// import { Empty } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Fragment } from "react";
import DefaultImage from "../../assets/images/default_image.svg";
import { useCallback } from "react";
import { throttler } from "helpers/helpers";
import { fileURL } from "helpers/baseURL";

const { Panel } = Collapse;

export default function ItemList() {
  const subcategoryParent = useRef();
  const subcategoryRef = useRef();
  const productFieldRef = useRef();

  const isFeaturedCategory = window.location.href.includes(
    "featured-categories"
  );
  const isFeaturedProduct = window.location.href.includes("featured-products");

  const { featuredItem = null } = useParams();
  const [featuredCategory, setFeaturedCat] = useState([]);

  const {
    categoryLoading,
    subCategories,
    categoryLast_id = "",
  } = useSelector((state) => state.app);
  const products = useSelector((state) => state.app.products);
  const categories = useSelector((state) => state.app.categories);
  const selectedCategory = useSelector((state) => state.app.selectedCategory);
  const message = useSelector((state) => state.app.message);
  const moreData = useSelector((state) => state.app.moreData);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const CategoriesWithProducts = useSelector(
    (state) => state.app.nestedCategoriesWithProducts
  );

  const user = useSelector((state) => state.app.data);
  const [selected, setSelected] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const productId = location.pathname.split("/")[2];
  const navigate = useNavigate();

  useEffect(() => {
    if (selected) {
      dispatch({ type: "SET_MESSAGE", payload: "" });
      // dispatch(getProducts(selected));
    }
  }, [selected]);

  useEffect(() => {
    if (!isFeaturedCategory && !isFeaturedProduct) {
      dispatch({ type: "GET_MORE_DATA", payload: true });
      dispatch(
        getSubCategory({
          cursor: "",
          perPage: 20,
          nested: false,
          subcategory: false,
          id: location.pathname.split("/")[2],
        })
      );
    }
  }, [location]);

  const onMainSectionScroll = (e) => {
    if (!subcategoryRef.current) {
      return;
    }
    const ele = document.getElementsByClassName("bottom-bar-holder");
    if (window.innerWidth < 913) {
      // if (subcategoryParent.current.clientHeight - e.target.scrollTop < 450) {
      //   subcategoryRef.current.style.position = "absolute";
      //   subcategoryRef.current.style.bottom = 0;
      //   subcategoryRef.current.style.top = "auto";
      //   if (ele) {
      //     subcategoryRef.current.style.marginBottom = ele?.[0]?.clientHeight;
      //   }
      //   return;
      // }
      // if (ele) {
      //   subcategoryRef.current.style.marginBottom = ele?.[0]?.clientHeight;
      // }
      // subcategoryRef.current.style.width = `${subcategoryRef?.current?.parentElement?.offsetWidth}px`;
      // subcategoryRef.current.style.position = e.target.scrollTop
      //   ? "fixed"
      //   : "relative";
      // subcategoryRef.current.style.bottom = "auto";
      // subcategoryRef.current.style.top = e.target.scrollTop > 0 ? 80 : "auto";
    } else {
      // if (
      //   subcategoryRef.current.clientHeight -
      //     e.target.scrollTop +
      //     subcategoryRef.current.offsetHeight <
      //   0
      // ) {
      //   subcategoryRef.current.style.position = "absolute";
      //   subcategoryRef.current.style.bottom = 0;
      //   subcategoryRef.current.style.top = "auto";
      //   return;
      // }
      // console.log(e.target.scrollTop);
      // subcategoryRef.current.style.width = `${subcategoryRef?.current?.parentElement?.offsetWidth}px`;
      // subcategoryRef.current.style.top =
      //   e.target.scrollTop > 50 ? 0 : `${e.target.scrollTop - 50}px`;
      // subcategoryRef.current.style.position = "absolute";
      // e.target.scrollTop > 148 ? "absolute" : "relative";
      // subcategoryRef.current.style.top = e.target.scrollTop > 150 ? 0 : "auto";
    }
  };

  const onResize = (e) => {
    subcategoryRef.current.style.width = `${subcategoryRef?.current?.parentElement?.offsetWidth}px`;
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    document.body.addEventListener("scroll", onMainSectionScroll);
    return () => {
      window.removeEventListener("resize", onResize);
      document.body.removeEventListener("scroll", onMainSectionScroll);
    };
  }, []);

  const getFeatured = useCallback(async () => {
    if (isFeaturedCategory || isFeaturedProduct) {
      let params = {};
      try {
        params = JSON.parse(atob(featuredItem));
        const res = await featuredItems(params, user.guestToken);
        setFeaturedCat(res.data || []);
        setSelected(res.data[0].id);
        dispatch(
          getSubCategory({
            cursor: "",
            perPage: 20,
            nested: false,
            subcategory: true,
            id: res.data[0].id,
          })
        );
        dispatch(
          setSelectedCategory({
            title: res.data[0].name,
            id: res.data[0].id,
            subcategory: true,
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    getFeatured();
  }, [isFeaturedCategory, isFeaturedProduct]);

  const fetchMoreData = () => {
    dispatch(
      getMoreProducts({
        cursor: products.pop().id,
        perPage: 20,
        nested: false,
        subcategory: selectedCategory?.subcategory || false,
        id: location.pathname.split("/")[2],
      })
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:24,
        height:24,
      }}
      spin
    />
  );
  return (
    <Fragment>
      <ItemListArea>
        <DashboardHeader />
        <ProductLinks links={categories} />
        <Container>
          <Row gutter={30} className=" position-relative">
            {(categoryLoading ||
              !!subCategories.length ||
              !!featuredCategory.length) && (
              <Col
                lg={6}
                xs={6}
                className="border p-0 "
                ref={subcategoryParent}
              >
                {categoryLoading ? (
                  Array(10)
                    .fill(1)
                    .map((item, i) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.53em 1em",
                          borderBottom:
                            "1px solid var(--white-smoke-secondary)",
                        }}
                      >
                        <ShimmerThumbnail
                          height={100}
                          width={100}
                          className="m-0"
                          rounded
                        />
                        <ShimmerBadge width={100} />
                      </div>
                    ))
                ) : (
                    isFeaturedCategory
                      ? featuredCategory.length
                      : subCategories?.length
                  ) ? (
                  <div
                    className="position-relative"
                    style={{
                      height: "100%",
                      position: "sticky",
                    }}
                  >
                    <div
                      className="d-flex flex-column flex-nowrap subcategory-display p-0"
                      ref={subcategoryRef}

                      // style={{
                      //   width: subcategoryParent?.current?.offsetWidth
                      //     ? `${subcategoryParent?.current?.offsetWidth}px`
                      //     : "auto",
                      // }}
                    >
                      {/* <Col
                        xs={24}
                        className="d-flex flex-column flex-nowrap subcategory-display p-0"
                        ref={subcategoryRef}
                        // style={{
                        //   width: subcategoryParent?.current?.offsetWidth
                        //     ? `${subcategoryParent?.current?.offsetWidth}px`
                        //     : "auto",
                        // }}
                      > */}
                      {(isFeaturedCategory
                        ? featuredCategory
                        : subCategories
                      )?.map((item, i) => {
                        const { icon_image, name, id } = item;
                        return (
                          <ItemCard
                            key={i}
                            image={icon_image}
                            heading={name}
                            id={id}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        );
                      })}
                      {/* </Col> */}
                    </div>
                  </div>
                ) : (
                  <Fragment />
                )}
              </Col>
            )}
            {/* <Col lg={6} xs={6} /> */}
            <Col
              ref={productFieldRef}
              lg={!subCategories.length && !featuredCategory.length ? 24 : 18}
              xs={18}
              className="fixedScroll justify-content-center border"
              style={{ height: "100%" }}
            >
              {products.length ? (
                <>
                  <h4
                    className="my-3 "
                    style={{ marginRight: "13px", marginLeft: "13px" }}
                  >
                    {selectedCategory.title}
                  </h4>
                  <InfiniteScroll
                    dataLength={products.length}
                    next={throttler(fetchMoreData, 300)}
                    hasMore={!!categoryLast_id} // Replace with a condition based on your data source
                    loader={
                      <div style={{ margin: "1em 0", textAlign: "center" }}>
                        <Spin indicator={antIcon} />
                      </div>
                    }
                    endMessage={
                      <p style={{ textAlign: "center", marginTop: 20 }}>
                        <b style={{ color: "var(--gray)" }}>
                          Yay! You have seen it all
                        </b>
                      </p>
                    }
                    height={800}
                    scrollableTarget="scroller-div"
                  >
                    <div>
                      <Row gutter={[16, 24]} id="">
                        {products?.map((item, i) => {
                          const {
                            product_variations,
                            name,
                            is_subscription,
                            location_id,
                            _id,
                            price_variation,
                          } = item;

                          const offText = Math.round(
                            product_variations[0]?.product_batches[0]
                              ?.discount_percentage
                          );

                          return (
                            <Col key={i} xxl={4} xl={6} lg={8} xs={12}>
                              <div style={{ marginBottom: "0.575em" }} key={i}>
                                <DealCard
                                  id={_id}
                                  offText={
                                    offText === 0 ? 0 : `${offText}% OFF`
                                  }
                                  brandLogo={"/images/express-logo.svg"}
                                  brandText={"Express"}
                                  brandImage={`${fileURL}${
                                    product_variations?.[0]?.product_images[0]
                                      ?.path || item?.product_images[0]?.path
                                  }`}
                                  footerText={`${currency_symbol}${product_variations[0]?.product_batches[0]?.membership_price?.toFixed(
                                    "0" || 0
                                  )} Membership Price`}
                                  heading={name}
                                  subheading={
                                    product_variations[0]?.name +
                                      product_variations[0]?.unit_of_measure ||
                                    "not found"
                                  }
                                  discount={
                                    product_variations[0]?.product_batches[0]
                                      ?.sale_price || 0
                                  }
                                  price={product_variations[0]?.product_batches[0]?.mrp.toFixed(
                                    2
                                  )}
                                  option={price_variation}
                                  product_variations={product_variations}
                                  quantity={0}
                                  subscribe={is_subscription}
                                  data={{
                                    product_id: _id,
                                    variation_id: product_variations[0]?.id,
                                    batch_id:
                                      product_variations[0]?.product_batches[0]
                                        ?.id,
                                    location_id: location_id,
                                    type: product_variations?.[0]?.type,
                                    membership_plan_id: "",
                                  }}
                                />
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </InfiniteScroll>
                </>
              ) : message === "not found" ? (
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Empty /> */}
                  <Empty
                    title="No item found!"
                    description="We can't find any item matching your search"
                    buttonText="Back to Home"
                    icon="Not_Found"
                    buttonCallback={() => navigate("/")}
                  />
                </div>
              ) : (
                <Row gutter={[16, 24]}>
                  {Array(10)
                    .fill(1)
                    .map((item, i) => (
                      <Col key={i}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          <ShimmerThumbnail
                            height={100}
                            width={200}
                            className="m-0"
                            rounded
                          />
                          <ShimmerBadge width={150} />
                          <ShimmerBadge width={100} />
                          <ShimmerBadge width={50} />
                        </div>
                      </Col>
                    ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </ItemListArea>
      {/* <DashboardFooter />
      <Footer /> */}

      <div className="d-sm-none d-xs-none d-md-none d-lg-block">
        <NewDashboardFooter />
        <NewFooter />
      </div>

      <Collapse
        className="border-0 border-b-0 d-md-block d-lg-none d-xl-none"
        expandIconPosition="end"
      >
        <Panel header={<p className="font-large">Useful Links</p>}>
          <NewDashboardFooter />
          <NewFooter />
        </Panel>
      </Collapse>
    </Fragment>
  );
}
