import React, { useState, useEffect, useRef } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import { Col, Row, Radio, message } from "antd";
import { CgShoppingBag } from "react-icons/cg";
import DashboardHeader from "components/DashboardHeader";
import DeliveryCard from "components/Cards/DeliveryCard";
import BasketCard from "components/Cards/BasketCard";
import AddPhoneNumberModal from "components/Modals/AddPhoneNumberModal";
import VerificationModal from "components/Modals/VerificationModal";
import RateModal from "components/Modals/RateModal";
import DeliveryModal from "components/Modals/DeliveryModal";
import OrderModal from "components/Modals/OrderModal";
import {
  AddNumber,
  AddNumberBtn,
  ConfirmDelivery,
  CouponBtn,
  FreeDelivery,
  MinOrderMessage,
  NewAddressCard,
  PaymentArea,
  SelectCard,
  ShippingCard,
} from "./index.styled";
import { getCartItems, placedOrder, userLoaded } from "redux/action/appActions";
import { useDispatch, useSelector } from "react-redux";
import BillDetails from "./BillDetails";
import SubTotal from "./SubTotal";
import Tip from "./Tip";
import Coupon from "./Coupon";
import Address from "./Address";
import Type from "./Type";
import PaymentOption from "./PaymentOption";
import PaymentDetail from "./PaymentDetail";
import InfoModal from "components/Modals/InfoModal";
import setAuthToken from "helpers/setAuthToken";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Empty from "components/empty";
import IFrameModal from "components/Modals/iFrameModal";
import { useNavigate } from "react-router-dom";
import { BsBoxArrowInRight } from "react-icons/bs";
import LoginModal from "components/Modals/LoginModal";
import { TOGGLE_LOGIN_MODAL } from "redux/Constants";
import { Fragment } from "react";
import { fileURL } from "helpers/baseURL";
import "./cart.css";
import MobileAddressSelector from "./MobileAddressSelector";
import PaymentTypeSelector from "./PaymentTypeSelector";

const Cart = () => {
  const cartBottomBarRef = useRef();
  const mobileBottomBarRef = useRef();
  const cartItemListRef = useRef();
  const user = useSelector((state) => state.app.data);
  const cart = useSelector((state) => state.app.cart);

  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const [deliveryRadioValue, setDeliveryRadioValue] = useState("Home Delivery");
  const [loginResponse, setLoginResponse] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const [deliveryOptionDetail, setDeliveryOptionDetail] = useState(null);
  const [selectedDeliveryDetail, setSelectedDeliveryDetail] = useState(null);
  const [deliveryType, setDeliveryType] = useState("standard_delivery");
  const [selectedAddress, setSelectedAddress] = useState(
    cart?.customerAddress?.customer_address?.find(
      (item) => item?.is_default === 0
    )
  );
  console.log("the selected delivery details", selectedDeliveryDetail);
  const [paymentMethods, setPaymentMethods] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("COD");
  const [iFrameModal, setiFrameModal] = useState(false);
  const [paymentLink, setPaymentLink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mobileAddressPopup, setMobileAddressPopup] = useState(false);
  const [mobilePaymentPopup, setMobilePaymentPopup] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMobileAddressSelect = () => {
    setMobileAddressPopup((prev) => !prev);
  };

  const togglePaymentModal = () => {
    setMobilePaymentPopup((prev) => !prev);
  };

  useEffect(() => {
    if (selectedAddress) {
      dispatch(getCartItems(setLoading, selectedAddress));
    } else {
      setLoading(true);
      dispatch(getCartItems(setLoading, selectedAddress));
    }
  }, [selectedAddress]);

  const defaultAddress = cart?.customerAddress?.customer_address?.find(
    (item) => item?.is_default === 0
  );

  useEffect(() => {
    selectedAddress === undefined && setSelectedAddress(defaultAddress);
  }, [defaultAddress]);

  const orderPlaced = async () => {
    setPaymentLoader(true);
    const data = {
      payment_type: selectedPaymentMethod,
      order_channel: "website",
      version: "1.0.0",
      order_amount: cart?.cart?.paymentDetails?.totalAmountInCart,
      discount_total: cart?.cart?.paymentDetails?.savings,
      delivery_charge: selectedDeliveryDetail.delivery_charges,
      actual_amount: paymentMethods.billDetails.topay,
      order_type: selectedDeliveryDetail.type,
      address_id: selectedAddress.id,
      channel: "App",
      promocode_id: "",
      deductedWalletAmount: "",
      deductedloyaltyAmount: "",
      generate_payment_link: true,
      is_order_split: false,
      delivery_type: selectedDeliveryDetail.type,
      tax_amount: 0,
      selectDate: "",
      selectTime: "",
      payment_details: JSON.stringify(cart.cart.paymentDetails),
    };
    const res = await placedOrder(data);
    if (res.status) {
      if (selectedPaymentMethod === "online") {
        window.location.href = res.data[0]?.payResult.data.payment_link;
        setPaymentLink(res.data[0]?.payResult.data.payment_link);
      } else {
        setOrderModal(true);
      }
    } else {
      dispatch(getCartItems(setLoading));
      message.error("Failed to place order");
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        height: 50,
      }}
      spin
    />
  );

  const onBottomButton = () => {
    if (cart.cart.paymentDetails.minAmount > cart.cart.paymentDetails.topay) {
      return navigate("/");
    } else if (!selectedAddress) {
      toggleMobileAddressSelect();
    } else if (selectedAddress && !selectedDeliveryDetail) {
      setDeliveryModal(true);
    } else {
      if (selectedPaymentMethod) {
        orderPlaced();
      } else {
        message.error("Please Select Delivery Method!");
      }
    }
  };

  const getButtonLabel = function () {
    if (cart.cart.paymentDetails.minAmount > cart.cart.paymentDetails.topay) {
      return "Continue Shopping";
    }

    return !selectedAddress
      ? "Select Address"
      : !selectedDeliveryDetail
      ? "Select delivery option"
      : selectedPaymentMethod && selectedPaymentMethod?.toLowerCase() === "cod"
      ? `Place Order | ${currency_symbol}${
          cart?.cart?.paymentDetails?.topay?.toFixed(2) || 0
        }`
      : `Make Payment | ${currency_symbol}${
          cart?.cart?.paymentDetails?.topay?.toFixed(2) || 0
        } `;
  };

  return (
    <Fragment>
      <DashboardHeader />
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          {cart?.cart?.cData?.length ? (
            <div className="positon-relative">
              <Container
                style={{
                  marginTop: 10,
                  marginBottom:
                    cartBottomBarRef?.current?.clientHeight + 20 || "12vh",
                }}
              >
                <Row gutter={[{ lg: 40 }]}>
                  <Col
                    className="cart-container-address"
                    xxl={15}
                    xl={14}
                    md={12}
                    xs={24}
                    style={{ height: "auto" }}
                  >
                    {selectedDeliveryDetail ? (
                      <PaymentDetail
                        selectedDeliveryDetail={selectedDeliveryDetail}
                        selectedAddress={selectedAddress}
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedAddress={setSelectedAddress}
                      />
                    ) : user?.SplashScreen?.customer?.mobile ||
                      user?.SplashScreen?.customer?.email ? (
                      <Fragment>
                        <Type
                          deliveryRadioValue={deliveryRadioValue}
                          setDeliveryRadioValue={(value) =>
                            setDeliveryRadioValue(value)
                          }
                        />
                        <Address
                          setSelectedAddress={setSelectedAddress}
                          deliveryRadioValue={deliveryRadioValue}
                          selectedAddress={selectedAddress}
                        />
                      </Fragment>
                    ) : (
                      <div
                        style={{
                          height: "80vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Empty
                          title="Please Login to Continue"
                          description="login with number or Email to access the cart."
                          buttonText="Login"
                          icon="arrow-box"
                          // buttonCallback={() => setPhoneNumberModal(true)}
                          buttonCallback={() =>
                            dispatch({ type: TOGGLE_LOGIN_MODAL })
                          }
                        />
                      </div>
                    )}
                  </Col>
                  <Col xxl={9} xl={10} md={24} xs={24}>
                    <ShippingCard className="cart-items-container-parent">
                      <AddNumber className="cart-order-button">
                        {!cart?.cart?.cData?.length ? (
                          <Fragment>
                            <div
                              className="d-flex align-items-center"
                              style={{ marginBottom: "2em" }}
                            >
                              <div className="logo">
                                <CgShoppingBag />
                              </div>
                              <div>
                                <h5>1 Item Unavailable</h5>
                                <p>
                                  Try alternative or clear cart to place an
                                  order
                                </p>
                              </div>
                            </div>
                            <AddNumberBtn className={"active"}>
                              Remove unavailable items
                            </AddNumberBtn>
                          </Fragment>
                        ) : !cart?.customerAddress?.mobile &&
                          !cart?.customerAddress?.email ? (
                          <>
                            {/* <div
                            className="d-flex align-items-center"
                            style={{ marginBottom: "2em" }}
                          >
                            <div className="logo">
                              <BsBoxArrowInRight />
                            </div>
                            <div>
                              <h5>Please Login to Continue</h5>
                              <p>
                              login with number or Email to access the cart.
                              </p>
                            </div>
                          </div>
                          <AddNumberBtn
                            onClick={() => setPhoneNumberModal(true)}
                            className={"active"}
                          >
                            Login
                          </AddNumberBtn> */}
                          </>
                        ) : selectedDeliveryDetail ? (
                          <>
                            <ConfirmDelivery>
                              <div className="d-flex align-items-center">
                                {/* <div className="imageBox">
                                <img
                                  src={
                                    deliveryOptionDetail?.standard_delivery
                                      ?.length
                                      ? "/images/standard-delivery-logo.svg"
                                      : "/images/express-delivery-logo.svg"
                                  }
                                  alt="click here" 
                                />overflow-y: scroll;
                              </div> */}
                                <div className="info">
                                  <p>
                                    {deliveryOptionDetail?.delivery_date}
                                    {/* Deliver today{" "}
                                  <strong>6:00 AM - 7:00 AM</strong> */}
                                  </p>
                                  {/* <p>
                                  Deliver Charges{" "}
                                  <span>
                                    {deliveryOptionDetail?.deliveryFee > 0
                                      ? deliveryOptionDetail?.deliveryFee
                                      : "FREE"}
                                  </span>
                                </p> */}
                                </div>
                              </div>
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() => setDeliveryModal(true)}
                              >
                                Change
                              </p>
                            </ConfirmDelivery>
                            <AddNumberBtn
                              onClick={() => {
                                if (selectedPaymentMethod) {
                                  orderPlaced();
                                } else {
                                  message.error(
                                    "Please select delivery method!"
                                  );
                                }
                              }}
                              className={"active"}
                              disabled={paymentLoader}
                            >
                              {selectedPaymentMethod &&
                              selectedPaymentMethod?.toLowerCase() === "cod"
                                ? "Place Order"
                                : "Make Payment"}
                            </AddNumberBtn>
                          </>
                        ) : (
                          <div
                            style={
                              cart.cart.paymentDetails.orderMsg
                                ? {
                                    backgroundColor:
                                      "var(--white-smoke-secondary)",
                                    borderRadius: "0.715em",
                                  }
                                : {}
                            }
                          >
                            <AddNumberBtn
                              disabled={
                                cart.cart.paymentDetails.minAmount >
                                cart.cart.paymentDetails.topay
                              }
                              onClick={() =>
                                selectedAddress
                                  ? setDeliveryModal(true)
                                  : message.error("please select address!")
                              }
                              className={"active"}
                            >
                              Select delivery option
                            </AddNumberBtn>

                            <MinOrderMessage>
                              <p>{cart.cart.paymentDetails.orderMsg}</p>
                            </MinOrderMessage>
                          </div>
                        )}

                        <AddPhoneNumberModal
                          isModalOpen={phoneNumberModal}
                          setIsModalOpen={setPhoneNumberModal}
                          setVerificationModal={setVerificationModal}
                          setUserNumber={(item) => setUserPhoneNumber(item)}
                          setLoginResponse={setLoginResponse}
                        />
                        <VerificationModal
                          isModalOpen={verificationModal}
                          setIsModalOpen={setVerificationModal}
                          setLoginModal={setPhoneNumberModal}
                          userPhoneNumber={userPhoneNumber}
                          setItem={setInfoModal}
                          itemValue={true}
                          loginResponse={loginResponse}
                          setLoginResponse={setLoginResponse}
                          callback={() => {
                            setLoading(true);
                            localStorage.setItem("token", loginResponse.token);
                            setVerificationModal(false);
                            // dispatch({ type: "USER_LOADED", payload: "" });
                            const data = {
                              isGuest: 0,
                              domain:
                                window.location.origin ===
                                "http://localhost:3000"
                                  ? "https://web.grocbay.com"
                                  : window.location.origin,
                              screen_type: "home_screen",
                            };
                            dispatch(userLoaded(data, loginResponse.token));
                            dispatch(getCartItems(setLoading));
                          }}
                        />
                        <InfoModal
                          isModalOpen={infoModal}
                          setIsModalOpen={setInfoModal}
                          userPhoneNumber={userPhoneNumber}
                          callback={(token) => {
                            setLoading(true);
                            const data = {
                              isGuest: 0,
                              domain:
                                window.location.origin ===
                                "http://localhost:3000"
                                  ? "https://web.grocbay.com"
                                  : window.location.origin,
                              screen_type: "home_screen",
                            };
                            dispatch(userLoaded(data, token));
                            dispatch(getCartItems(setLoading));
                            setInfoModal(false);
                          }}
                        />
                        {/* <RateModal
                    isModalOpen={rateModal}
                    setIsModalOpen={setRateModal}
                    setItem={setItem}
                    itemValue={2}
                  /> */}

                        <DeliveryModal
                          isModalOpen={deliveryModal}
                          setIsModalOpen={setDeliveryModal}
                          deliveryOptionDetail={deliveryOptionDetail}
                          setDeliveryOptionDetail={setDeliveryOptionDetail}
                          setSelectedDeliveryDetail={setSelectedDeliveryDetail}
                          selectedDeliveryDetail={selectedDeliveryDetail}
                          setDeliveryType={setDeliveryType}
                        />
                        <IFrameModal
                          isModalOpen={iFrameModal}
                          setIsModalOpen={setiFrameModal}
                          paymentLink={paymentLink}
                        />
                        <OrderModal
                          isModalOpen={orderModal}
                          setIsModalOpen={setOrderModal}
                        />
                      </AddNumber>
                      <div className="cart-items-container-mobile">
                        {/* <FreeDelivery>
                        <h5>Free Delivery</h5>
                        <p>
                          Shop {currency_symbol}500 more to get Free Delivery
                        </p>
                      </FreeDelivery> */}
                        <h5
                          style={{
                            fontWeight: "400",
                            color: "var(--black)",
                            marginBottom: "1.5em",
                          }}
                        >
                          Review items
                        </h5>
                        <div>
                          <div
                            className="cart-items-container-holder"
                            ref={cartItemListRef}
                          >
                            {cart?.cart?.cData?.map(
                              (
                                {
                                  product_variations,
                                  products,
                                  quantity,
                                  product_id,
                                  location_id,
                                },
                                i
                              ) => (
                                <BasketCard
                                  key={i}
                                  quantity={quantity}
                                  image={`${fileURL}${product_variations?.product_images[0]?.path}`}
                                  heading={products.name}
                                  text={`${product_variations.name} ${product_variations.unit_of_measure}`}
                                  discount={
                                    product_variations?.product_batches[0]
                                      .sale_price === 0
                                      ? product_variations.product_batches[0].mrp.toFixed(
                                          2
                                        )
                                      : product_variations?.product_batches[0].sale_price.toFixed(
                                          2
                                        )
                                  }
                                  price={product_variations.product_batches[0].mrp.toFixed(
                                    2
                                  )}
                                  data={{
                                    product_id: product_id,
                                    variation_id: product_variations.id,
                                    batch_id:
                                      product_variations.product_batches[0].id,
                                    location_id: location_id,
                                    type: product_variations.type,
                                    membership_plan_id: "",
                                  }}
                                  deliveryType={deliveryType}
                                />
                              )
                            )}
                          </div>
                          {/* <Coupon /> */}

                          {/* <Tip /> */}
                          <BillDetails />
                        </div>
                      </div>
                      <SubTotal />
                    </ShippingCard>
                  </Col>
                </Row>
              </Container>
              <div className="cart-bottom-bar" ref={cartBottomBarRef}>
                {(cart.cart.paymentDetails.minAmount <=
                  cart.cart.paymentDetails.topay ||
                  selectedDeliveryDetail ||
                  selectedAddress) && (
                  <Fragment>
                    <MobileAddressSelector
                      show={mobileAddressPopup}
                      toggleAddress={toggleMobileAddressSelect}
                      onAddressSelect={(item) => {
                        setSelectedAddress(item);
                        toggleMobileAddressSelect();
                      }}
                    />
                    {!!selectedDeliveryDetail && (
                      <PaymentTypeSelector
                        selectedDeliveryDetail={selectedDeliveryDetail}
                        selectedAddress={selectedAddress}
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        selectedPaymentMethod={selectedPaymentMethod}
                        show={mobilePaymentPopup}
                        togglePayment={togglePaymentModal}
                      />
                    )}

                    <div className="cart-mobile-address">
                      <span className="d-flex gap-2 align-items-center">
                        <span className="d-flex flex-column align-items-center">
                          <img
                            src={`/images/${
                              selectedAddress?.address_type === "Home"
                                ? "address-card-logo-3.svg"
                                : "work-logo.svg"
                            }`}
                            alt="click here"
                            className="mx-1"
                          />
                          <p className="m-0">{selectedAddress?.address_type}</p>
                        </span>
                        {/* <p className="m-0 cart-">
                            {selectedAddress?.location_address}
                          </p> */}
                      </span>
                      <p
                        className="cart-mobile-change"
                        onClick={toggleMobileAddressSelect}
                      >
                        Change
                      </p>
                    </div>

                    {!!selectedAddress && (
                      <ConfirmDelivery className="cart-bottom-bar-delivery">
                        <div className="d-flex align-items-center">
                          <div className="info">
                            <p className="m-0">
                              {deliveryOptionDetail?.delivery_date}
                            </p>
                          </div>
                        </div>
                        {deliveryOptionDetail?.delivery_date && (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => setDeliveryModal(true)}
                          >
                            Change
                          </p>
                        )}
                      </ConfirmDelivery>
                    )}

                    {!!selectedDeliveryDetail && (
                      <div className="cart-mobile-address">
                        <span className="d-flex gap-2 align-items-center">
                          <span className="d-flex flex-column align-items-center">
                            <h6 className="m-0 font-small">Payment Method: </h6>
                          </span>
                          <p className="capitalize m-0 font-small">
                            {selectedPaymentMethod}
                          </p>
                        </span>
                        <p
                          className="cart-mobile-change"
                          onClick={togglePaymentModal}
                        >
                          Change
                        </p>
                      </div>
                    )}
                  </Fragment>
                )}

                <div>
                  <AddNumberBtn onClick={onBottomButton} className="active">
                    {getButtonLabel()}
                  </AddNumberBtn>

                  {!!cart?.cart?.paymentDetails?.orderMsg && (
                    <MinOrderMessage className="cart-bottom-bar-min-order">
                      <p className="m-0">{cart.cart.paymentDetails.orderMsg}</p>
                    </MinOrderMessage>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: "91vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Empty
                title="Your Cart is empty!"
                description="There is nothing in your cart. Let's add some items"
                buttonText="Start Shopping"
                icon="cart_empty"
                buttonCallback={() => navigate("/")}
              />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Cart;
