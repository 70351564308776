import React from "react";
import { SlickSlider } from "./styles";
import { Fragment } from "react";

const Carousel = ({
  children,
  showSlide,
  centerMode,
  data,
  hasNextArrow = true,
}) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} sliderArrow`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img src="/images/slider-next-logo.svg" alt="click here" />
      </div>
    );
  };

  //   xs: 260px,
  // sm: 568px,
  // md: 768px,
  // lg: 992px,
  // xl: 1200px,
  // xxl: 1440px,

  const settings = {
    className: "center px-2",
    infinite: true,
    swipeToSlide: true,
    slidesToShow: showSlide
      ? showSlide > children?.length
        ? children?.length
        : showSlide
      : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: hasNextArrow ? <NextArrow /> : <Fragment />,
    prevArrow: <></>,
    centerMode: centerMode,
    centerPadding: "80px",
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: showSlide
            ? showSlide > children?.length
              ? children?.length
              : showSlide
            : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: showSlide
            ? showSlide > children?.length
              ? children?.length
              : showSlide
            : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: showSlide
            ? showSlide > children?.length
              ? children?.length
              : showSlide
            : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: showSlide
            ? showSlide > children?.length
              ? children?.length
              : showSlide
            : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: showSlide
            ? showSlide > children?.length
              ? children?.length
              : showSlide
            : 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return <SlickSlider {...settings}>{children}</SlickSlider>;
};

export default Carousel;
