import React, { useState, useEffect } from "react";
import { ConfirmButton, MembershipCard, PlanBtn } from "./index.styled";
import { Radio } from "antd";
import { getMembership } from "redux/action/appActions";
import { useSelector } from "react-redux";
import { fileURL } from "helpers/baseURL";

export default function MemberShip() {
  const [data, setData] = useState();
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [radioValue, setRadioValue] = useState(null);
  const getData = async () => {
    const res = await getMembership(user.guestToken);
    setData(res);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <MembershipCard>
      {/* <div className="info">
        <h2>
          Saving gets bigger with <span>Membership</span>
        </h2>
        <img src="/images/delivery-box-image.png" alt="click here" />
      </div> */}
      <img
        src={`${fileURL}${data?.getBanner?.design_banner}`}
        alt=""
        className="img-fluid"
      />
      {/* <div className="content">
        <h5>UNLOCK ACCESS TO BENEFITS</h5>
        <div className="content-card">
          <div className="card-inner">
            <div className="saving-card">
              <img src="/images/saving-logo.png" alt="click here" />
              <div>
                <h6>Extra saving on products</h6>
                <p>Save upto 30% off on all orders above ₹199</p>
              </div>
            </div>
            <div className="saving-card">
              <img src="/images/saving-logo.png" alt="click here" />
              <div>
                <h6>Free delivery</h6>
                <p>On all orders above ₹199</p>
              </div>
            </div>
            <div className="saving-card">
              <img src="/images/saving-logo.png" alt="click here" />
              <div>
                <h6>On time guarantee</h6>
                <p>We will ensure that your orders reach on time</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="card-footer">
        <h5>PICK YOUR PLAN</h5>
        <Radio.Group
          name="quantity-radio-group"
          value={radioValue}
          className="d-flex justify-content-center align-items-center flex-wrap"
          style={{ margin: "0 0 4em", fontSize: "1em" }}
        >
          {data?.plans?.map((item, i) => (
            <PlanBtn onClick={() => setRadioValue(i)}>
              <div>
                <h6>{item.plan_duration}</h6>
                <div className="d-flex align-items-baseline">
                  <h4>
                    {currency_symbol}
                    {item.discounted_price}
                  </h4>
                  <p>
                    {currency_symbol}
                    {item.mrp}
                  </p>
                </div>
              </div>
              <Radio value={i}></Radio>
            </PlanBtn>
          ))}
          {/* <PlanBtn onClick={() => setRadioValue(2)}>
            <div>
              <h6>1 Month</h6>
              <div className="d-flex align-items-baseline">
                <h4>₹199</h4>
                <p>₹249</p>
              </div>
            </div>
            <Radio value={2}></Radio>
          </PlanBtn>
          <PlanBtn onClick={() => setRadioValue(3)}>
            <div>
              <h6>1 Month</h6>
              <div className="d-flex align-items-baseline">
                <h4>₹199</h4>
                <p>₹249</p>
              </div>
            </div>
            <Radio value={3}></Radio>
          </PlanBtn> */}
        </Radio.Group>
      </div>
      <ConfirmButton>Confirm</ConfirmButton>
    </MembershipCard>
  );
}
