import styled from "styled-components";
import { Input } from "antd";
import { AutoComplete } from "antd";

export const SearchBar = styled(AutoComplete)`
  > div {
    width: 100% !important;
    height: auto !important;
    border: 1px solid var(--gray-secondary) !important;
    border-radius: 0.5em !important;
    outline: 0;
    box-shadow: none !important;
    font-size: 1.6em !important;
    font-weight: 300 !important;
    color: var(--black) !important;
    padding: 0.875em 1.654em !important;
    margin: 1.5em 0 2em !important;
    &::placeholder {
      color: var(--gray);
    }
    .ant-select-selection-search {
      padding: 0.875em 1.054em !important;
    }
    svg {
      font-size: 1.5em;
      margin-right: 0.625em;
      color: var(--gray);
    }
    & {
      @media (max-width: 576px) {
        /* height: 55px; */
        font-size: 1.4em;
        padding: 0.875em 1.25em;
        svg {
          font-size: 1.25em;
          margin-right: 0.25em;
        }
      }
    }
  }
`;
export const Label = styled.a`
  padding: 0.86em 1.15em;
  background-color: var(--white-smoke-secondary);
  border-radius: 0.625em;
  margin-right: 0.86em;
  margin-bottom: 0.5em;
  cursor: pointer;
  span {
    font-size: 1.25em;
    font-weight: 400;
    color: var(--primary) !important;
  }
  & {
    @media (max-width: 1200px) {
      margin-bottom: 0.5em;
    }
    @media (max-width: 576px) {
      padding: 0.86em 0;
      width: 46.5%;
      margin: 0.93em auto 0;
      text-align: center;
    }
  }
`;
export const ProductCard = styled.div`
  width: 100%;
  // max-width: 250px;
  margin-bottom: 3.215em;
  & {
    @media (max-width: 992px) {
      max-width: 45%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
`;
