import styled from "styled-components";

export const Card = styled.div`
  width: 109px;
  text-align: center;
  margin: 0.86em;
  cursor: pointer;
  img {
    width: 100%;
    height: 97px;
  }
  p {
    font-size: 0.86em;
    font-weight: 500;
    color: var(--black);
    width: 105px;
    margin: 0 auto 1.15em;
  }
`;
