import { ArrowLeft } from "assets/svg";
import AddAddressModal from "components/Modals/AddAddressModal";
import React, { Fragment, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";

const MobileAddressSelector = ({ show, toggleAddress, onAddressSelect }) => {
  const offCanvasRef = useRef();
  const [addAddress, setAddAddress] = useState(false);

  const customerAddress = useSelector(
    (state) => state.app.cart.customerAddress
  );

  const onTouchMove = function (event) {
    const body25 = (document.body.clientHeight * 30) / 100;
    const swipeHeight =
      document.body.clientHeight - event?.changedTouches?.[0]?.clientY;

    let height = body25 < swipeHeight ? swipeHeight : body25;

    offCanvasRef.current.dialog.style.height = `${height}px`;
  };

  return (
    <Fragment>
      <Offcanvas
        placement="bottom"
        className={`${addAddress ? "d-none" : ""} cart-address-offcanvas`}
        show={show}
        onHide={() => {
          setAddAddress(false);
          toggleAddress();
        }}
        onTouchMove={onTouchMove}
        ref={offCanvasRef}
      >
        <span className="d-flex align-items-center gap-2 py-1 mt-3 mb-1 px-3">
          <ArrowLeft onClick={toggleAddress} />
          <p className="m-0 font-large fw-600">Choose a delivery address</p>
        </span>
        {customerAddress?.customer_address?.map((item) => (
          <div
            className="cart-mobile-address-selector"
            onClick={() => onAddressSelect(item)}
            key={item?.id}
          >
            <span className="d-flex flex-column align-items-center">
              <img
                src={`/images/${
                  item?.address_type === "Home"
                    ? "address-card-logo-3.svg"
                    : "work-logo.svg"
                }`}
                alt="click here"
                className="mx-1"
              />
              <p className="m-0">{item?.address_type}</p>
            </span>
            <p className="m-0 cart-">{item?.location_address}</p>
          </div>
        ))}
        <div
          className="cart-mobile-address-selector"
          onClick={() => {
            setAddAddress(true);
            toggleAddress();
          }}
        >
          <p className="m-0 font-sm-small font-md-medium font-lg-large font-xl-large">
            + Add Adress
          </p>
        </div>
      </Offcanvas>
      {addAddress && (
        <AddAddressModal
          isModalOpen={addAddress}
          setIsModalOpen={setAddAddress}
          callProfile={() => {}}
        />
      )}
    </Fragment>
  );
};

export default MobileAddressSelector;
