import "./App.css";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-number-input/style.css";
import Routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "helpers/setAuthToken";
import { getALLData } from "./redux/action/appActions";
import { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import DefaultImage from "./assets/images/default_image.svg";
import { Helmet } from "react-helmet";
import "./_variable.scss";
import { fileURL } from "helpers/baseURL";

function App() {
  const refType = useSelector((state) => state.app.data.refType);
  const homePage = useSelector((state) => state.app.data);

  const dispatch = useDispatch();
  const getData = async () => {
    const token = localStorage.getItem("token");
    if (token?.length > 130) {
      setAuthToken(token);
    }
    const data = {
      isGuest: token?.length > 130 ? 0 : 1,
      // domain: "https://web.grocbay.com",
      domain:
        window.location.origin === "http://localhost:3000"
          ? "https://web.grocbay.com"
          : window.location.origin,
      screen_type: "home_screen",
    };
    dispatch(getALLData(data));
  };

  useEffect(() => {
    getData();
    const onError = function (event) {
      const elm = event.target;
      if (elm.tagName === "IMG") {
        elm.src = DefaultImage;
        elm.onerror = "";
      }
    };

    document.addEventListener("error", onError, true);
    return () => document.removeEventListener("error", onError, true);
  }, []);

  useEffect(() => {
    if (homePage?.HomePage) {
      document.documentElement.style.setProperty(
        "--primary",
        homePage.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--gray-secondary",
        homePage.SecondaryColor || "white"
      );
      document.documentElement.style.setProperty("--black", homePage.fontColor);
      document.documentElement.style.setProperty("--gray", homePage.fontColor2);
      if (homePage.user) {
        dispatch({ type: "USER_REGISTER", payload: homePage.user });
      }
    }
  }, [homePage]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        height: 50,
      }}
      spin
    />
  );

  if (homePage) {
    return (
      <div className="App">
        <Helmet>
          <link rel="icon" href={fileURL + refType?.favicon} />
          <title>{refType?.business_name}</title>
          <meta title={refType?.business_name} />
          <title>{refType?.business_name}</title>
          <meta name={refType?.business_name} content={homePage?.slogan} />
        </Helmet>
        <Routes />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }
}

export default App;
