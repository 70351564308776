import { Button } from "antd";
import { SomethingWentWrong } from "assets/svg";
import React, { Component } from "react";
import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";

const PageHeader = styled.label`
  color: black;
  font-size: 1.2rem;
  font-weight: 500;
`;

const PromoSpan = styled.span`
  color: #b4b4b4;
  font-family: Lexend;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 1rem;
`;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.email = this.props.refType?.email;
    this.state = { hasError: false };
    this.onTryAgain = this.onTryAgain.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  onTryAgain() {
    // this.props.history.goBack();
    window.history.back();
    this.setState((prev) => ({
      hasError: !prev.hasError,
    }));
  }

  render() {
    if (this.state.hasError) {
      return (
        <Offcanvas
          show={this.state.hasError}
          // onHide={pageFunctions.toggleCanvas}
          placement={"bottom"}
          style={{
            height: "100%",
          }}
          backdrop="static"
          enforceFocus
        >
          <div
            className="d-flex justify-content-center align-items-center w-100 user-select-none"
            style={{
              minHeight: "100svh",
            }}
          >
            <div className="d-flex align-items-center justify-content-center flex-column gap-1">
              <SomethingWentWrong style={{ width: "20vw", height: "30vh" }} />
              <PageHeader className="mt-0 text-center">
                Oops! Something Went Wrong
              </PageHeader>

              <PromoSpan className="m-0 text-center">
                We apologize for the inconvenience, but it seems that we're
              </PromoSpan>
              <PromoSpan className="m-0 mb-2 text-center">
                experiencing technical difficulties at the moment.
              </PromoSpan>
              <Button
                style={{
                  minHeight: "7svh",
                  minWidth: "10svw",
                  backgroundColor: this.props.PrimaryColor,
                  color: "white",
                }}
                onClick={this.onTryAgain}
              >
                Go Back
              </Button>
              <PromoSpan className="mt-4 text-center">
                If you think this is an error, contact our support team at&nbsp;
                <a
                  href="mailto:support@ordrio.com"
                  style={{
                    color: "black",
                  }}
                >
                  {this.email}
                </a>
              </PromoSpan>
            </div>
          </div>
        </Offcanvas>
      );
    }

    return this.props.children;
  }
}
