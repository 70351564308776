import styled from "styled-components";

export const Card = styled.div`
  background-color: var(--grey-ordinary);
  border-radius: 1.46em;
  text-align: center;
  padding: 1.112em 0.56em 0;
  p {
    font-size: 1.182em;
    font-weight: 500;
    color: var(--black);
  }
  img {
    margin: 0 auto;
    min-height: 50px;
    width: 70%;
  }
`;
