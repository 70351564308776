import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Empty } from "antd";
import Home from "../screens/home";
import ItemList from "screens/itemList";
import Product from "screens/product";
import Search from "screens/search";
import MyOrders from "screens/myOrders";
import Cart from "screens/cart";
import AllCategories from "screens/allCategories";
import AllRatings from "screens/allRatings";
import About2 from "screens/aboutus";
import TermsCondition2 from "screens/termsCondition";
import PrivacyPolicy from "screens/privacyPolicy";
import Orders from "screens/myOrders/Orders";
import OrderDetail from "screens/myOrders/OrderDetail";
import MemberShip from "screens/myOrders/MemberShip";
import Policy from "screens/myOrders/Policy";
import TermsCondition from "screens/myOrders/TermsCondition";
import About from "screens/myOrders/About";
import Address from "screens/myOrders/Address";
import Loyalty from "screens/myOrders/Loyalty";
import Wallet from "screens/myOrders/Wallet";
import Shopping from "screens/myOrders/Shopping";
import PaymentSuccess from "screens/cart/PaymentSuccess";
import PaymentFailed from "screens/cart/PaymentFailed";
import PaymentPending from "screens/cart/PaymentPending";
import ErrorBoundary from "error/ErrorBoundary";
import { connect } from "react-redux";
import Brands from "screens/brands";
import setAuthToken from "helpers/setAuthToken";
import { useEffect } from "react";
import BottomBar from "components/BottomBar/BottomBar";
import Profile from "components/Profile/Profile";
import NoDataFound from "components/NoDataFound";
import BannerRedirection from "screens/bannerRedirect";
import ShippingPolicy from "screens/Shipping Policy/ShippingPolicy";

const BoundaryWithState = connect((state) => state.app.data)(ErrorBoundary);

export default function Router() {
  useEffect(() => {
    setAuthToken();
  }, []);

  return (
    <BoundaryWithState>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/allCategories" element={<AllCategories />} />
          <Route
            path="/featured-categories/:featuredItem"
            element={<ItemList />}
          />
          <Route
            path="/featured-products/:featuredItem"
            element={<ItemList />}
          />

          <Route path="/featured-brands/:featuredItem" element={<Brands />} />
          <Route path="/itemList/:id" element={<ItemList />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/allRatings" element={<AllRatings />} />
          <Route path="/search" element={<Search />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About2 />} />
          <Route path="/e-page" element={<TermsCondition2 />} />
          <Route path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/payment-status/success" element={<PaymentSuccess />} />
          <Route path="/payment-status/failed" element={<PaymentFailed />} />
          <Route path="/payment-status/pending" element={<PaymentPending />} />
          <Route path="/no-data-found" element={<NoDataFound />} />
          <Route path="/banner-redirect" element={<BannerRedirection />} />
          <Route path="dashboard" element={<MyOrders />}>
            <Route path="orders" element={<Orders />} />
            <Route path="orders/:id" element={<OrderDetail />} />
            <Route path="membership" element={<MemberShip />} />
            <Route path="policy" element={<Policy />} />
            <Route path="conditions" element={<TermsCondition />} />
            <Route path="about" element={<About />} />
            <Route path="address" element={<Address />} />
            <Route path="loyalty" element={<Loyalty />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="shopping" element={<Shopping />} />
            <Route path="*" element={<Empty />} />
          </Route>
        </Routes>
        <BottomBar />
      </BrowserRouter>
    </BoundaryWithState>
  );
}
