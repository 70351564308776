import React from "react";
import { Container } from "react-bootstrap";
import { FooterArea, FooterInfo, PaymentArea, BottomFooter } from "./styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubCategory, setSelectedCategory } from "redux/action/appActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const DashboardFooter = () => {
  const user = useSelector((state) => state.app.data);
  const paymentModes = useSelector((state) => state.app.data.PaymentMode);
  const categories = useSelector((state) => state.app.data.categories);
  const { business_name } = useSelector((state) => state.app.data.refType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <FooterArea>
        <Container>
          <FooterInfo>
            {/* <div>
              <h4>Categories</h4>
              {categories.slice(0, 5)?.map(({ name, id }, i) => (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: "GET_SUB_CATEGORIES", payload: [] });
                    dispatch({
                      type: "GET_SUB_CATEGORIES_PRODUCTS",
                      payload: {
                        nestedSubCategoriesWithProducts: [],
                      },
                    });
                    dispatch({ type: "GET_PRODUCTS", payload: [] });
                    dispatch({ type: "SET_MESSAGE", payload: "" });
                    dispatch(setSelectedCategory({ title: name, id: id }));
                    navigate(`/itemList/${id}`);
                  }}
                >
                  {name}
                </a>
              ))} */}
            {/* <a href="#">Breakfast & Dairy</a>
              <a href="#">Personal Care</a>
              <a href="#">Grocery & Staples</a>
              <a href="#">Beverages</a>
              <a href="#">Meat & Sea foods</a> */}
            {/* ------------footer--------------- */}
            {/* </div> */}
            <div className="about-us">
              <h4>About Us</h4>
              {/* <Link to="#">Contact Us</Link> */}
              <Link to="/policy">Privacy Policy</Link>
              <Link to="/e-page">Terms & Conditions</Link>
              <Link to="/about">About us</Link>
              {/* <Link to="#">Rate us</Link> */}
            </div>
            <div>
              <h4>Social</h4>
              <a href="#">Facebook</a>
              <a href="#">Twitter</a>
              <a href="#">YouTube</a>
            </div>
          </FooterInfo>
          <PaymentArea>
            {/* <h4>Payment Mode</h4>
            <div style={{ marginBottom: "3.75em" }}>
              <img src={paymentModes.mastercard} alt="click here" />
              <img src={paymentModes.visa} alt="click here" />
              <img src={paymentModes.upi} alt="click here" />
              <img src={paymentModes.rupay} alt="click here" />
            </div> */}
            <h4>Registered Office Address</h4>
            <a href="#">{business_name}</a>
            <a href="#" dangerouslySetInnerHTML={{ __html: user?.address }}>
              {/* {user?.address} */}
            </a>
            {/* <a href="#">Udupi - 576101</a> */}
          </PaymentArea>
        </Container>
        <hr />
        <BottomFooter>
          <p>
            &copy; {new Date().getFullYear()} {window?.location?.origin}
          </p>
          <PaymentArea>
            <div>
              <img src={paymentModes.mastercard} alt="click here" />
              <img src={paymentModes.visa} alt="click here" />
              <img src={paymentModes.upi} alt="click here" />
              <img src={paymentModes.rupay} alt="click here" />
            </div>
          </PaymentArea>
        </BottomFooter>
      </FooterArea>
    </>
  );
};

export default DashboardFooter;
