import React from "react";
import ExploreCard from "../Cards/ExploreCard";
import { ShimmerThumbnail, ShimmerBadge } from "shimmer-react";
import { useNavigate } from "react-router-dom";
import { SlickSlider } from "components/Carousel/styles";

const ExploreSection = ({ data }) => {
  const navigate = useNavigate();

  const settings = {
    className: "center px-2",
    infinite: true,
    swipeToSlide: true,
    slidesToShow: data.length > 6 ? 6 : data.length,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <></>,
    centerMode: false,
    centerPadding: "80px",
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="mb-5" style={{ position: "relative" }}>
      {data?.description ? (
        <div
          className="main-heading my-1"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></div>
      ) : (
        <ShimmerBadge width={200} />
      )}

      {data?.items ? (
        <SlickSlider {...settings}>
          {data.items.map((item) => {
            const { icon_image, name, id } = item;
            return (
              <div onClick={() => navigate(`/itemList/${id}`)} key={id}>
                <ExploreCard image={icon_image} heading={name} />
              </div>
            );
          })}
        </SlickSlider>
      ) : (
        <ShimmerThumbnail height={200} width={200} className="m-0" rounded />
      )}
    </div>
  );
};

export default ExploreSection;
