import styled from "styled-components";

export const Foot = styled.footer`
  padding: 2em 0 3em;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h4 {
    font-size: 1.625em;
    font-weight: 400;
    margin: 0 1em 0 0;
  }
  p {
    font-size: 0.7em;
    font-weight: 300;
    color: var(--gray-ordinary);
    margin: 0;
  }
  h3 {
    font-size: 1.75em;
  }
  & {
    @media (max-width: 992px) {
      .container {
        display: block;
      }
    }
  }
`;
export const PhoneIcon = styled.div`
  padding: 1.125em;
  border: 1px solid var(--primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
