import React, { Fragment, useState } from "react";
import OrderCard from "components/Cards/OrderCard";
import { OngoingOrdersData } from "data/OngoingOrdersData";
import { PostOrdersData } from "data/PostOrdersData";
import { useEffect } from "react";
import { getOrders } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "antd";
import Empty from "components/empty";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

export default function Orders({ setStep }) {
  const user = useSelector((state) => state.app.data);
  const Orders = useSelector((state) => state.app.orders);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    dispatch(getOrders(setLoading));
  }, []);
  const onChange = (page) => {
    setCurrentPage(page);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: "var(--primary)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:100,
        height:100,
      }}
      spin
    />
  );
  return (
    <>
      <h4 className="box-heading">My Orders</h4>
      {loading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : Orders?.[0]?.ongoingOrders?.length || Orders?.[0]?.pastOrders?.length ? (
        <>
          {!!Orders[0]?.ongoingOrders?.length && (
            <Fragment>
              <div>
                <p className="card-heading">Ongoing Orders</p>
                {Orders[0].ongoingOrders
                  .slice(currentPage * 10 - 10, currentPage * 10)
                  .map((item) => {
                    const {
                      delivery_date,
                      delivery_time,
                      ordernumber,
                      created_at,
                      actual_amount,
                      order_status_message,
                      order_status,
                      id,
                    } = item;
                    return (
                      <OrderCard
                        title={moment(created_at).format("DD MMM, YYYY h:mm A")}
                        heading={ordernumber}
                        subheading={`Scheduled Delivery 
                    ${delivery_date} ${delivery_time}`}
                        text={order_status_message}
                        order_status={order_status}
                        price={actual_amount}
                        setStep={setStep}
                        id={id}
                        key={id}
                      />
                    );
                  })}
              </div>
              <Pagination
                defaultCurrent={currentPage}
                total={Orders[0].ongoingOrders.length}
                onChange={onChange}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </Fragment>
          )}
          <div style={{ marginTop: "3em" }}>
            <p className="card-heading">Post Orders</p>
            {Orders[0].pastOrders.map((item) => {
              const {
                delivery_date,
                delivery_time,
                ordernumber,
                created_at,
                // order_amount,
                actual_amount,
                order_status_message,
                order_status,
                id,
              } = item;
              return (
                <OrderCard
                  title={moment(created_at).format("DD MMM, YYYY h:mm A")}
                  heading={ordernumber}
                  subheading={`Scheduled Delivery 
                  ${delivery_date} ${delivery_time}`}
                  text={order_status_message}
                  order_status={order_status}
                  price={actual_amount}
                  setStep={setStep}
                  id={id}
                  key={id}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty
            title="No order placed"
            description="We are waiting to delivery your first order."
            buttonText="Start Shopping"
            icon="orders_empty"
            buttonCallback={() => navigate("/")}
          />
        </div>
      )}
    </>
  );
}
