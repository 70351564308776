import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CodeInput } from "react-code-verification-input";
import { BiMessage } from "react-icons/bi";
import { TbPhone } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_EDIT_USER, UPDATE_USER } from "redux/Constants";
import {
  callme,
  resendOTP,
  sendOTP,
  updateCustomerWithNumber,
} from "redux/action/appActions";
import { ModalStyle } from "../styles";
import { ActionButtons, VerifyArea } from "./styles";

const UpdateNumberOtp = ({ data, setData, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.data);
  const [count, setCount] = useState(30); // Set your initial count value here
  const [code, setCode] = useState("");
  const { country_code, ref } = user?.SplashScreen?.customer || {};


  const handleResend = async () => {
    const res = await resendOTP({
      country_code: country_code,
      mobile: String(data.mobile),
      ref: ref,
    });
    if (res.status) {
      setIsOpen(false);
    }
  };

  const handleCall = async () => {
    const res = await callme({
      country_code: country_code,
      mobile: String(data.mobile),
      ref: ref,
    });
  };

  const handleSend = async () => {
    if (code.length === 4) {
      const res = await sendOTP({
        hashed_otp: data.otp,
        otp: code,
        user_type: "EXISTS",
        customer_token: "",
        ref: user.ref,
      });
      setCode("");
      if (res.status) {
        const updateRes = await updateCustomerWithNumber({
          name: data.name,
          mobile: String(data.mobile),
          email: data.email,
        });

        dispatch({
          type: UPDATE_USER,
          payload: updateRes?.data?.customer,
        });

        if (updateRes.status) {
          message.success("User updated successfully");
          setData(null);

          setIsOpen(false);
        }
      } else {
        message.error("OTP verification failed");
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, [count]);

  useEffect(() => {
    handleSend();
  }, [code]);

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={608}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <VerifyArea>
        <h4>OTP Verification</h4>
        <h5>We have sent a verification code to</h5>
        <h5>
          {data?.country_code} {data?.mobile}
          <img
            onClick={() => {
              setIsOpen(false);
              dispatch({ type: TOGGLE_EDIT_USER });
            }}
            src="/images/edit-logo.svg"
            alt="click here"
          />
        </h5>
        <CodeInput
          autoFocus={true}
          length={4}
          onChange={(value) => {
            setCode(value);
          }}
          value={code}
        />
        <p>Didn't receive the OTP verification code?</p>
        <ActionButtons>
          <Button disabled={count === 0 ? false : true} onClick={handleResend}>
            <BiMessage />
            Resend {count !== 0 && <>in {count} Sec.</>}
          </Button>
          <Button disabled={count === 0 ? false : true} onClick={handleCall}>
            <TbPhone />
            Call {count !== 0 && <>in {count} Sec.</>}
          </Button>
        </ActionButtons>
      </VerifyArea>
    </ModalStyle>
  );
};

export default UpdateNumberOtp;
