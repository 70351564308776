import { SlickSlider } from "components/Carousel/styles";
import { fileURL } from "helpers/baseURL";
import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const CategoryCarousel = ({ categories }) => {
  const navigate = useNavigate();
  const catLength = categories.length;
  const settings = {
    className: "center px-2",
    infinite: false,
    swipeToSlide: true,
    slidesToShow: categories.length > 6 ? 6 : categories,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <></>,
    centerMode: false,
    nextArrow: <Fragment />,
    centerPadding: "80px",
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: catLength > 10 ? 10 : catLength,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: catLength > 8 ? 8 : catLength,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: catLength > 7 ? 7 : catLength,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: catLength > 6 ? 6 : catLength,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: catLength > 4 ? 4 : catLength,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <SlickSlider {...settings}>
      {categories?.map((item) => {
        return (
          <Fragment key={item.id}>
            <div
              className="d-flex flex-column justify-content-between align-items-center gap-3 cursor-pointer text-center max-vw-12"
              onClick={() => navigate(`/itemList/${item.id}`)}
            >
              <img
                style={{
                  mixBlendMode: "color-burn",
                  objectFit: "scale-down",
                  maxHeight: "100px",
                  maxWidth: "100px",
                }}
                className="w-100 h-100 "
                src={fileURL + item.icon_image}
                alt={item.name}
              />
              <p
                className="m-0 max-w-50 font-xs-xx-small font-sm-smaller font-md-small"
                style={{
                  fontWeight: 600,
                }}
              >
                {item.name}
              </p>
            </div>
          </Fragment>
        );
      })}
    </SlickSlider>
  );
};

export default CategoryCarousel;
