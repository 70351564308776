import { message } from "antd";

import apiRequest from "../../api/apiRequest";
import {
  locationService,
  orderService,
  customerService,
  settingService,
  storeService,
  cartService,
  productService,
  homeService,
  dashboardService,
} from "../../helpers/baseURL";

import {
  USER_LOADED,
  USER_REGISTER,
  GET_ALL_CATEGORIES,
  GET_SUB_CATEGORIES,
  GET_PRODUCTS,
  GET_CATEGORIES_PRODUCTS,
  GET_SUB_CATEGORIES_PRODUCTS,
  SET_MESSAGE,
  SET_SELECTED_PRODUCT,
  SET_POPULAR_CATEGORIES,
  SET_SEARCH_PRODUCT,
  SET_SEARCH_SUGGESTION,
  SET_CART,
  SET_ORDERS,
  ADD_LOCATION,
  UPDATE_CART,
  DECREMENT_CART,
  INCREMENT_CART,
  ADD_CART,
  SET_SELECTED_CATEGORY,
  UPDATE_SUB_CATEGORIES,
  GET_MORE_DATA,
  TOGGLE_CATEGORY_LOADING,
} from "../Constants";
import setAuthToken from "helpers/setAuthToken";

export const getALLData = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  let config = {
    headers: {
      Authorization: token,
    },
  };
  dashboardService
    .post("/api/v1/website/get-home-page", data, config)
    .then((response) => {
      let defLoc = localStorage.getItem("defLoc");
      localStorage.setItem("ref", response?.data?.data?.ref);
      if (!defLoc) {
        localStorage.setItem(
          "defLoc",
          JSON.stringify(response?.data?.data?.default_location)
        );
      }
      localStorage.setItem(
        "refType",
        JSON.stringify(response?.data?.data?.refType)
      );
      if (data.isGuest === 1) {
        localStorage.setItem("token", response.data.data.guestToken);
        setAuthToken(
          data.isGuest === 1 ? response.data.data.guestToken : token
        );
      }
      dispatch({
        type: USER_LOADED,
        payload: {
          ...response.data.data,
          guestToken:
            data.isGuest === 1 ? response.data.data.guestToken : token,
        },
      });
    });
};

export const userLoaded = (data, token) => (dispatch) => {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  dashboardService
    .post("/api/v1/website/get-home-page", data, config)
    .then((response) => {
      localStorage.setItem("ref", response?.data?.data?.ref);
      localStorage.setItem(
        "refType",
        JSON.stringify(response?.data?.data?.refType)
      );
      dispatch({
        type: USER_LOADED,
        payload: {
          ...response.data.data,
          guestToken: token,
        },
      });
    });
};

export const featuredItems = async (data) => {
  const response = await homeService.post(
    "/api/v1/setting/feature-view-all?dev=3",
    data
  );

  return response.data;
};

export const addCart = (data, callback) => async (dispatch) => {
  let res = await cartService
    .post("/api/v1/cart/add-to-cart", data)
    .then((response) => {
      if (!response.data.status) {
        message.error(response.data.message);
        callback(response.data.status);
      } else {
        dispatch({
          type: ADD_CART,
          payload: {
            cData: response.data?.data?.cData,
            paymentDetails: response?.data?.data?.paymentDetails,
          },
        });
        message.success(response.data.message);
        callback(response.data.status);
      }
    })
    .catch((err) => {
      message.error(err.response.data.message);
      callback(err.response.data.status);
    });

  return res || {};
};

export const incrementCart = (data, callback) => (dispatch) => {
  return cartService.post("/api/v1/cart/increment", data).then((response) => {
    if (response.data.status === false) {
      message.error(response.data.message);
      callback(response.data.status);
    } else {
      dispatch({
        type: DECREMENT_CART,
        payload: {
          cData: response.data.data.cData,
          paymentDetails: response.data.data.paymentDetails,
        },
      });
      callback(response.data.status);
    }
  });
};

export const incrementCartScreen = (data, setLoading) => (dispatch) => {
  return cartService
    .post("/api/v1/cart/increment", data)
    .then((response) => {
      if (response.data.status === false) {
        message.error(response.data.message);
        setLoading(false);
        return response;
      } else {
        setLoading(false);
        dispatch({
          type: UPDATE_CART,
          payload: {
            paymentDetails: response.data.data.paymentDetails,
            cData: response.data.data.cData,
          },
        });
        dispatch({
          type: DECREMENT_CART,
          payload: {
            paymentDetails: response.data.data.paymentDetails,
            cData: response.data.data.cData,
          },
        });
        return response;
      }
    })
    .catch((err) => {
      if (err?.response?.data?.message?.length < 30) {
        message.error(err?.response?.data?.message);
      }
    });
};

export const decrementCart = (data, callback) => (dispatch) => {
  return cartService.post("/api/v1/cart/decrement", data).then((response) => {
    if (response.data.status === false) {
      message.error(response.data.message);
      callback(response.data.status);
    } else {
      dispatch({
        type: DECREMENT_CART,
        payload: {
          cData: response.data.data.cData,
          paymentDetails: response.data.data.paymentDetails,
        },
      });
      callback(response.data.status);
    }
  });
};

export const decrementCartScreen = (data, setLoading) => (dispatch) => {
  return cartService.post("/api/v1/cart/decrement", data).then((response) => {
    if (response.data.status === false) {
      message.success(response.data.message);

      setLoading(false);
      dispatch({
        type: UPDATE_CART,
        payload: {
          paymentDetails: response?.data?.data?.paymentDetails,
          cData: response?.data?.data?.cData,
        },
      });
      return response?.data?.status;
    } else {
      setLoading(false);
      dispatch({
        type: UPDATE_CART,
        payload: {
          paymentDetails: response.data.data.paymentDetails,
          cData: response.data.data.cData,
        },
      });
      dispatch({
        type: DECREMENT_CART,
        payload: {
          paymentDetails: response.data.data.paymentDetails,
          cData: response.data.data.cData,
        },
      });
      return response.data.data;
    }
  });
};

export const login = async (data) => {
  return await customerService
    .post("/api/v1/auth/customer/login", data)
    .then((response) => {
      return response.data;
    });
};

export const register = (data, callback) => (dispatch) => {
  customerService
    .post("/api/v1/auth/customer/register", data)
    .then((response) => {
      dispatch({ type: USER_REGISTER, payload: response.data });
      localStorage.setItem("token", response.data.token);
      callback(response.data.token);
    });
};
export const sendOTP = async (data) => {
  return await customerService
    .post("/api/v1/auth/customer/verify-otp", data)
    .then((response) => {
      return response.data;
    });
};
export const resendOTP = async (data) => {
  return await customerService
    .post("/api/v1/auth/customer/resend-otp", data)
    .then((response) => {
      return response.data;
    });
};
export const callme = async (data) => {
  return await customerService
    .post("/api/v1/auth/customer/callme", data)
    .then((response) => {
      return response.data;
    });
};
export const getAllCategory = () => (dispatch) => {
  productService
    .post("/api/v1/product/get-allcategory", { page: 1 })
    .then((response) => {
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: response.data.data[0].results,
      });
    });
};

export const getSubCategory = (data) => (dispatch) => {
  dispatch({ type: TOGGLE_CATEGORY_LOADING });
  productService
    .post("/api/v2/product/category-by-id", data)
    .then((response) => {
      if (response.data.data.mainCategoriesWithProducts.length) {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            products: response.data.data.mainCategoriesWithProducts,
            subCategories: response.data.data.subcategory,
            categoryLast_id: response.data?.data?.last_id,
          },
        });

        if (response.data.data.mainCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
        // productData = ;
      } else if (
        response.data.data?.subcategory.length &&
        response.data.data.nestedCategoriesWithProducts.length
      ) {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            subCategories: response.data.data?.subcategory,
            products: response.data.data.nestedCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.nestedCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else if (
        !response.data.data?.subcategory.length &&
        response.data.data.nestedCategoriesWithProducts.length
      ) {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            products: response.data.data.nestedCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.nestedCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else if (
        response.data.data?.subcategory.length &&
        response.data.data.subCategoriesWithProducts.length
      ) {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            subCategories: response.data.data?.subcategory,
            products: response.data.data.subCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.subCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else if (
        !response.data.data?.subcategory.length &&
        response.data.data.subCategoriesWithProducts.length
      ) {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            products: response.data.data.subCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.subCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else {
        dispatch(notFound());
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: {
            products: [],
          },
        });
      }
      dispatch({ type: TOGGLE_CATEGORY_LOADING });
    });
};

export const getMoreProducts = (data) => (dispatch) => {
  dispatch({ type: GET_MORE_DATA, payload: true });

  productService
    .post("/api/v2/product/category-by-id", data)
    .then((response) => {
      if (response.data.data.subCategoriesWithProducts.length) {
        dispatch({
          type: UPDATE_SUB_CATEGORIES,
          payload: {
            products: response.data.data.subCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.subCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else if (response.data.data.mainCategoriesWithProducts.length) {
        dispatch({
          type: UPDATE_SUB_CATEGORIES,
          payload: {
            products: response.data.data.mainCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.mainCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else if (response.data.data.nestedCategoriesWithProducts.length) {
        dispatch({
          type: UPDATE_SUB_CATEGORIES,
          payload: {
            products: response.data.data.nestedCategoriesWithProducts,
            categoryLast_id: response.data?.data?.last_id,
          },
        });
        if (response.data.data.nestedCategoriesWithProducts.length !== 20) {
          dispatch({ type: GET_MORE_DATA, payload: false });
        }
      } else {
        dispatch({ type: GET_MORE_DATA, payload: false });
      }
    });
};

export const notFound = () => (dispatch) => {
  dispatch({ type: SET_MESSAGE, payload: "not found" });
};

export const setSelectedCategory = (data) => (dispatch) => {
  dispatch({ type: SET_SELECTED_CATEGORY, payload: data });
};

export const getProducts = (id) => (dispatch) => {
  productService
    .post("/api/v1/product/subcategory-by-id", { id })
    .then((response) => {
      if (response.data.data.subCategoriesWithProducts.length) {
        dispatch({
          type: GET_PRODUCTS,
          payload: response.data.data.subCategoriesWithProducts,
        });
        // productData = ;
      } else if (response.data.data.nestedCategoriesWithProducts.length) {
        dispatch({
          type: GET_SUB_CATEGORIES_PRODUCTS,
          payload: {
            nestedSubCategoriesWithProducts:
              response.data.data.nestedCategoriesWithProducts,
          },
        });
      } else {
        dispatch(notFound());

        message.error("Data not found!");
      }
    });
};

export const getProductDetails = (id) => (dispatch) => {
  productService.get(`/api/v1/get-product-by-id?id=${id}`).then((response) => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: response.data,
    });
  });
};
export const getRatingReviews = async (id) => {
  return await productService
    .post("/api/v1/product/get-product-review", { productId: id })
    .then((response) => {
      return response.data;
      // dispatch({
      //   type: SET_SELECTED_PRODUCT,
      //   payload: response.data,
      // });
    });
};

export const setRating = async (data) => {
  await productService
    .post("/api/v1/product/add-review", data)
    .then((response) => {
      message.success(response.data.message);
    });
};

export const getSearch = (setLoading) => (dispatch) => {
  productService
    .post("/api/v1/product/popular-categories", { key: "categories" })
    .then((response) => {
      dispatch({ type: SET_POPULAR_CATEGORIES, payload: response.data.data });
      setLoading(false);
    });
};

export const getSearchSuggestion = (key) => (dispatch) => {
  productService
    .post("/api/v1/product/search-suggestions", { key })
    .then((response) => {
      dispatch({ type: SET_SEARCH_SUGGESTION, payload: response.data.data });
      // message.success(response.data.message);
    });
};

export const search = (key, setLoading) => (dispatch) => {
  productService.post("/api/v1/product/search", { key }).then((response) => {
    dispatch({ type: SET_SEARCH_PRODUCT, payload: response.data.data });
    setLoading(false);
    // message.success(response.data.message);
  });
};
export const selectedSearch = (params, setLoading) => (dispatch) => {
  productService({
    method: "get",
    url: "/api/v1/get-product-by-id",
    params: params,
  }).then((response) => {
    dispatch({
      type: SET_SEARCH_PRODUCT,
      payload: [response.data.data.products],
    });
    setLoading(false);
    // dispatch({ type: SET_SEARCH_PRODUCT, payload: response.data.data });
    // message.success(response.data.message);
  });
};
export const getCartItems =
  (setLoading = false, data = null) =>
  (dispatch) => {
    cartService
      .post("/api/v1/cart/get-cart-items", {
        address_id: data?.id || "",
        type: "",
        delivery_type: "",
      })
      .then((response) => {
        dispatch({
          type: SET_CART,
          payload: response.data.data,
        });
        setLoading && setLoading(false);
      });
  };

export const setDeliveryOption = (type) => {
  return cartService
    .post("/api/v1/cart/split-cart-items", {
      delivery_type: type,
      // execute: false,
      // shipments_length: 1,
      // delivery_charge: "12hour",
    })
    .then((response) => {
      // dispatch({
      //   type: UPDATE_CART,
      //   payload: {
      //     paymentDetails: response.data.data.paymentDetails,
      //     cData: response.data.data.cData,
      //   },
      // });
      return response.data.data;
    });
};
export const getPaymentOption = (data) => {
  return orderService.post("/api/v1/order/payment", data).then((response) => {
    return response.data.data;
  });
};

export const getCoupons = () => {
  return storeService
    .post("/api/v1/app/promocode/check-availability")
    .then((response) => {
      return response.data.data?.promoData;
    });
};
export const applyCoupon = (data) => {
  if (data) {
    return storeService
      .post("/api/v1/app/promocode/check-availability", data)
      .then((response) => {
        return response.data.data?.promoData;
      });
  }
};
export const placedOrder = async (data) => {
  return await orderService
    .post("/api/v1/order/split-order", data)
    .then((response) => {
      return response.data;
    });
};

export const getOrders = (setLoading) => (dispatch) => {
  return orderService
    .post("/api/v1/order/get-all-orders", { page: 1 })
    .then((response) => {
      dispatch({
        type: SET_ORDERS,
        payload: response.data.data,
      });
      setLoading(false);
    });
};
export const getSelectedOrder = (id) => {
  return orderService
    .post("/api/v1/order/get-order-details", { id })
    .then((response) => {
      return response.data.data;
    });
};
export const addAddress = (data) => {
  return customerService
    .post("/api/v1/customer/add-address?dev=1", data)
    .then((response) => {
      if (response.data.status === false) {
        message.error(response.data.message);
        return response.data.data;
      } else {
        message.success(response.data.message);
        return response.data.data;
      }
    });
};
export const editAddress = (data) => {
  return customerService
    .post("/api/v1/customer/update-address", data)
    .then((response) => {
      if (response.data.status === false) {
        message.error(response?.data?.message);
        return response.data.data;
      } else {
        message.success(
          response?.data?.message || "Address Updated Successfully"
        );
        return response.data.data;
      }
    });
};
export const deleteAddress = async (id) => {
  return await customerService
    .delete("/api/v1/customer/delete-address", {
      data: { address_id: id },
    })
    .then((response) => {
      if (response.data.status === false) {
        message.error(response?.data?.message);
        return response?.data?.data;
      } else {
        message.success(
          response?.data?.message || "Address Deleted Successfully"
        );
        return response?.data?.data;
      }
    });
};
export const getProfileData = () => {
  return customerService
    .get("/api/v1/customer/get-customer-profile")
    .then((response) => {
      return response?.data?.data;
    });
};
export const getMembership = () => {
  return storeService
    .get("/api/v1/membership/get-plan-details")
    .then((response) => {
      return response.data.data;
    });
};
export const getLoyaltyWallet = (type) => {
  return customerService
    .post("/api/v1/customer/wallet-log", { type })
    .then((response) => {
      return response.data;
    });
};
export const getAbout = (type) => {
  return settingService
    .post("/api/v1/setting/get-pages", { type })
    .then((response) => {
      return response.data.data;
    });
};

export const editUser = async (data) => {
  return await customerService
    .post("/api/v1/customer/update-customer-profile", data)
    .then((response) => {
      return response.data;
    });
};

export const cancelOrder = async (data) => {
  return await orderService
    .post("/api/v1/order/change-order-status", data)
    .then((response) => {
      return response.data;
    });
};
export const addLocation = (data, callback, handleLoading) => (dispatch) => {
  return locationService
    .post("/api/v1/location/check-location", data)
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: ADD_LOCATION, payload: data });
        callback();
        localStorage.setItem(
          "defLoc",
          JSON.stringify({
            lat: response?.data?.data?.latitude,
            lng: response?.data?.data?.longitude,
          })
        );
        return {
          status: true,
        };
      } else {
        // callback();
        handleLoading && handleLoading();
        message.error(response.data.message);
        return {
          status: false,
        };
      }
    });
};

export const updateUser = (data) => async (dispatch) => {
  await customerService
    .post("/customer/update-customer-profile", data)
    .then((res) => {
      console.log(res);
    });
};

export const sendUpdateOtp = async (data) => {
  try {
    const res = await customerService.post("/api/v1/customer/send-sms", data);
    return res.data;
  } catch (err) {
    return { status: false };
  }
};

export const updateCustomerWithNumber = async (data) => {
  try {
    const res = await customerService.post(
      "/api/v1/customer/update-customer-mobile?dev=3",
      data
    );
    return res.data;
  } catch (err) {
    return { status: false };
  }
};
