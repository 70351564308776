import Empty from "components/empty";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Shopping() {
  const navigate = useNavigate();
  return (
    <>
      <H4 style={{ fontWeight: "600" }}>Shopping List</H4>
      <div
        style={{
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          title="Your shopping list is empty!"
          description="items added to your list will appear here."
          buttonText="Back to home"
          icon="shopping_empty"
          buttonCallback={() => navigate("/")}
        />
      </div>
    </>
  );
}
const H4 = styled("h4")``;
