import styled from "styled-components";

export const Card = styled.div`
  padding: 0.78em 1em;
  border-bottom: 1px solid var(--white-smoke-secondary);
  border-top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    margin-right: 1.15em;
  }
  h5 {
    font-size: 1.13em;
    font-weight: 400;
    color: var(--black);
    margin: 0;
    &.active {
      color: var(--primary);
    }
  }
  & {
    @media (max-width: 992px) {
      display: flex;
      flex-direction:column;
      justify-content:center;
      padding: 0.86em;
      // width: 50%;
      img {
        width: 60px;
        height: 60px;
        maring-bottom:4px;
        margin-right: 0.575em;
      }
      h5 {
        text-align:center;
        font-size: 0.7em;
      }
    }
  }
`;
