import React from "react";
import { Container } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";
import { Foot, PhoneIcon } from "./styles";
import { useSelector } from "react-redux";

const Footer = () => {
  const links = useSelector((state) => state.app.data?.DownloadApp);
  const { mobile } = useSelector((state) => state.app.data);
  return (
    <>
      <Foot>
        <Container>
          <div className="d-md-flex align-items-center">
            <h4>Download App</h4>
            <a href={links?.ios} target="_blank">
              <img
                className="mt-md-0 mt-4"
                src="/images/app-store.svg"
                alt="click here"
              />
            </a>
            <a href={links?.android} target="_blank">
              <img
                className="ms-2 mt-md-0 mt-4"
                src="/images/play-store.svg"
                alt="click here"
              />
            </a>
          </div>
          <div
            className="d-flex align-items-center ms-lg-0 ms-auto mt-lg-0 mt-4"
            style={{ width: "max-content" }}
          >
            <PhoneIcon>
              <FiPhoneCall size={30} />
            </PhoneIcon>
            <div className="ms-3">
              {/* <h3 className="m-0">987 6543 210</h3> */}
              <h3 className="m-0">{mobile}</h3>
              {/* <p>Working 8:00 AM - 7.00 PM</p> */}
            </div>
          </div>
        </Container>
      </Foot>
    </>
  );
};

export default Footer;
