import React, { useState, useEffect, Fragment } from "react";
import { Button, Radio } from "antd";
import { DeliveryModalStyle, SubmitBtn } from "../styles";
import { IoMdClose } from "react-icons/io";
import {
  Bordered,
  DateService,
  ExpressCard,
  Prices,
  Shipment,
  StandardCard,
  TimeService,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { setDeliveryOption } from "redux/action/appActions";
import ShipmentItem from "./ShipmentItem";
import { IoClose } from "react-icons/io5";
import { UPDATE_CART, PAYMENT_DETAILS_ON_TYPE } from "redux/Constants";

const DeliveryModal = ({
  isModalOpen,
  setIsModalOpen,
  setDeliveryOptionDetail,
  deliveryOptionDetail,
  setSelectedDeliveryDetail,
  selectedDeliveryDetail,
  setDeliveryType,
}) => {
  const cart = useSelector((state) => state.app.cart);
  console.log("the value of cart ", cart);
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [radio, setRadio] = useState("standard_delivery");
  const [selectedShipment, setSelectedShipment] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = async (type) => {
    setLoading(true);
    setRadio(type);
    setDeliveryType(type);
    const res = await setDeliveryOption(type);

    dispatch({ type: PAYMENT_DETAILS_ON_TYPE, payload: res.paymentDetails });
    // dispatch({
    //   type: UPDATE_CART,
    //   payload: {
    //     paymentDetails: res.data?.data?.paymentDetails,
    //     cData: res.data?.data?.cData,
    //   },
    // });

    setDeliveryOptionDetail(res);
    setLoading(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    if (radio && isModalOpen) {
      handleChange(radio);
    }
  }, [radio, isModalOpen]);

  const formatDeliveryCharges = (charges, currencySymbol) =>
    charges === 0 ? "Free" : `${currencySymbol} ${charges}`;

  return (
    <>
      <DeliveryModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        closable={false}
      >
        <h4
          style={{
            fontSize: "1.5em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Select delivery option</span>{" "}
          {loading ? <Spin indicator={antIcon} /> : ""}
        </h4>

        {/* close-btn */}

        <Button
          type="button"
          aria-labal="Close"
          className="ant-modal-close"
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          <span className="ant-modal-close-x">
            <span
              role="img"
              aria-label="close"
              className="anticon anticon-close ant-modal-close-icon"
            >
              <IoMdClose />
            </span>
          </span>
        </Button>

        <Radio.Group
          name="delivery-card-radio-group"
          defaultValue={radio}
          value={radio}
          className="customRadio"
        >
          {console.log(
            "============================",
            cart?.delivery_type?.customer_address?.[0]?.delivery_blocks_data
              ?.delivery_blocks
          )}
          {/* {cart?.delivery_type?.slice(0,2).map((item, i)  */}
          {cart?.delivery_type?.map((item, i) => (
            <StandardCard
              key={i}
              style={
                radio === item.type
                  ? {
                      border: "1px solid var(--primary)",
                      backgroundColor: "var(--gray-secondary)",
                    }
                  : {}
              }
              className="cursor-pointer"
              onClick={() => {
                setRadio(item.type);
              }}
            >
              <div className="card-header">
                <div className="d-flex align-items-start">
                  <img
                    src={
                      item.type === "standard_delivery"
                        ? "/images/standard-delivery-logo.svg"
                        : "/images/express-delivery-logo.svg"
                    }
                    alt="click here"
                  />
                  <div>
                    {/* <h5>
                      {ischeduled_deliverytem.type === "standard_delivery" ? "Standard Delivery"
                        : item.type === "express_delivery" ? "Express Delivery"
                          : item.type} | {currency_symbol}
                      {item.delivery_charges} */}

                    <h5>
                      {item.type === "standard_delivery"
                        ? "Standard Delivery"
                        : item.type === "express_delivery"
                        ? "Express Delivery"
                        : // : item.type === ""
                          // ? "Free"
                          item.type}{" "}
                      |{" "}
                      {formatDeliveryCharges(
                        item.delivery_charges,
                        currency_symbol
                      )}
                    </h5>

                    <p>{item.description}</p>
                  </div>
                </div>
                <Radio disabled={loading} value={item.type}></Radio>
              </div>
              {item.type === radio
                ? deliveryOptionDetail?.shipments.length > 1 &&
                  deliveryOptionDetail?.shipments?.map(
                    ({ name, paymentDetails, items }, i) => (
                      <Fragment key={i}>
                        {/* shipment  */}

                        <Shipment>
                          <div>
                            <h6>{name}</h6>
                            <p>Delivery Charge {paymentDetails.deliveryFee}</p>
                          </div>
                          <div>
                            <p>Delivery on 15 Dec 2021</p>
                          </div>
                          <div>
                            {selectedShipment === name ? (
                              <IoClose
                                style={{
                                  fontSize: "2em",
                                  color: "var(--gray)",
                                  cursor: "pointer",
                                }}
                                onClick={() => setSelectedShipment("")}
                              />
                            ) : (
                              <Button onClick={() => setSelectedShipment(name)}>
                                View Items
                              </Button>
                            )}
                          </div>
                        </Shipment>
                        {selectedShipment === name && (
                          <ShipmentItem data={items} />
                        )}
                      </Fragment>
                    )
                  )
                : ""}
            </StandardCard>
          ))}
          {/* <ExpressCard
                style={{
                  opacity: loading ? 0.5 : 1,
                  transition: "200ms all ease-in-out",
                  cursor: loading ? "not-allowed" : "pointer",
                }}
                onClick={() => handleChange(type)}
              >
                <img
                  src={
                    type === "standard_delivery"
                      ? "/images/standard-delivery-logo.svg"
                      : "/images/express-delivery-logo.svg"
                  }
                  alt="click here"
                />
                <div>
                  <h5>
                    {type} | ₹{delivery_charges}
                  </h5>
                  <p>{description}</p>
                </div>
                <Radio disabled={loading} value={type}></Radio>
              </ExpressCard> */}

          {/* <StandardCard>
            <div className="card-header" onClick={() => setRadio(2)}>
              <div className="d-flex align-items-start">
                <img
                  src="/images/standard-delivery-logo.svg"
                  alt="click here"
                />
                <div>
                  <h5>
                    Schedule Delivery | <span>FREE</span>
                  </h5>
                  <p>Select slot and time</p>
                </div>
              </div>
              <Radio value={2}></Radio>
            </div>
            <Shipment>
              <div>
                <h6>Shipment 1</h6>
                <p>Delivery Charge `40</p>
              </div>
              <div>
                <p>Delivery on 15 Dec 2021</p>
              </div>
              <div>
                <Button>View Items</Button>
              </div>
            </Shipment>
            <Shipment>
              <div>
                <h6>Shipment 2</h6>
                <p>
                  Delivery Charge <span>FREE</span>
                </p>
              </div>
              <div>
                <p>Standard Delivery</p>
              </div>
              <div>
                <Button>View Items</Button>
              </div>
            </Shipment>
            <Bordered></Bordered>
            <DateService>
              <h6>Select Date of Service</h6>
              <Radio.Group
                defaultValue={1}
                optionType="button"
                buttonStyle="solid"
                className="d-flex justify-content-md-start justify-content-center flex-wrap"
              >
                <Radio.Button value={1}>
                  <span>Wed</span>
                  <p>28</p>
                </Radio.Button>
                <Radio.Button value={2}>
                  <span>Thu</span>
                  <p>29</p>
                </Radio.Button>
                <Radio.Button value={3}>
                  <span>Fri</span>
                  <p>30</p>
                </Radio.Button>
              </Radio.Group>
            </DateService>
            <TimeService>
              <h6>Select Time of Service</h6>
              <Radio.Group
                defaultValue={1}
                optionType="button"
                buttonStyle="solid"
                className="d-flex justify-content-md-between justify-content-center flex-wrap"
              >
                <Radio.Button value={1}>6:00 AM - 7:00 AM</Radio.Button>
                <Radio.Button value={2}>8:00 AM - 9:00 AM</Radio.Button>
                <Radio.Button value={3}>10:00 AM - 11:00 AM</Radio.Button>
                <Radio.Button value={4}>12:00 PM - 1:00 PM</Radio.Button>
              </Radio.Group>
            </TimeService>
          </StandardCard> */}
        </Radio.Group>
        <Prices>
          <div>
            <span className="d-flex gap-2">
              <p>Total Cart Value</p>
              {deliveryOptionDetail?.paymentDetails?.tax_type &&
                deliveryOptionDetail?.paymentDetails?.total_tax > 0 && (
                  <p
                    style={{
                      color: "var(--gray)",
                      fontWeight: "normal",
                    }}
                  >{`(Inclusive of taxes)`}</p>
                )}
            </span>
            <p>
              {currency_symbol}
              {cart?.cart?.paymentDetails?.totalAmountInCart?.toFixed(2)}
            </p>
          </div>
          <div>
            <p>Delivery Fee</p>
            <p className="special">
              {/* if > then print Free >= print 0.00 */}
              {/* {deliveryOptionDetail?.deliveryFee > 0
                ? `${currency_symbol} ${deliveryOptionDetail?.deliveryFee?.toFixed(
                    2
                  )}`
                : "Free"} */}
              {loading ? (
                <LoadingOutlined
                  style={{
                    fontSize: 10,
                    color: "var(--primary)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 10,
                    height: 10,
                  }}
                  spin
                />
              ) : deliveryOptionDetail?.deliveryFee > 0 ? (
                `${currency_symbol} ${deliveryOptionDetail?.deliveryFee?.toFixed(
                  2
                )}`
              ) : (
                "Free"
              )}
            </p>
          </div>
          <div>
            <p className="special">Total Savings</p>
            <p className="special">
              {currency_symbol}
              {Number(cart?.cart?.paymentDetails?.savings).toFixed(2)}
            </p>
          </div>
        </Prices>
        <SubmitBtn>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              const selectedType = cart?.delivery_type?.find(
                (item) => item.type === radio
              );

              if (selectedType) {
                setIsModalOpen(false);
                setSelectedDeliveryDetail({
                  ...selectedType,
                  shipments_length: deliveryOptionDetail?.shipments?.length,
                });
              }
            }}
          >
            Select delivery option
          </Button>
        </SubmitBtn>
      </DeliveryModalStyle>
    </>
  );
};

export default DeliveryModal;
