import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Collapse } from "antd";
import DashboardHeader from "components/DashboardHeader";
import EditModal from "components/Modals/EditModal";
import Empty from "components/empty";
import { Container } from "react-bootstrap";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TOGGLE_LOGIN_MODAL } from "redux/Constants";
import { getProfileData } from "redux/action/appActions";
import { UserCollapse, UserInfo } from "./index.styled";

export default function Profile() {
  const customer = useSelector((state) => state.app.data.SplashScreen.customer);
  const MenuItems = useMemo(
    () => [
      { id: "idx123", name: "My Orders", path: "" },
      {
        id: "idx124",
        name: "My Account",
        path: "",
        children: [
          {
            id: "idx125",
            text: "Addresses",
            path: "/dashboard/address",
          },
          // {
          //   id: "idx126",
          //   text: "Shopping List",
          //   path: "/dashboard/shopping",
          // },
          // {
          //   id: "idx127",
          //   text: "Loyalty",
          //   path: "/dashboard/loyalty",
          // },
          // {
          //   id: "idx128",
          //   text: "Wallet",
          //   path: "/dashboard/wallet",
          // },
        ],
      },
      // { id: "idx129", name: "Membership", path: "" },
      {
        id: "idx130",
        name: "About Us",
        path: "",
        children: [
          {
            id: "idx132",
            text: "Privacy Policy",
            path: "/dashboard/policy",
          },
          {
            id: "idx133",
            text: "Terms & Conditions",
            path: "/dashboard/e-page",
          },
          {
            id: "idx134",
            text: "About us",
            path: "/dashboard/about",
          },
        ],
      },
    ],
    []
  );
  const [userData, setUserData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { Panel } = Collapse;

  const getData = async () => {
    const res = await getProfileData();
    setUserData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <DashboardHeader />
      {customer?.email || customer?.mobile ? (
        <Container className="border-none pt-2">
          <UserInfo>
            <div className="header">
              <h4>{userData?.customer?.name || "Loading..."}</h4>
              <div>
                <p onClick={() => setEditModal(true)}>Edit</p>

                <p
                  onClick={async () => {
                    await localStorage.removeItem("token");
                    navigate("/");
                  }}
                >
                  Logout
                </p>
              </div>
            </div>
            <div className="info">
              <p>
                {`${userData?.customer?.country_code || "Loading..."} ${
                  userData?.customer?.mobile || "Loading..."
                }`}{" "}
              </p>
              <div></div>
              <p>{userData?.customer?.email}</p>
            </div>
          </UserInfo>

          <UserCollapse collapsible="false">
            {MenuItems?.map((item, index) => {
              if (item.children?.length) {
                return (
                  <Panel header={<h4>{item.name}</h4>} key={item.id}>
                    {item.children?.map((item) => {
                      const { text, path, active } = item;
                      return (
                        <p
                          className={`linkPara ${active}`}
                          onClick={() => navigate(path)}
                        >
                          <span>{text}</span>
                          <MdOutlineKeyboardArrowRight />
                        </p>
                      );
                    })}
                  </Panel>
                );
              } else {
                return (
                  <Panel
                    className="not-collapsible"
                    header={<h4>{item.name}</h4>}
                    key="1"
                    onClick={() => navigate("/dashboard/orders")}
                  />
                );
              }
            })}
          </UserCollapse>
        </Container>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty
            title="Please Login to Continue"
            description="login with number or Email to access the cart."
            buttonText="Login"
            icon="arrow-box"
            // buttonCallback={() => setPhoneNumberModal(true)}
            buttonCallback={() => dispatch({ type: TOGGLE_LOGIN_MODAL })}
          />
        </div>
      )}

      <EditModal
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        setOtpModal={setOtpModal}
      />
    </Fragment>
  );
}
