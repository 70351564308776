import React, { useState } from "react";
import { Button, message } from "antd";
import CancelOrderPopup from "components/Modals/CancelOrderPopupModal";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelOrder, getOrders } from "redux/action/appActions";
import { Card } from "./styles";

const OrderCard = ({
  title,
  heading,
  subheading,
  text,
  price,
  setStep,
  id,
  order_status,
}) => {
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleConsole = () => {};

  const handleCancel = async () => {
    const res = await cancelOrder({ order_id: id, status: "Cancelled" });

    if (res.status) {
      message.success("Order Cancelled Successfully");
    } else {
      message.error("Failed to Cancel Order");
    }
    dispatch(getOrders(handleConsole));
  };

  return (
    <Card>
      <div className="card-header">
        <p>{title}</p>
        <div
          className="icon"
          onClick={() => navigate(`/dashboard/orders/${id}`)}
        >
          <MdKeyboardArrowRight />
        </div>
      </div>
      <div className="card-body">
        <div>
          <h4>{heading}</h4>
          <p>
            {localStorage.getItem("ref") === "ORDRIO-1015"
              ? ["Delivered", "Cancelled"].includes(order_status)
                ? ""
                : "Your order will be delivered soon"
              : subheading}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <div
            className="dot"
            style={
              order_status === "Received"
                ? { backgroundColor: "var(--lime-green)" }
                : order_status === "Out_For_Delivery"
                ? { backgroundColor: "var(--dark-orange-primary)" }
                : order_status === "Delivered"
                ? { backgroundColor: "var(--sea-green)" }
                : order_status === "Cancelled"
                ? { backgroundColor: "var(--red-primary)" }
                : order_status === "Pending"
                ? { backgroundColor: "var(--lemon-yellow)" }
                : order_status === "Accepted"
                ? { backgroundColor: "var(--orange-primary)" }
                : order_status === "Packed"
                ? { backgroundColor: "var(--dark-blue-primary)" }
                : order_status === "Assign_Delivery"
                ? { backgroundColor: "var(--orange-primary)" }
                : order_status === "Delivery_Partner_is_on_the_way_to_store"
                ? { backgroundColor: "var(--orange-primary)" }
                : order_status === "Waiting_for_Order_to_be_ready"
                ? { backgroundColor: "var(--orange-primary)" }
                : order_status === "Rescheduled"
                ? { backgroundColor: "var(--dark-orange-secondary)" }
                : {}
            }
          ></div>
          <p>{text}</p>
        </div>
      </div>
      <div className="card-footer">
        <h5>{`${currency_symbol}${price.toFixed(2)}`}</h5>
        {order_status !== "Out_For_Delivery" &&
        order_status !== "Cancelled" &&
        order_status !== "Failed" &&
        order_status !== "Accepted" &&
        order_status !== "Assign_Delivery" &&
        order_status !== "Pending" ? (
          <Button
            onClick={() => {
              order_status === "Received" && setIsModalVisible(true);
            }}
          >
            {order_status === "Received"
              ? "Cancel Order"
              : order_status === "Delivered"
              ? "Rate Order"
              : ""}
          </Button>
        ) : (
          ""
        )}
      </div>
      <CancelOrderPopup
        orderNumber={heading}
        onConfirm={handleCancel}
        isModalVisible={isModalVisible}
        setIsModalVisible={() => setIsModalVisible()}
      />
    </Card>
  );
};

export default OrderCard;
