import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Collapse } from "antd";
import Container from "react-bootstrap/Container";
import Header from "components/Header";
import DashboardHeader from "components/DashboardHeader";
import ProductLinks from "components/ProductLinks";
import Carousel from "../../components/Carousel";
import BakeryCard from "../../components/Cards/BakeryCard";
import DealSection from "components/DealSection";
import CategoriesSection from "components/CategoriesSection";
import ExploreSection from "components/ExploreSection";
import DashboardFooter from "components/DashboardFooter";

import NewDashboardFooter from "components/NewDashboardFooter";
import NewFooter from "components/NewFooter";

import Footer from "components/Footer";
import "./home-page.scss";
import { BakerySectionData } from "../../data/BakerySectionData";
import { BrandsSectionData } from "../../data/BrandsSectionData";
import { FocusBrandsSectionData } from "../../data/FocusBrandsSectionData";
import { FocusArea, HomeArea } from "./index.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "shimmer-react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { fileURL } from "helpers/baseURL";
import DefaultImage from "../../assets/images/default_image.svg";
import BrandCarousel from "screens/brands/BrandCarousel";
import CategoryCarousel from "screens/Carousel/CategoryCarousel";
import BannerCarousel from "screens/Carousel/BannerCarousel";
import SquareBannerCarousel from "screens/Carousel/SquareBannerCarousel";

const { Panel } = Collapse;
const utsavkartOrigin = "utsavkart.com";
const redirectRoute = {
  Category: "/featured-categories/",
  Brands: "/featured-brands/",
  "Specific Product": "/featured-products/",
};
const typeHash = {
  Category: "categories",
  Brands: "brands",
  "Specific Product": "products",
};

export default function Home() {
  const categories = useSelector((state) => state.app.data.categories);
  const navigate = useNavigate();
  const homePage = useSelector((state) => state.app.data);

  const onBannerClick = useCallback((bannerItem) => {
    const { items_for = "", items = [] } = bannerItem;

    if (items_for) {
      let makeParams = {
        type: typeHash[items_for],
      };
      makeParams[items.length === 1 ? "id" : "ids"] =
        items.length === 1
          ? items[0]?.id
          : JSON.stringify(items?.map((item) => item.id));

      navigate(
        `${redirectRoute[items_for]}${btoa(JSON.stringify(makeParams))}`
      );

      // if (items.length === 1) {
      //   makeParams.id = items[0]?.id;
      // } else {
      //   makeParams.ids = items.map((item) => item.id);
      // }
    }
  }, []);

  return (
    <Fragment>
      <Header />
      <DashboardHeader />
      <ProductLinks />
      <HomeArea>
        <div className="home-container">
          <div className="d-flex flex-column gap-5 mt-3">
            {window.location.origin.includes(utsavkartOrigin) && (
              <CategoryCarousel categories={categories} />
            )}
            {homePage?.HomePage?.data[0]?.home_page?.map((item, index) => {
              if (
                item?.banner_style === "Landscape" &&
                item?.banner_type === "Slider" &&
                item?.type === "banners"
              ) {
                return <BannerCarousel item={item?.nested_data} key={index} />;
              } else if (
                item?.banner_style === "Square" &&
                item?.banner_type === "Slider" &&
                item?.type === "banners"
              ) {
                return (
                  <SquareBannerCarousel item={item?.nested_data} key={index} />
                );
              } else if (
                item?.banner_style === "Square" &&
                item?.banner_type === "Static" &&
                item?.type === "banners"
              ) {
                return (
                  <SquareBannerCarousel item={item?.nested_data} key={index} />
                );
              } else if (
                item?.description === "<h2>Deal of the day</h2>" ||
                (item?.type === "products" &&
                  item?.description !== "<h2>Snack store</h2>")
              ) {
                return (
                  <div
                    style={{
                      backgroundColor: item?.background_color,
                      // padding: "20px",
                      borderRadius: "10px",
                    }}
                    key={index}
                  >
                    <DealSection data={item} />
                  </div>
                );
              } else if (
                item?.type === "products" &&
                item?.description === "<h2>Snack store</h2>"
              ) {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: item?.background_color,
                      // padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <DealSection data={item} />
                  </div>
                );
              } else if (item?.description === "<p>ttttt</p>") {
                return (
                  <Row gutter={[{ sm: 10, md: 20, lg: 40 }]} key={index}>
                    {item?.nested_data?.map((item, i) => (
                      <Col sm={12} md={12} lg={12} xs={24} key={i}>
                        <img
                          className="my-lg-0 my-1"
                          style={{ width: "100%" }}
                          src={`${fileURL}${item.image}`}
                          alt="click here"
                        />
                      </Col>
                    ))}
                  </Row>
                );
              } else if (
                item?.category_type === "Static" &&
                item?.type === "categories"
              ) {
                return <CategoriesSection data={item} key={index} />;
              } else if (
                item?.banner_style === "Landscape" &&
                item?.banner_type === "Slider" &&
                item?.type === "banners"
              ) {
                return (
                  <Fragment key={index}>
                    {item?.nested_data?.length ? (
                      <Carousel
                        showSlide={3}
                        centerMode={false}
                        data={item?.nested_data?.length}
                        hasNextArrow={false}
                      >
                        {item?.nested_data?.map((item, i) => (
                          <img
                            key={item.id}
                            src={`${fileURL}/${item.image}`}
                            alt="click here"
                          />
                        ))}
                      </Carousel>
                    ) : (
                      ""
                    )}
                  </Fragment>
                );
              } else if (
                item?.brand_type === "Slider" &&
                item?.type === "brands"
              ) {
                return (
                  <FocusArea key={index}>
                    <BrandCarousel item={item} />
                  </FocusArea>
                );
              } else if (item?.description === "<h2>Deal of the day</h2>") {
                return <DealSection data={item} key={index} />;
              } else if (
                item?.description === "<h2>Explore more categories</h2>"
              ) {
                return <ExploreSection data={item} key={index} />;
              }
              return <Fragment key={index} />;
            })}
          </div>
        </div>

        {/* <DashboardFooter /> */}
        {/* <Footer /> */}

        <div className="d-sm-none d-xs-none d-md-none d-lg-block">
          <NewDashboardFooter />
          <NewFooter />
        </div>

        <Collapse
          className="border-0 border-b-0 d-md-block d-lg-none d-xl-none footer-collapse"
          expandIconPosition="end"
        >
          <Panel
            header={<p className="font-large">Helpful Links</p>}
            className="p-0"
          >
            <NewDashboardFooter />
            <NewFooter />
          </Panel>
        </Collapse>
      </HomeArea>
    </Fragment>
  );
}
