import React from "react";
import { Bill, BillDetails } from "./index.styled";
import { useDispatch, useSelector } from "react-redux";

export default function BillDetail() {
  const cart = useSelector((state) => state.app.cart);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);

  return (
    <BillDetails className="mt-3">
      <h6>Bill Details</h6>
      <Bill>
        <p>{`Total Cart Value ${
          cart.cart.paymentDetails?.tax_type === "inclusive" &&
          cart.cart.paymentDetails?.total_tax > 0
            ? "(Inclusive of all Taxes)"
            : ""
        }`}</p>
        <p>
          {currency_symbol}
          {cart?.cart?.paymentDetails?.totalAmountInCart?.toFixed(2)}
        </p>
      </Bill>
      <Bill>
        <p>Item Savings</p>
        <p className="special">
          -{currency_symbol}
          {Number(cart?.cart?.paymentDetails?.savings)?.toFixed(2)}
        </p>
      </Bill>
      {/* <Bill>
        <p>Loyalty</p>
        <p>
          -{currency_symbol}
          {cart?.cart?.paymentDetails?.loyalty?.toFixed(2)}
        </p>
      </Bill> */}
      <Bill>
        <p>Delivery Fee</p>
        <p className="special">
          {cart?.cart?.paymentDetails?.deliveryFee > 0
            ? `${currency_symbol}${cart?.cart?.paymentDetails?.deliveryFee?.toFixed(
                2
              )}`
            : "Free"}
        </p>
      </Bill>
    </BillDetails>
  );
}
