import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--white-smoke-primary);
  padding-bottom: 0.575em;
  margin-bottom: 1.75em;
  .cashback-logo {
    width: 50px;
    height: 50px;
    background-color: var(--white-smoke-secondary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    img {
      &.loyalty {
        width: 60%;
        height: 60%;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(13deg)
          brightness(104%) contrast(101%);
      }
    }
  }
  h6 {
    font-weight: 400;
    margin-bottom: 0.3em;
  }
  p {
    font-size: 1.15em;
    font-weight: 300;
    color: var(--gray);
    margin: 0;
  }
  h4 {
    font-size: 1.75em;
    font-weight: 400;
    color: var(--primary);
  }
  & {
    @media (max-width: 576px) {
      display: block;
      h4 {
        text-align: end;
      }
    }
  }
`;
