import React from "react";
import { Button } from "antd";
import VariationCard from "components/Cards/VariationCard";
import { data } from "data/VariationCardsData";
import { ModalStyle, SubmitBtn } from "../styles";
import { useSelector } from "react-redux";
import { fileURL } from "helpers/baseURL";

const VariationModal = ({
  isModalOpen,
  setIsModalOpen,
  product_variations,
  location_id,
  id,
}) => {
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  return (
    <>
      <ModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        closable={false}
      >
        {product_variations?.map((item) => {
          const offText = Math.round(
            item?.product_batches[0]?.discount_percentage
          );

          const discountedPrice = item?.product_batches[0]?.sale_price === 0 ? item?.product_batches[0]?.mrp : item?.product_batches[0]?.sale_price
          return (
            <VariationCard
              key={item._id}
              image={`${fileURL}${item.product_images[0]?.path}`}
              quantity={item?.name + item?.unit_of_measure}
              discount={`${currency_symbol}${discountedPrice.toFixed(2)}`}
              price={`${currency_symbol}${item?.product_batches[0]?.mrp.toFixed(2)}`}
              sale={offText === 0 ? 0 : `${offText}% OFF`}
              data={{
                product_id: id,
                variation_id: item?.id,
                batch_id: item?.product_batches[0]?.id,
                location_id: location_id,
                type: item?.type,
                membership_plan_id: "",
              }}
            />
          );
        })}
        <SubmitBtn>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setIsModalOpen(false)}
          >
            Continue
          </Button>
        </SubmitBtn>
      </ModalStyle>
    </>
  );
};

export default VariationModal;
