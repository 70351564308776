import React, { useEffect, useState } from "react";
import { AddressCardsData } from "data/AddressCardsData";
import { AddAddress } from "./index.styled";
import {
  MdKeyboardArrowLeft,
  MdOutlineAddCircleOutline,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import AddressCard from "components/Cards/AddressCard";
import AddAddressModal from "components/Modals/AddAddressModal";
import ConfirmModal from "components/Modals/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAddress,
  getCartItems,
  getProfileData,
} from "redux/action/appActions";
import EditAddressModal from "components/Modals/EditAddressModal";
import Empty from "components/empty";
import { UPDATE_USER_ADDRESS } from "redux/Constants";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function Address() {
  const cart = useSelector((state) => state.app.cart);
  const user = useSelector((state) => state.app.data);
  const [addressModal, setAddressModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();

  const getData = async () => {
    const res = await getProfileData();
    setUserData(res);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 100,
      }}
      spin
    />
  );

  const handleConsole = () => {};

  const handleDelete = async () => {
    const res = await deleteAddress(selectedAddress.id);
    dispatch({ type: UPDATE_USER_ADDRESS, payload: res });
    dispatch(getCartItems(handleConsole));
    getData();

    setDeleteModal(false);
    setSelectedAddress("");
  };

  return (
    <>
      {" "}
      <AddAddress>
        <h4 style={{ fontWeight: "600" }}>Addresses</h4>
        {userData?.customer?.customer_address?.length ? (
          <>
            <p className="addNew" onClick={() => setAddressModal(true)}>
              <MdOutlineAddCircleOutline />
              Add new address
            </p>

            {userData?.customer?.customer_address?.map((item) => {
              return (
                <AddressCard
                  key={item?.id}
                  image={`/images/${
                    item?.address_type === "Home"
                      ? "address-card-logo-3.svg"
                      : "work-logo.svg"
                  }`}
                  heading={item?.address_type}
                  subheading={item?.location_address}
                  text={`Ph : ${item?.mobile}`}
                  reachAddress={item?.reach_address}
                  address={item?.address}
                  showDeleteModal={() => {
                    setSelectedAddress(item);
                    setDeleteModal(true);
                  }}
                  showEditModal={() => {
                    setSelectedAddress(item);
                    setEditModal(true);
                  }}
                />
              );
            })}
          </>
        ) : loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty
              title="No address found!"
              description="You don't have any addresses saved."
              buttonText="Add an address"
              icon="address_empty"
              buttonCallback={() => setAddressModal(true)}
            />
          </div>
        )}
      </AddAddress>
      {addressModal && (
        <AddAddressModal
          isModalOpen={addressModal}
          setIsModalOpen={setAddressModal}
          edit={editModal}
          selectedAddress={selectedAddress}
          callProfile={() => getData()}
        />
      )}
      <EditAddressModal
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        selectedAddress={selectedAddress}
        callProfile={() => getData()}
      />
      <ConfirmModal
        isModalOpen={deleteModal}
        setIsModalOpen={() => {
          setDeleteModal(false);
          setSelectedAddress("");
        }}
        handleOK={handleDelete}
      />
    </>
  );
}
