import React from "react";
import { Card } from "./styles";
import { fileURL } from "helpers/baseURL";

const ExploreCard = ({ heading, image }) => {
  return (
    <Card className="cursor-pointer flex-1">
      <p className="text-overflow-none">{heading}</p>
      <img src={`${fileURL}${image}`} alt="click here" className="img-fluid" />
    </Card>
  );
};

export default ExploreCard;
