import styled from "styled-components";

export const Card = styled.div`
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.36em;
  padding: 2.2em 1.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-right: 1em;
    &.loyalty {
      width: 28px;
      height: 28px;
      filter: invert(66%) sepia(85%) saturate(1822%) hue-rotate(343deg)
        brightness(100%) contrast(102%);
    }
  }
  h5 {
    font-size: 1.2em;
  }
  .card-info {
    p {
      font-size: 0.86em;
      font-weight: 400;
      color: var(--gray);
      margin: 0;
    }
  }
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 32px;
        height: 32px;
        border: 1px solid var(--primary);
        &::after {
          width: 9px;
          height: 19px;
          top: 45%;
        }
      }
      &::after {
        display: none;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: var(--primary) !important;
          border-color: var(--primary) !important;
        }
      }
    }
  }
  & {
    @media (min-width: 1600px) {
      img {
        margin-right: 1.5em;
      }
      h5 {
        font-size: 1.44em;
      }
    }
    @media (max-width: 1400px) {
      flex-direction: column;
      justify-content: space-around;
      padding: 1.15em;
      & > div {
        &:first-child {
          margin: 0 auto 0.5em 0;
        }
        &:last-child {
          margin: 0.5em 0 0 auto;
        }
      }
    }
    @media (max-width: 767px) {
      margin: 0.5em 0;
    }
  }
`;
