import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import VariationModal from "components/Modals/VariationModal";
import {
  AddBtn,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardInfo,
  OptionButton,
  QuantityBtn,
  SubscribeBtn,
  Heading,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  incrementCart,
  decrementCart,
  getProductDetails,
} from "redux/action/appActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { SpeedoMeter } from "assets/svg";
import "./DealCard.css";
import { getOptionsText } from "helpers/helpers";

const DealCard = ({
  link,
  cursor,
  offText,
  brandLogo,
  brandText,
  brandImage,
  footerText,
  heading,
  subheading,
  discount,
  price,
  option,
  quantity,
  subscribe,
  data,
  id,
  product_variations,
}) => {
  const cartData = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [total, setTotal] = useState("1");
  const [variationModal, setVariationModal] = useState(false);
  const [counterVisible, setCounterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const callback = (status) => {
    if (status) {
      setCounterVisible(true);
    }
    setLoading(false);
  };

  const addtoCart = async () => {
    setLoading(true);
    dispatch(addCart(data, callback));
  };

  useEffect(() => {
    if (cartData.HomePage && data.product_id) {
      const product = cartData?.HomePage?.data[0]?.cart?.cData.find(
        (item) => item.product_id === data.product_id
      );
      if (product) {
        setTotal(product.quantity);
      } else {
        setTotal("1");
      }
    }
  }, [cartData, data]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:24,
        height:24,
      }}
      spin
    />
  );
  const handleDecrement = async () => {
    setLoading(true);
    // (total >= 1 && total % 5 !== 0)

    const callback = (status) => {
      if (status) {
        if (total > 1) {
          setTotal(total - 1);
          setLoading(false);
        } else {
          setCounterVisible(false);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    dispatch(
      decrementCart(
        {
          product_id: data?.product_id,
          variation_id: data?.variation_id,
          batch_id: data?.batch_id,
          location_id: data?.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };
  const handleIncrement = async () => {
    setLoading(true);
    const callback = (status) => {
      if (status) {
        setTotal(total + 1);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    dispatch(
      incrementCart(
        {
          product_id: data?.product_id,
          variation_id: data?.variation_id,
          batch_id: data?.batch_id,
          location_id: data?.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };
  return (
    <div
      className="new-bbbb"
      style={{
        background: "white !important",
      }}
    >
      <Card
        style={{
          // border: '2px solid red',
          backgroundColor: "white",
          borderRadius: "14px",
        }}
        onClick={() => {
          dispatch({
            type: "SET_SELECTED_PRODUCT",
            payload: "",
          });
          navigate(`/product/${id}`);
        }}
      >
        <CardHeader
          style={{
            height: 20,
          }}
        >
          {offText && offText !== 0 ? (
            <div>
              <p>{offText}</p>
            </div>
          ) : (
            <></>
          )}
          {/* <div>
            <p>
              <SpeedoMeter /> Express
              {brandText}
            </p>
          </div> */}
        </CardHeader>

        <CardBody
          style={{
            position: "relative",
            // border: "2px solid",
            padding: "10px 0px",
            height: "130px",
            boxSizing: "border-box",
          }}
        >
          <img
            style={{
              margin: "0.5em auto",
              objectFit: "contain",
              minHeight: "60px",
              minWidth: "80%",
              maxWidth: "100%",
              maxHeight: "100px",
            }}
            src={brandImage}
            alt="click here"
          />

          {/* quantity ==Stock */}
          {!product_variations[0]?.product_batches[0]?.stock ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                fontSize: "20px",
                fontWeight: "600",
                color: "var(--primary)",
              }}
            >
              Out of Stock
            </div>
          ) : null}
        </CardBody>

        {/* Img bottom text */}
        {/* <CardHeader>
          <div style={{ width: "100%" }}>
            <p>
              $18 Membership Price
            </p>
          </div>
        </CardHeader> */}

        {/* <CardFooter>
          <span>{footerText}</span>
        </CardFooter> */}
      </Card>
      <Heading
        className="font-sm-xx-small font-md-small font-lg-small font-xl-small font-small mt-2"
        style={{
          overflow: "hidden",
          // border: "2px solid orange",
          maxWidth: "200px",
          height: "30px",
          textOverflow: "ellipsis",
          padding: "0px 2px",
          whiteSpace: "nowrap",
        }}
      >
        {heading}
      </Heading>

      {/* CardInfo */}

      <p
        className="p-0"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {subheading}
      </p>

      <CardInfo
        className="d-flex align-items-center"
        style={{
          // border: "2px solid red",
          padding: "0px 2px",
        }}
      >
        <div className="d-flex align-items-center gap-1">
          <h6>{`${currency_symbol}${Number(discount===0 ? price : discount).toFixed(2)}`}</h6>
          {discount!=0&& <span className="discount">{`${currency_symbol}${Number(price).toFixed(2)}`}</span>}
        </div>

        <div style={{ height: "34px" }}>
          {option ? (
            <>
              {/* <OptionDrop menu={{ items }} placement="bottom">
          </OptionDrop> */}
              {product_variations.length > 1 ? (
                <OptionButton onClick={() => setVariationModal(true)}>
                  {getOptionsText(product_variations.length)}
                  <RiArrowDownSLine />
                </OptionButton>
              ) : (cartData?.HomePage &&
                  cartData?.HomePage?.data[0]?.cart?.cData?.find(
                    (item) => item.product_id === data.product_id
                  )) ||
                counterVisible ? (
                <QuantityBtn>
                  <span onClick={handleDecrement}>-</span>
                  <span>{total}</span>
                  <span onClick={handleIncrement}>+</span>
                </QuantityBtn>
              ) : (
                <AddBtn
                  onClick={() => {
                    addtoCart();
                  }}
                >
                  <span>Add +</span>
                </AddBtn>
              )}
              <VariationModal
                isModalOpen={variationModal}
                setIsModalOpen={setVariationModal}
                product_variations={product_variations}
                location_id={data.location_id}
                id={data.product_id}
              />
            </>
          ) : (
            <>
              {loading ? (
                <div style={{ margin: "0 16px 16px 0" }}>
                  <Spin indicator={antIcon} />
                </div>
              ) : !counterVisible &&
                !(
                  cartData?.HomePage &&
                  cartData?.HomePage?.data[0]?.cart?.cData?.find(
                    (item) => item?.product_id === data?.product_id
                  )
                ) ? (
                <div>
                  {product_variations[0]?.product_batches[0]?.stock > 0 ? (
                    <AddBtn
                      onClick={() => {
                        addtoCart();
                      }}
                    >
                      <span>Add +</span>
                    </AddBtn>
                  ) : (
                    <>
                      <AddBtn>
                        <span>Out of Stock</span>
                      </AddBtn>
                    </>
                  )}
                </div>
              ) : (cartData?.HomePage &&
                  cartData?.HomePage?.data[0]?.cart?.cData?.find(
                    (item) => item.product_id === data.product_id
                  )) ||
                counterVisible ? (
                <QuantityBtn>
                  <span onClick={handleDecrement}>-</span>
                  <span>{total}</span>
                  <span onClick={handleIncrement}>+</span>
                </QuantityBtn>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </CardInfo>

      {/* Subscribe */}
      {/* {subscribe === 1 ? (
        <SubscribeBtn href="#">
          <p>
            <img src="/images/subscribe-logo.svg" alt="click here" />
            Subscribe
          </p>
          <p>
            <MdKeyboardArrowRight />
          </p>
        </SubscribeBtn>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default DealCard;
