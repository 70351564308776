import styled from "styled-components";

export const HamburgerMenu = styled.button`
  padding: 10px;
  border: none;
  background: var(--gray-secondary);
`;

export const CategoryButton = styled.button`
  /* width: 180px; */
  /* height: 50px; */
  /* width: 200px;
  height: 60px; */
  /* font-size: 1em; */
  padding: 0.75em 1.5em;
  font-size: 1.375em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white) !important;
  background: var(--primary);
  border-radius: 8px;
  border: 0;
  outline: 0;
  box-shadow: none;
  margin-right: 1em;
  position: absolute;
  left: 0;
  top: 0;
  img {
    width: 20px;
    margin-right: 0.375em;
  }

  & {
    @media (min-width: 1400px) {
      margin-right: 1.5em;
      /* width: 224px; */
      img {
        width: 29px;
        margin-right: 0.51em;
      }
    }
    @media (max-width: 576px) {
      padding: 0.5em 1em;
    }
    /* @media (min-width: 1640px) {
      width: 220px;
      height: 55px;
      font-size: 1.375em;
      margin-right: 1.5em;
      img {
        margin-right: 1em;
      }
    }
    @media (min-width: 1710px) {
      width: 244px;
      height: 60px;
      img {
        width: 28px;
      }
    } */
    /* @media (max-width: 1199px) {
      width: 200px;
      height: 50px;
    } */
  }
`;
export const Labels = styled.div`
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content:between
  // width: max-content;
  // > div {
  //   overflow: auto;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }

  .ant-tabs-nav-more {
    width: 60px !important;
    .anticon {
      display: none !important;
    }

    &:after {
      right: 0;
      bottom: 0;
      left: 0;
      top: 50% !important;
      transform: translateY(-50%) !important;
      content: "More" !important;
      height: auto !important;
      font-size: 1.2em;
      font-weight: 400;
    }
  }

  .ant-tabs-nav {
    margin: 0;
    
  }
  .ant-tabs-ink-bar {
    display: none;
   
  }
  .ant-tabs-nav {
    &:before {
      display: none;
      
    }
  }
  .ant-tabs {
    margin-left: 12em;
    
    @media (min-width: 1800px) {
      margin-left: 15em;

    }
  }
  .links {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // .custom-more-tab-content {
  //   height: 400px; 
  //   overflow-y: auto;
  // }

  // .ant-tabs-nav-more::after{
  //   content: "",
  //   height: 400px; 
  //   overflow-y: auto;

  }
`;
export const Text = styled.a`
  font-size: medium;
  color: var(--black) !important;
  margin: 0.375em 1.5em 0 0;
  font-weight: 400;
  text-shadow: none;
  // white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
  & {
    @media (max-width: 1440px) {
      font-size: larger;
    }
    @media (max-width: 1200px) {
      font-size: large;
    }
    @media (max-width: 996px) {
      font-size: medium;
    }
    @media (max-width: 768px) {
      font-size: small;
    }
  }
`;
