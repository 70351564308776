import styled from "styled-components";

export const ItemListArea = styled.div`
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  .fixedScroll {
    height: calc(100vh - 159px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & {
    @media (max-width: 992px) {
      overflow-y: unset;
      .fixedScroll {
        height: 100%;
        &:first-child {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
  .infinite-scroll-component {
    overflow-x: hidden !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
