import styled from "styled-components";

export const ExpressCard = styled.div`
  width: 100%;
  /* height: 100%; */
  /* min-height: 89px; */
  border: 1px solid var(--grey-legendary);
  border-radius: 0.375em;
  padding: 1.25em;
  margin-bottom: 1.25em;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 1.5em;
  }

  h5 {
    font-weight: 600;
    color: var(--black);
    font-size: 1.25em;
  }
  p {
    font-size: 0.75em;
    font-weight: 400;
    color: var(--gray);
    margin: 0;
  }
  .ant-radio-wrapper {
    margin-left: auto;
    .ant-radio-inner {
      width: 26px;
      height: 26px;
      border: 1px solid var(--grey-legendary);
      position: relative;
      &::after {
        padding: 1.5625em;
        position: absolute;
        left: -36%;
        top: -39%;
        background: var(--primary);
        border-radius: 50%;
        margin-block-start: -1px;
        margin-inline-start: -1px;
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        background-color: unset !important;
      }
    }
  }
  & {
    @media (max-width: 576px) {
      padding: 0 1em;
      img {
        margin-right: 1em;
      }
      h5 {
        font-size: 1em;
        margin-bottom: 0.25em;
      }
    }
  }
`;
export const StandardCard = styled.div`
  padding: 1.5em 0;
  border: 1px solid var(--grey-legendary);
  border-radius: 0.375em;
  margin-bottom: 0.875em;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25em;
    margin-bottom: 1.5em;
    cursor: pointer;
    img {
      margin-right: 1.5em;
    }
    h5 {
      font-weight: 600;
      color: var(--black);
      font-size: 1.25em;
      span {
        color: var(--primary);
      }
    }
    p {
      font-size: 0.75em;
      font-weight: 400;
      color: var(--gray);
      margin: 0;
    }
    .ant-radio-wrapper {
      .ant-radio-inner {
        width: 26px;
        height: 26px;
        border: 1px solid var(--grey-legendary);
        position: relative;
        &::after {
          padding: 1.5625em;
          position: absolute;
          left: -36%;
          top: -39%;
          background: var(--primary);
          border-radius: 50%;
          margin-block-start: -1px;
          margin-inline-start: -1px;
        }
      }
      &.ant-radio-wrapper-checked {
        .ant-radio-inner {
          background-color: unset !important;
        }
      }
    }
  }
  & {
    @media (max-width: 576px) {
      .card-header {
        padding: 0 1em;
        img {
          margin-right: 1em;
        }
        h5 {
          font-size: 1em;
          margin-bottom: 0.25em;
        }
      }
    }
  }
`;
export const Shipment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25em 0.75em;
  margin-bottom: 0.5em;
  h6 {
    font-weight: 600;
    color: var(--black);
  }
  p {
    font-size: 0.75em;
    color: var(--gray);
    margin: 0;
    span {
      color: var(--primary);
    }
  }
  button {
    width: 117px;
    height: 33px;
    border: 1px solid var(--grey-legendary);
    border-radius: 0.625em;
    span {
      font-size: 0.8125em;
      font-weight: 400;
      color: var(--primary);
    }
  }
  &:first-child {
    border-bottom: 2px solid var(--white-smoke-secondary);
  }
  & {
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      & > div {
        &:nth-child(2) {
          margin: 0.5em auto;
        }
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
`;
export const Bordered = styled.div`
  width: 100%;
  height: 11px;
  background-color: var(--white-smoke-secondary);
  margin-bottom: 1.5em;
`;
export const DateService = styled.div`
  padding: 0 1.25em;
  margin-bottom: 1.5em;
  .ant-radio-button-wrapper {
    width: 4.5em;
    height: 4.5em;
    border: 1px solid var(--light-grey) !important;
    border-radius: 1em;
    padding: 0;
    margin: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &::before {
      display: none;
    }
    &:hover {
      border-color: var(--primary) !important;
      background: unset !important;
      & > span:last-child {
        p {
          color: var(--primary) !important;
        }
      }
    }
    &.ant-radio-button-wrapper-checked {
      border-color: var(--primary) !important;
      background-color: var(--grey-secondary);
    }
    & > span:last-child {
      span {
        font-size: 0.75em;
        font-weight: 300;
        color: var(--gray);
      }
      p {
        font-size: 1em;
        font-weight: 500;
        color: var(--black);
        margin: 0;
      }
    }
  }
`;
export const TimeService = styled.div`
  padding: 0 1.25em;
  .ant-radio-button-wrapper {
    width: 228px;
    height: 44px;
    border: 1px solid var(--grey-legendary) !important;
    border-radius: 0.625em;
    padding: 0;
    margin: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: none;
    }
    &:hover {
      border-color: var(--primary) !important;
      background: unset !important;
      & > span:last-child {
        color: var(--primary) !important;
      }
    }
    &.ant-radio-button-wrapper-checked {
      border-color: var(--primary) !important;
      background-color: var(--grey-secondary);
    }
    & > span:last-child {
      font-size: 1em;
      font-weight: 400;
      color: var(--black);
      margin: 0;
    }
  }
`;
export const Prices = styled.div`
  margin: 1em 0;
  div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.43em;
    p {
      font-size: 1em;
      font-weight: 600;
      color: var(--black);
      margin: 0;
      &.special {
        color: var(--primary);
      }
    }
  }
`;
