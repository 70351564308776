import React, { useEffect, useState } from "react";
import { WalletContent, WalletInfo } from "./index.styled";
import { LoyaltyTransactionData } from "data/LoyaltyTransactionData";
import TransactionCard from "components/Cards/TransactionCard";
import { getLoyaltyWallet, getAbout } from "redux/action/appActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Empty from "components/empty";

export default function Loyalty() {
  const [data, setData] = useState("");
  const user = useSelector((state) => state.app.data);
  const navigate = useNavigate();
  const getData = async () => {
    const res = await getLoyaltyWallet("loyalty", );
    setData(res);
  };
  useEffect(() => {
    getData();
  }, []);
  if (data?.data?.length) {
    return (
      <>
        <WalletInfo>
          <h4>Available Points</h4>
          <h1>{data?.availablePoints}</h1>
        </WalletInfo>
        <WalletContent>
          <h5 style={{ marginBottom: "1.5em" }}>Loyalty Transaction</h5>
          {data?.data?.map((item) => {
            const { created_at, credit, debit } = item;
            if (Number(credit) > 0 || Number(debit) > 0) {
              return (
                <TransactionCard
                  image={"/images/loyalty-star-icon.png"}
                  loyalty="loyalty"
                  heading={"Cashback Received from Grocbay"}
                  subheading={moment(created_at).format("DD MMM, YYYY hh:mm A")}
                  balance={
                    Number(credit) > 0
                      ? `+${Number(credit).toFixed(2)}`
                      : Number(debit) > 0
                      ? `-${Number(debit).toFixed(2)}`
                      : ""
                  }
                />
              );
            }
          })}
        </WalletContent>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          title="No loyalty points"
          description="You haven't made any transactions yet"
          buttonText="Back to home"
          icon="loyality_empty"
          buttonCallback={() => navigate("/")}
        />
      </div>
    );
  }
}
