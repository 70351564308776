import React from "react";
import { Button, Modal } from "antd";
import "./styles.css";

const CancelOrderPopup = ({
  orderNumber,
  onConfirm,
  isModalVisible = false,
  setIsModalVisible,
}) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    onConfirm();
  };

  return (
    <>
      <Modal
        title="Confirm Order Cancellation"
        open={isModalVisible}
        style={{}}
        closable={false}
        onCancel={() => {}}
        centered
        footer={null}
      >
        <div>
          <h4 className="order-number">{orderNumber}</h4>
          <p className="content-cancel-popup">
            Are you sure you want to cancel this order?
          </p>
          <p className="content-cancel-popup">This action cannot be undone</p>
          <div className="custom-footer">
            <Button key="confirm" className="primary-btn" onClick={handleConfirm}>
              Yes, cancel the order
            </Button>
            <Button key="cancel" className="secondary-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelOrderPopup;