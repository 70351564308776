import styled from "styled-components";

export const Card = styled.div`
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.75em;
  padding: 1.86em 2.15em;
  margin: 0 0 1.43em;
  h5,
  p {
    margin: 0;
  }
  p {
    font-size: 1.286em;
    font-weight: 300;
    color: var(--gray);
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.15em;
    .icon {
      /* width: 32px;
    height: 27px; */
      width: max-content;
      padding: 0.15em 0.286em;
      border: 1px solid var(--white-smoke-primary);
      border-radius: 0.215em;
      cursor: pointer;
      margin-bottom: 1.75em;
      /* display: flex;
    justify-content: center;
    align-items: center; */
      svg {
        font-size: 1.86em;
      }
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.15em;
    h4 {
      color: var(--black);
      margin-bottom: 0.67em;
    }
    .dot {
      /* width: 24.5px;
      height: 24.5px; */
      padding: 0.875em;
      background: var(--primary);
      border-radius: 50%;
      margin-right: 0.575em;
    }
    p {
      color: var(--black);
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      /* width: 145px;
      height: 41.5px; */
      height: auto;
      padding: 0.7em 1.15em;
      background-color: var(--white-smoke-ordinary);
      border: 1px solid var(--primary) !important;
      border-radius: 0.36em;
      /* display: flex;
      justify-content: center;
      align-items: center; */
      span {
        font-size: 1.286em;
        font-weight: 400;
        color: var(--black);
      }
    }
  }
  & {
    @media (max-width: 768px) {
      p {
        font-size: 1em;
      }
      .card-header {
        align-items: baseline;
        margin: 0;
      }
    }
    @media (max-width: 576px) {
      padding: 1.75em;
      .card-body {
        display: block;
        p {
          font-size: 0.86em;
        }
        & > div:last-child {
          margin: 0.86em 0 0;
          .dot {
            margin-left: auto;
          }
        }
      }
      .card-footer {
        display: block;
        button {
          margin: 0.8em 0 0 auto;
          span {
            font-size: 1.071em;
          }
        }
      }
    }
  }
`;
