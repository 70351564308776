import axios from "axios";
import * as Services from "./baseURL";

const setAuthToken = (token) => {
  if (!token) {
    token = localStorage.getItem("token");
  }

  if (token) {
    axios.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  Object.keys(Services).forEach((item) => {
    if (item !== "fileURL") {
      if (token) {
        Services[item].defaults.headers.common["Authorization"] = token;
      } else {
        delete Services[item].defaults.headers.common["Authorization"];
      }
    }
  });
};

export default setAuthToken;
