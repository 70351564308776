// Authentication Types
export const USER_LOADED = "USER_LOADED";
export const USER_REGISTER = "USER_REGISTER";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_CATEGORIES_PRODUCTS = "GET_CATEGORIES_PRODUCTS";
export const GET_SUB_CATEGORIES_PRODUCTS = "GET_SUB_CATEGORIES_PRODUCTS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export const SET_POPULAR_CATEGORIES = "SET_POPULAR_CATEGORIES";
export const SET_SEARCH_PRODUCT = "SET_SEARCH_PRODUCT";
export const SET_SEARCH_SUGGESTION = "SET_SEARCH_SUGGESTION";
export const SET_CART = "SET_CART";
export const UPDATE_CART = "UPDATE_CART";
export const ADD_CART = "ADD_CART";
export const INCREMENT_CART = "INCREMENT_CART";
export const DECREMENT_CART = "DECREMENT_CART";
export const SET_ORDERS = "SET_ORDERS";
export const ADD_LOCATION = "ADD_LOCATION";
export const UPDATE_HOME_CART = "UPDATE_HOME_CART";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const UPDATE_SUB_CATEGORIES = "UPDATE_SUB_CATEGORIES";
export const GET_MORE_DATA = "GET_MORE_DATA";
export const LOGOUT = "LOGOUT";
export const PAYMENT_DETAILS_ON_TYPE = "PAYMENT_DETAILS_ON_TYPE";
export const LOGIN = "LOGIN";
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const TOGGLE_CATEGORY_LOADING = "TOGGLE_CATEGORY_LOADING";
export const TOGGLE_AUTH_LOADING = "TOGGLE_AUTH_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const TOGGLE_EDIT_USER = "TOGGLE_EDIT_USER";
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";
