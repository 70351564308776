import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { ModalStyle, ChangeLocation, SubmitBtn } from "../styles";
import MapBox from "components/mapBox";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { CustomAutocomplete } from "../AddAddressModal/styles";
import { addLocation } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";

const ConfirmLocationModal = ({
  isModalOpen,
  setIsModalOpen,
  setCurrentLocation,
  setLocationModal,
}) => {
  const [center, setCenter] = useState({ lat: 44, lng: -80 });
  const [fullAddress, setFullAddress] = useState();
  const user = useSelector((state) => state.app.data);
  const dispatch = useDispatch();
  // const defaultFunction = async () => {
  //   navigator.geolocation.getCurrentPosition(async function (position) {
  //     setCenter({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //   });
  // };
  // useEffect(() => {
  //   defaultFunction();
  // }, []);

  const handleSubmit = () => {
    const data = {
      //   name: name,
      //   address_type: addressType,
      //   address: address,
      //   pincode: pinCode,
      //   mobile: mobile,
      //   city: city,
      //   country: country,
      //   latitude: latitude,
      //   longitude: longitude,
      //   is_default: isDefault,
      //   reach_address: reachAddress,
      //   location_address: locationAddress,
      //   location_id: locationId,
      latitude: fullAddress.latLng.lat.toString(),
      longitude: fullAddress.latLng.lng.toString(),
      type: "exact",
      location_name: fullAddress.locality,
      location_addresses: fullAddress.formatted_address,
    };
    dispatch(addLocation(data));

    setIsModalOpen(false);
    setLocationModal(false);
    setCurrentLocation(false);
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address?.formatted_address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(address.place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const city =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      )?.long_name || "";
    const country =
      place.address_components.find((c) => c.types.includes("country"))
        ?.long_name || "";
    const locality =
      place.address_components.find((c) => c.types.includes("locality"))
        ?.long_name || "";
    setCenter(latLng);
    setFullAddress({
      postalCode: postalCode,
      latLng: latLng,
      city: city,
      country: country,
      formatted_address: address.formatted_address,
      locality: locality,
    });
  };
  return (
    <>
      <ModalStyle
        title={false}
        footer={false}
        centered
        width={670}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <MapBox center={center} />
          <div style={{ height: "10px" }}></div>
          <CustomAutocomplete
            apiKey={process.env.REACT_APP_MAPBOX_TOKEN}
            onPlaceSelected={handleSelect}
            placeholder="Search place..."
          />
          {/* <ChangeLocation>
            <div>
              <img src="/images/address-card-logo-1.svg" alt="click here" />
              <div>
                <h5>Ambalpady</h5>
                <p>22nd Main Rd, Banashankari Stage II, Banashankari</p>
              </div>
            </div>
            <span
              onClick={() => {
                setIsModalOpen(false);
                setLocationModal(true);
                setCurrentLocation(false);
              }}
            >
              change
            </span>
          </ChangeLocation> */}
          <SubmitBtn>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              Continue
            </Button>
          </SubmitBtn>
        </div>
      </ModalStyle>
    </>
  );
};

export default ConfirmLocationModal;
