import React from "react";
import CategoryCard from "../Cards/CategoryCard";
import { Col, Row } from "antd";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "shimmer-react";

const CategoriesSection = ({ data }) => {
  return (
    <>
      <div className="my-2">
        {data?.description ? (
          <div
            dangerouslySetInnerHTML={{ __html: data?.description }}
            style={{ marginBottom: "2em" }}
            className="main-heading"
          ></div>
        ) : (
          <ShimmerBadge width={200} />
        )}
        {data?.sub_description && (
          <div
            dangerouslySetInnerHTML={{ __html: data?.sub_description }}
            style={{ marginBottom: "2em" }}
            className="main-heading"
          ></div>
        )}

        <Row gutter={[{ md: 30, sm: 10, xs: 10 }]}>
          {data?.items ? (
            data?.items?.map((item) => {
              const { icon_image, name, category_id } = item;
              return (
                <Col xl={3} lg={4} md={3} sm={3} xs={6} key={category_id}>
                  <CategoryCard
                    image={icon_image}
                    heading={name}
                    id={category_id}
                  />
                </Col>
              );
            })
          ) : (
            <ShimmerThumbnail
              height={200}
              width={200}
              className="m-0"
              rounded
            />
          )}
        </Row>
      </div>
    </>
  );
};

export default CategoriesSection;
