import React, { useState } from "react";
import { Radio, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BiSearch } from "react-icons/bi";
import { ModalStyle2 } from "../styles";
import { AddressCard, SearchBar, SetLocation } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { addLocation } from "redux/action/appActions";
import { CustomAutocomplete } from "../AddAddressModal/styles";

const LocationModal = ({
  isModalOpen,
  setIsModalOpen,
  setConfirmLocationModal,
  currentLocation,
  setCurrentLocation,
  setSearchLocationModal,
  defaultLocation,
}) => {
  const [center, setCenter] = useState({ lat: 44, lng: -80 });
  const [fullAddress, setFullAddress] = useState();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.app.data);
  const user = useSelector((state) => state.app.data);
  const userLocation = useSelector((state) => state.app.location);
  const dispatch = useDispatch();
  const callback = () => {
    setLoading(false);
    setIsModalOpen(false);
    setCurrentLocation(false);
  };

  const handleLoading = () => {
    setLoading(false);
  };

  const handleSubmit = async (address) => {
    const data = {
      //   name: name,
      //   address_type: addressType,
      //   address: address,
      //   pincode: pinCode,
      //   mobile: mobile,
      //   city: city,
      //   country: country,
      //   latitude: latitude,
      //   longitude: longitude,
      //   is_default: isDefault,
      //   reach_address: reachAddress,
      //   location_address: locationAddress,
      //   location_id: locationId,
      latitude: address.latLng.lat.toString(),
      longitude: address.latLng.lng.toString(),
      type: "exact",
      location_name: address.locality,
      location_addresses: address.formatted_address,
    };

    let res = dispatch(addLocation(data, callback, handleLoading));
    if (res.status) {
      localStorage.setItem("user-address", JSON.stringify(data));
    }
  };

  const handleSelect = async (address) => {
    setLoading(true);
    const results = await geocodeByAddress(address?.formatted_address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(address.place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const city =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      )?.long_name || "";
    const country =
      place.address_components.find((c) => c.types.includes("country"))
        ?.long_name || "";
    const locality =
      place.address_components.find((c) => c.types.includes("locality"))
        ?.long_name || "";
    setCenter(latLng);
    setFullAddress({
      postalCode: postalCode,
      latLng: latLng,
      city: city,
      country: country,
      formatted_address: address.formatted_address,
      locality: locality,
    });
    handleSubmit({
      postalCode: postalCode,
      latLng: latLng,
      city: city,
      country: country,
      formatted_address: address.formatted_address,
      locality: locality,
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
      }}
      spin
    />
  );
  return (
    <>
      <ModalStyle2
        title={false}
        footer={false}
        centered
        width={670}
        open={isModalOpen}
        onCancel={() =>
          !data?.SplashScreen?.customer?.location_name &&
          !userLocation?.location_name
            ? ""
            : setIsModalOpen(false)
        }
        hideClose={{
          appLocation: data?.SplashScreen?.customer?.location_name,
          backendLocation: userLocation?.location_name,
        }}
      >
        <div>
          <h4 style={{ fontWeight: "600", marginBottom: "1.0625em" }}>
            Set delivery location
          </h4>
          <CustomAutocomplete
            apiKey={process.env.REACT_APP_MAPBOX_TOKEN}
            onPlaceSelected={handleSelect}
            placeholder="Search place..."
          />
          {loading && <Spin indicator={antIcon} />}

          {/* <SearchBar
            placeholder="Search Location..."
            prefix={<BiSearch />}
            enterButton="Search"
            onClick={() => setSearchLocationModal(true)}
          /> */}
          {/* MdMyLocation */}
          <SetLocation
            onClick={() => {
              setCurrentLocation(true);
              setConfirmLocationModal(true);
              defaultLocation();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.807"
              height="33"
              viewBox="0 0 24.807 33"
            >
              <path
                id="Path_1414"
                data-name="Path 1414"
                d="M5.59,12.633a6.817,6.817,0,1,1,11.622,4.562c-4.493,4.493-11.622.786-11.622-4.562ZM12.633,32.99H12.2a3.659,3.659,0,0,1-1.229-.943L7.984,28.955c-.373-.482-.634-.642-1.011-1.123l-2.53-3.38C1.839,20.784.01,16.79.01,11.975.01,5.775,5.642-.01,11.831-.01a11.9,11.9,0,0,1,6.024,1.2,13.867,13.867,0,0,1,4.811,4.218l1.081,1.874c2.653,6.225-.06,12.185-3.445,17.269-.62.931-1.1,1.546-1.739,2.364-.59.755-1.173,1.4-1.782,2.158a12.092,12.092,0,0,1-1.05,1.083c-.2.2-.3.291-.488.5-.128.139-.1.117-.232.261-1.2,1.323-1.859,1.937-2.375,2.074Z"
                transform="translate(-0.01 0.01)"
                fillRule="evenodd"
              />
            </svg>
            <span>Use my current location</span>
            {/* <Radio
              onClick={() => {
                setCurrentLocation(true);
                setConfirmLocationModal(true);
                defaultLocation();
              }}
              checked={currentLocation}
              value={currentLocation}
            >
              Use my current location
            </Radio> */}
          </SetLocation>
          {data?.SplashScreen?.customer?.customer_address?.length > 0 ? (
            <div>
              <p style={{ fontWeight: "300", color: "var(--gray)" }}>
                Saved Addresses
              </p>
              {data?.SplashScreen?.customer?.customer_address.map(
                ({ address_type, location_address }, i) => (
                  <AddressCard key={i}>
                    <img
                      src={
                        address_type === "Home"
                          ? "/images/address-card-logo-3.svg"
                          : address_type === "Work"
                          ? "/images/address-card-logo-2.svg"
                          : "/images/address-card-logo-1.svg"
                      }
                      alt="click here"
                    />
                    <div>
                      <h5>{address_type}</h5>
                      <p>{location_address}</p>
                    </div>
                  </AddressCard>
                )
              )}
              {/* <AddressCard>
                <img src="/images/address-card-logo-2.svg" alt="click here" />
                <div>
                  <h5>Office</h5>
                  <p>Opp to Billekhalli Bus Stop, Bannerghatta road...</p>
                </div>
              </AddressCard>
              <AddressCard>
                <img src="/images/address-card-logo-3.svg" alt="click here" />
                <div>
                  <h5>Home</h5>
                  <p>NEar Mount Carmel Institute of Management, Palace...</p>
                </div>
              </AddressCard> */}
            </div>
          ) : (
            ""
          )}
        </div>
      </ModalStyle2>
    </>
  );
};

export default LocationModal;
