import React, { useEffect, useState } from "react";
import { getLoyaltyWallet, getAbout } from "redux/action/appActions";
import { useSelector } from "react-redux";

export default function Policy() {
  const [data, setData] = useState("");
  const getData = async () => {
    const res1 = await getAbout("Privacy",);
    setData(res1.body);
  };
  useEffect(() => {
    getData();
  }, []);
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
}
