import React from "react";
import {
  AddNumber,
  AddNumberBtn,
  ConfirmDelivery,
  CouponBtn,
  FreeDelivery,
  NewAddressCard,
  PaymentArea,
  SelectCard,
  ShippingCard,
} from "./index.styled";
import { Col, Row, Radio } from "antd";
import DeliveryCard from "components/Cards/DeliveryCard";

export default function Type({ deliveryRadioValue, setDeliveryRadioValue }) {
  return (
    // <SelectCard>
    //   <h4>Select delivery type</h4>
    //   <Radio.Group
    //     name="delivery-radio-group"
    //     defaultValue={deliveryRadioValue}
    //     value={deliveryRadioValue}
    //     className="d-lg-flex align-items-center custom-radio"
    //     style={{ width: "100%", fontSize: "1em" }}
    //   >
    //     {/* {cart?.delivery_type?.map(({ description, type }) => (
    //     <DeliveryCard
    //       heading={type}
    //       text={description}
    //       value={type}
    //       radioValue={deliveryRadioValue}
    //       setRadioValue={setDeliveryRadioValue}
    //     />
    //   ))} */}

    //   {/* Remove for Home Delivery */}

    //     {/* <DeliveryCard
    //       heading="Home Delivery"
    //       text="Get your order delivered to your doorstep"
    //       value={"Home Delivery"}
    //       radioValue={deliveryRadioValue}
    //       setRadioValue={(value) => setDeliveryRadioValue(value)}
    //     /> */}

    //     {/* <DeliveryCard
    //       heading="Store Pickup"
    //       text="Pick your order at your convenience"
    //       value={"Store Pickup"}
    //       radioValue={deliveryRadioValue}
    //       setRadioValue={(value) => setDeliveryRadioValue(value)}
    //     /> */}
    //   </Radio.Group>
    // </SelectCard>
    <></>
  );
}
