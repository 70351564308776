import React, { useEffect, useState } from "react";
import { getLoyaltyWallet, getAbout } from "redux/action/appActions";
import { useSelector } from "react-redux";
import Header from "components/Header";
import DashboardHeader from "components/DashboardHeader";
import ProductLinks from "components/ProductLinks";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";

export default function TermsCondition() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const getData = async () => {
    console.log(searchParams.get("type"));

    let type = searchParams.get("type");
    let page = "Terms and Condition";
    if (type) {
      page = type;
    }
    setLoading(true);
    const res1 = await getAbout(page);
    setData(res1.body);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 150,
        color: "var(--primary)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:150,
        height:150,
      }}
      spin
    />
  );
  return (
    <>
      <Header />
      <DashboardHeader />
      <ProductLinks />
      {loading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="container" style={{ marginTop: "2em" }}>
          <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
      )}
    </>
  );
}
