import styled from "styled-components";

export const Head = styled.div`
  background: var(--primary);
  padding: 0.875em 0;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  p,
  a {
    margin: 0;
    color: var(--white) !important;
    font-size: 1.375em;
  }
  a {
    font-weight: 500;
    text-decoration: none;
  }
  & {
    /* @media (min-width: 1200px) {
      p,
      a {
        font-size: 1.375em;
      }
    } */
    @media (max-width: 1199px) {
      display: none;
    }
  }
`;
