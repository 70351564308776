import React from "react";
import DealCard from "../Cards/DealCard";
import { data } from "../../data/DealCardsData";
import { DealArea, DealInfo } from "./styles";
import Carousel from "../Carousel";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "shimmer-react";
import { ShimmerPostList } from "shimmer-react";
import { useSelector } from "react-redux";
import { SlickSlider } from "components/Carousel/styles";
import { fileURL } from "helpers/baseURL";

const DealSection = ({ data }) => {
  const settings = {
    className: "center",
    infinite: data?.items?.length > 1 ? true : false,
    variableWidth: data?.items?.length > 1 ? false : true,
    swipeToSlide: true,
    slidesToShow:  8,
    slidesToScroll: 3,
    initialSlide: 0,
    centerMode: false,
    centerPadding: "80px",
    responsive: data?.items?.length > 1 && [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: data?.items?.length < 8 ? false : true,
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: data?.items?.length < 6 ? false : true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: data?.items?.length < 5 ? false : true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: data?.items?.length < 4 ? false : true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: data?.items?.length < 4 ? false : true,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data?.items?.length < 2 ? false : true,
          centerMode: false,
        },
      },
    ],
  };

  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  return (
    <>
      <DealArea className="px-2">
        {data?.description ? (
          <DealInfo>
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            {data?.sub_description && (
              <h4
                dangerouslySetInnerHTML={{ __html: data?.sub_description }}
              ></h4>
            )}
          </DealInfo>
        ) : (
          <>
            <ShimmerBadge width={200} />
          </>
        )}

        {
          data?.items ? (
            <SlickSlider {...settings}>
              {data?.items?.map((item, i) => {
                const {
                  product_variations,
                  name,
                  is_subscription,
                  location_id,
                  id,
                  price_variation,
                } = item;
                const offText = Math.round(
                  product_variations[0]?.product_batches[0]?.discount_percentage
                );

                return (
                  <div key={i}>
                    <DealCard
                      id={id}
                      offText={offText === 0 ? 0 : `${offText}% OFF`}
                      brandLogo={"/images/express-logo.svg"}
                      brandText={"Express"}
                      brandImage={`${fileURL}${
                        product_variations?.[0]?.product_images[0]?.path ||
                        item.product_images[0]?.path
                      }`}
                      //Deal Of The Day//
                      footerText={`${currency_symbol}${product_variations[0]?.product_batches[0]?.membership_price?.toFixed(
                        0
                      )} Membership Price`}
                      heading={name}
                      subheading={
                        product_variations[0]?.name +
                        product_variations[0]?.unit_of_measure
                      }
                      discount={
                        product_variations[0]?.product_batches[0]?.sale_price
                      }
                      price={product_variations[0]?.product_batches[0]?.mrp.toFixed(
                        2
                      )}
                      option={price_variation}
                      product_variations={product_variations}
                      quantity={0}
                      subscribe={is_subscription}
                      data={{
                        product_id: id,
                        variation_id: product_variations[0]?.id,
                        batch_id: product_variations[0]?.product_batches[0]?.id,
                        location_id: location_id,
                        type: product_variations[0]?.type,
                        membership_plan_id: "",
                      }}
                    />
                  </div>
                );
              })}
            </SlickSlider>
          ) : (
            // <div> Out Of Stack</div>
            <>
              <ShimmerThumbnail
                height={200}
                width={300}
                className="m-0"
                rounded
              />
              <ShimmerBadge width={300} />
              <ShimmerBadge width={200} />
              <ShimmerBadge width={100} />
            </>
          )
          // <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} />
        }
      </DealArea>
    </>
  );
};

export default DealSection;
