export const LoyaltyTransactionData = [
  {
    image: "/images/loyalty-star-icon.png",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/loyalty-star-icon.png",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/loyalty-star-icon.png",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/loyalty-star-icon.png",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
];
