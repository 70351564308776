import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import DashboardHeader from "components/DashboardHeader";
import CategoryCard from "components/Cards/CategoryCard";
import { useSelector } from "react-redux";
import { ShimmerThumbnail, ShimmerBadge } from "shimmer-react";

const AllCategories = () => {
  const categories = useSelector((state) => state.app.data.categories);

  return (
    <Fragment>
      <DashboardHeader />
      <Container>
        <Row gutter={[{ sm: 20, md: 30, xs: 10 }]} style={{ marginTop: "2em" }}>
          {categories?.length
            ? categories?.map((item, i) => {
                const { icon_image, name, id } = item;
                return (
                  <Col xl={3} lg={3} md={4} sm={4} xs={6} key={i}>
                    <CategoryCard image={icon_image} heading={name} id={id} />
                  </Col>
                );
              })
            : Array(10)
                .fill(1)
                .map((item, i) => (
                  <Col xl={3} lg={4} md={6} xs={12} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginBottom: "2.87em",
                      }}
                    >
                      <ShimmerThumbnail
                        height={100}
                        width={100}
                        className="m-0"
                        rounded
                      />
                      <ShimmerBadge width={100} />
                    </div>
                  </Col>
                ))}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AllCategories;
