import {
  USER_LOADED,
  USER_REGISTER,
  GET_ALL_CATEGORIES,
  GET_SUB_CATEGORIES,
  GET_PRODUCTS,
  GET_CATEGORIES_PRODUCTS,
  GET_SUB_CATEGORIES_PRODUCTS,
  SET_MESSAGE,
  SET_SELECTED_PRODUCT,
  SET_POPULAR_CATEGORIES,
  SET_SEARCH_PRODUCT,
  SET_SEARCH_SUGGESTION,
  SET_CART,
  SET_ORDERS,
  ADD_LOCATION,
  UPDATE_CART,
  DECREMENT_CART,
  INCREMENT_CART,
  ADD_CART,
  UPDATE_HOME_CART,
  SET_SELECTED_CATEGORY,
  UPDATE_SUB_CATEGORIES,
  GET_MORE_DATA,
  LOGOUT,
  PAYMENT_DETAILS_ON_TYPE,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_CATEGORY_LOADING,
  TOGGLE_AUTH_LOADING,
  TOGGLE_EDIT_USER,
  UPDATE_USER,
  UPDATE_USER_ADDRESS,
} from "../Constants";

const initState = {
  data: "",
  categories: [],
  subCategories: [],
  products: [],
  nestedCategoriesWithProducts: [],
  categoryLoading: false,
  user: "",
  message: "",
  default_location: {},
  selectedProduct: "",
  popular: "",
  searchProducts: [],
  suggestion: [],
  cart: "",
  orders: [],
  location: "",
  selectedCategory: "",
  moreData: true,
  isLoginOpen: false,
  categoryLast_id: "",
  authLoading: false,
};

const appReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_EDIT_USER: {
      return { ...state, editModalOpen: !state.editModalOpen };
    }
    case UPDATE_USER_ADDRESS: {
      state.data.SplashScreen.customer.customer_address = payload;
      return { ...state };
    }
    case UPDATE_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          SplashScreen: {
            ...state.data.SplashScreen,
            customer: action.payload,
          },
        },
      };
    }
    case TOGGLE_AUTH_LOADING:
      return {
        ...state,
        authLoading: !state.authLoading,
      };
    case TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        isLoginOpen: !state.isLoginOpen,
      };
    case TOGGLE_CATEGORY_LOADING:
      return { ...state, categoryLoading: !state.categoryLoading };
    case USER_LOADED:
      return {
        ...state,
        authLoading: false,
        data: payload,
      };
    case USER_REGISTER:
      return {
        ...state,
        user: payload,
      };
    case LOGOUT:
      return {
        ...state,
        data: state.data,
        user: payload,
        data: "",
        location: "",
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case GET_SUB_CATEGORIES:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_SUB_CATEGORIES:
      return {
        ...state,
        products: [...state.products, ...payload.products],
        categoryLast_id: payload.categoryLast_id,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
        nestedCategoriesWithProducts: [],
      };
    case GET_CATEGORIES_PRODUCTS:
      return {
        ...state,
        subCategories: payload.subCategories,
        nestedCategoriesWithProducts: payload.nestedCategoriesWithProducts,
        products: [],
      };
    case GET_SUB_CATEGORIES_PRODUCTS:
      return {
        ...state,
        nestedCategoriesWithProducts: payload.nestedSubCategoriesWithProducts,
        products: [],
      };
    case GET_MORE_DATA:
      return {
        ...state,
        moreData: payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };
    case SET_POPULAR_CATEGORIES:
      return {
        ...state,
        popular: payload,
      };
    case SET_SEARCH_PRODUCT:
      return {
        ...state,
        searchProducts: payload,
      };
    case SET_SEARCH_SUGGESTION:
      return {
        ...state,
        suggestion: payload,
      };
    case SET_CART:
      return {
        ...state,
        cart: payload,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case ADD_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: { ...state.cart, cart: payload },
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case PAYMENT_DETAILS_ON_TYPE:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart: {
            ...state.cart.cart,
            paymentDetails: payload,
          },
        },
      };
    case ADD_CART:
      return {
        ...state,
        data: {
          ...state.data,
          HomePage: {
            ...state.data.HomePage,
            data: [
              {
                ...state.data.HomePage.data[0],
                cart: {
                  ...state.data.HomePage.data[0].cart,
                  cData: payload.cData,
                  paymentDetails: payload.paymentDetails,
                },
              },
            ],
          },
        },
      };
    case INCREMENT_CART:
      return {
        ...state,
        data: {
          ...state.data,
          HomePage: {
            ...state.data.HomePage,
            data: [
              {
                ...state.data.HomePage.data[0],
                cart: {
                  ...state.data.HomePage.data[0].cart,
                  cData: payload.cData,
                  paymentDetails: payload.paymentDetails,
                },
              },
            ],
          },
        },
      };
    case DECREMENT_CART:
      return {
        ...state,
        data: {
          ...state.data,
          HomePage: {
            ...state.data.HomePage,
            data: [
              {
                ...state.data.HomePage.data[0],
                cart: {
                  ...state.data.HomePage.data[0].cart,
                  cData: payload.cData,
                  paymentDetails: payload.paymentDetails,
                },
              },
            ],
          },
        },
      };
    case UPDATE_HOME_CART:
      return {
        ...state,
        data: {
          ...state.data,
          HomePage: {
            ...state.data.HomePage,
            data: [
              {
                ...state.data.HomePage.data[0],
                cart: {
                  ...state.data.HomePage.data[0].cart,
                  cData: payload.cData,
                },
              },
            ],
          },
        },
      };

    default:
      return { ...state };
  }
};

export default appReducer;
