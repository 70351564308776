import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.36em;
  padding: 2.286em 1.75em;
  margin-bottom: 2.286em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  img {
    margin-right: 0.86em;
  }
  h5 {
    font-size: 1.286em;
    color: var(--black);
  }
  h6 {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.557em;
    color: var(--gray);
  }
  .ant-radio-wrapper {
    .ant-radio-inner {
      width: 26px;
      height: 26px;
      border: 1px solid var(--grey-legendary);
      position: relative;
      &::after {
        padding: 1.8em;
        position: absolute;
        top: -50%;
        left: -50%;
        background: var(--primary);
        border-radius: 50%;
        margin-block-start: -1px;
        margin-inline-start: -1px;
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        background-color: unset !important;
      }
    }
  }
  &:first-child {
    margin-right: 1.645em;
  }
  &:last-child {
    margin-left: 1.645em;
  }
  & {
    @media (min-width: 1400px) {
      h5 {
        font-size: 1.5em;
      }
      h6 {
        font-size: 1.15em;
      }
    }
    @media (max-width: 991px) {
      margin: 0 0 2.286em !important;
    }
  }
`;

export const Address = styled.p`
  font-size: 1.071em;
  font-weight: 300;
  color: var(--gray);
  margin: 0px;
`;
