import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import EditModal from "components/Modals/EditModal";
import UpdateNumberOtp from "components/Modals/VerificationModal/UpdateNumberOtp";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getALLData, getProfileData } from "redux/action/appActions";
import { UserCard, UserCollapse, UserInfo } from "./index.styled";

export default function Sidebar({ step, setStep }) {
  const user = useSelector((state) => state.app.data);
  const [editModal, setEditModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [userUpdateData, setUserUpdateData] = useState({});
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountData = [
    {
      text: "Addresses",
      link: "/dashboard/address",
    },
    // {
    //   text: "Shopping List",
    //   link: "/dashboard/shopping",
    // },
    // {
    //   text: "Loyalty",
    //   link: "/dashboard/loyalty",
    // },
    // {
    //   text: "Wallet",
    //   link: "/dashboard/wallet",
    // },
    // {
    //   text: "Settings",
    //   link: "settings",
    // },
  ];
  const aboutData = [
    {
      text: "Privacy Policy",
      link: "/dashboard/policy",
    },
    // {
    //   text: "Terms & Conditions",
    //   link: "/dashboard/e-page",
    // },
    {
      text: "About us",
      link: "/dashboard/about",
    },
    // {
    //   text: "Rate us",
    //   link: "/dashboard/rate",
    // },
  ];
  const { Panel } = Collapse;

  const getData = async () => {
    const res = await getProfileData();
    setUserData(res);
  };

  useEffect(() => {
    getData();
  }, [user]);

  //destructuring userData?.customer?.country_code , userData?.customer?.mobile, userData?.customer?.name and userData?.customer?.email
  const { country_code, mobile, name, email } = userData?.customer || {};
  const mobileNumber = country_code
    ? `${country_code} ${mobile}`
    : mobile && `${mobile}`;

  return (
    <UserCard>
      <UserInfo>
        <div className="header">
          <h4>{name || "Loading..."}</h4>
          <div>
            {/* <p onClick={() => dispatch({ type: TOGGLE_EDIT_USER })}>Edit u</p> */}
            <p onClick={() => setEditModal(true)}>Edit</p>
            <p
              onClick={() => {
                localStorage.removeItem("token");
                // navigate(0);
                navigate("/");
                dispatch({ type: "LOGOUT", payload: "" });
                window.location.reload();

                //----------AFTER LOGOUT HOMEPAGE-------//
                const data = {
                  isGuest: 0,
                  domain:
                    window.location.origin === "http://localhost:3000"
                      ? "https://web.grocbay.com"
                      : window.location.origin,
                  screen_type: "home_screen",
                };

                dispatch(getALLData(data));
              }}
            >
              Logout
            </p>
          </div>
        </div>
        <div className="info">
          <p>{mobileNumber}</p>
          {mobileNumber && <div></div>}
          <p>{email}</p>
        </div>
      </UserInfo>
      <EditModal
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        setOtpModal={setOtpModal}
        setUserUpdateData={setUserUpdateData}
      />
      <UpdateNumberOtp
        data={userUpdateData}
        isOpen={otpModal}
        setIsOpen={setOtpModal}
        setData={setUserUpdateData}
      />
      <UserCollapse collapsible="false">
        <Panel
          className="not-collapsible"
          header={<h4>My Orders</h4>}
          key="1"
          onClick={() => navigate("/dashboard/orders")}
        ></Panel>
        <Panel header={<h4>My Account</h4>} key="2">
          {accountData?.map((item) => {
            const { text, link, active } = item;
            return (
              <p key={text}
                className={`linkPara ${active}`}
                onClick={() => navigate(link)}
              >
                <span>{text}</span>
                <MdOutlineKeyboardArrowRight />
              </p>
            );
          })}
        </Panel>
        {/* <Panel
          className="not-collapsible"
          header={<h4>Membership</h4>}
          key="3"
          onClick={() => navigate("/dashboard/membership")}
        ></Panel> */}
        <Panel header={<h4>About</h4>} key="4">
          {aboutData?.map((item) => {
            const { text, link } = item;
            return (
              <p key={text} className="linkPara" onClick={() => navigate(link)}>
                <span>{text}</span>
                <MdOutlineKeyboardArrowRight />
              </p>
            );
          })}
        </Panel>
      </UserCollapse>
    </UserCard>
  );
}
