import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import DashboardHeader from "components/DashboardHeader";
import PopularCard from "components/Cards/PopularCard";
import { data } from "data/PopularCardsData";
import { Label, ProductCard, SearchBar } from "./index.styled";
import { ChipsCardsData } from "data/ChipsCardsData";
import DealCard from "components/Cards/DealCard";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import Empty from "components/empty";
import { useNavigate } from "react-router-dom";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "shimmer-react";
import { useDispatch } from "react-redux";
import {
  getSearchSuggestion,
  search,
  selectedSearch,
  getSearch,
} from "redux/action/appActions";
import { useEffect } from "react";
import { fileURL } from "helpers/baseURL";

export default function Search() {
  const popularCategory = useSelector((state) => state.app.popular);
  const searchProducts = useSelector((state) => state.app.searchProducts);
  const suggestion = useSelector((state) => state.app.suggestion);
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearch = (value) => {
    if (value) {
      setSearchValue(value);
      dispatch(getSearchSuggestion(value));
    } else {
      setSearchValue(value);
      // dispatch({ type: "SET_SEARCH_PRODUCT", payload: [] });
    }
  };
  const onSelect = (value) => {
    const res = suggestion?.find((item) => item?.id === value);

    setSearchValue(res.name);
    setLoading(true);
    dispatch(
      selectedSearch(
        {
          id: res?.id,
          variationId: res?.product_variations
            ? res?.product_variations[0]?.id
            : "",
        },
        setLoading
      )
    );
  };
  const handleSearch = (e) => {
    if (e.which === 13) {
      setLoading(true);
      dispatch(search(searchValue, setLoading));
    }
  };
  useEffect(() => {
    setLoading(true);
    dispatch({ type: "SET_SEARCH_PRODUCT", payload: [] });
    dispatch(getSearch(setLoading));
  }, []);
  const options = suggestion?.map(({ name, id }) => ({
    value: id,
    label: name,
  }));
  return (
    <>
      <DashboardHeader />
      <Container>
        <SearchBar
          style={{
            width: "100%",
          }}
          onKeyDown={handleSearch}
          allowClear
          onSearch={onSearch}
          onSelect={onSelect}
          value={searchValue}
          // onChange={handleSearch}
          options={options}
          placeholder="Search for brand or items"
          filterOption={(inputValue, option) => {
            return (
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            );
          }}
        />
        {/* <SearchBar
          placeholder="Search for brand or items"
          prefix={<BiSearch />}
          // enterButton="Search"
          // allowClear
          // autoFocus
          value={searchValue}
          onKeyDown={handleKeyDown}
          onChange={handleSearch}
        /> */}
        {/* --------------------- */}
        {searchProducts?.length && searchValue ? (
          <h4 style={{ fontWeight: "400", marginBottom: "1.5em" }}>
            "{searchProducts?.length}" results of "{searchValue}"


          </h4>


        )
          : !searchProducts?.length && searchValue && !popularCategory ? (
            <ShimmerBadge width={500} />
          ) : searchProducts?.length === 0 && searchValue.length ? (
            // <h5>No results found for "{searchValue}".</h5>
            (console.log("===========Enter value"),
              (
                <Empty
                  title="No item found!"
                  description="We can't find any item matching your search"
                  buttonText="Back to Home"
                  icon="Not_Found"
                  buttonCallback={() => navigate("/")}
                />
              ))
          ) : null}

        {/* --------------------- */}
        {loading ? (
          <Row gutter={[16, 24]}>
            {Array(10)
              .fill(1)
              .map((item, i) => (
                <Col key={i}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <ShimmerThumbnail
                      height={100}
                      width={200}
                      className="m-0"
                      rounded
                    />
                    <ShimmerBadge width={150} />
                    <ShimmerBadge width={100} />
                    <ShimmerBadge width={50} />
                  </div>
                </Col>
              ))}
          </Row>
        ) : searchProducts?.length && searchValue ? (
          <>
            {/* <div className="d-md-flex justify-content-between flex-wrap"> */}
            <Row gutter={32}>
              {searchProducts?.map((item, i) => {
                const {
                  product_variations,
                  name,
                  is_subscription,
                  location_id,
                  id,
                  price_variation,
                } = item;
                const offText = Math.round(
                  product_variations[0]?.product_batches[0]?.discount_percentage
                );

                return (
                  <Col xs={12} lg={4} key={i}>
                    <ProductCard>
                      <DealCard
                        id={id}
                        offText={offText === 0 ? 0 : `${offText}% OFF`}
                        brandLogo={"/images/express-logo.svg"}
                        brandText={"Express"}
                        brandImage={`${fileURL}${product_variations[0]?.product_images[0]?.path}`}
                        footerText={`${currency_symbol}${product_variations[0]?.product_batches[0]?.membership_price?.toFixed(
                          0
                        )} Membership Price`}
                        heading={name}
                        subheading={
                          product_variations[0]?.name +
                          product_variations[0]?.unit_of_measure
                        }
                        discount={
                          product_variations[0]?.product_batches[0]?.sale_price
                        }
                        price={product_variations[0]?.product_batches[0]?.mrp.toFixed(
                          2
                        )}
                        option={price_variation}
                        product_variations={product_variations}
                        quantity={0}
                        subscribe={is_subscription}
                        data={{
                          product_id: id,
                          variation_id: product_variations[0]?.id,
                          batch_id:
                            product_variations[0]?.product_batches[0]?.id,
                          location_id: location_id,
                          type: product_variations[0]?.type,
                          membership_plan_id: "",
                        }}
                      />
                    </ProductCard>
                  </Col>
                );
              })}
            </Row>
            {/* </div> */}
          </>
        ) : popularCategory ? (
          <>
            {/* <h4 style={{ fontWeight: "400", marginBottom: "1em" }}>Popular</h4> */}
            {/* <div className="d-flex flex-wrap mb-5">
              {popularCategory?.trendingCategories ? (
                <>
                  {popularCategory?.popularItems?.map(({ name }) => (
                    <Label href="#">
                      <span>{name}</span>s
                    </Label>
                  ))}
                </>
              ) : (
                data?.map((item) => {
                  const { image, heading } = item;
                  return (
                    <>
                      <ShimmerThumbnail
                        height={30}
                        width={50}
                        className="mx-3"
                        rounded
                      />
                    </>
                  );
                })
              )}
            </div> */}
            <h4 style={{ fontWeight: "400", marginBottom: "1em" }}>Popular</h4>
            <div className="d-flex justify-content-between flex-wrap">
              {popularCategory?.trendingCategories
                ? popularCategory?.trendingCategories?.map(
                  ({ name, icon_image, id }) => (
                    <PopularCard image={icon_image} heading={name} id={id} key={id} />
                  )
                )
                : data?.map((item) => {
                  const { image, heading } = item;
                  return (
                    <>
                      <ShimmerThumbnail
                        height={150}
                        width={150}
                        className="m-0"
                        rounded
                      />
                    </>
                  );
                })}
            </div>
          </>
        ) : (
          "  "
          // <p>No results found for "{searchValue}".</p>
          // <>

          //   <h2
          //     className="text-center"
          //     style={{
          //       fontWeight: "600",
          //       color: "var(--gray)",
          //       margin: "1.5em 0",
          //     }}
          //   >
          //     No Data {searchValue};

          //   </h2>
          // </>
        )}
      </Container>
    </>
  );
}
