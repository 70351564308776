import React, { useEffect, useState } from "react";
import { CategoryButton, HamburgerMenu, Labels, Text } from "./styles";
import { Container, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllCategory, setSelectedCategory } from "redux/action/appActions";
import { Button, Tabs } from "antd";
import { useCallback } from "react";
import { useMemo } from "react";
import { fileURL } from "helpers/baseURL";
import { GiHamburgerMenu } from "react-icons/gi";
import { Hamburger } from "assets/svg";

const utsavkartOrigin = "utsavkart.com";
const ProductLinks = ({ links }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const categories = useSelector((state) => state.app.data.categories);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), []);

  const currentDisplay = useMemo(() => categories.slice(0, 6), []);
  const currMenu = useMemo(() => categories, []);
  const onCategoryClick = useCallback((e, name, id) => {
    e.preventDefault();
    dispatch({ type: "GET_SUB_CATEGORIES", payload: [] });
    dispatch({
      type: "GET_SUB_CATEGORIES_PRODUCTS",
      payload: {
        nestedSubCategoriesWithProducts: [],
      },
    });
    dispatch({ type: "GET_PRODUCTS", payload: [] });
    dispatch({ type: "SET_MESSAGE", payload: "" });
    dispatch(setSelectedCategory({ title: name, id: id }));

    navigate(`/itemList/${id}`);
    setMenuOpen(false);
  }, []);

  return (
    <>
      {!window.location.origin.includes(utsavkartOrigin) && (
        <div
          style={{
            backgroundColor: "var(--gray-secondary)",
            boxShadow: "0 0 5px #00000029",
            paddingBottom: "1.5em",
          }}
        >
          <Container>
            <div className="d-xl-flex justify-content-between gap-5 align-items-center d-md-none d-sm-none d-xs-none d-xss-none d-lg-none">
              {/* <button className="button" onClick={() => navigate("/allCategories")}>
            <img src="/images/category-icon.svg" alt="click here" />
            Categories
          </button> */}
              <div className="d-flex justify-content-around flex-1">
                {currentDisplay?.map(({ name, id }, i) => (
                  <Text
                    href="#"
                    onClick={(e) => onCategoryClick(e, name, id)}
                    key={id}
                  >
                    {name}
                  </Text>
                ))}
              </div>

              {currMenu.length > 0 && (
                <HamburgerMenu onClick={toggleMenu}>
                  <Hamburger />
                </HamburgerMenu>
              )}
            </div>
          </Container>
        </div>
      )}
      <Offcanvas show={menuOpen} onHide={toggleMenu} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>All Categories</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column gap-1">
            {currMenu?.map((item) => (
              <div
                key={item.id}
                className="d-flex hover-bg-blue rounded-2 gap-3 p-2 cursor-pointer align-items-center"
                onClick={(e) => onCategoryClick(e, item.name, item.id)}
              >
                <img
                  src={fileURL + item.icon_image}
                  alt={item.name}
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                  loading="eager"
                />
                <h6 className="m-0">{item.name}</h6>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProductLinks;
