import { SlickSlider } from "components/Carousel/styles";
import { fileURL } from "helpers/baseURL";
import React from "react";
import { Fragment } from "react";

const BrandCarousel = ({ item, showSlide = 6, centerMode, data }) => {
  const settings = {
    className: "center px-2",
    infinite: item.length < 6 ? false : true,
    swipeToSlide: true,
    slidesToShow: showSlide > data ? data : showSlide,
    nextArrow: <Fragment />,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <></>,
    centerMode: false,
    centerPadding: "80px",
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: item?.length < 6 ? false : true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: item?.length < 4 ? false : true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: item?.length < 4 ? false : true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: item?.length < 3 ? false : true,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: item?.length < 2 ? false : true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div style={{ position: "relative" }} className="brand-carousel">
      <div
        dangerouslySetInnerHTML={{
          __html: item?.description,
        }}
        className="main-heading"
      >
        {/* <span style={{ color: "var(--primary)" }}>Brands</span> in focus */}
      </div>
      {item?.sub_description && (
        <h4
          dangerouslySetInnerHTML={{
            __html: item?.sub_description,
          }}
          style={{ fontWeight: "300" }}
        ></h4>
      )}
      <SlickSlider {...settings}>
        {item?.items.map((item, i) => (
          <img
            key={i}
            className="max-dh-40"
            style={{ width: "100%" }}
            src={`${fileURL}${item.icon_image}`}
            alt="click here"
          />
        ))}
      </SlickSlider>
    </div>
  );
};

export default BrandCarousel;
