import { Checkbox } from "antd";
import styled from "styled-components";

export const EditArea = styled.div`
  h4 {
    font-size: 1.715em;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 1.46em;
  }
  .ant-form-item {
    margin-bottom: 2.5em;
    .ant-input-number {
      width: 100%;
      outline: 0;
      box-shadow: none;
      border: 0;
      input {
        height: auto;
      }
    }
    input {
      width: 100%;
      /* height: 100%;
      min-height: 73px; */
      padding: 1.25em 1.5em;
      background-color: var(--white-smoke-secondary);
      border: 0px;
      outline: 0px;
      box-shadow: none;
      border-radius: 0.635em;
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
      &::placeholder {
        color: var(--gray-primary);
      }
    }
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-wrapper {
    .ant-checkbox {
      margin: 5px;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary);
        &::after {
          width: 7px;
          height: 13px;
          top: 45%;
        }
      }
      &::after {
        display: none;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: var(--primary) !important;
          border-color: var(--primary) !important;
        }
      }
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper {
    .ant-checkbox {
      margin: 5px;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary);
        &::after {
          width: 7px;
          height: 13px;
          top: 45%;
        }
      }
      &::after {
        display: none;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: var(--primary) !important;
          border-color: var(--primary) !important;
        }
      }
    }
  }
`;
