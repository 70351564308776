import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  /* height: 98px; */
  border: 1px solid var(--grey-legendary);
  border-radius: 0.36em;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875em 2.5em;
  margin-bottom: 2.36em;
  &:nth-last-child(2) {
    margin-bottom: 0;
  }
  img {
    width: 52px;
    height: 72px;
    margin-right: 2em;
  }
  h5,
  h6,
  p,
  span {
    margin: 0;
  }
  h5 {
    font-size: 1.4286em;
    font-weight: 400;
    color: var(--gray);
  }
  .price {
    font-size: 0.86em;
    font-weight: 400;
    color: var(--gray);
    text-decoration: line-through;
    margin-left: 0.215em;
  }
  .action {
    width: 94px;
    height: 33px;
    button,
    div {
      span {
        font-size: 1.071em;
      }
    }
  }
  & {
    @media (max-width: 576px) {
      padding: 0.875em 1.375em;
      .action {
        width: 75px;
        button,
        div {
          span {
            font-size: 0.93em;
          }
        }
      }
      img {
        margin-right: 0.875em;
      }
      h5 {
        font-size: 1.071em;
      }
    }
  }
`;
export const Sale = styled.div`
  /* width: 55px;
  height: 20px; */
  padding: 0.215em 0.5em;
  background: var(--primary);
  border-bottom-right-radius: 1.071em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: absolute;
  top: 0;
  left: 0;
  p {
    font-size: 0.715em;
    font-weight: 500;
    color: var(--white);
    margin: 0;
  }
`;
export const AddBtn = styled.button`
  width: 100%;
  height: 100%;
  background: var(--primary);
  border-radius: 0.36em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  box-shadow: none;
  border: 0;
  span {
    font-weight: 500;
    color: var(--white);
  }
`;
export const QuantityBtn = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary);
  border-radius: 0.36em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  span {
    font-weight: 500;
    color: var(--primary);
    &:first-child,
    &:last-child {
      cursor: pointer;
    }
  }
`;
