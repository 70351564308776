import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyle2 = styled(Modal)`
  .ant-spin-spinning {
    position: absolute;
    right: 50px;
    top: 127px;
  }
  .ant-modal-close {
    display: ${(props) =>
      !props.hideClose?.appLocation &&
      !props.hideClose?.backendLocation &&
      "none"};
  }
  .ant-modal-content {
    border-radius: 0.86em;
    padding: 3.43em 2.286em;
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 1.75em 1.15em;
      }
    }
  }
`;

export const ModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.86em;
    padding: 3.43em 2.286em;
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 1.75em 1.15em;
      }
    }
  }
`;

export const DeliveryModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.75em;
    padding: 1.5em;
    height: 100%;
  }
  .ant-modal-body {
    height: 100%;
  }
  .ant-modal-footer {
    margin: 0;
  }
  .customRadio {
    font-size: 1em;
    width: 100%;
  }

  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 1.15em;
      }
    }
  }
`;
export const OrderModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 2.75rem;
    padding: 3rem 0;
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 2rem 1rem;
      }
    }
  }
`;
export const CouponModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 1.65em;
    padding: 2.145em 0;
    .info {
      padding: 0 2.286em;
      h4 {
        margin-bottom: 1.5em;
      }
      .ant-input-affix-wrapper {
        width: 100%;
        /* height: 64px; */
        border: 1px solid var(--grey-legendary) !important;
        border-radius: 0.5em;
        box-shadow: none;
        padding: 1.5em;
        input {
          font-size: 1.286em;
          font-weight: 400;
          color: var(--black);
          &::placeholder {
            color: var(--grey-legendary);
          }
        }
        .ant-input-suffix {
          a {
            font-size: 1.286em;
            font-weight: 400;
            color: var(--primary);
          }
        }
        &.disable {
          .ant-input-suffix {
            position: relative;
            cursor: no-drop;
            a {
              pointer-events: none;
              opacity: 0.55;
            }
          }
        }
      }
    }
    .label {
      width: 100%;
      background-color: var(--white-smoke-secondary);
      padding: 0.715em 2.286em;
      margin: 2em 0;
      p {
        text-transform: uppercase;
        font-family: "Baloo 2", cursive;
        color: var(--gray);
        margin: 0;
      }
    }
    .content {
      height: 400px;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .coupons-card {
      padding: 0 2.286em 2em;
      img {
        width: 75px;
        height: 24px;
        margin-bottom: 0.86em;
      }
      h6 {
        font-size: 1.286em;
        font-weight: 400;
        color: var(--black);
        margin-bottom: 0.56em;
      }
      p {
        font-size: 1em;
        font-weight: 400;
        color: var(--gray);
        a {
          font-weight: 500;
          color: var(--black);
        }
      }
      .action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          /* width: 154px;
          height: 45px; */
          height: auto;
          padding: 0.75em 2.5em;
          background-color: unset;
          border: 1px solid var(--primary) !important;
          box-shadow: none;
          /* display: flex;
          justify-content: center;
          align-items: center; */
          span {
            font-size: 1.36em;
            font-weight: 400;
            color: var(--black);
          }
        }
        a {
          font-size: 1.286em;
          font-weight: 400;
          color: var(--primary);
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    .bordered {
      width: 100%;
      height: 4px;
      background-color: var(--white-smoke-secondary);
      margin-bottom: 1.715em;
    }
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 1.75em 0;
      }
    }
  }
`;
export const CouponAppliedModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 4.25em;
    padding: 2.86em 3.5em;
    text-align: center;
    svg {
      /* width: 100px;
      height: 100px; */
      font-size: 7.5em;
      color: var(--primary);
      margin-bottom: 0.386em;
    }
    h5,
    h2,
    p,
    a {
      color: var(--black);
    }
    h2 {
      font-size: 2.15em;
      font-weight: 600;
      margin: 0.333em 0;
    }
    p {
      margin-bottom: 4.5em;
    }
    a {
      font-size: 1.215em;
    }
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 2.286em 1em;
      }
    }
  }
`;
export const RateModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 1.8em;
    padding: 2.86em 3.715em 2.286em;
    text-align: center;
    height: 700px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    h4,
    h5 {
      color: var(--black);
    }
    h5 {
      font-size: 1.575em;
    }
    img {
      // width: 155px;
      height: 200px;
      margin-bottom: 1.75em;
    }
    .header {
      margin-bottom: 3.5em;
    }
    h5 {
      font-weight: 400;
    }
    .content {
      h5 {
        margin-bottom: 1.37em;
      }
      .ant-rate {
        margin: 0 0 1.5em;
        li {
          color: var(--white-smoke-legendary);
          margin: 0 1em;
          svg {
            /* width: 42px;
            height: 42px; */
            font-size: 2.125em;
          }
          &.ant-rate-star-full,
          &.ant-rate-star-half,
          &.ant-rate-star-active {
            color: var(--primary);
          }
        }
      }
    }
    .ant-form {
      padding-top: 1em;
      .ant-form-item {
        .ant-form-item-label {
          padding-bottom: 1.5em;
          text-align: center;
          label {
            &::before {
              display: none;
            }
            h5 {
              margin-bottom: 0;
            }
          }
        }
        .ant-form-item-control {
          textarea {
            width: 100%;
            border: 1px solid var(--white-smoke-primary) !important;
            border-radius: 0.75em;
            box-shadow: none;
            padding: 1.5em 1.75em;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    margin: 0;
  }
  & {
    /* @media (min-width: 768px) {
      .ant-modal-content {
        h5 {
          font-size: 1.375rem;
        }
      }
    } */
    @media (max-width: 576px) {
      .ant-modal-content {
        padding: 1.75em 1.15em;
        .header {
          margin-bottom: 2.15em;
          h4 {
            font-size: 1.315em;
          }
        }
        .content {
          .ant-rate {
            li {
              margin: 0 0.5em;
              svg {
                font-size: 1.75em;
              }
            }
          }
        }
        .ant-form {
          .ant-form-item {
            .ant-form-item-control {
              textarea {
                &::placeholder {
                  font-size: 0.86em;
                }
              }
            }
          }
        }
        h5 {
          font-size: 1.15em;
        }
      }
    }
  }
`;
export const ChangeLocation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.286em;
  h5 {
    font-weight: 600;
    color: var(-black);
  }
  span {
    font-size: 0.86em;
    font-weight: 500;
    color: var(--primary);
    text-transform: uppercase;
    cursor: pointer;
  }
  p {
    color: var(--gray);
  }
  & > div:first-child {
    display: flex;
    align-items: baseline;
    img {
      margin-right: 0.575em;
      filter: invert(68%) sepia(9%) saturate(1988%) hue-rotate(118deg)
        brightness(92%) contrast(86%);
    }
  }
`;
export const SubmitBtn = styled.div`
  button {
    width: 100%;
    /* height: 69px; */
    height: auto;
    padding: 1.15em 1em;
    background: var(--primary) !important;
    border-color: var(--primary) !important;
    border-radius: 0.715em;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin: 2.3em 0 0;
    span {
      font-size: 1.515em;
      font-weight: 400;
      color: var(--white);
    }
  }
  & {
    @media (max-width: 576px) {
      button {
        /* height: 50px; */
        span {
          font-size: 1.2em;
        }
      }
    }
  }
`;
