import styled from "styled-components";

export const OrderPlaced = styled.div`
  width: 100%;
  max-width: 458px;
  margin: 0 auto;
  text-align: center;
  svg {
    width: 130px;
    height: 130px;
    color: var(--red);
    margin-bottom: 2em;
  }
  h1 {
    font-weight: 600;
    color: var(--black);
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--black);
    margin: 2rem 0;
  }
  p {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--primary) !important;
    margin: 0;
    cursor: pointer;
  }
  & {
    @media (min-width: 768px) {
      h1 {
        font-size: 2.75rem;
      }
      h4 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
`;
export const Button = styled.button`
  width: 100%;
  height: auto;
  padding: 0.75em 1em;
  background: var(--primary);
  border-color: var(--primary);
  border-radius: 0.715em;
  margin: 1.5em 0 0;
  font-size: 1em;
  font-weight: 400;
  color: var(--white);
`;
