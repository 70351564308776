import React, { useState, useEffect } from "react";
import { Card, Sale, AddBtn, QuantityBtn } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  incrementCart,
  decrementCart,
  getProductDetails,
} from "redux/action/appActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const VariationCard = ({ image, quantity, discount, price, sale, data }) => {
  const cartData = useSelector((state) => state.app.data);
  const [total, setTotal] = useState("1");
  const [counterVisible, setCounterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const callback = (status) => {
    if (status) {
      setCounterVisible(true);
    }
    setLoading(false);
  };
  const addtoCart = async () => {
    setLoading(true);
    dispatch(addCart(data, callback));
  };
  useEffect(() => {
    if (cartData.HomePage && data.product_id) {
      const product = cartData?.HomePage?.data[0].cart.cData.find(
        (item) => item.variation_id === data.variation_id
      );
      if (product) {
        setTotal(product.quantity);
      } else {
        setTotal(1);
      }
    }
  }, [cartData, data]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:24,
        height:24,
      }}
      spin
    />
  );
  const handleDecrement = async () => {
    setLoading(true);
    const callback = () => {
      if (total > 1) {
        setTotal(total - 1);
        setLoading(false);
      } else {
        setCounterVisible(false);
        setLoading(false);
      }
    };
    dispatch(
      decrementCart(
        {
          product_id: data.product_id,
          variation_id: data.variation_id,
          batch_id: data.batch_id,
          location_id: data.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };
  const handleIncrement = async () => {
    setLoading(true);
    const callback = () => {
      setTotal(total + 1);
      setLoading(false);
    };
    dispatch(
      incrementCart(
        {
          product_id: data.product_id,
          variation_id: data.variation_id,
          batch_id: data.batch_id,
          location_id: data.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };
  return (
    <>
      <Card>
        {sale && sale !== 0 ? (
          <Sale>
            <p>{sale}</p>
          </Sale>
        ) : (
          <></>
        )}

        <div className="d-flex align-items-center">
          <img src={image} alt="click here" />
          <h5>{quantity}</h5>
        </div>
        <div className="d-flex align-items-baseline">
          <p>{discount}</p>
          {discount!=price && <span className="price">{price}</span>}
        </div>
        <div>
          <div className="action">
            <>
              {loading ? (
                <div style={{ margin: "0 16px 16px 0" }}>
                  <Spin indicator={antIcon} />
                </div>
              ) : !counterVisible &&
                !(
                  cartData?.HomePage &&
                  cartData?.HomePage?.data[0]?.cart?.cData?.find(
                    (item) => item.variation_id === data.variation_id
                  )
                ) ? (
                <AddBtn
                  onClick={() => {
                    addtoCart();
                  }}
                >
                  <span>Add +</span>
                </AddBtn>
              ) : (cartData?.HomePage &&
                  cartData?.HomePage?.data[0]?.cart?.cData?.find(
                    (item) => item.variation_id === data.variation_id
                  )) ||
                counterVisible ? (
                <QuantityBtn>
                  <span onClick={handleDecrement}>-</span>
                  <span>{total}</span>
                  <span onClick={handleIncrement}>+</span>
                </QuantityBtn>
              ) : (
                ""
              )}
            </>
          </div>
        </div>
      </Card>
    </>
  );
};

export default VariationCard;
