import React, { useEffect } from "react";
import { Col, Row } from "antd";
import CategoryCard from "components/Cards/CategoryCard";
import DashboardHeader from "components/DashboardHeader";
import ProductLinks from "components/ProductLinks";
import { Fragment } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ItemListArea } from "screens/itemList/index.styled";
import { ShimmerThumbnail } from "shimmer-react";
import "./styles.css";

const BannerRedirection = (props) => {
  const categories = useSelector((state) => state.app.categories);
  const location = useLocation();
  const { data } = location.state || {};
  const { items } = data;
  const navigate = useNavigate();

  useEffect(() => {
    const handleBack = () => {
      navigate(`/`);
    };

    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [navigate]);

  return (
    <Fragment>
      <ItemListArea>
        <DashboardHeader />
        <ProductLinks links={categories} />
        <Container style={{ padding: "1rem" }}>
          <Row gutter={[{ md: 30, sm: 10, xs: 10 }]}>
            {items ? (
              items?.map((item) => {
                const { icon_image, name, id } = item;
                return (
                  <Col xl={3} lg={4} md={3} sm={3} xs={6} key={id}>
                    <CategoryCard image={icon_image} heading={name} id={id} />
                  </Col>
                );
              })
            ) : (
              <ShimmerThumbnail
                height={200}
                width={200}
                className="m-0"
                rounded
              />
            )}
          </Row>
        </Container>
      </ItemListArea>
    </Fragment>
  );
};

BannerRedirection.propTypes = {};

export default BannerRedirection;
