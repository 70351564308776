import styled from "styled-components";
import { Button, Collapse } from "antd";

export const UserCard = styled.div`
  border: 1px solid var(--white-smoke-primary);
  padding: 1.75em 0;
  height: 100%;
`;
export const UserInfo = styled.div`
  padding: 0 1.75em 2.5em;
  margin-bottom: 2.5em;
  border-bottom: 1px solid var(--white-smoke-primary);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.15em;
    h4 {
      font-weight: 400;
      color: var(--black);
      margin: 0;
    }
    & > div {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        cursor: pointer;
        &:first-child {
          color: var(--black);
          margin-right: 2.75em;
        }
        &:last-child {
          color: var(--red);
        }
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    p {
      font-size: 0.86em;
      font-weight: 300;
      color: var(--gray);
      margin: 0;
    }
    & > div:nth-child(2) {
      width: 5px;
      height: 5px;
      background-color: var(--gray);
      border-radius: 50%;
      margin: 0 1.67em;
    }
  }
  & {
    @media (min-width: 1400px) {
      .header {
        & > div {
          p {
            font-size: 1.15em;
          }
        }
      }
      .info {
        p {
          font-size: 1.286em;
        }
      }
    }
    /* @media (max-width: 576px) {
      .info {
        p {
          font-size: 0.75rem;
        }
      }
    } */
  }
`;
export const UserCollapse = styled(Collapse)`
  border: 0 !important;
  background-color: unset !important;
  .ant-collapse-item {
    border: 0 !important;
    background-color: unset !important;
    padding: 0 1.75em 2.5em !important;
    margin-bottom: 2.5em !important;
    border-bottom: 1px solid var(--white-smoke-primary) !important;
    border-radius: 0 !important;
    .ant-collapse-header {
      padding: 0;
      border: 0 !important;
      background-color: unset !important;
      position: relative;
      .ant-collapse-expand-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-60%);
        padding: 0;
        .anticon {
          svg {
            /* width: 16px;
            height: 16px; */
            font-size: 1.34em;
            color: var(--gray);
          }
        }
      }
      .ant-collapse-header-text {
        h4 {
          font-size: 1.75em;
          font-weight: 400;
          color: var(--black);
        }
      }
    }
    .ant-collapse-content {
      border: 0 !important;
      .ant-collapse-content-box {
        padding: 0.286em 0 0;
        p {
          margin: 0;
          font-size: 1.286em;
          font-weight: 300;
          color: var(--gray);
          &.linkPara {
            margin: 0.56em 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            span,
            svg {
              margin: 0;
              font-weight: 300;
              color: var(--gray);
            }
            span {
              /* font-size: 1.286em; */
            }
            svg {
              font-size: 1.15em;
            }
            &.active {
              span,
              svg {
                font-weight: 400;
                color: var(--black);
              }
            }
          }
        }
      }
    }
    &.not-collapsible {
      .ant-collapse-header {
        svg {
          transform: unset !important;
        }
      }
      .ant-collapse-content-box {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0 !important;
      border-bottom: 0 !important;
    }
  }
  & {
    @media (max-width: 576px) {
      .ant-collapse-item {
        .ant-collapse-header {
          .ant-collapse-header-text {
            h4 {
              font-size: 1.5em;
            }
          }
          /* .ant-collapse-expand-icon {
            .anticon {
              svg {
                width: 12px;
                height: 12px;
              }
            }
          } */
        }
      }
    }
  }
`;
export const UserOrders = styled.div`
  border: 1px solid var(--white-smoke-primary);
  padding: 2.15em;
  margin-top: 1.75em;
  height: 100%;
  .box-heading {
    font-size: 1.75em;
    font-weight: 600;
    color: var(--black);
    margin: 0;
  }
  .card-heading {
    font-size: 1.286em;
    font-weight: 300;
    color: var(--gray);
    margin: 1.115em 0;
  }
  .ant-pagination {
    li {
      display: none;
    }
    li:first-child {
      display: inline-block;
    }
    li:last-child {
      display: inline-block;
    }
    li.ant-pagination-item-active {
      display: inline-block;
      border-color: var(--primary);
    }
  }
  & {
    @media (min-width: 1400px) {
      padding: 2.15em 2.86em;
    }
    @media (min-width: 992px) {
      margin-top: 0;
    }
    @media (max-width: 576px) {
      padding: 1.75em;
    }
  }
`;
export const OrderView = styled.div`
  .icon {
    /* width: 32px;
    height: 27px; */
    width: max-content;
    padding: 0.15em 0.286em;
    border: 1px solid var(--white-smoke-primary);
    border-radius: 0.215em;
    cursor: pointer;
    margin-bottom: 1.75em;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    svg {
      font-size: 1.86em;
    }
  }
  p {
    font-weight: 300;
    color: var(--gray);
  }
  .order-info {
    padding-bottom: 1.715em;
    h2 {
      color: var(--black);
      margin-bottom: 0.315em;
    }
    h5 {
      font-weight: 400;
      color: var(--black);
      margin: 0;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dot {
      /* width: 24.5px;
      height: 24.5px; */
      padding: 0.875em;
      background: var(--primary);
      border-radius: 50%;
      margin-right: 0.575em;
    }
  }
  .order-mods {
    padding: 1.75em 0;
    border-top: 1px solid var(--white-smoke-primary);
    border-bottom: 1px solid var(--white-smoke-primary);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    & > div {
      &:first-child {
        text-align: start;
      }
      &:last-child {
        text-align: end;
      }
    }
    p {
      margin: 0;
    }
    h5 {
      font-size: 1.43em;
      font-weight: 400;
      color: var(--black);
      margin: 0 0 0.5em;
    }
  }
  .order-items {
    padding: 1.75em 0;
  }
  .address {
    padding: 1.43em 0;
    border-top: 1px solid var(--white-smoke-primary);
    border-bottom: 1px solid var(--white-smoke-primary);
    h5 {
      font-weight: 400;
    }
    & > h5:first-child {
      color: var(--black);
      margin-bottom: 1.125em;
    }
    .address-info {
      display: flex;
      align-items: baseline;
      img {
        margin-right: 0.575em;
      }
    }
    .card-body {
      p {
        &:not(:first-child) {
          margin-bottom: 0.25em;
        }
      }
    }
  }
  .payment-details {
    padding: 2em 0 2.86em;
    h5 {
      font-size: 1.43em;
    }
    & > h5:first-child {
      margin-bottom: 0.8em;
    }
    .details-info {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      & > div {
        &:first-child {
          text-align: start;
        }
        &:last-child {
          text-align: end;
        }
      }
      h5 {
        font-weight: 300;
        color: var(--gray);
        margin: 0 0 0.6em;
        &.special {
          color: var(--primary);
        }
        &.dark {
          font-weight: 400;
          color: var(--black);
        }
      }
    }
  }
  & {
    @media (min-width: 1400px) {
      p {
        font-size: 1.286em;
      }
    }
    @media (max-width: 576px) {
      h5 {
        font-size: 1.286em;
      }
      p {
        font-size: 1em;
      }
      .order-info {
        h2 {
          font-size: 1.43em;
        }
        /* .dot {
          width: 18px;
          height: 18px;
        } */
        & > div {
          &:last-child {
            display: block;
            p {
              &:last-child {
                text-align: end;
              }
            }
          }
        }
      }
    }
  }
`;
export const OrderItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.15em;
  .image-box {
    /* width: 69px;
    height: 69px; */
    padding: 0.315em 0.5em;
    border: 1px solid var(--grey-legendary);
    border-radius: 0.36em;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin-right: 1.43em;
    img {
      width: 42px;
      height: 54px;
    }
  }
  .product-info {
    p {
      &:first-child {
        font-weight: 400;
        margin-bottom: 0.56em;
        color: var(--black);
      }
      &:last-child {
        font-family: "Baloo 2", cursive !important;
        margin-bottom: 0;
      }
    }
  }
  & {
    @media (max-width: 576px) {
      .image-box {
        /* width: 60px;
        min-width: 60px;
        height: 60px; */
        height: max-content;
        margin-right: 0.86em;
        img {
          width: 40px;
          height: 50px;
        }
      }
      .product-info {
        p {
          &:first-child {
            font-size: 0.86em;
            margin-bottom: 0.67em;
          }
        }
      }
      & > h5:last-child {
        font-size: 1.15em;
        text-align: end;
        margin-left: 0.67em;
      }
    }
  }
`;
export const ReorderBtn = styled(Button)`
  width: 100%;
  /* height: 69px; */
  height: auto;
  padding: 1.23em 1em;
  background: var(--primary);
  border-radius: 0.715em;
  border: 0;
  outline: 0;
  box-shadow: none;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  span {
    font-size: 1.575em;
    font-weight: 400;
    color: var(--white);
  }
`;
export const AddAddress = styled.div`
  p {
    font-size: 1.071em;
    font-weight: 300;
    color: var(--gray);
    margin: 0;
  }
  .addNew {
    color: var(--primary);
    margin: 1.5em 0;
    cursor: pointer;
    svg {
      font-size: 1.17em;
      margin-right: 0.5em;
    }
  }
  & {
    @media (min-width: 1400px) {
      p {
        font-size: 1.286em;
        svg {
        }
      }
    }
  }
`;
export const WalletInfo = styled.div`
  text-align: center;
  padding: 0 0 2.75em;
  border-bottom: 1px solid var(--white-smoke-primary);
  h4 {
    font-size: 1.75em;
    font-weight: 300;
    color: var(--gray);
    margin-bottom: 0.82em;
  }
  h1 {
    font-size: 2.86em;
    color: var(--black);
    margin: 0;
  }
`;
export const WalletContent = styled.div`
  padding: 2.286em 0;
  h5 {
    font-size: 1.575em;
    font-weight: 400;
    margin-bottom: 1.635em;
  }
  h6 {
    font-size: 1.286em;
  }
  /* & {
    @media (min-width: 1400px) {
      h5 {
        font-size: 1.375rem;
      }
      h6 {
        font-size: 1.125rem;
      }
    }
  } */
`;
export const AddMoney = styled.div`
  display: flex;
  margin-bottom: 2.75em;
  .ant-input-number-affix-wrapper {
    width: 100%;
    /* height: 68px; */
    border: 1px solid var(--gray-secondary) !important;
    border-radius: 0.715em;
    padding: 1em 1.625em;
    margin-right: 1.286em;
    box-shadow: none;
    .ant-input-number-prefix {
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
    }
    .ant-input-number {
      .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input-wrap {
        height: 100%;
      }
    }
    input {
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
    }
  }
  button {
    /* width: 100%;
    max-width: 211px;
    height: 68px; */
    height: auto;
    padding: 1.5em 3.5em;
    background: var(--primary);
    border-radius: 0.715em;
    border: 0;
    outline: 0;
    box-shadow: none;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    span {
      font-size: 1.286em;
      font-weight: 500;
      color: var(--white);
    }
  }
  & {
    @media (max-width: 576px) {
      display: block;
      .ant-input-number-affix-wrapper {
        margin: 0 0 1.15em 0;
      }
      button {
        width: 100%;
      }
    }
  }
`;
export const MembershipCard = styled.div`
  width: 100%;
  background-color: var(--black);
  border-radius: 0.86em;
  color: var(--white);
  padding: 3em 2.5em;
  h5 {
    font-weight: 300;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.15em;
    margin-bottom: 2.75em;
    h2 {
      width: 100%;
      max-width: 460px;
      font-size: 2.15em;
      font-weight: bold;
      color: var(--white);
      line-height: 1.455em;
      margin: 0;
      span {
        color: var(--cream);
      }
    }
    img {
      width: 175px;
      height: 175px;
    }
  }
  .content {
    margin-bottom: 2.5em;
    h5 {
      font-size: 1.575em;
      margin-bottom: 1.455em;
    }
    .content-card {
      background-color: var(--black-ordinary);
      box-shadow: 0px -3px 36px var(--black-legendary);
      border-radius: 1.286em;
      padding: 2.15em 0;
      .card-inner {
        width: 100%;
        max-width: max-content;
        margin: 0 auto;
      }
    }
    .saving-card {
      display: flex;
      align-items: center;
      margin-bottom: 2.86em;
      img {
        width: 50px;
        height: 50px;
        margin-right: 2.15em;
        filter: invert(83%) sepia(36%) saturate(374%) hue-rotate(340deg)
          brightness(102%) contrast(103%);
      }
      h6 {
        font-size: 1.36em;
        color: var(--cream);
        margin-bottom: 0.53em;
      }
      p {
        font-size: 0.86em;
        font-weight: 300;
        color: var(--white);
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .card-footer {
    h5 {
      margin-bottom: 1.9em;
    }
  }
  & {
    @media (min-width: 1400px) {
      h5 {
        /* font-size: 1.375rem; */
      }
      .info {
        padding: 0 4.5em;
        margin-bottom: 5em;
        h2 {
          font-size: 2.75em;
        }
      }
    }
    @media (max-width: 767px) {
      .info {
        padding: 0;
        img {
          width: 75px;
          height: 75px;
        }
        h2 {
          line-height: unset;
          font-size: 1.286em;
        }
      }
    }
    @media (max-width: 576px) {
      .content {
        h5 {
          font-size: 1.286em;
        }
      }
      .card-inner {
        .saving-card {
          padding-right: 0.5em;
          img {
            width: 40px;
            height: 40px;
            margin-right: 1.625em;
          }
          h6 {
            font-size: 1.15em;
          }
        }
      }
    }
  }
`;
export const PlanBtn = styled.div`
  width: 211px;
  /* height: 82px; */
  border: 2px solid var(--cream);
  border-radius: 1.215em;
  padding: 0.875em 1.36em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  margin: 0.572em;
  h6,
  h4,
  p {
    color: var(--white);
    margin: 0;
  }
  h6 {
    font-size: 1.215em;
    margin-bottom: 0.24em;
  }
  p {
    font-size: 0.715em;
    text-decoration: line-through;
    margin-left: 0.5em;
  }
  .ant-radio-wrapper {
    .ant-radio-inner {
      width: 26px;
      height: 26px;
      border: 2px solid var(--cream);
      background-color: transparent !important;
      position: relative;
      &::after {
        padding: 1.7em;
        position: absolute;
        top: -50%;
        left: -50%;
        background-color: var(--cream);
        border-radius: 50%;
        margin-block-start: -2px;
        margin-inline-start: -2px;
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        background-color: transparent !important;
      }
    }
  }
  & {
    @media (min-width: 1440px) {
    }
  }
`;
export const ConfirmButton = styled(Button)`
  width: 100%;
  /* height: 75px; */
  height: auto;
  padding: 1.262em 0;
  background-color: var(--cream) !important;
  border-color: var(--cream) !important;
  border-radius: 0.715em;
  margin-top: 4.5em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  span {
    font-size: 1.725em;
    font-weight: 500;
    color: var(--black);
  }
`;
