import Slider from "react-slick";
import styled from "styled-components";

export const SlickSlider = styled(Slider)`
  position: unset;
  .slick-list {
    padding-left: 0 !important;
    .slick-track {
      .slick-slide {
        padding-right: 1.75em;
        div {
          cursor: pointer;
        }
      }
    }
  }
  .sliderArrow {
    width: auto;
    height: auto;
    position: absolute;
    top: 4%;
    right: 0;
    /* right: 130px; */
    img {
      /* width: 36px; */
      height: 36px;
    }
    &::before {
      content: "";
    }
  }
  & {
    /* @media (max-width: 1680px) {
      .sliderArrow {
        right: 100px;
      }
    }
    @media (max-width: 992px) {
      .sliderArrow {
        right: 24px;
      }
    } */
    @media (max-width: 576px) {
      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0 0.5em;
          }
        }
      }
      .sliderArrow {
        top: 2.25%;
        img {
          /* width: 28px; */
          height: 28px;
        }
      }
    }
  }
`;
