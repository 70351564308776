import styled from "styled-components";


export const FooterIcon = styled.div`
    font-size: 20px;
    width: 35px;
    height: 35px; 
    background-color: #142134; 
    border-radius: 6px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:white;
    margin:0px 4px;
`


export const playImg = styled.img`
    width:100% !important;
`


export const FooterStyle = styled.div`
    display:block;
    @media and (max-width:786px){
        display:none !important;
    }
`