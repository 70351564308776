import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { OrderModalStyle } from "../styles";
import { OrderPlaced } from "./styles";
import { useDispatch, useSelector } from "react-redux";

const OrderModal = ({ isModalOpen, setIsModalOpen }) => {
  const cart = useSelector((state) => state.app.data.HomePage.data[0].cart);
  const navigate = useNavigate();

  const [state, setState] = useState({
    business_name: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    let refData = localStorage.getItem("refType");

    if (refData) {
      try {
        refData = JSON.parse(refData);
        if (refData?.business_name) {
          setState({
            business_name: refData?.business_name,
          });
        }
      } catch (error) {}
    }
  }, []);
  return (
    <>
      <OrderModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        closable={false}
      >
        <OrderPlaced>
          <BsFillCheckCircleFill />
          <h1>Order Placed!</h1>
          <h4>
            Thank you for your order{" "}
            {state?.business_name && (
              <>
                with <span>{state?.business_name}</span>!{" "}
              </>
            )}
            Your order was placed successfully
          </h4>
          <p
            onClick={() => {
              setIsModalOpen(false);
              dispatch({
                type: "UPDATE_HOME_CART",
                payload: {
                  cData: [],
                },
              });
              navigate("/dashboard/orders");
            }}
          >
            View Details
          </p>
        </OrderPlaced>
      </OrderModalStyle>
    </>
  );
};

export default OrderModal;
