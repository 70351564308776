import { SlickSlider } from "components/Carousel/styles";
import { fileURL } from "helpers/baseURL";
import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const BannerCarousel = ({ item }) => {
  const navigate = useNavigate();

  const settings = {
    className: "center px-2",
    // infinite: true,
    infinite: item.length < 6 ? false : true,
    swipeToSlide: true,
    autoplay: true,
    slidesToShow: item.length > 6 ? 6 : item.length,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <Fragment />,
    centerMode: false,
    centerPadding: "80px",
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: item.length > 3 ? 3 : item.length,
          slidesToScroll: 1,
          infinite: item.length < 3 ? false : true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: item.length > 3 ? 3 : item.length,
          slidesToScroll: 1,
          infinite: item.length < 3 ? false : true,
          
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: item.length > 3 ? 3 : item.length,
          slidesToScroll: 3,
          infinite: item.length < 3 ? false : true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: item.length > 2 ? 2 : item.length,
          slidesToScroll: 1,
          infinite: item.length < 2 ? false : true,
        },
      },
      {
        breakpoint: 558,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: item.length < 1 ? false : true,
        },
      },
    ],
  };

  //will fix optimize the code when are able to test all the senarios
  const handleBannerRedirection = (data) => {
    if (data?.items_for === "Category") {
      if (data?.items.length === 0) {
        navigate(`/no-data-found`);
      } else {
        // data?.items.map((item) => {
        //   navigate(`/itemList/${item?.id}`);
        // });
        data?.items.forEach((item) => {
          navigate(`/banner-redirect`, { state: { data: data } });
        });
      }
    } else if (data?.items_for === "Brands") {
      if (data?.items.length === 0) {
        navigate(`/no-data-found`);
      } else {
        data?.items.map((item) => {
          navigate(`/featured-brands/${item?.id}`);
        });

        data?.items.forEach((item) => {
          navigate(`/banner-redirect`, { state: { data: data } });
        });
      }
    } else if (data?.items_for === "Specific Product") {
      if (data?.items.length === 0) {
        navigate(`/no-data-found`);
      } else {
        // data?.items.map((item) => {
        //   navigate(`/product/${item?.id}`);
        // });
        data?.items.forEach((item) => {
          navigate(`/banner-redirect`, { state: { data: data } });
        });
      }
    } else if (data?.items_for === "Custom Link") {
      if (data?.items.length === 0) {
        if (data?.url) {
          window.open(data?.url, "_blank");
        } else {
          navigate(`/no-data-found`);
        }
      }
    } else {
      navigate(`/no-data-found`);
    }
  };

  return (
    <SlickSlider {...settings}>
      {item.map((child) => (
        <img
          key={child.image}
          src={`${fileURL}/${child.image}`}
          alt="click here"
          className="banner"
          onClick={() => handleBannerRedirection(child)}
        />
      ))}
    </SlickSlider>
  );
};

export default BannerCarousel;
