export const data = [
  {
    image: "/images/category-card-image-1.png",
    heading: "Breakfast & Diary",
  },
  {
    image: "/images/category-card-image-2.png",
    heading: "Tea, Coffee & Health Drink",
  },
  {
    image: "/images/category-card-image-3.png",
    heading: "Snacks & Munchies",
  },
  {
    image: "/images/category-card-image-1.png",
    heading: "Breakfast & Diary",
  },
  {
    image: "/images/category-card-image-2.png",
    heading: "Tea, Coffee & Health Drink",
  },
  {
    image: "/images/category-card-image-3.png",
    heading: "Snacks & Munchies",
  },
  {
    image: "/images/category-card-image-1.png",
    heading: "Breakfast & Diary",
  },
  // {
  //   image: "/images/category-card-image-2.png",
  //   heading: "Tea, Coffee & Health Drink",
  // },
  // {
  //   image: "/images/category-card-image-3.png",
  //   heading: "Snacks & Munchies",
  // },
];
