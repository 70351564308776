export const AddressCardsData = [
  {
    heading: "Home",
    subheading:
      "22nd Main Rd, Banashankari Stage II, Banashankari Majestic, Bangalore,",
    text: "Ph : 987554210",
  },
  {
    heading: "Home",
    subheading:
      "22nd Main Rd, Banashankari Stage II, Banashankari Majestic, Bangalore,",
    text: "Ph : 987554210",
  },
];
