import styled from "styled-components";
import { Input } from "antd";

export const SearchBar = styled(Input)`
  width: 100%;
  /* height: 61px; */
  border: 1px solid var(--gray-secondary) !important;
  border-radius: 0.684em;
  outline: 0;
  box-shadow: none !important;
  padding: 1.15em !important;
  font-size: 1.15em;
  font-weight: 300;
  color: var(--black);
  padding: 1.125em 1.5em;
  &::placeholder {
    color: var(--gray-ordinary);
  }
  svg {
    font-size: 1.25em;
    margin-right: 0.5em;
    color: var(--gray-ordinary);
  }
`;
export const SetLocation = styled.div`
  margin: 1.75em 0;
  cursor: pointer;
  width: fit-content;
  span {
    font-size: 1.15em;
    font-weight: 500;
    color: var(--primary);
  }
  svg {
    margin-right: 1.35em;
    fill: var(--primary);
  }
  // .ant-radio-wrapper {
  //   align-items: center;
  //   &:hover {
  //     .ant-radio {
  //       background-color: var(--white-smoke-secondary);
  //     }
  //   }
  //   .ant-radio {
  //     width: 29px;
  //     height: 29px;
  //     border-radius: 50%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     transition: all 250ms ease-in-out;
  //     .ant-radio-inner {
  //       width: 18px;
  //       height: 18px;
  //       border: 1px solid var(--grey-legendary);
  //       position: relative;
  //       &::after {
  //         padding: 1.0715em;
  //         position: absolute;
  //         top: -50%;
  //         left: -50%;
  //         background: var(--primary);
  //         border-radius: 50%;
  //         margin-block-start: 1px;
  //         margin-inline-start: 1px;
  //       }
  //     }
  //     &.ant-radio-checked {
  //       background-color: var(--white-smoke-secondary);
  //     }
  //   }
  //   &.ant-radio-wrapper-checked {
  //     .ant-radio-inner {
  //       background-color: unset !important;
  //     }
  //   }
  //   & > span:last-child {
  //     font-size: 1.15em;
  //     font-weight: 500;
  //     color: var(--primary);
  //   }
  // }
`;
export const AddressCard = styled.div`
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid var(--grey-ordinary);
  margin-bottom: 1.15em;
  img {
    margin-right: 0.86em;
  }
  h5 {
    font-weight: 600;
    color: var(-black);
    margin-bottom: 0.25em;
  }
  p {
    color: var(--gray);
    margin-bottom: 1.15em;
  }
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
`;
