import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TiLocation } from "react-icons/ti";
import { BiSearch } from "react-icons/bi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { Badge, Collapse } from "antd";
import LoginModal from "components/Modals/LoginModal";
import VerificationModal from "components/Modals/VerificationModal";
import InfoModal from "components/Modals/InfoModal";
import EditModal from "components/Modals/EditModal";
import LocationModal from "components/Modals/LocationModal";
import ConfirmLocationModal from "components/Modals/ConfirmLocationModal";
import SearchLocationModal from "components/Modals/searchLocationModal";
import "./header.css";
import {
  ItemsSearch,
  Logo,
  Drop,
  Dropdowns,
  Login,
  LocationButton,
  UserInfo,
  ProfileDrop,
  UserCollapse,
  Share,
  Logout,
  DashboardHead,
} from "./styles";
import {
  getSearch,
  getALLData,
  userLoaded,
  setSelectedCategory,
  getCartItems,
} from "redux/action/appActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import setAuthToken from "helpers/setAuthToken";
import { useCallback } from "react";
import { TOGGLE_LOGIN_MODAL } from "redux/Constants";
import { Hamburger, LocationIcon } from "assets/svg";
import { fileURL } from "helpers/baseURL";
import UpdateNumberOtp from "components/Modals/VerificationModal/UpdateNumberOtp";
import RegisterModal from "components/Modals/RegisterModal";

const accountData = [
  {
    text: "Addresses",
    link: "/dashboard/address",
  },
  // {
  //   text: "Shopping List",
  //   link: "/dashboard/shopping",
  // },
  // {
  //   text: "Loyalty",
  //   link: "/dashboard/loyalty",
  // },
  // {
  //   text: "Wallet",
  //   link: "/dashboard/wallet",
  // },
  // {
  //   text: "Settings",
  //   step: 0,
  //   num: 0,
  // },
];

const aboutData = [
  {
    text: "Privacy Policy",
    link: "/dashboard/policy",
  },
  // {
  //   text: "Terms & Conditions",
  //   link: "/dashboard/e-page",
  // },
  {
    text: "About us",
    link: "/dashboard/about",
  },
  {
    text: "Rate us",
    link: "/dashboard/rate",
  },
];

const DashboardHeader = ({ setStep }) => {
  let localAddr = localStorage.getItem("user-address");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.app.data);
  const userLocation = useSelector((state) => state.app.location);
  const loginModal = useSelector((state) => state.app.isLoginOpen);

  const categories = useSelector((state) => state.app.data.categories);

  const { pathname } = useLocation();

  const setLoginModal = useCallback(() => {
    dispatch({ type: TOGGLE_LOGIN_MODAL });
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [confirmLocationModal, setConfirmLocationModal] = useState(false);
  const [searchLocationModal, setSearchLocationModal] = useState(false);
  const [userNumber, setUserNumber] = useState({});
  const [loginResponse, setLoginResponse] = useState("");
  const [username, setUsername] = useState("");
  const [currentLocation, setCurrentLocation] = useState(false);
  const [userDropOpen, setUserDropOpen] = useState(false);
  const [center, setCenter] = useState();

  const [otpModal, setOtpModal] = useState(false);
  const [userUpdateData, setUserUpdateData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const { Panel } = Collapse;

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      setUserDropOpen(false);
    }
  };

  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), []);

  const handleOpenChange = (flag) => {
    setUserDropOpen(flag);
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <UserInfo
            onClick={() => {
              setEditModal(true);
            }}
          >
            <div>
              <p>{username}</p>
              {/* <p>{userPhoneNumber}</p> */}
            </div>
            <div>
              <p>Edit</p>
            </div>
          </UserInfo>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <UserCollapse>
          <Panel
            className="not-collapsible"
            header={
              <>
                <span>My Orders</span>
                <span>Order Details, Status & More</span>
              </>
            }
            onClick={() => {
              navigate("/dashboard/orders");
            }}
            key="1"
          ></Panel>
          {/* <Panel
            className="not-collapsible"
            header={
              <>
                <span>Membership</span>
                <span>Saving gets bigger with membership</span>
              </>
            }
            onClick={() => {
              navigate("/dashboard/membership");
            }}
            key="2"
          ></Panel> */}
          <Panel
            header={
              <>
                <span>My Account</span>
                <span></span>
                {/* <span>Loyalty, Wallet, Setting & More</span> */}
              </>
            }
            key="3"
          >
            {accountData?.map((item, i) => {
              const { text, link } = item;
              return (
                <p
                  key={i}
                  className="linkPara"
                  onClick={() => {
                    navigate(link);
                  }}
                >
                  <span>{text}</span>
                  <MdOutlineKeyboardArrowRight />
                </p>
              );
            })}
          </Panel>
        </UserCollapse>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <>
    //       <Share>
    //         <div>
    //           <p>Invite friends, Get rewards</p>
    //           <p>
    //             Share your code <strong>odc25g</strong>{" "}
    //             <img src="/images/copy-icon.svg" alt="click here" />
    //           </p>
    //         </div>
    //         <div>
    //           <p>Share</p>
    //         </div>
    //       </Share>
    //     </>
    //   ),
    // },
    {
      key: "4",
      label: (
        <UserCollapse>
          <Panel
            header={
              <>
                <span>About</span>
                <span>Privacy policy, Rate us & more</span>
              </>
            }
            key="1"
          >
            {aboutData?.map((item, i) => {
              const { text, link } = item;
              return (
                <p
                  key={i}
                  className="linkPara"
                  onClick={() => {
                    navigate(link);
                  }}
                >
                  <span>{text}</span>
                  <MdOutlineKeyboardArrowRight />
                </p>
              );
            })}
          </Panel>
        </UserCollapse>
      ),
    },
    {
      key: "5",
      label: (
        <>
          <Logout
            onClick={() => {
              localStorage.removeItem("token");
              // navigate(0);
              navigate("/");
              dispatch({ type: "LOGOUT", payload: "" });
              window.location.reload();

              //----------AFTER LOGOUT HOMEPAGE-------//
              const data = {
                isGuest: 0,
                domain:
                  window.location.origin === "http://localhost:3000"
                    ? "https://web.grocbay.com"
                    : window.location.origin,
                screen_type: "home_screen",
              };

              dispatch(getALLData(data));
            }}
          >
            <p>Logout</p>
            <MdOutlineKeyboardArrowRight />
          </Logout>
        </>
      ),
    },
  ];

  const defaultLocation = async () => {
    let defLoc = JSON.parse(localStorage.getItem("defLoc"));
    if (!localAddr) {
      setCenter({
        lat: defLoc?.Lat || defLoc?.lat,
        lng: defLoc?.Lng || defLoc?.lng,
      });
    } else {
      try {
        localAddr = localAddr ? JSON.parse(localAddr) : localAddr;

        setCenter({
          lat: parseFloat(localAddr.latitude),
          lng: parseFloat(localAddr.longitude),
        });
      } catch (err) {
        navigator.geolocation.getCurrentPosition(async function (position) {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    }
  };

  useEffect(() => {
    defaultLocation();
  }, []);

  useEffect(() => {
    let prevAddr = localStorage.getItem("user-address");

    if (
      !data?.SplashScreen?.customer?.location_name &&
      !userLocation?.location_name &&
      !prevAddr
    ) {
      setLocationModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (locationModal) {
      setLocationModal(false);
    }
  }, [userLocation]);

  useEffect(() => {
    //this will allow disable the location modal if pathamane is /policy
    if (pathname === "/policy") {
      setLocationModal(false);
    }
  }, [pathname]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);

  const onCategoryClick = useCallback((e, name, id) => {
    e.preventDefault();
    dispatch({ type: "GET_SUB_CATEGORIES", payload: [] });
    dispatch({
      type: "GET_SUB_CATEGORIES_PRODUCTS",
      payload: {
        nestedSubCategoriesWithProducts: [],
      },
    });
    dispatch({ type: "GET_PRODUCTS", payload: [] });
    dispatch({ type: "SET_MESSAGE", payload: "" });
    dispatch(setSelectedCategory({ title: name, id: id }));

    navigate(`/itemList/${id}`);
    setMenuOpen(false);
  }, []);

  return (
    <DashboardHead
      className={
        location?.pathname === "/search" ||
        location?.pathname === "/cart" ||
        location?.pathname === "/myOrders" ||
        location?.pathname === "/allCategories" ||
        location?.pathname === "/allRatings"
          ? "topHead"
          : ""
      }
    >
      <EditModal
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        setOtpModal={setOtpModal}
        setUserUpdateData={setUserUpdateData}
      />

      <UpdateNumberOtp
        data={userUpdateData}
        isOpen={otpModal}
        setIsOpen={setOtpModal}
        setData={setUserUpdateData}
      />

      <Navbar expand="xl">
        <Container>
          <Navbar.Brand href="" className="me-0" onClick={() => navigate("/")}>
            <Logo
              src={fileURL + data?.refType?.business_logo}
              alt="click here"
            />
          </Navbar.Brand>

          <div
            onClick={() => {
              setLocationModal(true);
            }}
            className="location-button d-lg-flex align-items-center d-md-none d-sm-none d-xs-none max-vw-50"
          >
            <LocationIcon />
            <div>
              <h6>
                {userLocation?.location_name
                  ? userLocation?.location_name
                  : data?.SplashScreen?.customer?.location_name
                  ? data?.SplashScreen?.customer?.location_name
                  : ""}
              </h6>
              <p className="text-overflow-none" style={{ maxWidth: "200px" }}>
                {userLocation?.location_addresses
                  ? userLocation?.location_addresses
                  : data?.SplashScreen?.customer?.location_address
                  ? data?.SplashScreen?.customer?.location_address
                  : ""}
              </p>
            </div>
          </div>

          <LocationModal
            isModalOpen={locationModal}
            setIsModalOpen={setLocationModal}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            setConfirmLocationModal={setConfirmLocationModal}
            setSearchLocationModal={setSearchLocationModal}
            defaultLocation={defaultLocation}
          />
          {confirmLocationModal && (
            <ConfirmLocationModal
              isModalOpen={confirmLocationModal}
              setIsModalOpen={setConfirmLocationModal}
              setLocationModal={setLocationModal}
              setCurrentLocation={setCurrentLocation}
              center={center}
              setCenter={setCenter}
            />
          )}
          <SearchLocationModal
            isModalOpen={searchLocationModal}
            setIsModalOpen={setSearchLocationModal}
            setLocationModal={setLocationModal}
            setCurrentLocation={setCurrentLocation}
          />
          <div className="d-flex gap-4">
            <Drop
              className="odd d-xl-none"
              title={
                // <Badge count="0" offset={[-2, 2]} color="var(--red)">
                //   <img src="/images/notification-logo.svg" alt="click here" />
                // </Badge>
                <></>
              }
            />

            <div onClick={toggleMenu} className="d-xl-none">
              <Hamburger />
            </div>
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="d-sm-none d-xs-none d-md-none"
          >
            <Nav className="ms-auto align-items-end">
              {location.pathname !== "/search" && (
                <ItemsSearch
                  onClick={() => {
                    navigate("/search");
                  }}
                >
                  <BiSearch />
                  <p>Search for brand or items</p>
                </ItemsSearch>
              )}
              <Dropdowns>
                <Drop
                  title={
                    <Badge
                      onClick={() => navigate("/cart")}
                      count={data?.HomePage?.data[0]?.cart?.cData?.length}
                      offset={[-2, 2]}
                      color="var(--red)"
                    >
                      <img src="/images/shopping-logo.svg" alt="click here" />
                    </Badge>
                  }
                />
                <Drop
                  className="odd"
                  title={
                    // <Badge count="0" offset={[-2, 2]} color="var(--red)">
                    //   <img
                    //     src="/images/notification-logo.svg"
                    //     alt="click here"
                    //   />
                    // </Badge>
                    <></>
                  }
                />
                {!data?.SplashScreen?.customer?.mobile &&
                !data?.SplashScreen?.customer?.email ? (
                  <>
                    <Login onClick={() => setLoginModal(true)}>
                      <img src="/images/profile-logo.svg" alt="click here" />
                      <p>Login</p>
                    </Login>
                    <LoginModal
                      isModalOpen={loginModal}
                      setIsModalOpen={setLoginModal}
                      setUserNumber={setUserNumber}
                      setLoginResponse={setLoginResponse}
                      setVerificationModal={setVerificationModal}
                    />
                    <VerificationModal
                      isModalOpen={verificationModal}
                      setIsModalOpen={setVerificationModal}
                      userPhoneNumber={userNumber}
                      loginResponse={loginResponse}
                      setLoginModal={setLoginModal}
                      setItem={setInfoModal}
                      itemValue={true}
                      setLoginResponse={setLoginResponse}
                      callback={() => {
                        localStorage.setItem("token", loginResponse.token);
                        setInfoModal(false);
                        const data = {
                          isGuest: 0,
                          domain:
                            window.location.origin === "http://localhost:3000"
                              ? "https://web.grocbay.com"
                              : window.location.origin,
                          screen_type: "home_screen",
                        };
                        dispatch(userLoaded(data, loginResponse.token));
                        dispatch(getCartItems());
                      }}
                    />
                    <RegisterModal
                      isModalOpen={infoModal}
                      userPhoneNumber={userNumber}
                      callback={(token) => {
                        const data = {
                          isGuest: 0,
                          domain:
                            window.location.origin === "http://localhost:3000"
                              ? "https://web.grocbay.com"
                              : window.location.origin,
                          screen_type: "home_screen",
                        };
                        dispatch(getALLData(data));
                        setInfoModal(false);
                      }}
                    />
                    {/* <InfoModal
                      isModalOpen={infoModal}
                      // setIsModalOpen={setInfoModal}
                      userPhoneNumber={userNumber}
                      callback={(token) => {
                        const data = {
                          isGuest: 0,
                          domain:
                            window.location.origin === "http://localhost:3000"
                              ? "https://web.grocbay.com"
                              : window.location.origin,
                          screen_type: "home_screen",
                        };
                        dispatch(getALLData(data));
                        setInfoModal(false);
                      }}
                    /> */}
                  </>
                ) : (
                  <ProfileDrop
                    menu={{
                      items,
                      onClick: handleMenuClick,
                    }}
                    onOpenChange={handleOpenChange}
                    open={userDropOpen}
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <img src="/images/profile-logo.svg" alt="click here" />
                      <p>{data?.SplashScreen?.customer?.name}</p>
                    </a>
                  </ProfileDrop>
                )}
              </Dropdowns>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Container className="d-md-flex d-sm-flex d-xs-flex d-lg-none d-xl-none justify-content-between gap-1 overflow-hidden">
          <div className="wp-70">
            {location.pathname !== "/search" && (
              <ItemsSearch
                onClick={() => {
                  navigate("/search");
                }}
              >
                <BiSearch />
                <p className="text-overflow-none">Search for brand or items</p>
              </ItemsSearch>
            )}
          </div>
          <div className="wp-25">
            {userLocation && (
              <div
                onClick={() => setLocationModal(true)}
                className="location-button"
              >
                <LocationIcon className="d-md-none d-sm-none d-xs-none" />

                <div>
                  <h6 className="font-xs-smaller font-sm-small ">
                    {userLocation?.location_name
                      ? userLocation?.location_name
                      : data?.SplashScreen?.customer?.location_name
                      ? data?.SplashScreen?.customer?.location_name
                      : ""}
                  </h6>
                  <p className="font-xs-smaller font-sm-small text-overflow-none">
                    {userLocation?.location_addresses
                      ? userLocation?.location_addresses
                      : data?.SplashScreen?.customer?.location_address
                      ? data?.SplashScreen?.customer?.location_address
                      : ""}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Container>
        <Offcanvas show={menuOpen} onHide={toggleMenu} placement="end">
          <div className="custom-close">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>All Categories</Offcanvas.Title>
            </Offcanvas.Header>
          </div>
          <Offcanvas.Body>
            <div className="d-flex flex-column gap-1">
              {categories?.map((item) => (
                <div
                  key={item.id}
                  className="d-flex hover-bg-blue rounded-2 gap-3 p-2 cursor-pointer align-items-center"
                  onClick={(e) => onCategoryClick(e, item?.name, item.id)}
                >
                  <img
                    src={fileURL + item.icon_image}
                    alt={item?.name}
                    style={{
                      height: "50px",
                      width: "50px",
                    }}
                    loading="eager"
                  />
                  <h6 className="m-0">{item?.name}</h6>
                </div>
              ))}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
    </DashboardHead>
  );
};

export default DashboardHeader;
