import React, { useMemo } from "react";
import { Container } from "react-bootstrap";
import { FooterArea, FooterInfo, PaymentArea, BottomFooter } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./NewDashboardFooter.css";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { FooterIcon } from "components/NewFooter/styles";
import { useState } from "react";
import { useEffect } from "react";
import { getAbout } from "redux/action/appActions";

const usefulLinks = [
  // {
  //   label: "Contact us",
  //   path: "",
  // },
  {
    label: "Privacy Policy",
    path: "/policy",
  },
  {
    label: "Terms & Conditions",
    path: "/e-page",
  },
  {
    label: "Refund Policy",
    path: "/e-page?type=Refund",
  },
  {
    label: "Return & Exchange Policy",
    path: "/e-page?type=Return",
  },
  {
    label: "Shipping Policy",
    path: "/shippingpolicy",
  },
  // {
  //   label: "About us",
  //   path: "/about",
  // },
];

const NewDashboardFooter = () => {
  const navigate = useNavigate();
  const categories = useSelector((state) => state.app.data.categories);
  const store = useSelector((state) => state.app.data.refType);
  const address = useSelector((state) => state.app.data.address);
  const paymentSVG = useSelector((state) => state.app.data.PaymentMode);
  const [data, setData] = useState("");
  const makeCat = useMemo(() => categories.slice(0, 5), [categories]);

  const getData = async () => {
    const res1 = await getAbout("Introduction");
    setData(res1.body);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FooterArea>
      <Container
        style={{ backgroundColor: "white" }}
        className="new-footer-container mt-4"
      >
        <div style={{ width: "100%" }}>
          <div className="d-flex justify-content-between row gap-4 mt-4 ">
            <div className="col-xl-3 col-lg-5 col-sm-12 col-xs-12 footer-aboutud-container">
              <div
                onClick={() => navigate("/about")}
                className="overflow-hidden cursor-pointer"
                style={{
                  maxHeight: 130,
                }}
              >
                <p className="footer-title-main">{`About ${store.business_name}`}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.replace("<br>", ""),
                  }}
                  className="about-us-danger-html"
                />
              </div>
              {/* {console.log(store, "New Dash index")} */}
              {!!store.email && (
                <div className="col-12 mt-3">
                  <p className="footer-title-main">Contact Email</p>
                  <div className="mt-1">
                    <a
                      href={`mailto:${store.email}`}
                      className=" font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text cursor-pointer"
                    >
                      {store.email}
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="col-xl-2 col-lg-5 col-xs-12 col-sm-12  d-flex gap-4">
              <span className="">
                <p className="footer-title-main">Featured Categories</p>
                {makeCat.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column"
                    style={{
                      minWidth: "45%",
                    }}
                  >
                    <a
                      key={item.id}
                      className="font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text cursor-pointer"
                      onClick={() => navigate(`/itemList/${item.id}`)}
                    >
                      {item.name}
                    </a>
                  </div>
                ))}
              </span>

              <span className=" d-sm-block d-xs-block d-md-block d-lg-none">
                <p className="footer-title-main ">Useful Links</p>
                <div className="mt-1 d-flex flex-wrap flex-column">
                  {usefulLinks.map((item) => (
                    <a
                      key={item.label}
                      onClick={() => item.path && navigate(item.path)}
                      className=" font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text cursor-pointer"
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
              </span>
            </div>
            <div className="col-xl-3 col-lg-5 col-xs-12 col-sm-12">
              <span className="pe-4 d-sm-none d-xs-none d-md-none d-lg-block mb-4">
                <p className="footer-title-main ">Useful Links</p>
                <div className="mt-1 d-flex flex-wrap flex-column">
                  {usefulLinks.map((item) => (
                    <a
                      key={item.label}
                      onClick={() => item.path && navigate(item.path)}
                      className=" font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text cursor-pointer"
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
              </span>
              <span>
                <p className="footer-title-main ">Payment mode</p>
                <div className="d-flex gap-4">
                  {Object.keys(paymentSVG).map((item, index) => (
                    <img
                      key={index}
                      src={paymentSVG[item]}
                      alt="payment"
                      className="footer-payment-svg"
                    />
                  ))}
                </div>
              </span>
            </div>
            <div className="col-xl-3 col-lg-5 col-xs-12 col-sm-12">
              <p className="footer-title-main">Address</p>
              <div className="mt-1 mb-4">
                <a className=" font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text">
                  {store.business_name}
                </a>
                <a
                  className=" font-xs-medium font-sm-medium font-md-medium font-lg-large font-xl-large footer-children-text "
                  dangerouslySetInnerHTML={{ __html: address }}
                />
                {/* <a>Udapi - 576101</a> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </FooterArea>
  );
};

export default NewDashboardFooter;
