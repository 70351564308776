import React, { useEffect, useState } from "react";
import { NewAddressCard, SelectCard } from "./index.styled";
import { Col, Row, Radio, message } from "antd";
import DeliveryAddressCard from "components/Cards/DeliveryAddressCard";
import AddAddressModal from "components/Modals/AddAddressModal";
import { useSelector } from "react-redux";
import Empty from "components/empty";
import { getProfileData } from "redux/action/appActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function Address({
  deliveryRadioValue,
  setSelectedAddress,
  selectedAddress,
}) {
  const cart = useSelector((state) => state.app.cart);
  const defaultAddress = cart?.customerAddress?.customer_address?.find(
    (item) => item?.is_default === 0
  );
  const [addressRadioValue, setAddressRadioValue] = useState(
    defaultAddress?.id
  );
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const res = await getProfileData();
    setUserData(res);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        height: 50,
      }}
      spin
    />
  );

  return (
    <SelectCard
      style={{
        height: "90%",
        // flexDirection:"column",
        // margin: "1.5em",
        marginLeft: "1.5em",
        overflowY: "scroll",
      }}
    >
      <h4>
        {deliveryRadioValue === "Home Delivery"
          ? "Select delivery address"
          : "Select store address"}
      </h4>
      {deliveryRadioValue === "Home Delivery" ? (
        <>
          {loading ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <>
              {userData?.customer?.customer_address.length ? (
                <Radio.Group
                  name="delivery-radio-group"
                  defaultValue={addressRadioValue}
                  value={addressRadioValue}
                  className="d-lg-flex align-items-center flex-column"
                  style={{ width: "100%", fontSize: "1em" }}
                >
                  {userData?.customer?.customer_address?.map((item, i) => (
                    <DeliveryAddressCard
                      key={item?.id}
                      checked={item?.id === selectedAddress?.id}
                      image={`/images/${
                        item.address_type === "Home"
                          ? "address-card-logo-3.svg"
                          : "work-logo.svg"
                      }`}
                      heading={`${item.address_type}`}
                      text={item.location_address}
                      reachAddress={item?.reach_address}
                      address={item?.address}
                      value={item.id}
                      radioValue={addressRadioValue}
                      setRadioValue={(value) => {
                        setSelectedAddress(item);
                        setAddressRadioValue(value);
                      }}
                      showDeleteModal={() => {
                        setSelectedAddress(item);
                        setDeleteModal(true);
                      }}
                      showEditModal={() => {
                        setSelectedAddress(item);
                        setEditModal(true);
                      }}
                      setAddNewAddress={setAddNewAddress}
                      item={item}
                      callProfile={() => getData()}
                    />
                  ))}
                </Radio.Group>
              ) : (
                <div
                  style={{
                    height: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Empty
                    title="No address found!"
                    description="You don't have any addresses saved."
                    buttonText="Add an address"
                    icon="address_empty"
                    buttonCallback={() => setAddNewAddress(true)}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
      {/* {deliveryRadioValue === "Home Delivery" ? (
        (cart?.customerAddress?.mobile || cart?.customerAddress?.email) &&
        userData?.customer?.customer_address.length ? (
          <Radio.Group
            name="delivery-radio-group"
            defaultValue={addressRadioValue}
            value={addressRadioValue}
            className="d-lg-flex align-items-center flex-column"
            style={{ width: "100%", fontSize: "1em" }}
          >
            {userData?.customer?.customer_address?.map((item, i) => (
              <DeliveryAddressCard
                key={item?.id}
                checked={item?.id === selectedAddress?.id}
                image={`/images/${
                  item.address_type === "Home"
                    ? "address-card-logo-3.svg"
                    : "work-logo.svg"
                }`}
                heading={`${item.address_type}`}
                text={item.location_address}
                reachAddress={item?.reach_address}
                address={item?.address}
                value={item.id}
                radioValue={addressRadioValue}
                setRadioValue={(value) => {
                  setSelectedAddress(item);
                  setAddressRadioValue(value);
                }}
                showDeleteModal={() => {
                  setSelectedAddress(item);
                  setDeleteModal(true);
                }}
                showEditModal={() => {
                  setSelectedAddress(item);
                  setEditModal(true);
                }}
                setAddNewAddress={setAddNewAddress}
                item={item}
              />
            ))}
          </Radio.Group>
        ) : (
          <div
            style={{
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty
              title="No address found!"
              description="You don't have any addresses saved."
              buttonText="Add an address"
              icon="address_empty"
              buttonCallback={() => setAddNewAddress(true)}
            />
          </div>
        )
      ) : ( */}
      {/* <DeliveryAddressCard
            image="/images/work-logo.svg"
            heading="Work"
            text="22nd Main Rd, Banashankari Stage II, Banashankari Majestic, Bangalore Karnataka India"
            value={2}
            radioValue={addressRadioValue}
            setRadioValue={setAddressRadioValue}
           />  */}
      {/* "" */}
      {/* // <Radio.Group
          name="delivery-radio-group"
          defaultValue={storeRadioValue}
          value={storeRadioValue}
          className="d-lg-flex align-items-center"
          style={{ width: "100%", fontSize: "1em" }}
        > */}
      {/* <DeliveryAddressCard
            image="/images/address-card-logo-1.svg"
            heading="Majestic"
            text="22nd Main Rd, Banashankari Stage II, Banashankari Majestic, Bangalore Karnataka India"
            value={1}
            radioValue={storeRadioValue}
            setRadioValue={setStoreRadioValue}
          /> */}
      {/* //   <DeliveryAddressCard
        //     image="/images/address-card-logo-1.svg"
        //     heading="HSR Layout"
        //     text="32, Hotel Suprabhatha Building, 32, Near Ananda Rao Circle, HSR layout Bangalore, Karnataka India"
        //     value={2}
        //     radioValue={storeRadioValue}
        //     setRadioValue={setStoreRadioValue}
        //   /> */}
      {/* // </Radio.Group> */}
      {/* )} */}
      {deliveryRadioValue === "Home Delivery" &&
      userData?.customer?.customer_address.length > 0 ? (
        <div className="d-md-flex justify-content-center">
          <NewAddressCard
            onClick={() =>
              !cart?.customerAddress?.mobile
                ? message.error("Please enter your number!")
                : setAddNewAddress(true)
            }
          >
            <img src="/images/location-logo.svg" alt="click here" />
            <p>Add new address</p>
          </NewAddressCard>
          {/* <div style={{ width: "50%" }}></div> */}
        </div>
      ) : (
        ""
      )}
      {addNewAddress && (
        <AddAddressModal
          isModalOpen={addNewAddress}
          setIsModalOpen={setAddNewAddress}
          callProfile={() => getData()}
        />
      )}
    </SelectCard>
  );
}
