import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  CartWhite,
  CategoryFilled,
  CategoryOutline,
  HomeFilled,
  HomeOutline,
  ProfileFilled,
  ProfileOutline,
} from "../../assets/svg";
import "./BottomBar.css";
import "./CartBar.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const MenuItems = [
  {
    id: "1x38",
    name: "Home",
    default: HomeOutline,
    active: HomeFilled,
    path: "/",
  },
  {
    id: "1x31",
    name: "Categories",
    default: CategoryOutline,
    active: CategoryFilled,
    path: "/allCategories",
  },
  {
    id: "1x32",
    name: "Profile",
    default: ProfileOutline,
    active: ProfileFilled,
    path: "/profile",
  },
];

const BottomBar = () => {
  const exception = useMemo(() => ["/cart"], []);
  const data = useSelector((state) => state.app.data || {});
  const cart = useMemo(() => data.HomePage.data[0].cart, [data]);

  const [active, setActive] = useState("");

  const navigate = useNavigate();

  const origin = window.location.pathname;

  const isBottomBarHidden = exception.includes(window.location.pathname);

  const isCartVisible = useMemo(
    () =>
      !exception.some((item) => origin.includes(item)) &&
      cart.cData.length > 0 &&
      window.innerWidth < 788,
    [origin, cart.cData.length, exception]
  );

  const onMenuClick = useCallback((route) => {
    if (route.path) {
      navigate(route.path);
      setActive(route.id);
    }
  }, []);

  useEffect(() => {
    MenuItems.forEach((item) => {
      if (origin.includes(item.path)) {
        setActive(item.id);
      }
    }, []);
  }, [origin]);

  if (isBottomBarHidden) return <Fragment />;

  return (
    <Fragment>
      <div
        className="d-lg-none d-xl-none d-md-block"
        style={{
          marginTop: isCartVisible ? "16vh" : "7.5vh",
        }}
      />

      <div className="min-sw-100 min-h-8 bottom-bar-holder d-xl-none bg-zIndex">
        {isCartVisible && (
          <div
            className="cart-items-holder mb-2"
            onClick={() => navigate("/cart")}
          >
            <div
              className="cart-items-container"
              style={{
                border: `1px solid ${data.PrimaryColor}`,
              }}
            >
              {cart.paymentDetails.orderMsg && (
                <div
                  className="bottom-bar-cart-items-common bottom-bar-cart-items-top"
                  style={{
                    backgroundColor: data.SecondaryColor,
                    color: data.PrimaryColor,
                  }}
                >
                  <p className="m-0 text-overflow-none">
                    {cart.paymentDetails.orderMsg}
                  </p>
                </div>
              )}
              <div
                className="bottom-bar-cart-items-common bottom-bar-cart-items-bottom"
                style={{
                  backgroundColor: data.PrimaryColor,
                }}
              >
                <p className="m-0">{`${cart.cData.length} Items | ${
                  data.refType.currency_symbol
                } ${parseFloat(cart.paymentDetails.topay).toFixed(2)}`}</p>
                <p className="d-flex m-0">
                  <CartWhite />
                  View Cart
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="d-md-flex d-sm-flex d-xs-flex justify-content-center hd-8 menu-container">
          <div className="menu-holder">
            {MenuItems?.map((item) => (
              <div
                key={item.id}
                className={`menu-item ${active !== item.id ? "inactive" : ""}`}
                onClick={() => onMenuClick(item)}
              >
                {active === item.id ? (
                  <item.active className="menu-icons" />
                ) : (
                  <item.default className="menu-icons" fill="gray" />
                )}
                <p
                  className="m-0"
                  style={{
                    scale: active !== item.id ? 1 : 1.2,
                  }}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BottomBar;
