import React, { useState } from "react";
import { Radio } from "antd";
import { Address } from "./styles";
import { deleteAddress, getCartItems } from "redux/action/appActions";
import { useDispatch } from "react-redux";
import { UPDATE_USER_ADDRESS } from "redux/Constants";
import ConfirmModal from "components/Modals/ConfirmModal";
import EditAddressModal from "components/Modals/EditAddressModal";

const DeliveryAddressCard = ({
  radioValue,
  setRadioValue,
  value,
  image,
  heading,
  text,
  checked,
  showEditModal,
  showDeleteModal,
  item,
  reachAddress,
  address,
  callProfile
}) => {
  const [deleteModel, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const dispatch = useDispatch();
  const handleConsole = () => {};

  const handleDelete = async () => {
    const res = await deleteAddress(addressId);
    dispatch({ type: UPDATE_USER_ADDRESS, payload: res });
    callProfile();
    dispatch(getCartItems(handleConsole));
    setAddressId("");
    setDeleteModal(false);
  };

  return (
    <div
      className="address-card-cart"
      style={
        radioValue === value || checked
          ? {
              borderColor: "var(--primary)",
              backgroundColor: "var(--gray-secondary)",
            }
          : {}
      }
    >
      <div onClick={(e) => setRadioValue(value)}>
        <div className="d-flex" style={{ marginBottom: "1.15em" }}>
          <img src={image} alt="click here" className="mx-1" />
          <h5 className="m-0">{heading}</h5>
        </div>
        {/* <h6 className="m-0 mb-2">{text}</h6> */}
        <Address className="m-0">{`${reachAddress} , ${address}`}</Address>
        {/* <Address className="m-0">{address}</Address> */}
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            zIndex: 9999,
          }}
        >
          <p
            onClick={() => {
              setEditModal(true);
            }}
          >
            Edit
          </p>
          <p
            onClick={() => {
              setAddressId(value);
              setDeleteModal(true);
            }}
            style={{ marginLeft: 6 }}
          >
            Delete
          </p>
        </div>
        <Radio
          style={{
            position: "relative",
            left: "30%",
          }}
          value={value}
          onChange={() => {
            setRadioValue(value);
          }}
          checked={checked}
        />
      </div>
      <ConfirmModal
        isModalOpen={deleteModel}
        setIsModalOpen={() => {
          setDeleteModal(false);
        }}
        handleOK={handleDelete}
      />

      <EditAddressModal
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        selectedAddress={item}
        callProfile={()=>callProfile()}
      />
    </div>
  );
};

export default DeliveryAddressCard;
