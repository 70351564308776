import styled from "styled-components";

export const Card = styled.div`
  border: 1px solid var(--white-smoke-primary);
  border-radius: 0.786em;
  padding: 1.715em 1.86em;
  margin: 0 0 1.43em;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row-reverse;
    margin-bottom: 1.286em;
    div {
      display: flex;
      align-items: center;
      h5 {
        margin: 0 0 0 0.5em;
      }
      & > p {
        cursor: pointer;
        &:first-child {
          margin-right: 1.56em;
        }
      }
    }
  }
  .card-body {
    p {
      margin-left: 1.67em;
      &:first-child {
        margin-bottom: 0.78em;
      }
    }
  }
  & {
    @media (max-width: 576px) {
      padding: 1.43em 1.15em;
    }
  }
`;
