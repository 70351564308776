import styled from "styled-components";

export const LoginArea = styled.div`
  h4 {
    font-weight: 600;
    color: var(--black);
  }
  h6 {
    font-size: 1.286em;
    font-weight: 300;
    color: var(--gray-legendary);
    &:nth-child(2) {
      margin-bottom: 1.78em;
    }
    &:nth-last-child(1) {
      margin: 1.78em 0 1.335em;
      text-align: center;
    }
  }
  p {
    &:nth-last-child(2) {
      color: var(--gray);
      strong {
        color: var(--black);
      }
    }
  }
  .ant-form {
    .ant-form-item {
      margin: 0;
      .ant-form-item-label {
        label {
          &::before {
            display: none;
          }
        }
      }
      .PhoneInput {
        border: 1px solid var(--gray-secondary);
        border-radius: 1.075em;
        padding: 1.125em 1.43em;
        /* height: 73px; */
        .PhoneInputCountry {
          width: 100%;
          max-width: 70px;
          height: 100%;
          min-height: 40px;
          margin-right: 0.86em;
          select {
            width: 100%;
            height: 100%;
          }
          .PhoneInputCountryIcon {
            width: 100%;
            height: 100%;
            background-color: unset;
            box-shadow: unset;
          }
        }
        .PhoneInputCountrySelectArrow {
          border: 0;
          transform: unset;
          opacity: unset;
          content: "";
          margin-left: 0.86em;
          background: url("/images/input-down-arrow.svg") no-repeat center;
          background-size: cover;
          width: 20px;
          height: 7px;
        }
        input {
          border: 0;
          outline: 0;
          box-shadow: none;
          font-size: 1.286em;
          font-weight: 400;
          background-color: unset;
          color: var(--black);
        }
      }
    }
  }
  & {
    @media (max-width: 576px) {
      h6 {
        font-size: 1em;
      }
    }
  }
`;
export const InputLabel = styled.p`
  margin: 0;
  font-size: 1.15em;
  font-weight: 400;
  color: var(--black);
`;
export const SignInButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    /* width: 172px; */
    height: auto;
    padding: 1em 3em;
    font-size: 1.0715em;
    font-weight: 400;
    border-color: var(--light-gray-secondary) !important;
    color: var(--black) !important;
    svg {
      font-size: 1.335em;
      vertical-align: text-bottom;
      margin-right: 0.4em;
    }
    &:nth-child(2) {
      svg {
        color: var(--facebook-blue);
      }
    }
  }
  & {
    @media (max-width: 640px) {
      button {
        padding: 1em 2em;
      }
    }
    @media (max-width: 576px) {
      display: block;
      button {
        width: 100%;
        margin: 0.5em 0;
      }
    }
  }
`;
