import React from "react";
import { useNavigate } from "react-router-dom";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { OrderModalStyle } from "../styles";
import { OrderPlaced, Button } from "./styles";

const OrderModal = ({ isModalOpen, setIsModalOpen, handleOK }) => {
  const navigate = useNavigate();
  return (
    <>
      <OrderModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        closable={false}
      >
        <OrderPlaced>
          <BsExclamationOctagonFill />
          <h1>Address Delete!</h1>
          <h4>Are you sure you want to delete this Address</h4>
          <div>
            <Button
              style={{ backgroundColor: "#fff", color: "var(--primary)" }}
              onClick={setIsModalOpen}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleOK();
              }}
            >
              Yes
            </Button>
          </div>
        </OrderPlaced>
      </OrderModalStyle>
    </>
  );
};

export default OrderModal;
