import styled from "styled-components";

export const AddNumber = styled.div`
  .PhoneInput {
    border: 1px solid var(--gray-secondary);
    border-radius: 0.9375rem;
    padding: 0.875rem 1.25rem;
    margin: 2.5rem 0;
    .PhoneInputCountry {
      width: 100%;
      max-width: 60px;
      height: 100%;
      min-height: 40px;
      margin-right: 0.75rem;
      select {
        width: 100%;
        height: 100%;
      }
      .PhoneInputCountryIcon {
        width: 100%;
        height: 100%;
        background-color: unset;
        box-shadow: unset;
      }
    }
    .PhoneInputCountrySelectArrow {
      border: 0;
      transform: unset;
      opacity: unset;
      content: "";
      margin-left: 0.25rem;
      background: url("/images/input-down-arrow.svg") no-repeat center;
      background-size: cover;
      width: 13px;
      height: 7px;
    }
    input {
      border: 0;
      outline: 0;
      box-shadow: none;
      font-size: 1.125rem;
      font-weight: 400;
      background-color: unset;
      color: var(--black);
    }
  }
`;
