import styled from "styled-components";
import { Collapse, Dropdown } from "antd";
import { NavDropdown } from "react-bootstrap";

export const DashboardHead = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--gray-secondary);
  height: 100%;
  /* min-height: 126px; */
  &.topHead {
    // background-color: var(--white);
    box-shadow: 0 0 0.3125em var(--black-primary);
  }
  nav {
    padding: 1.5em 0 1.5em;
  }
  & {
    @media (max-width: 576px) {
      nav {
        padding: 1em 0;
      }
      .navbar-toggler {
        margin-left: auto;
      }
    }
  }
`;

export const Logo = styled.img`
  width: 200px;
  // height: 47px;
  max-height: 40px;
  object-fit: contain;
  margin-right: 1.5em;
  cursor: pointer;
  & {
    @media (min-width: 1800px) {
      width: 284px;
      margin-right: 4em !important;
    }
    @media (min-width: 1600px) {
      width: 284px;
      margin-right: 2em;
    }
    @media (max-width: 1200px) {
      width: 175px;
      margin-right: 0;
    }
    @media (max-width: 568px) {
      width: 40vw;
      margin-right: 0;
    }
  }
`;
export const LocationButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 2.75em;
    margin-right: 0.25em;
    fill: var(--primary);
  }
  h6 {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 0;
    &::after {
      content: "";
      border: solid var(--black);
      border-width: 0 2.75px 2.75px 0;
      display: inline-block;
      padding: 0.3125em;
      transform: rotate(45deg);
      margin-left: 1em;
      vertical-align: 0.255em;
    }
  }
  p {
    margin: 0;
  }
  & {
    /* @media (min-width: 1480px) {
      p {
        font-size: 1em;
      }
    }
    @media (min-width: 1380px) {
      font-size: 0.875em;
    }
    @media (max-width: 1200px) {
      img {
        width: 18px;
      }
    } */
    @media (max-width: 576px) {
      svg {
        font-size: 2.125em;
        margin-right: 0.125em;
      }
      h6 {
        &::after {
          padding: 0.1875em;
          margin-left: 0.75em;
        }
      }
    }
  }
`;
export const ItemsSearch = styled.div`
  width: 350px;
  height: 50px;
  background-color: var(--white-smoke);
  border-color: var(--white-smoke) !important;
  border-radius: 0.6875em;
  /* margin-right: 0.75em; */
  margin-right: 2.5em;
  padding: 0 1.25em;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 1.75em;
    color: var(--gray);
    margin-right: 1em;
  }
  p {
    font-size: 1.0625em;
    font-weight: 300;
    color: var(--gray);
    margin: 0;
  }
  & {
    @media (min-width: 1400px) {
      width: 31.25em;
      height: 56px;
    }
    /* @media (min-width: 1400px) {
      width: 400px;
    }
    @media (min-width: 1600px) {
      width: 500px;
      height: 56.6px;
    } */
    @media (max-width: 1280px) {
      width: 250px;
      height: 40px;
      svg {
        font-size: 1.25em;
      }
      p {
        font-size: 0.875em;
      }
    }
    @media (max-width: 1199px) {
      margin: 1em 0 0;
      width: 100%;
    }
  }
`;
export const Dropdowns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & {
    @media (max-width: 1199px) {
      width: 100%;
      margin: 1em 0 0;
    }
  }
`;
export const Drop = styled(NavDropdown)`
  &.odd {
    margin: 0 auto;
  }
  .dropdown-toggle {
    &::after {
      display: none !important;
    }
  }
  .dropdown-menu {
    display: none;
  }
  & {
    @media (min-width: 1200px) {
      &.odd {
        margin: 0 1.75em;
      }
    }
    @media (min-width: 1400px) {
      &.odd {
        margin: 0 3.335em;
      }
    }
    @media (max-width: 1400px) {
      .dropdown-toggle {
        img {
          width: 24px;
        }
      }
    }
    @media (max-width: 1200px) {
      .dropdown-toggle {
        div {
          p {
            font-size: 1em !important;
          }
        }
      }
    }
    @media (max-width: 576px) {
      .dropdown-toggle {
        img {
          width: 22px;
        }
        .ant-scroll-number {
          min-width: 16px;
          height: 16px;
          line-height: 1.15em;
          span {
            font-size: 0.875em !important;
          }
        }
      }
    }
  }
`;
export const ProfileDrop = styled(Dropdown)`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0;
    margin-left: 0.75em;
    font-size: 1.375em;
    color: var(--black);
    text-transform: capitalize;
  }
  & {
    @media (max-width: 576px) {
      img {
        width: 22px;
      }
      /* p {
        font-size: 1.125em;
      } */
    }
  }
`;
export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 0.75em;
  border-bottom: 2px solid var(--white-smoke-secondary);
  p {
    margin: 0;
  }
  & > div {
    &:first-child {
      p {
        &:first-child {
          font-size: 1.125em;
          font-weight: 600;
          color: var(--black);
        }
        &:last-child {
          font-size: 0.75em;
          font-weight: 300;
          color: var(--gray);
        }
      }
    }
    &:last-child {
      p {
        font-size: 0.875em;
        font-weight: 500;
        color: var(--primary);
      }
    }
  }
  & {
    @media (max-width: 576px) {
      padding-bottom: 0.375em;
      & > div {
        &:first-child {
          p {
            &:first-child {
              font-size: 0.875em;
              font-weight: 600;
              color: var(--black);
            }
            &:last-child {
              font-size: 0.625em;
              font-weight: 300;
              color: var(--gray);
            }
          }
        }
        &:last-child {
          p {
            font-size: 0.75em;
            font-weight: 500;
            color: var(--primary);
          }
        }
      }
    }
  }
`;
export const UserCollapse = styled(Collapse)`
  border: 0 !important;
  background-color: unset !important;
  .ant-collapse-item {
    border: 0 !important;
    background-color: unset !important;
    padding: 0 0 0.625em !important;
    margin-bottom: 0.625em !important;
    border-bottom: 2px solid var(--white-smoke-secondary) !important;
    border-radius: 0 !important;
    .ant-collapse-header {
      padding: 0;
      border: 0 !important;
      background-color: unset !important;
      position: relative;
      .ant-collapse-expand-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 0;
      }
      .ant-collapse-header-text {
        span {
          display: block;
          &:first-child {
            font-size: 0.9375em;
            font-weight: 500;
            color: var(--black);
          }
          &:last-child {
            font-size: 0.625em;
            font-weight: 300;
            color: var(--gray);
          }
        }
      }
    }
    .ant-collapse-content {
      border: 0 !important;
      .ant-collapse-content-box {
        padding: 0.5em 0 0;
        p {
          margin: 0;
          font-size: 0.8125em;
          font-weight: 400;
          color: var(--gray);
          &.linkPara {
            margin: 0 0 0.375em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span,
            svg {
              margin: 0;
              font-size: 0.8125em;
              font-weight: 400;
              color: var(--gray);
            }
            svg {
              font-size: 1em;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.ant-collapse-item-active {
      .anticon {
        svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
    &.not-collapsible {
      .ant-collapse-header {
        svg {
          transform: unset !important;
        }
      }
      .ant-collapse-content-box {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  & {
    @media (max-width: 768px) {
      .ant-collapse-item {
        padding: 0 0 0.375em !important;
        margin-bottom: 0.375em !important;
      }
    }
    @media (max-width: 576px) {
      .ant-collapse-item {
        padding: 0 0 0.25em !important;
        margin-bottom: 0.25em !important;
        .ant-collapse-header {
          .ant-collapse-header-text {
            span {
              &:first-child {
                font-size: 0.75em;
              }
              &:last-child {
                font-size: 0.5625em;
              }
            }
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0.25em 0 0;
            p {
              font-size: 0.625em !important;
              span {
                font-size: 0.6875em !important;
              }
              svg {
                font-size: 0.8125em !important;
              }
            }
          }
        }
      }
    }
  }
`;
export const Share = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 0.75em;
  border-bottom: 2px solid var(--white-smoke-secondary);
  p {
    margin: 0;
  }
  & > div {
    &:first-child {
      p {
        &:first-child {
          font-size: 0.9375em;
          font-weight: 500;
          color: var(--black);
        }
        &:last-child {
          font-size: 0.625em;
          font-weight: 300;
          color: var(--gray);
          strong {
            color: var(--primary);
          }
        }
      }
    }
    &:last-child {
      p {
        font-size: 0.625em;
        font-weight: 400;
        color: var(--primary);
      }
    }
  }
  & {
    @media (max-width: 576px) {
      & > div {
        &:first-child {
          p {
            &:first-child {
              font-size: 0.75em;
            }
            &:last-child {
              font-size: 0.5625em;
            }
          }
        }
        &:last-child {
          p {
            font-size: 0.625em;
            font-weight: 400;
            color: var(--primary);
          }
        }
      }
    }
  }
`;
export const Logout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 0.9375em;
    font-weight: 500;
    color: var(--black);
    margin: 0;
  }
  svg {
    font-size: 1.25em;
    color: var(--gray);
  }
  & {
    @media (max-width: 576px) {
      p {
        font-size: 0.75em;
      }
      svg {
        font-size: 1.125em;
      }
    }
  }
`;
export const Login = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0;
    margin-left: 0.75em;
    font-size: 1.375em;
    color: var(--black);
  }
  & {
    @media (max-width: 1400px) {
      p {
        font-size: 1.125em;
      }
      img {
        width: 25px;
      }
    }
    @media (max-width: 576px) {
      img {
        width: 22px;
      }
      p {
        font-size: 1.125em;
      }
    }
  }
`;
