import { Dropdown } from "antd";
import styled from "styled-components";

export const Card = styled.div`
  border: 1px solid var(--grey-legendary);
  height:100%
  border-radius: 0.9375em;
  overflow: hidden;
  margin: 0 0 0.5em;
  text-align: center;
  cursor: pointer;
  p {
    font-size: 0.775em;
  }
  span {
    font-size: 0.75em;
  }
  h6 {
    font-size: 1.125em;
  }
  h5 {
    font-size: 1.1875em;
    font-weight: 500;
  }
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    p {
      margin: 0;
    }
    &:first-child {
      border-bottom-right-radius: 0.9375em;
      background: var(--primary);
      p {
        font-weight: 500;
        color: var(--white);
      }
    }
  //   &:last-child {
  //     border-bottom-left-radius: 0.9375em;
  //     background-color: var(--grey-secondary);
  //     svg {
  //       margin: 0 0.25em 0 0;
  //       display: inline-block;
  //       fill: var(--primary);
  //     }
  //     p {
  //       font-weight: 400;
  //       color: var(--primary);
  //       display: inline-block;
  //     }
  //   }
  // }
`;
export const CardBody = styled.div`
  text-align: center;,
  // position:"absolute"
  


`;
export const Heading = styled.h5`
  // font-size: 1em;
  width: 100%;
  max-width: 163px;
`;
export const CardFooter = styled.div`
  background-color: var(--white-smoke-secondary);
  text-align: center;
  span {
    color: var(--primary);
  }
`;
export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  div {
    h6 {
      font-size: 1em;
      &:first-child {
        color: var(--gray);
        margin: 0.75em 0;
        font-weight: 400;
      }
      &:nth-child(2) {
        font-weight: 600;
        display: inline-block;
      }
    }
    .discount {
      color: var(--gray-primary);
      text-decoration: line-through;
      margin-left: 0.25em;
      cursor: pointer;
      font-size: 0.75em;
    }
  }
`;
export const AddBtn = styled.button`
  // width: 111px;
  // height: 34px;
  padding: 0.4em 1em;
  background: var(--primary);
  border-radius: 0.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: 0;
  box-shadow: none;
  border: 0;
  margin-bottom: 1em;
  span {
    font-size: 0.8em;
    font-weight: 500;
    color: var(--white);
  }
`;
export const QuantityBtn = styled.div`
  width: 5.5em;
  padding: 0.2em 0em;
  // height: 34px;
  border: 1px solid var(--primary);
  border-radius: 0.3125em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-bottom: 1em;
  span {
    font-size: 1em;
    font-weight: 500;
    color: var(--primary);
    &:first-child,
    &:last-child {
      cursor: pointer;
    }
  }
`;
export const OptionDrop = styled(Dropdown)`
  // width: 111px;
  // height: 34px;
  padding: 0.5em;
  background: var(--primary);
  color: var(--white);
  border-radius: 0.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: 0;
  box-shadow: none;
  border: 0;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
  svg {
    font-size: 1.375em;
  }
`;
export const OptionButton = styled.button`
  // width: 111px;
  // height: 34px;
  padding: 0.5em;
  background: var(--primary);
  color: var(--white);
  border-radius: 0.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: 0;
  box-shadow: none;
  border: 0;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
  svg {
    font-size: 1.375em;
  }
`;
export const SubscribeBtn = styled.a`
  text-decoration: none;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75em;
  background-color: var(--grey-primary);
  color: var(--primary);
  border-radius: 0 0 0.9375em 0.9375em;
  img {
    margin-right: 0.25em;
    display: inline-block;
  }
  p,
  svg {
    color: var(--primary);
    font-weight: 500;
    margin: 0;
  }
  p {
    display: inline-block;
  }
  svg {
    font-size: 1.375em;
  }
`;
