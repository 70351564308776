import { Button, Form, Input, InputNumber, Radio, message } from "antd";
import React, { useState, useEffect } from "react";
import { ChangeLocation, ModalStyle, SubmitBtn } from "../styles";
import { AddressArea, CustomAutocomplete } from "./styles";
import { editAddress, getCartItems } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import MapBox from "components/mapBox";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import axios from "axios";

const EditAddressModal = ({ isModalOpen, setIsModalOpen, selectedAddress, callProfile }) => {
  const [center, setCenter] = useState({ lat: 44, lng: -80 });
  const [fullAddress, setFullAddress] = useState();
  const [showAddress, setShowAddress] = useState(true);
  const user = useSelector((state) => state.app.data);
  const cart = useSelector((state) => state.app.cart);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleConsole = () => {};
  const handleSubmit = async (value) => {
    if (fullAddress) {
      const data = {
        address_id: selectedAddress.id,
        name: cart?.customerAddress?.name,
        address_type: value?.address_type,
        address: value?.landmark,
        pincode: fullAddress.postalCode,
        mobile: value?.mobile,
        city: fullAddress.city,
        country: fullAddress.country,
        latitude: fullAddress.latLng.lat.toString(),
        longitude: fullAddress.latLng.lng.toString(),
        is_default: 1,
        reach_address: value?.address,
        location_address: fullAddress.formatted_address,
      };
      const res = await editAddress(data);
      if (res) {
        callProfile();
        setIsModalOpen(false);
      }
    } else {
      message.error("please select address!");
    }
  };
  const defaultFunction = async () => {
    setFullAddress({
      postalCode: selectedAddress.pincode,
      latLng: {
        lat: Number(selectedAddress.latitude),
        lng: Number(selectedAddress.longitude),
      },
      city: selectedAddress.city,
      country: selectedAddress.country,
      formatted_address: selectedAddress.location_address,
      locality: selectedAddress.location_address,
    });
    setShowAddress(false);
    form.setFieldsValue({
      mobile: selectedAddress.mobile,
      landmark: selectedAddress.address,
      address: selectedAddress.reach_address,
      address_type: selectedAddress.address_type,
    });
    setCenter({
      lat: Number(selectedAddress.latitude),
      lng: Number(selectedAddress.longitude),
    });
  };
  useEffect(() => {
    if (selectedAddress) {
      defaultFunction();
    }
  }, [selectedAddress]);

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address?.formatted_address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(address.place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const city =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      )?.long_name || "";
    const country =
      place.address_components.find((c) => c.types.includes("country"))
        ?.long_name || "";
    const locality =
      place.address_components.find((c) => c.types.includes("locality"))
        ?.long_name || "";

    setCenter(latLng);
    setFullAddress({
      postalCode: postalCode,
      latLng: latLng,
      city: city,
      country: country,
      formatted_address: address.formatted_address,
      locality: locality,
    });
    setShowAddress(false);
  };
  return (
    <>
      <ModalStyle
        title={false}
        footer={false}
        centered
        width={670}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <AddressArea>
          <h5 style={{ margin: "0 0 0.875em" }}>Add new address</h5>
          {/* <img
            style={{
              width: "100%",
              height: "300px",
            }}
            src="/images/location-map-image.png"
            alt="click here"
          /> */}
          <MapBox center={center} />
          {fullAddress && !showAddress ? (
            <ChangeLocation>
              <div>
                <img src="/images/address-card-logo-1.svg" alt="click here" />
                <div>
                  <h5>{fullAddress?.locality}</h5>
                  <p>{fullAddress?.formatted_address}</p>
                </div>
              </div>
              <span onClick={() => setShowAddress(true)}>change</span>
            </ChangeLocation>
          ) : (
            ""
          )}

          <Form
            form={form}
            name="location-form"
            layout="vertical"
            onFinish={handleSubmit}
          >
            {showAddress ? (
              <Form.Item
                label={false}
                name="fulladdress"
                rules={[
                  {
                    required: fullAddress ? false : true,
                    message: "Please input your address!",
                  },
                ]}
                style={{ marginTop: "1.43em" }}
              >
                <CustomAutocomplete
                  apiKey={process.env.REACT_APP_MAPBOX_TOKEN}
                  onPlaceSelected={handleSelect}
                  placeholder="Search place..."
                  // options={{
                  //   types: ["(regions)"],
                  //   componentRestrictions: { country: "ru" },
                  // }}
                  // defaultValue="Amsterdam"
                />
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item
              label={false}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input your House/Flat/Block No!",
                },
              ]}
            >
              <Input placeholder="House / Flat / Block No." />
            </Form.Item>
            <Form.Item
              label={false}
              name="landmark"
              rules={[
                {
                  required: true,
                  message: "Please input your landmark!",
                },
              ]}
            >
              <Input placeholder="Landmark" />
            </Form.Item>
            <Form.Item
              label={false}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please input your mobile number!",
                },
              ]}
            >
              <InputNumber placeholder="Mobile Number" />
            </Form.Item>
            <div className="save-address">
              <p>Save as</p>
              <Form.Item
                label={false}
                name="address_type"
                rules={[
                  {
                    required: true,
                    message: "Please select type!",
                  },
                ]}
              >
                <Radio.Group
                  defaultValue={"Home"}
                  optionType="button"
                  buttonStyle="solid"
                  className="d-flex justify-content-md-start justify-content-between flex-wrap"
                >
                  <Radio.Button value={"Home"}>
                    <img
                      src="/images/address-card-logo-3.svg"
                      alt="click here"
                    />
                    <p>Home</p>
                  </Radio.Button>
                  <Radio.Button value={"Work"}>
                    <img
                      src="/images/address-card-logo-2.svg"
                      alt="click here"
                    />
                    <p>Work</p>
                  </Radio.Button>
                  <Radio.Button value={"Other"}>
                    <img
                      src="/images/address-card-logo-1.svg"
                      alt="click here"
                    />
                    <p>Other</p>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <SubmitBtn>
              <Button htmlType="submit">Save Address</Button>
            </SubmitBtn>
          </Form>
        </AddressArea>
      </ModalStyle>
    </>
  );
};

export default EditAddressModal;
