import styled from "styled-components";

export const DealArea = styled.div`
  position: relative;
`;
export const DealInfo = styled.div`
  h2,
  h4 {
    color: var(--black);
  }
  h2 {
    font-size: 2.125em;
    font-weight: 600;
  }
  h4 {
    font-size: 1.5em;
    font-weight: 300;
  }
  & {
    @media (max-width: 576px) {
      h2 {
        font-size: 1.6em;
      }
      h4 {
        font-size: 1.25em;
      }
    }
  }
  /* & {
    @media (min-width: 1400px) {
      h2 {
        font-size: 2.125em;
      }
    }
    @media (max-width: 576px) {
      h2 {
        font-size: 1.25em;
      }
      h4 {
        font-size: 1.125em;
      }
    }
  } */
`;
