import React from "react";
import { Card } from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory, setSelectedCategory } from "redux/action/appActions";
import DefaultImage from "../../../assets/images/default_image.svg";
import { fileURL } from "helpers/baseURL";

const ItemCard = ({ image, heading, id, selected, setSelected }) => {
  const user = useSelector((state) => state.app.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Card className="" onClick={() => {
        setSelected(id);
        dispatch(
          getSubCategory({
            cursor: "",
            perPage: 20,
            nested: false,
            subcategory: true,
            id: id,
          })
        );
        dispatch(
          setSelectedCategory({ title: heading, id: id, subcategory: true })
        );
      }}
      >
        <img
          src={image ? `${fileURL}/${image}` : DefaultImage}
          alt="click here"
          onError={(e) => {
            e.target.src = DefaultImage;
            e.onerror = null;
          }}
        />
        <h5 className={id === selected ? "active" : ""}>{heading}</h5>
      </Card>
    </>
  );
};

export default ItemCard;
