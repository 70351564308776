import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { ModalStyle } from "../styles";
import { InfoArea } from "./styles";
import { register } from "redux/action/appActions";
import { useDispatch } from "react-redux";

const InfoModal = ({ isModalOpen, callback, userPhoneNumber }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (value) => {
    dispatch(register({ ...value, ...userPhoneNumber }, callback));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <ModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        closable={false}
      >
        <InfoArea>
          <h4>Add your info</h4>
          <Form
            name="info-form"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={false}
              name="name"
              rules={[
                {
                  required: true,
                  pattern: /^[a-zA-Z ]{3,}$/,
                  message: "Please enter valid name!",
                },
              ]}
            >
              <Input placeholder="User Name" name="name" />
            </Form.Item>
            <Form.Item
              label={false}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                I have an referral code
              </Checkbox>
            </Form.Item>
            {checked === true ? (
              <Form.Item
                label={false}
                name="referral"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="Referral Code (optional)" />
              </Form.Item>
            ) : (
              ""
            )}
            {/* <SubmitBtn> */}
            <Button
              type="primary"
              htmlType="submit"
              // onClick={() => {
              //   setIsModalOpen(false);
              //   setUserLogin(true);
              // }}
            >
              Continue
            </Button>
            {/* </SubmitBtn> */}
          </Form>
        </InfoArea>
      </ModalStyle>
    </>
  );
};

export default InfoModal;
