import styled from "styled-components";

export const EditArea = styled.div`
  h4 {
    font-size: 1.715em;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 1.46em;
  }
  .ant-form-item {
    margin-bottom: 2.5em;
    .ant-input-number {
      width: 100%;
      outline: 0;
      box-shadow: none;
      border: 0;
      input {
        height: auto;
      }
    }
    input {
      width: 100%;
      /* height: 100%;
      min-height: 73px; */
      padding: 1.25em 1.5em;
      background-color: var(--white-smoke-secondary);
      border: 0px;
      outline: 0px;
      box-shadow: none;
      border-radius: 0.635em;
      font-size: 1.286em;
      font-weight: 400;
      color: var(--black);
      &::placeholder {
        color: var(--gray-primary);
      }
    }
  }
`;
