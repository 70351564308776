import React from "react";
import { Empty } from "antd";
import "./styles.css";

const NoDataFound = () => {
  return (
    <div className="no-data-found-container">
      <Empty
        title="No item found!"
        description="Sorry, No item found!"
        buttonText="Back to Home"
        icon="Not_Found"
        // buttonCallback={() => navigate("/")}
      />
    </div>
  );
};

export default NoDataFound;
