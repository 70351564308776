import styled from "styled-components";

export const FooterArea = styled.div`
  background-color: var(--white-smoke);
  padding: 2em 0;
  .container {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    font-size: 1.625em;
    font-weight: 400;
    margin-bottom: 0.7em;
    color: var(--black);
    /* & {
      @media (min-width: 1400px) {
        font-size: 1.625em;
      }
    } */
  }
  a {
    /* font-size: 1.25em; */
    font-size: 1.3125em;
    font-weight: 300;
    text-decoration: none;
    color: var(--gray);
    margin-bottom: 0.3em;
    display: block;
    /* & {
      @media (min-width: 1400px) {
        font-size: 1.3125em;
      }
      @media (max-width: 576px) {
        font-size: 0.875em;
      }
    } */
  }
  & {
    @media (max-width: 1199px) {
      .container {
        display: block;
      }
    }
  }
`;
export const FooterInfo = styled.div`
  display: flex;
  align-items: baseline;
  margin: 1em 0;
  .about-us {
    margin: 0 3em 0 5em;
    /* margin: 0 2em 0 2em; */
  }
  & {
    @media (max-width: 1199px) {
      margin-bottom: 1.5em;
    }
    @media (max-width: 576px) {
      .about-us {
        margin: 0 1.25em 0;
      }
    }
    /* @media (min-width: 1200px) {
      .about-us {
        margin: 0 3em 0 5em;
      }
    }
    @media (max-width: 992px) {
      .about-us {
        margin: 0 3em 0 5em;
      }
    }
    @media (max-width: 576px) {
      .about-us {
        margin: 0 1.25em 0;
      }
    } */
  }
`;
export const PaymentArea = styled.div`
  position: relative;
  height: 100%;
  margin:1em 0px;
  &::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: var(--grey-legendary);
    position: absolute;
    top: 0;
    left: -5%;
  }
  img {
    display: inline-block;
    margin-right: 0.6em;
    height: 30px;
  }
  & {
    @media (min-width: 1200px) {
      &::before {
        left: -40%;
      }
    }
    @media (min-width: 768px) {
      img {
        margin-right: 1.15em;
      }
    }
  }
`;
export const BottomFooter = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-around;
  p {
    font-size: 1.625em;
    font-weight: 400;
    margin-bottom: 0.7em;
    color: var(--black);
  }
`;
