import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubtotalCard } from "./index.styled";

export default function SubTotal() {
  const cart = useSelector((state) => state.app.cart);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  return (
    <SubtotalCard>
      <div className="d-flex align-items-baseline">
        <h6>
          To Pay : {currency_symbol}
          {cart?.cart?.paymentDetails?.topay?.toFixed(2)}
        </h6>
        <span style={{ marginLeft: "0.33em" }}>
          {currency_symbol}
          {cart?.cart?.paymentDetails?.totalAmountInCart?.toFixed(2)}
        </span>
      </div>
      <div>
        <p>
          {currency_symbol}
          {cart?.cart?.paymentDetails?.totalSavings?.toFixed(2)} Saved on this
          order
        </p>
      </div>
    </SubtotalCard>
  );
}
