import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AddNumberBtn, PaymentArea } from "./index.styled";
import { Col, Row, Radio } from "antd";
import WalletCard from "components/Cards/WalletCard";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getPaymentOption } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_AUTH_LOADING } from "redux/Constants";

const PaymentTypeSelector = ({
  selectedAddress,
  selectedDeliveryDetail,
  paymentMethods,
  setPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  show,
  togglePayment,
}) => {
  const user = useSelector((state) => state.app.data);
  const cart = useSelector((state) => state.app.cart);
  const dispatch = useDispatch();

  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const getData = async () => {
    const data = {
      address_id: selectedAddress.id,
      delivery_type: selectedDeliveryDetail.type,
      promocode_id: "",
      delivery_id: selectedDeliveryDetail.id,
      delivery_charge: selectedDeliveryDetail.delivery_charges,
      shipments_length: selectedDeliveryDetail.shipments_length,
      payment_details: JSON.stringify(cart.cart.paymentDetails),
    };
    const res = await getPaymentOption(data, user.guestToken);
    setPaymentMethods(res);
  };

  const customerAddress = useSelector(
    (state) => state.app.cart.customerAddress
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      open={show}
      onCancel={togglePayment}
      footer={[]}
      className="d-md-none d-lg-block payment-type-selector-popup"
      centered
    >
      <div className="delivery-type">
        <h5 className="box-heading">Select Payment option</h5>
        <div className="more-options">
          <Radio.Group
            name="delivery-radio-group"
            value={selectedPaymentMethod}
            className="custom-radio"
            style={{ width: "100%", fontSize: "1em" }}
          >
            {paymentMethods?.paymentMethod?.map((item, i) => (
              <div
                key={i}
                className="option d-flex justify-content-between my-2"
                onClick={() => {
                  dispatch({ type: TOGGLE_AUTH_LOADING });
                  setSelectedPaymentMethod(item?.value);
                }}
              >
                <h5 className="box-heading font-sm-medium font-md-large">
                  {item?.title}
                </h5>
                <Radio value={item.value} />
              </div>
            ))}
          </Radio.Group>
        </div>
        <AddNumberBtn onClick={togglePayment} className="active mt-4">
          Continue
        </AddNumberBtn>
      </div>
    </Modal>
  );
};

export default PaymentTypeSelector;
