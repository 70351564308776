import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";

export const CustomAutocomplete = styled(Autocomplete)`
  width: 100%;
  /* height: 68px; */
  // margin-bottom: 1.43em;
  height: auto;
  border: 1px solid var(--gray-secondary) !important;
  border-radius: 0.715em;
  padding: 1.75em 1.86em;
  box-shadow: none;
  outline: none;
  &::placeholder {
    font-weight: 300;
    color: var(--gray);
  }
`;
export const AddressArea = styled.div`
  .ant-form {
    .ant-form-item {
      margin-bottom: 1.43em;
      input {
        width: 100%;
        /* height: 68px; */
        height: auto;
        border: 1px solid var(--gray-secondary) !important;
        border-radius: 0.715em;
        padding: 1.75em 1.86em;
        box-shadow: none;
        &::placeholder {
          font-weight: 300;
          color: var(--gray);
        }
      }
      .ant-input-number {
        width: 100%;
        /* height: 68px; */
        box-shadow: none !important;
        border: 0 !important;
        .ant-input-number-handler-wrap {
          display: none;
        }
        .ant-input-number-input-wrap {
          /* height: 100%; */
          input {
            /* width: 100%; */
            /* height: 100%; */
            border-radius: 0.715em;
            padding: 1.75em 1.86em;
            border: 1px solid var(--gray-secondary) !important;
            font-weight: 400;
            color: var(--black);
          }
        }
      }
    }
  }
  .save-address {
    & > p:first-child {
      font-size: 1.0715em;
      font-weight: 300;
      color: var(--gray);
      margin: 0;
    }
  }
  .ant-radio-button-wrapper {
    /* width: 97px;
    height: 36px; */
    height: auto;
    padding: 0.15em 1em;
    border: 1px solid var(--grey-legendary) !important;
    border-radius: 0.36em;
    margin: 0.86em 0.715em 0px 0px;
    &::before {
      display: none;
    }
    &:hover {
      border-color: var(--primary) !important;
      background: unset !important;
    }
    & > span:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 0.4em;
        filter: invert(53%) sepia(1%) saturate(0%) hue-rotate(156deg)
          brightness(105%) contrast(90%);
      }
      p {
        font-size: 1.0715em;
        font-weight: 400;
        color: var(--gray);
        margin: 0;
      }
    }
    &.ant-radio-button-wrapper-checked {
      border-color: var(--primary) !important;
      background-color: var(--grey-secondary);
      & > span:last-child {
        img {
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg)
            brightness(97%) contrast(103%);
        }
        p {
          color: var(--black);
        }
      }
    }
  }
`;
