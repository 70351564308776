import React, { useState } from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import { ModalStyle, SubmitBtn } from "../styles";
import { EditArea } from "./styles";
import { editUser, sendUpdateOtp } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { TOGGLE_EDIT_USER, UPDATE_USER } from "redux/Constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const EditModal = ({
  isModalOpen,
  setIsModalOpen,
  setUserUpdateData,
  setOtpModal,
}) => {
  const user = useSelector((state) => state.app.data.SplashScreen.customer);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (value) => {
    await new Promise((res) => {
      setSubmitting(true);
      res(true);
    });

    if (String(value.mobile) !== user.mobile) {
      const data = await sendUpdateOtp({
        mobile: value.mobile,
        country_code: user.country_code,
        key: "",
      });

      if (data.status) {
        setOtpModal(true);
        setUserUpdateData({ ...value, otp: data.otp });
        dispatch({ type: TOGGLE_EDIT_USER });
        setSubmitting(false);
      } else {
        message.error("Failed to send OTP");
      }

      return;
    }

    const res = await editUser(value);
    if (res?.status) {
      message.success("Profile Updated Successfully");
      dispatch({
        type: UPDATE_USER,
        payload: res?.data,
      });

      setIsModalOpen(false);
    } else {
      message.error("Failed to update profile");
    }
    setSubmitting(false);
  };

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={608}
      open={isModalOpen}
      onCancel={() => setIsModalOpen()}
    >
      <EditArea>
        <h4>Edit your profile</h4>
        <Form
          name="info-form"
          initialValues={{
            name: user?.name,
            mobile: user?.mobile,
            email: user?.email,
          }}
          layout="vertical"
          onFinish={onSubmit}
        >
          {(values, formInsatance) => (
            <Fragment>
              <Form.Item
                label={false}
                name="name"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z ]{3,}$/,
                    message: "Please enter valid name!",
                  },
                ]}
              >
                <Input placeholder="User Name" name="name" />
              </Form.Item>
              <Form.Item
                label={false}
                name="mobile"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit mobile number!",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Mobile Number" name="mobile" />
              </Form.Item>
              <Form.Item
                label={false}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" name="email" />
              </Form.Item>
              <SubmitBtn>
                <Button type="primary" htmlType="submit">
                  {submitting ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{
                            fontSize: 30,
                            color: "var(--primary)",
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            width:30,
                            height:30,
                          }}
                        />
                      }
                    />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </SubmitBtn>
            </Fragment>
          )}
        </Form>
      </EditArea>
    </ModalStyle>
  );
};

export default EditModal;
