import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import DashboardHeader from "components/DashboardHeader";
import ProductLinks from "components/ProductLinks";
import ItemCard from "components/Cards/ItemCard";
import { ItemListArea } from "screens/itemList/index.styled";
import DealCard from "components/Cards/DealCard";
import DashboardFooter from "components/DashboardFooter";
import Footer from "components/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMoreProducts, featuredItems } from "redux/action/appActions";
import { ShimmerThumbnail, ShimmerBadge } from "shimmer-react";
import { Empty } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Fragment } from "react";
import DefaultImage from "../../assets/images/default_image.svg";
import { useCallback } from "react";
import { fileURL } from "helpers/baseURL";

export default function Brands() {
  const isFeatured = window.location.href.includes("featured-brands");
  const { featuredItem = null } = useParams();
  const [featuredCategory, setFeaturedCat] = useState([]);

  const {
    categoryLoading,
    subCategories,
    categoryLast_id = "",
  } = useSelector((state) => state.app);
  const products = useSelector((state) => state.app.products);
  const categories = useSelector((state) => state.app.categories);
  const selectedCategory = useSelector((state) => state.app.selectedCategory);
  const message = useSelector((state) => state.app.message);
  const moreData = useSelector((state) => state.app.moreData);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const CategoriesWithProducts = useSelector(
    (state) => state.app.nestedCategoriesWithProducts
  );

  const user = useSelector((state) => state.app.data);
  const [selected, setSelected] = useState();
  const location = useLocation();
  const dispatch = useDispatch();

  const getFeatured = useCallback(async () => {
    if (isFeatured) {
      let params = {};
      try {
        params = JSON.parse(atob(featuredItem));
        const res = await featuredItems(params, user.guestToken);
        setFeaturedCat(res.data || []);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    getFeatured();
  }, [isFeatured]);

  const fetchMoreData = () => {
    dispatch(
      getMoreProducts({
        cursor: products.pop().id,
        perPage: 20,
        nested: false,
        subcategory: selectedCategory?.subcategory || false,
        id: location.pathname.split("/")[2],
      })
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:24,
        height:24,
      }}
      spin
    />
  );
  return (
    <Fragment>
      <ItemListArea>
        <DashboardHeader />
        <ProductLinks links={categories} />
        <Container>
          <Row gutter={30}>
            {(categoryLoading ||
              !!subCategories?.length ||
              !!featuredCategory?.length) && (
              <Col lg={6} xs={24} className="fixedScroll">
                {categoryLoading ? (
                  Array(10)
                    .fill(1)
                    .map((item, i) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.53em 1em",
                          borderBottom:
                            "1px solid var(--white-smoke-secondary)",
                        }}
                      >
                        <ShimmerThumbnail
                          height={100}
                          width={100}
                          className="m-0"
                          rounded
                        />
                        <ShimmerBadge width={200} />
                      </div>
                    ))
                ) : (
                    isFeatured
                      ? featuredCategory?.length
                      : subCategories?.length
                  ) ? (
                  (isFeatured ? featuredCategory : subCategories)?.map(
                    (item, i) => {
                      const { icon_image, name, id } = item;
                      return (
                        <ItemCard
                          key={i}
                          image={icon_image}
                          heading={name}
                          id={id}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      );
                    }
                  )
                ) : (
                  <Fragment />
                )}
              </Col>
            )}
            <Col
              lg={!subCategories.length && !featuredCategory.length ? 24 : 18}
              xs={24}
              className="fixedScroll justify-content-center"
            >
              {products.length ? (
                <>
                  <h4 className="my-3">{selectedCategory.title}</h4>

                  <InfiniteScroll
                    dataLength={products.length}
                    next={fetchMoreData}
                    hasMore={!!categoryLast_id} // Replace with a condition based on your data source
                    loader={
                      <div style={{ margin: "1em 0", textAlign: "center" }}>
                        <Spin indicator={antIcon} />
                      </div>
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    height={800}
                  >
                    <Row gutter={[16, 24]}>
                      {products?.map((item, i) => {
                        const {
                          product_variations,
                          name,
                          is_subscription,
                          location_id,
                          _id,
                          price_variation,
                        } = item;

                        const offText = Math.round(
                          product_variations[0]?.product_batches[0]?.discount_percentage
                        );

                        return (
                          <>
                            <Col key={i}>
                              <div style={{ marginBottom: "0.575em" }} key={i}>
                                <DealCard
                                  id={_id}
                                  offText={offText === 0 ? 0 : `${offText}% OFF`}
                                  brandLogo={"/images/express-logo.svg"}
                                  brandText={"Express"}
                                  brandImage={
                                    product_variations?.[0]?.product_images?.[0]
                                      ?.path
                                      ? `${fileURL}${product_variations[0]?.product_images[0]?.path}`
                                      : DefaultImage
                                  }
                                  footerText={`${currency_symbol}${product_variations[0]?.product_batches[0]?.membership_price?.toFixed(
                                    "0" || 0
                                  )} Membership Price`}
                                  heading={name}
                                  subheading={
                                    product_variations[0]?.name +
                                      product_variations[0]?.unit_of_measure ||
                                    "not found"
                                  }
                                  discount={
                                    product_variations[0]?.product_batches[0]
                                      ?.sale_price || 0
                                  }
                                  price={product_variations[0]?.product_batches[0]?.mrp.toFixed(
                                    2
                                  )}
                                  option={price_variation}
                                  product_variations={product_variations}
                                  quantity={0}
                                  subscribe={is_subscription}
                                  data={{
                                    product_id: _id,
                                    variation_id: product_variations[0]?.id,
                                    batch_id:
                                      product_variations[0]?.product_batches[0]
                                        ?.id,
                                    location_id: location_id,
                                    type: product_variations?.[0]?.type,
                                    membership_plan_id: "",
                                  }}
                                />
                              </div>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </InfiniteScroll>
                </>
              ) : message === "not found" ? (
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Empty />
                </div>
              ) : (
                <Row gutter={[16, 24]}>
                  {Array(10)
                    .fill(1)
                    .map((item, i) => (
                      <Col key={i}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          <ShimmerThumbnail
                            height={100}
                            width={200}
                            className="m-0"
                            rounded
                          />
                          <ShimmerBadge width={150} />
                          <ShimmerBadge width={100} />
                          <ShimmerBadge width={50} />
                        </div>
                      </Col>
                    ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </ItemListArea>
      <DashboardFooter />
      <Footer />
    </Fragment>
  );
}
