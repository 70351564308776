import styled from "styled-components";

export const HomeArea = styled.div`
  z-index: 0;
  .main-heading {
    h2 {
      font-size: 2.125em;
      font-weight: 600;
      color: var(--black);
    }
    & + h4 {
      font-size: 1.5em;
    }
  }
  .bakery-cards {
    // display: flex;
    margin: 2.1em 0 0;
    position: relative;
    padding: 3em 0 0 0;
    // overflow-x: scroll;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  .brand-cards {
    /* width: max-content; */
    // overflow-x: scroll;
    // display: flex;
    margin: 4.215em 0;
    padding: 4em 0;
    position: relative;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    img.banner {
      // height: 13.5vw;
      // width: 13.5vw !important;
      // margin-right: 1.75em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  & {
    @media (max-width: 576px) {
      .main-heading {
        h2 {
          font-size: 1.6em;
        }
        & + h4 {
          font-size: 1.25em;
        }
      }
    }
  }
  /* & {
    @media (min-width: 1400px) {
      .main-heading {
        h2{

          font-size: 2.125em;
        }
      }
    }
    @media (max-width: 576px) {
      .main-heading {
        h2{

          font-size: 1.25em;
        }
        & + h4 {
          font-size: 1.125em;
        }
      }
    }
  } */
`;
export const FocusArea = styled.div`
  padding: 2em;
  background-color: var(--grey-secondary);
  h4 {
    font-size: 1.625em;
    margin-bottom: 2em;
    /* & {
      @media (min-width: 1400px) {
        font-size: 1.625em;
      }
    } */
  }
`;
