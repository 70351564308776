import React from "react";
import { Card } from "./styles";
import { useDispatch } from "react-redux";
import { getSubCategory } from "redux/action/appActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fileURL } from "helpers/baseURL";

const PopularCard = ({ image, heading, id }) => {
  const user = useSelector((state) => state.app.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({ type: "GET_SUB_CATEGORIES", payload: [] });
    dispatch({
      type: "GET_SUB_CATEGORIES_PRODUCTS",
      payload: {
        nestedSubCategoriesWithProducts: [],
      },
    });
    dispatch({ type: "GET_PRODUCTS", payload: [] });
    dispatch({ type: "SET_MESSAGE", payload: "" });
    dispatch(
      getSubCategory({
        cursor: "",
        perPage: 20,
        nested: false,
        subcategory: false,
        id: id,
      })
    );
    navigate(`/itemList/${id}`);
  };
  return (
    <>
      <Card onClick={handleClick}>
        <img src={`${fileURL}${image}`} alt="not found" />
        <p>{heading}</p>
      </Card>
    </>
  );
};

export default PopularCard;
