import styled from "styled-components";

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.75em;
  img {
    width: 137px;
    height: 137px;
    margin-right: 2em;
  }
  h4 {
    font-size: 1.67em;
    margin-bottom: 0.5em;
  }
  h4,
  h5 {
    font-weight: 400;
    color: var(--black);
  }
  h5 {
    font-size: 1.4em;
  }
  h5,
  p {
    display: inline-block;
    margin: 0;
  }
  p {
    font-weight: 300;
    color: var(--gray-primary);
    text-decoration: line-through;
    margin-left: 0.75em;
  }
  & {
    @media (min-width: 1200px) {
      h5 {
        font-size: 1.5975em;
      }
    }
    @media (max-width: 576px) {
      img {
        width: 100px;
        height: 100px;
        margin-right: 1.25em;
      }
      h4 {
        font-size: 1.3em;
      }
      h5 {
        font-size: 1.125em;
      }
      p {
        font-size: 0.86em;
        /* margin-left: 0.375rem; */
      }
    }
  }
`;
export const RattingCard = styled.div`
  border: 1px solid var(--white-smoke-primary);
  border-top-left-radius: 0.36em;
  border-bottom-left-radius: 0.36em;
  padding: 2.5em;
  & {
    @media (min-width: 1200px) {
      border-right: 0;
    }
    @media (max-width: 1200px) {
      border-bottom: 0;
      border-top-left-radius: 0.36em;
      border-top-right-radius: 0.36em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media (max-width: 576px) {
      padding: 2.36em 1.75em;
    }
  }
`;
export const Ratting = styled.div`
  margin-bottom: 2.15em;
  div {
    display: flex;
    align-items: center;
    h2 {
      font-size: 2.286em;
      font-weight: 600;
      color: var(--black);
      margin-right: 0.565em;
      margin-bottom: 0.3125em;
    }
    .ant-rate {
      height: 100%;
      margin-bottom: calc(0.375em + 0.3125em);
      li {
        color: var(--white-smoke-legendary);
        margin-right: 0.4em !important;
        svg {
          /* width: 22px;
          height: 22px; */
          font-size: 1.375em;
        }
        &.ant-rate-star-full {
          color: var(--yellow) !important;
        }
      }
    }
  }
  p {
    font-size: 1.36em;
    font-weight: 300;
    color: var(--gray);
    margin: 0;
  }
  & {
    /* @media (min-width: 1420px) {
      div {
        .ant-rate {
          li {
            margin-right: 0.6em !important;
            svg {
              font-size: 1.375em;
            }
          }
        }
      }
    } */
    /* @media (min-width: 1200px) {
      p {
        font-size: 1.1875rem;
      }
    } */
  }
`;
export const ProgressArea = styled.div`
  .progress-line {
    display: flex;
    align-items: center;
    margin-bottom: 0.86em;
    h5 {
      margin-bottom: 0;
    }
    svg {
      /* width: 15px;
      height: 15px; */
      font-size: 1.215em;
      color: var(--gray-ordinary);
      margin: 0 0.75em 0 0.5em;
    }
    .ant-progress {
      margin: 0;
      margin-inline-end: 0;
      .ant-progress-outer {
        padding-inline-end: calc(2.15em + 1.5em);
        height: 9px !important;
        .ant-progress-inner {
          height: 9px;
        }
      }
      .ant-progress-text {
        font-weight: 300;
        color: var(--gray);
      }
    }
  }
`;
export const ReviewCard = styled.div`
  border: 1px solid var(--white-smoke-primary);
  border-top-right-radius: 0.36em;
  border-bottom-right-radius: 0.36em;
  border-bottom-left-radius: 0.36em;
  padding: 2.5em 0;
  h4 {
    font-size: 1.75em;
    color: var(--black);
    margin-left: 1.75em;
  }
  .product-review {
    border-bottom: 1px solid var(--white-smoke-primary);
    padding: 2.15em 3em 1.435em;
    .review-box {
      /* width: 58px;
      height: 29px; */
      width: max-content;
      padding: 0.36em 1.15em;
      border-radius: 0.36em;
      /* display: flex;
      justify-content: center;
      align-items: center; */
      h6 {
        font-size: 1.286em;
        font-weight: 400;
        margin: 0;
        svg {
          /* width: 14px;
          height: 14px; */
          font-size: 0.681em;
        }
      }
      &.success {
        background-color: var(--grey-primary);
        h6 {
          color: var(--primary);
        }
      }
      &.danger {
        background-color: var(--light-red);
        h6 {
          color: var(--red);
        }
      }
    }
    p {
      font-size: 1.286em;
      font-weight: 300;
      color: var(--gray);
      margin: 0;
    }
    h5 {
      font-weight: 400;
      color: var(--black);
      margin: 0.9em 0 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  & {
    @media (max-width: 1200px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.36em;
      border-bottom-right-radius: 0.36em;
    }
    @media (max-width: 768px) {
      h4 {
        margin-left: 1em;
      }
      .product-review {
        padding: 2em 1.75em 1.35em;
        p {
          font-size: 1em;
          margin-top: 1.15em;
          text-align: end;
        }
        h5 {
          font-size: 1.0715em;
        }
      }
    }
  }
`;
