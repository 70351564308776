import React from "react";
import { DeliveryModalStyle } from "../styles";

export default function IFrameModal({
  isModalOpen,
  setIsModalOpen,
  paymentLink,
}) {
  return (
    <DeliveryModalStyle
      title={false}
      footer={false}
      centered
      width={"100%"}
      height={"100%"}
      open={isModalOpen}
      closable={false}
    >
      <iframe
        title="Embedded Web Page"
        src={paymentLink} // Replace with the URL of the web page you want to display
        width="100%"
        height="100%"
      />
    </DeliveryModalStyle>
  );
}
