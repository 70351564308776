export const RecentTransactionData = [
  {
    image: "/images/cashback-logo.svg",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/cashback-logo.svg",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/cashback-logo.svg",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
  {
    image: "/images/cashback-logo.svg",
    heading: "Cashback Received from Grocbay",
    subheading: "13 Jan, 2022 11:30 AM",
  },
];
