import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey);
  border-radius: 0.75em;
  // padding: 2em 1em 1.5em;
  width: 28vw;
  // min-width: max-content;
  margin-right: 1.25em;
  &:last-child {
    margin-right: 0;
  }
  button {
    background-color: var(--black);
    border-radius: 0.25em;
    color: var(--white);
    font-size: 1em;
    font-weight: 500;
    padding: 0.625em 1.5em;
    outline: none;
    box-shadow: none;
    border: 0;
  }
  h5 {
    /* font-size: 1.5em; */
    font-size: 1.75em;
    font-weight: 700;
    color: var(--black);
    margin: 0;
  }
  p {
    /* font-size: 1em; */
    font-size: 1.125em;
    color: var(--primary);
    margin: 1em 0;
  }
  & {
    /* @media (min-width: 1500px) {
      h5 {
        font-size: 2em;
      }
      p {
        font-size: 1.3em;
      }
    } */
    /* @media (min-width: 1400px) {
      h5 {
        font-size: 1.625em;
      }
      p {
        font-size: 1.125em;
      }
    } */
    /* @media (max-width: 1200px) {
      display: block;
      & > div {
        &:last-child {
          text-align: end;
        }
      }
    }
    @media (max-width: 992px) {
      margin: 1em 0;
    } */
    /* @media (max-width: 576px) {
      display: flex;
      button {
        padding: 0.375em 1em;
        font-size: 0.75em;
      }
      h6 {
        font-size: 1em;
      }
      p {
        font-size: 0.75em;
      }
      & > div {
        &:last-child {
          img {
            width: 120px;
          }
        }
      }
    } */
  }
`;
