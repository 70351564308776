import React, { useState } from "react";
import { Button, message } from "antd";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { ModalStyle, SubmitBtn } from "../styles";
import { AddNumber } from "./styles";
import { login } from "redux/action/appActions";
import { useSelector } from "react-redux";

const AddPhoneNumberModal = ({
  isModalOpen,
  setIsModalOpen,
  setVerificationModal,
  // userPhoneNumber,
  // setUserPhoneNumber,
  setUserNumber,
  setLoginResponse,
}) => {
  const user = useSelector((state) => state.app.data);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const handleOnChange = (value) => {
    setUserPhoneNumber(value);
  };
  const onFinish = async () => {
    const number = formatPhoneNumberIntl(userPhoneNumber).split(" ");
    const data = {
      mobile: `${number[1]}${number[2]}`,
      country_code: number[0],
    };
    const res = await login(data);
    if (res.status) {
      setLoginResponse(res);
      setUserNumber(data);
      setVerificationModal(true);
      setIsModalOpen(false);
    } else {
      message.error("you have enter wrong number!");
    }
  };
  return (
    <>
      <ModalStyle
        title={false}
        footer={false}
        centered
        width={608}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <AddNumber>
          <h4>Add your phone number</h4>
          <PhoneInput
            international
            // countryCallingCodeEditable={false}
            defaultCountry="IN"
            value={userPhoneNumber}
            onChange={handleOnChange}
            countries={user?.country?.map((item) => item.iso)}
          />
          <SubmitBtn>
            <Button onClick={onFinish}>Confirm</Button>
          </SubmitBtn>
        </AddNumber>
      </ModalStyle>
    </>
  );
};

export default AddPhoneNumberModal;
