import React, { useEffect, useState } from "react";
import { PaymentArea } from "./index.styled";
import { Col, Row, Radio } from "antd";
import WalletCard from "components/Cards/WalletCard";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getPaymentOption } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_AUTH_LOADING } from "redux/Constants";

export default function PaymentDetail({
  selectedAddress,
  selectedDeliveryDetail,
  paymentMethods,
  setPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setSelectedAddress,
}) {
  // const [value, setValue] = useState("");
  const user = useSelector((state) => state.app.data);
  const cart = useSelector((state) => state.app.cart);
  const dispatch = useDispatch();

  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const getData = async () => {
    const data = {
      address_id: selectedAddress.id,
      delivery_type: selectedDeliveryDetail.type,
      promocode_id: "",
      delivery_id: selectedDeliveryDetail.id,
      delivery_charge: selectedDeliveryDetail.delivery_charges,
      shipments_length: selectedDeliveryDetail.shipments_length,
      payment_details: JSON.stringify(cart.cart.paymentDetails),
    };
    const res = await getPaymentOption(data, user.guestToken);
    setPaymentMethods(res);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <PaymentArea>
      <div className="home-deliver">
        <div className="d-flex align-items-center">
          <img
            src={`/images/${
              selectedAddress?.address_type === "Home"
                ? "address-card-logo-3.svg"
                : "work-logo.svg"
            }`}
            alt="click here"
          />
          <div className="homeInfo">
            <h5>
              Deliver to <strong>{selectedAddress?.address_type}</strong>
            </h5>
            <p>{selectedAddress?.reach_address}</p>
            <p>{selectedAddress?.address}</p>
          </div>
        </div>
        <p>Change</p>
      </div>
      <div className="delivery-type">
        <h5 className="box-heading">Select Payment option</h5>
        {/* <Col  xs={24}>
            <WalletCard
            image="/images/wallet-icon.svg"
            heading="Grocbay Wallet"
            text={`Wallet Balance ${currency_symbol}${paymentMethods?.remainingwalletAmount}`}
            price={`${currency_symbol}${paymentMethods?.remainingwalletAmount}`}
            />
          </Col> */}
        {/* <Col md={12} xs={24}>
            <WalletCard
            image="/images/loyalty-icon.png"
            odd="loyalty"
            heading="Loyalty"
            text={`Loyalty Balance ${currency_symbol}${paymentMethods?.remainingloyaltyAmount}`}
            price={`${currency_symbol}${paymentMethods?.remainingloyaltyAmount}`}
            />
          </Col> */}
        <div className="more-options">
          <Radio.Group
            name="delivery-radio-group"
            value={selectedPaymentMethod}
            className="custom-radio"
            style={{ width: "100%", fontSize: "1em" }}
          >
            {paymentMethods?.paymentMethod?.map((item, i) => (
              <div
                key={i}
                className="option"
                style={
                  selectedPaymentMethod === item?.value
                    ? {
                        borderColor: "var(--primary)",
                        backgroundColor: "var(--gray-secondary)",
                      }
                    : {}
                }
                onClick={() => {
                  dispatch({ type: TOGGLE_AUTH_LOADING });
                  setSelectedPaymentMethod(item?.value);
                }}
              >
                <h5 className="box-heading">{item?.title}</h5>
                <Radio value={item.value} />
              </div>
            ))}
          </Radio.Group>
        </div>
      </div>
    </PaymentArea>
  );
}
