import React from "react";
import styled from "styled-components";

export default function Empty({
  title,
  description,
  icon,
  buttonText,
  buttonCallback,
}) {
  return (
    <Wrapper>
      <img src={`/images/${icon}.svg`} alt="" />
      <H1>{title}</H1>
      <P>{description}</P>
      {buttonText && <Button onClick={buttonCallback}>{buttonText}</Button>}
    </Wrapper>
  );
}
const Wrapper = styled("div")`
  text-align: center;
`;
const H1 = styled("h1")`
  font-size: 2.5em;
  margin: 0.5em 0;
  text-align: center;
  color: var(--black);
`;
const P = styled("p")`
  font-size: 1.25em;
  margin: 0;
  text-align: center;
  color: var(--gray);
`;
const Button = styled("button")`
  font-size: 1.25em;
  border: none;
  outline: none;
  padding: 0.5em 1em;
  background: var(--primary);
  color: #fff;
  border-radius: 1em;
  margin-top: 1.5em;
`;
