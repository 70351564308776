import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { fileURL } from "helpers/baseURL";

export default function ShipmentItem({ data }) {
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  return (
    <Wrapper>
      <H2>
        Total number of items <span>: {data?.length}</span>
      </H2>
      {data?.map(({ products }) => (
        <Card>
          <div className="d-flex align-items-center">
            <Image>
              <img
                src={`${fileURL}${products?.product_variations[0]?.product_images[0]?.path}`}
                alt=""
              />
            </Image>
            <div>
              <H4>{products?.name}</H4>
              <P>{`${products?.product_variations[0]?.name} ${products?.product_variations[0]?.unit_of_measure}`}</P>
            </div>
          </div>
          <div>
            <H4>
              {currency_symbol}
              {products?.product_variations[0]?.product_batches[0]?.sale_price}
            </H4>
            <H6>
              {currency_symbol}
              {products?.product_variations[0]?.product_batches[0]?.mrp.toFixed(
                2
              )}
            </H6>
          </div>
        </Card>
      ))}
    </Wrapper>
  );
}
const Wrapper = styled("div")`
  padding: 0 1em;
`;
const Card = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
`;
const Image = styled("div")`
  border: 1px solid var(--gray-legendary);
  border-radius: 0.36em;
  padding: 0.645em 1em;
  margin-right: 1em;
  img {
    width: 45px;
    height: 58px;
  }
`;
const H2 = styled("h2")`
  font-size: 0.875em;
  font-weight: 400;
  color: var(--gray);
  margin-bottom: 1em;
  span {
    color: var(--black);
  }
`;
const H4 = styled("h4")`
  font-size: 1.1286em;
  font-weight: 500;
  color: var(--black);
  margin: 0;
  width: 100%;
  max-width: 220px;
`;
const H6 = styled("h6")`
  font-size: 0.86em;
  font-weight: 400;
  color: var(--gray);
  text-decoration: line-through;
  margin: 0;
  text-align: right;
`;
const P = styled("p")`
  font-size: 0.86em;
  font-weight: 400;
  color: var(--gray);

  margin: 0;
`;
