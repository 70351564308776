import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Col, Progress, Rate, Row } from "antd";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import DashboardHeader from "components/DashboardHeader";
import {
  ProductInfo,
  ProgressArea,
  Ratting,
  RattingCard,
  ReviewCard,
} from "./index.styled";
import { useSelector } from "react-redux";
import moment from "moment";
import { getRatingReviews } from "redux/action/appActions";
import { fileURL } from "helpers/baseURL";

const AllRatings = () => {
  const product = useSelector((state) => state.app.selectedProduct.data);
  const cartData = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [ratingReview, setRatingReview] = useState("");

  useEffect(() => {
    if (product?.products?.id) {
      getRatingReviews(product.products.id).then((res) =>
        setRatingReview(res.data)
      );
    }
  }, []);
  return (
    <>
      <DashboardHeader />
      <Container>
        <div style={{ margin: "2.5em 0" }}>
          <ProductInfo>
            <img
              src={`${fileURL}${product?.products?.product_variations[0]?.product_images[0]?.path}`}
              alt="click123 here"
            />
            <div>
              <h4>{product?.products?.name}</h4>
              <h5>
                {currency_symbol}
                {product?.products?.product_variations[0]?.product_batches[0]?.sale_price.toFixed(
                  2
                )}
              </h5>
              <p>
                {currency_symbol}
                {product?.products?.product_variations[0]?.product_batches[0]?.mrp.toFixed(
                  2
                )}
              </p>
            </div>
          </ProductInfo>
          <Row gutter={0}>
            <Col xl={7} xs={24}>
              <RattingCard>
                <Ratting>
                  <div>
                    <h2>
                      {ratingReview?.averageRating
                        ? ratingReview?.averageRating
                        : 0}
                    </h2>
                    {/* <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  <AiOutlineStar /> */}
                    <Rate disabled value={ratingReview?.averageRating} />
                  </div>
                  <p>
                    {ratingReview?.totalCustomersRating
                      ? ratingReview?.totalCustomersRating
                      : 0}{" "}
                    Ratings &{" "}
                    {ratingReview?.totalReviews
                      ? ratingReview?.totalReviews
                      : 0}{" "}
                    Reviews
                  </p>
                </Ratting>
                <ProgressArea>
                  <div className="progress-line">
                    <h5>5</h5>
                    <AiFillStar />
                    <Progress
                      percent={100}
                      strokeColor="var(--primary)"
                      trailColor="var(--grey)"
                      format={() =>
                        ratingReview?.getReviews?.filter(
                          (item) => item.rating === 5
                        ).length
                      }
                    />
                  </div>
                  <div className="progress-line">
                    <h5>4</h5>
                    <AiFillStar />
                    <Progress
                      percent={80}
                      strokeColor="var(--primary)"
                      trailColor="var(--grey)"
                      format={() =>
                        ratingReview?.getReviews?.filter(
                          (item) => item.rating === 4
                        ).length
                      }
                    />
                  </div>
                  <div className="progress-line">
                    <h5>3</h5>
                    <AiFillStar />
                    <Progress
                      percent={60}
                      strokeColor="var(--primary)"
                      trailColor="var(--grey)"
                      format={() =>
                        ratingReview?.getReviews?.filter(
                          (item) => item.rating === 3
                        ).length
                      }
                    />
                  </div>
                  <div className="progress-line">
                    <h5>2</h5>
                    <AiFillStar />
                    <Progress
                      percent={40}
                      strokeColor="var(--yellow)"
                      trailColor="var(--grey)"
                      format={() =>
                        ratingReview?.getReviews?.filter(
                          (item) => item.rating === 2
                        ).length
                      }
                    />
                  </div>
                  <div className="progress-line">
                    <h5>1</h5>
                    <AiFillStar />
                    <Progress
                      percent={20}
                      strokeColor="var(--red)"
                      trailColor="var(--grey)"
                      format={() =>
                        ratingReview?.getReviews?.filter(
                          (item) => item.rating === 1
                        ).length
                      }
                    />
                  </div>
                </ProgressArea>
              </RattingCard>
            </Col>
            <Col xl={17} xs={24}>
              <ReviewCard>
                <h4>Product Reviews</h4>
                {ratingReview?.getReviews?.map((item) => (
                  <div className="product-review">
                    <div className="d-md-flex justify-content-between align-items-center">
                      <div className="review-box success">
                        <h6>
                          {item.rating} <AiFillStar />
                        </h6>
                      </div>
                      <p>
                        {item.customers.name} (
                        {moment(item.created_at).fromNow()})
                      </p>
                    </div>
                    <h5>{item.review}</h5>
                  </div>
                ))}
              </ReviewCard>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default AllRatings;
