import React, { useEffect, useState } from "react";
import { AddMoney, WalletContent, WalletInfo } from "./index.styled";
import { RecentTransactionData } from "data/RecentTransactionData";
import TransactionCard from "components/Cards/TransactionCard";
import { Button, InputNumber } from "antd";
import { getLoyaltyWallet, getAbout } from "redux/action/appActions";
import { useSelector } from "react-redux";
import Empty from "components/empty";
import moment from "moment";

export default function Wallet() {
  const user = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  let [state, setState] = useState({
    availablePoints: 0,
    transactions: [],
  });
  const getData = async () => {
    const res = await getLoyaltyWallet("wallet");
    console.log(res);
    setState({
      availablePoints: res.availablePoints,
      transactions: res.data,
    });
  };

  const addMoneyToWallet = () => {
    console.log("dsas");
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <WalletInfo>
        <h4>Wallet Balance</h4>
        <h1>
          {currency_symbol} {state.availablePoints}
        </h1>
      </WalletInfo>
      <WalletContent>
        <h5>Add Money to Wallet</h5>
        <AddMoney>
          <InputNumber type="number" prefix={currency_symbol} />
          <Button
            onClick={() => {
              addMoneyToWallet();
            }}
          >
            Add Money
          </Button>
        </AddMoney>
        <h5 style={{ marginBottom: "1.5em" }}>Recent Transaction</h5>

        {state?.transactions.length === 0 && (
          <div
            style={{
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty
              title="No transaction yet!"
              description="You haven't made any transactions yet"
              icon="wallet_empty"
            />{" "}
          </div>
        )}

        {state?.transactions?.map((item) => {
          return (
            <TransactionCard
              image={"/images/cashback-logo.svg"}
              heading={item?.note}
              subheading={moment(item?.created_at).format(
                "DD MMM, YYYY h:mm A"
              )}
            />
          );
        })}
      </WalletContent>
    </>
  );
}
