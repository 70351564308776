import React, { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Radio, Row, Spin } from "antd";
import DealCard from "components/Cards/DealCard";
import Carousel from "components/Carousel";
import DashboardHeader from "components/DashboardHeader";
import RateModal from "components/Modals/RateModal";
import VariationModal from "components/Modals/VariationModal";
import NewDashboardFooter from "components/NewDashboardFooter";
import NewFooter from "components/NewFooter";
import ProductLinks from "components/ProductLinks";
import ProductList from "components/ProductList";
import { fileURL } from "helpers/baseURL";
import { getOptionsText } from "helpers/helpers";
import { Container } from "react-bootstrap";
import { RiArrowDownSLine } from "react-icons/ri";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addCart,
  decrementCart,
  getProductDetails,
  getRatingReviews,
  incrementCart,
} from "redux/action/appActions";
import {
  ShimmerBadge,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "shimmer-react";
import DefImg from "../../../src/assets/images/default_image.svg";
import {
  OptionButton,
  ProductArea,
  ProductDescription,
  ProductInfo,
  ProductQuantity,
  QuantityBtn,
  RelatedProduct,
  StyledButton,
} from "./index.styled";
import "./styles.css";
const utsavkartOrigin = "utsavkart.com";
const { Panel } = Collapse;

const Product = () => {
  const { id = "" } = useParams();
  const selectedProduct = useSelector((state) => state.app.selectedProduct);
  const cartData = useSelector((state) => state.app.data);
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const product = selectedProduct?.data?.products;
  //VariationCard also has same state which is used like this //Will be fixing this issue later. temparary fix for now.
  const [total, setTotal] = useState("1");
  const [variationModal, setVariationModal] = useState(false);
  const [counterVisible, setCounterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [radioValue, setRadioValue] = useState();
  const [rateModal, setRateModal] = useState(false);
  const [ratingReview, setRatingReview] = useState("");
  const [selectedProductVariation, setSelectedProductVariation] = useState(0);
  const [selectedImg, setSelectedImg] = useState();
  const productImgDefaultRender = `${fileURL}${product?.product_variations[0]?.product_images[0]?.path}`;
  const productImgDef = `${fileURL}${product?.product_images[0]?.path}`;
  const [refreshUI, setRefreshUI] = useState(false);
  const [quantityInCart, setQuantityInCart] = useState(0);
  const columnCount =
    window.innerWidth <= 425 ? 2 : window.innerWidth <= 768 ? 3 : 5;

  const { product_variations, location_id } = product || {};
  const product_id = product?.id;

  useEffect(() => {
    document.body.scrollTop = 0;
    if (product) {
      setRadioValue(product_variations[0]?._id);
      setSelectedProductVariation(0);

      const matchingCartItem = cartData?.HomePage?.data[0]?.cart?.cData?.find(
        (item) => item?.variation_id === product_variations[0]?.id
      );

      if (matchingCartItem) {
        setQuantityInCart(matchingCartItem.quantity);
      } else {
        setQuantityInCart(0);
      }
    }
  }, [product]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (product?.id) {
      getRatingReviews(product?.id).then((res) => setRatingReview(res.data));
    }
  }, [rateModal, selectedProduct]);

  useEffect(() => {
    if (cartData.HomePage && product_id) {
      setRadioValue(
        product?.product_variations[selectedProductVariation]?._id
      );
      const prod = cartData?.HomePage?.data[0]?.cart?.cData?.find(
        (item) =>
          item?.variation_id ===
          product_variations[selectedProductVariation]?.id
      );
      if (prod) {
        setTotal(prod?.quantity);
      } else {
        setTotal(1);
      }
    }
  }, [cartData, selectedProduct]);

  useEffect(() => {
    if (product) {
      const imagePath = selectedProductVariation
        ? `${fileURL}${product?.product_variations[selectedProductVariation]?.product_images[0]?.path}`
        : `${fileURL}${product?.product_variations[0]?.product_images[0]?.path}`;
      setSelectedImg(imagePath);
    }
  }, [product, selectedProductVariation]);

  const addtoCart = async (isBuyNow) => {
    const callback = () => {
      if (isBuyNow) {
        navigate("/cart");
      } else {
        setCounterVisible(true);
        setLoading(false);
        setQuantityInCart(quantityInCart + 1);
        setRefreshUI(!refreshUI);
      }
    };
    setLoading(true);
    dispatch(
      addCart(
        {
          product_id: product?.id,
          variation_id: product_variations[selectedProductVariation]?.id,
          batch_id:
            product_variations[selectedProductVariation]?.product_batches[0]
              ?.id,
          location_id: product?.location_id,
          type: product_variations[selectedProductVariation]?.type,
          membership_plan_id: "",
        },
        callback
      )
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getProductDetails(id));
    }
  }, [id]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 35,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 35,
        height: 35,
      }}
      spin
    />
  );

  const addLoading = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 20,
        height: 20,
      }}
      spin
    />
  );

  const handleRadioChange = (value, index) => {
    setRadioValue(value);
    setSelectedProductVariation(index);

    const matchingCartItem = cartData?.HomePage?.data[0]?.cart?.cData?.find(
      (item) => item?.variation_id === product_variations[index]?.id
    );

    if (matchingCartItem) {
      setQuantityInCart(matchingCartItem.quantity);
    } else {
      setQuantityInCart(0);
    }
  };

  const handleIncrement = async () => {
    setLoading(true);
    const callback = () => {
      setTotal(total + 1);
      setLoading(false);
      setQuantityInCart(quantityInCart + 1);
      setRefreshUI(!refreshUI);
    };
    dispatch(
      incrementCart(
        {
          product_id: product?.id,
          variation_id: product_variations[selectedProductVariation]?.id,
          batch_id:
            product_variations[selectedProductVariation]?.product_batches[0]
              ?.id,
          location_id: product?.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };

  const handleDecrement = async () => {
    setLoading(true);
    const callback = () => {
      if (total >= 1) {
        setTotal(total - 1);
        setLoading(false);
        setQuantityInCart(quantityInCart - 1);
        setRefreshUI(!refreshUI);
      } else {
        setCounterVisible(false);
        setLoading(false);
      }
    };
    dispatch(
      decrementCart(
        {
          product_id: product?.id,
          variation_id: product_variations[selectedProductVariation]?.id,
          batch_id:
            product_variations[selectedProductVariation]?.product_batches[0]
              ?.id,
          location_id: product?.location_id,
          delivery_type: "standard_delivery",
        },
        callback
      )
    );
  };

  return (
    <>
      {product && (
        <>
          <DashboardHeader />
          <ProductLinks />
          <Container>
            <ProductArea>
              <Row gutter={[{ md: 40 }]}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {product ? (
                    <p className="product-location">
                      <p onClick={() => navigate("/")}> Home / </p>
                      {product?.category_ids[0]?.label &&
                        `${product?.category_ids[0]?.label}/`}
                      <span>{product?.name}</span>
                    </p>
                  ) : (
                    <ShimmerTitle width={300} />
                  )}
                </div>
                <Col lg={12} style={{ zIndex: 2 }}>
                  <div className="product-outer-container">
                    <div className="product-inner-container">
                      {
                        product?.product_variations[selectedProductVariation]
                          ?.product_images.length > 1 && (
                          <ProductList
                            products={
                              product?.product_variations[
                                selectedProductVariation
                              ]?.product_images
                            }
                            selectedImgPath={(i) =>
                              setSelectedImg(`${fileURL}${i}`)
                            }
                            type={window.innerWidth <= 1150 && "horizontal"}
                          />
                        )
                        //  })
                      }
                      {/* <div className="me-md-4">
                    {product?.product_images?.map(
                      (item) => {
                        if (product?.product_images.length > 1) {
                          return (
                            <ImageBox key={item?.id} className="product-image-box">
                              <img
                                src={`${fileURL}${item?.path}`}
                                alt="click here"
                                onError={(e) => {
                                  e.target.src = DefImg;
                                  e.onerror = null;
                                }}
                              />
                            </ImageBox>
                          );
                        }
                        return <Fragment />;
                      }
                    )}
                    {product?.product_images.length >
                      1 && (
                      <More>
                        <MdKeyboardArrowDown />
                      </More>
                    )}
                  </div> */}
                    </div>
                    <div className="product-zoom-container">
                      {window.innerWidth <= 1150 ? (
                        <img
                          src={
                            selectedImg ? selectedImg : productImgDefaultRender
                          }
                          alt="click here"
                          onError={(e) => {
                            e.target.src = productImgDef;
                            e.onerror = null;
                          }}
                          className="product-image-mobile"
                        />
                      ) : (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Product Image",
                              isFluidWidth: true,
                              onError: (e) => {
                                e.target.src = productImgDef;
                                e.onerror = null;
                              },
                              src: selectedImg
                                ? selectedImg
                                : productImgDefaultRender,
                              // srcSet: this.srcSet,
                              // sizes:
                              //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                            },
                            largeImage: {
                              src: selectedImg
                                ? selectedImg
                                : productImgDefaultRender,
                              onError: (e) => {
                                e.target.src = productImgDef;
                                e.onerror = null;
                              },
                              width: 1200,
                              height: 1800,
                            },
                            enlargedImageContainerDimensions: {
                              width: "250%",
                              height: "100%",
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={12} style={{ zIndex: 1 }} id="product-col-two">
                  <ProductInfo>
                    <div id="enlarged">
                      {/* <div className="discount-label">
                  <p>Buy 2 get 1 free</p>
                </div> */}
                      {product ? (
                        <h3 style={{ fontWeight: "400" }}>
                          {product?.name}
                          {/* <br />
                  Pack */}
                        </h3>
                      ) : (
                        <ShimmerBadge width={400} />
                      )}
                      <Radio.Group
                        name="quantity-radio-group"
                        defaultValue={radioValue}
                        value={radioValue}
                        className="d-flex align-items-center custom-scrollbar"
                        style={{
                          margin: "2.286em 0",
                          fontSize: "1em",
                        }}
                      >
                        {product?.price_variation ? (
                          <>
                            {product?.product_variations?.map((item, index) => (
                              <ProductQuantity
                                key={item?.id}
                                onClick={() =>
                                  handleRadioChange(item?._id, index)
                                }
                                style={{
                                  borderColor:
                                    radioValue === item?._id
                                      ? "var(--primary)"
                                      : "",
                                  marginRight: "1em",
                                }}
                              >
                                <Radio value={`${item?._id}`}>
                                  {item?.name} {item?.unit_of_measure}
                                </Radio>
                              </ProductQuantity>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </Radio.Group>

                      <div className="product-price">
                        {product ? (
                          <>
                            <h3>
                              {currency_symbol}
                              {radioValue
                                ? product?.product_variations.find(
                                    (item) => item._id === radioValue
                                  )?.product_batches[0]?.sale_price === 0
                                  ? product?.product_variations
                                      .find((item) => item._id === radioValue)
                                      ?.product_batches[0]?.mrp.toFixed(2)
                                  : product?.product_variations
                                      .find((item) => item._id === radioValue)
                                      ?.product_batches[0]?.sale_price.toFixed(
                                        2
                                      )
                                : ""}
                            </h3>
                            <h5>
                              {product?.product_variations.find(
                                (item) => item._id === radioValue
                              )?.product_batches[0]?.sale_price !== 0 && (
                                <>
                                  {currency_symbol}
                                  {radioValue
                                    ? product?.product_variations
                                        .find(
                                          (item) => item._id === radioValue
                                        )
                                        ?.product_batches[0]?.mrp.toFixed(2)
                                    : ""}
                                </>
                              )}
                            </h5>
                          </>
                        ) : (
                          <ShimmerBadge width={100} />
                        )}
                      </div>
                      <>
                        {product ? (
                          <div className="add-to-cart-container">
                            {quantityInCart === 0 ? (
                              loading ? (
                                <div style={{ margin: "0 16px 16px 0" }}>
                                  <Spin indicator={antIcon} />
                                </div>
                              ) : (
                                <>
                                  <div className="d-flex gap-3">
                                    <StyledButton
                                      onClick={() => {
                                        addtoCart(false);
                                      }}
                                    >
                                      Add
                                    </StyledButton>
                                    {/* <Button
                                  className="add-btn"
                                  onClick={() => {
                                    addtoCart(false);
                                  }}
                                >
                                  <span>Add +</span>
                                </Button> */}

                                    {(true ||
                                      window.location.origin.includes(
                                        utsavkartOrigin
                                      )) && (
                                      <StyledButton
                                        onClick={() => {
                                          addtoCart(true);
                                        }}
                                      >
                                        Buy Now
                                      </StyledButton>
                                      // <QuantityBtn
                                      //   onClick={() => {
                                      //     addtoCart(true);
                                      //   }}
                                      // >
                                      //   <span className="mx-3">Buy Now</span>
                                      // </QuantityBtn>
                                    )}
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                <QuantityBtn>
                                  <span
                                    onClick={
                                      loading ? () => {} : handleDecrement
                                    }
                                  >
                                    -
                                  </span>
                                  <span>
                                    {loading ? (
                                      <Spin indicator={addLoading} />
                                    ) : (
                                      quantityInCart
                                    )}
                                  </span>
                                  <span onClick={handleIncrement}>+</span>
                                </QuantityBtn>
                              </>
                            )}
                          </div>
                        ) : (
                          <ShimmerThumbnail
                            height={50}
                            width={150}
                            className="m-0"
                            rounded
                          />
                        )}
                      </>
                      {/* <p className="membership-text">
                    {product ? (
                      product?.product_variations[0]?.product_batches[0]
                        ?.membership_price > 0 ? (
                        `${currency_symbol}${product?.product_variations[0]?.product_batches[0]?.membership_price}  Membership Price`
                      ) : (
                        ""
                      )
                    ) : (
                      <ShimmerBadge width={100} />
                    )}
                  </p> */}
                      {/* <Button className="add-btn" onClick={(e) => navigate("/cart")}>
                  Add
                </Button> */}
                      {/* {product ? (
                        product?.price_variation ? (
                          <>
                            <OptionButton
                              onClick={() => setVariationModal(true)}
                            >
                              {getOptionsText(product_variations.length)}{" "}
                              <RiArrowDownSLine />
                            </OptionButton>
                            <VariationModal
                              isModalOpen={variationModal}
                              setIsModalOpen={setVariationModal}
                              product_variations={product_variations}
                              location_id={location_id}
                              id={product_id}
                            />
                          </>
                        ) : (
                          <>
                            {loading ? (
                              <div style={{ margin: "0 16px 16px 0" }}>
                                <Spin indicator={antIcon} />
                              </div>
                            ) : !counterVisible &&
                              !(
                                cartData?.HomePage &&
                                cartData?.HomePage?.data[0]?.cart?.cData?.find(
                                  (item) => item?.product_id === product?.id
                                )
                              ) ? (
                              //  <div>
                              //   {product_variations.product_batches.stock > 0 ? (
                              //     <Button
                              //       onClick={() => {
                              //         addtoCart();
                              //       }}
                              //     >
                              //       <span>Add +</span>
                              //     </Button>
                              //   ) : (
                              //     <>
                              //       {" "}
                              //       <Button>
                              //         <span>Out of Stock</span>
                              //       </Button>
                              //     </>
                              //   )}
                              // </div>, */}

                      {/* <div className="d-flex gap-3">
                                <Button
                                  className="add-btn"
                                  onClick={() => {
                                    addtoCart(false);
                                  }}
                                >
                                  <span>Add +</span>
                                </Button> */}

                      {/* ------------ */}
                      {/* {(true ||
                                  window.location.origin.includes(
                                    utsavkartOrigin
                                  )) && (
                                  <QuantityBtn
                                    onClick={() => {
                                      addtoCart(true);
                                    }}
                                    k
                                  >
                                    <span className="mx-3">Buy Now</span>
                                  </QuantityBtn>
                                )}
                              </div>
                            ) : (cartData?.HomePage &&
                                cartData?.HomePage?.data[0]?.cart?.cData?.find(
                                  (item) => item?.product_id === product?.id
                                )) ||
                              counterVisible ? (
                              <QuantityBtn>
                                <span onClick={handleDecrement}>-</span>
                                <span>{total}</span>
                                <span onClick={handleIncrement}>+</span>
                              </QuantityBtn>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      ) : (
                        <ShimmerThumbnail
                          height={50}
                          width={150}
                          className="m-0"
                          rounded
                        />
                      )}   */}
                      {/* <Ratting>
                    <h4>Ratings & Reviews</h4>
                    <div>
                      <h1>
                        {ratingReview?.averageRating
                          ? ratingReview?.averageRating
                          : 0}
                      </h1>
                      <Rate disabled value={ratingReview?.averageRating} />
                    </div>
                  </Ratting> */}
                      {/* <Link to="/allRatings" className="product-review">
                    {ratingReview?.totalCustomersRating
                      ? ratingReview?.totalCustomersRating
                      : 0}{" "}
                    Ratings &{" "}
                    {ratingReview?.totalReviews
                      ? ratingReview?.totalReviews
                      : 0}{" "}
                    Reviews <MdOutlineKeyboardArrowRight />
                  </Link> */}
                      {/* <a
                    href="#"
                    className="product-rate"
                    onClick={() => setRateModal(true)}
                  >
                    Rate Product
                  </a> */}
                      {/* <RateModal
                        isModalOpen={rateModal}
                        setIsModalOpen={setRateModal}
                        product={product}
                      /> */}
                    </div>
                  </ProductInfo>
                </Col>
                <Col lg={12}>
                  <div>
                    {product ? (
                      <ProductDescription>
                        <div>
                          {product?.product_descriptions?.description?.includes(
                            "Product Description :"
                          ) ? (
                            <>
                              <h4>Product Description</h4>
                              <h5>
                                {product?.product_descriptions?.description
                                  ?.split("Product Description :")[1]
                                  ?.split("Disclaimer :")[0]
                                  ?.replace(
                                    /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
                                    ""
                                  )}
                              </h5>
                              <h4>Disclaimer</h4>
                              <h5>
                                {product?.product_descriptions?.description
                                  ?.split("Product Description :")[1]
                                  ?.split("Disclaimer :")[1]
                                  ?.replace(
                                    /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
                                    ""
                                  )}
                              </h5>
                            </>
                          ) : (
                            <>
                              <h4>Product Description</h4>
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html:
                                    product?.product_descriptions?.description,
                                }}
                              ></h5>
                            </>
                          )}
                        </div>
                      </ProductDescription>
                    ) : (
                      <>
                        <ShimmerTitle />
                        <ShimmerText />
                        <ShimmerTitle />
                        <ShimmerText />
                      </>
                    )}
                  </div>
                </Col>

                {/* <div className="fluid__image-container">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: "Wristwatch by Ted Baker London",
                      isFluidWidth: true,
                      src: Img,
                      // srcSet: this.srcSet,
                      sizes:
                        "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                    },
                    largeImage: {
                      src: Img,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImageContainerDimensions: {
                      width: "200%",
                      height: "100%",
                    },
                  }}
                />
                 
              </div> */}
                {/* <div className="fluid__instructions">
                <h3>Enlarged Image Container Dimensions Example</h3>
                <p>
                  Specify dimensions as percentage of small image or number of
                  pixels.
                </p>
                <p>
                  May be percentage for one dimension and number for the other.
                </p>
                <p>
                  Not applied when enlargedImagePosition is set to 'over', the
                  default for touch input.
                </p>
              </div>
              <div style={{ height: "500px" }} />
            </div> */}
              </Row>
            </ProductArea>

            <RelatedProduct style={{ marginTop: "2em" }}>
              {
                selectedProduct?.data ? (
                  <>
                    <h2>{selectedProduct?.data?.related_items?.label}</h2>
                    <Carousel
                      showSlide={columnCount}
                      centerMode={
                        selectedProduct?.data?.related_items?.data.length <= 3
                          ? true
                          : false
                      }
                      className="max-dh-50"
                    >
                      {selectedProduct?.data?.related_items?.data?.map(
                        (item, i) => {
                          const {
                            product_variations,
                            name,
                            is_subscription,
                            location_id,
                            id,
                            price_variation,
                          } = item;

                          const offText = Math.round(
                            product_variations[0]?.product_batches[0]
                              ?.discount_percentage
                          );

                          return (
                            <div key={i}>
                              <DealCard
                                id={id}
                                offText={offText === 0 ? 0 : `${offText}% OFF`}
                                brandLogo={"/images/express-logo.svg"}
                                brandText={"Express"}
                                brandImage={`${fileURL}${product_variations[0]?.product_images?.[0]?.path}`}
                                footerText={`${currency_symbol}${product_variations[0]?.product_batches[0]?.membership_price?.toFixed(
                                  0
                                )} Membership Price`}
                                heading={name}
                                subheading={
                                  product_variations[0]?.name +
                                  product_variations[0]?.unit_of_measure
                                }
                                discount={
                                  product_variations[0]?.product_batches[0]
                                    .sale_price
                                }
                                price={product_variations[0]?.product_batches[0]?.mrp.toFixed(
                                  2
                                )}
                                option={price_variation}
                                product_variations={product_variations}
                                quantity={0}
                                subscribe={is_subscription}
                                data={{
                                  product_id: id,
                                  variation_id: product_variations[0]?.id,
                                  batch_id:
                                    product_variations[0]?.product_batches[0]
                                      ?.id,
                                  location_id: location_id,
                                  type: product_variations[0]?.type,
                                  membership_plan_id: "",
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </Carousel>
                  </>
                ) : (
                  <>
                    <ShimmerThumbnail
                      height={200}
                      width={300}
                      className="m-0"
                      rounded
                    />
                    <ShimmerBadge width={300} />
                    <ShimmerBadge width={200} />
                    <ShimmerBadge width={100} />
                  </>
                )
                // <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} />
              }
            </RelatedProduct>
          </Container>
          <div className="d-sm-none d-xs-none d-md-none d-lg-block">
            <NewDashboardFooter />
            <NewFooter />
          </div>

          <Collapse
            className="border-0 border-b-0 d-md-block d-lg-none d-xl-none footer-collapse"
            expandIconPosition="end"
          >
            <Panel
              header={<p className="font-large">Helpful Links</p>}
              className="p-0"
            >
              <NewDashboardFooter />
              <NewFooter />
            </Panel>
          </Collapse>
        </>
      )}
    </>
  );
};

export default Product;
