import React, { useState } from "react";
import { Button, Input } from "antd";
import { CouponModalStyle } from "../styles";
import { Fragment } from "react";

const CouponModal = ({ isModalOpen, toggleCouponModal, data, onApply }) => {
  const [value, setValue] = useState("");

  const onClose = () => {
    toggleCouponModal();
    setValue("");
  };

  return (
    <CouponModalStyle
      title={false}
      footer={false}
      centered
      width={698}
      open={isModalOpen}
      onCancel={toggleCouponModal}
    >
      <div className="info">
        <h4>Apply Coupon</h4>
        <Input
          className={value === "" ? "disable" : ""}
          placeholder="Enter Coupon Code"
          suffix={
            <a href="#" onClick={onClose}>
              Apply
            </a>
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="label">
        <p>AVAILABLE COUPONS</p>
      </div>
      <div className="content">
        {data?.map((item) => (
          <Fragment>
            <div className="coupons-card">
              <h6>Get {item?.discount_value}% off</h6>
              {item?.description && (
                <p>
                  {item?.description} <a href="#">View More</a>
                </p>
              )}

              <div className="action-buttons">
                <Button>{item?.promocode}</Button>
                <a href="#" onClick={() => onApply(item)}>
                  Apply
                </a>
              </div>
            </div>
            <div className="bordered"></div>
          </Fragment>
        ))}

        {/* <div className="coupons-card">
            <img src="/images/paytm-logo.png" alt="click here" />
            <h6>Get assured cashback between ₹25 to ₹100 using Paytm wallet</h6>
            <p>
              Assured cashback between ₹25 to ₹100 on Paytm wallet transactions
              above ₹399 <a href="#">View More</a>
            </p>
            <div className="action-buttons">
              <Button>PAYTMFEST</Button>
              <a href="#" onClick={onClose}>
                Apply
              </a>
            </div>
          </div>
          <div className="bordered"></div>
          <div className="coupons-card">
            <img src="/images/paytm-logo.png" alt="click here" />
            <h6>Get flat ₹100 discount using ICICI Bank Net Banking</h6>
            <p>
              Flat ₹100 discount on orders above ₹700
              <a href="#"> View More</a>
            </p>
            <div className="action-buttons">
              <Button>PAYTMFEST</Button>
              <a href="#" onClick={onClose}>
                Apply
              </a>
            </div>
          </div> */}
      </div>
    </CouponModalStyle>
  );
};

export default CouponModal;
