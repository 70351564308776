import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  Card,
  ImageBox,
  ItemButton,
  ProductPrice,
  QuantityBtn,
  QuantityAndPriceContainer,
  ProductPriceContainer,
} from "./styles";
import {
  decrementCartScreen,
  incrementCartScreen,
} from "redux/action/appActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import DefaultImage from "../../../assets/images/default_image.svg";

const BasketCard = ({
  quantity,
  image,
  heading,
  text,
  discount,
  price,
  data,
  deliveryType,
}) => {
  const { currency_symbol } = useSelector((state) => state.app.data.refType);
  const [total, setTotal] = useState(quantity);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDecrement = async () => {
    if (total > 1) {
      setLoading(true);

      const res = await dispatch(
        decrementCartScreen(
          {
            product_id: data.product_id,
            variation_id: data.variation_id,
            batch_id: data.batch_id,
            location_id: data.location_id,
            delivery_type: deliveryType,
          },

          setLoading
        )
      );
      if (res) {
        setTotal(total - 1);
      }
      // setLoading(false);
    } else {
      setLoading(true);

      const res = dispatch(
        decrementCartScreen(
          {
            product_id: data.product_id,
            variation_id: data.variation_id,
            batch_id: data.batch_id,
            location_id: data.location_id,
            delivery_type: deliveryType,
          },
          setLoading
        )
      );
    }
  };

  const handleIncrement = async () => {
    setLoading(true);

    const res = await dispatch(
      incrementCartScreen(
        {
          product_id: data.product_id,
          variation_id: data.variation_id,
          batch_id: data.batch_id,
          location_id: data.location_id,
          delivery_type: deliveryType,
        },
        setLoading
      )
    );
    if (res?.data?.status === true) {
      setTotal(total + 1);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "var(--primary)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
      }}
      spin
    />
  );

  return (
    <Card>
      <div className="d-flex">
        <ImageBox>
          <img
            src={image ? image : DefaultImage}
            alt="click here"
            onError={(e) => {
              e.target.src = DefaultImage;
              e.onerror = null;
            }}
          />
        </ImageBox>
        <div className="product-info">
          <h6>{heading}</h6>
          <p>{text}</p>
        </div>
      </div>
      <QuantityAndPriceContainer
        isColumn={window.innerWidth < 1450 && window.innerWidth > 1200}
      >
        <div className="d-md-block d-none">
          {loading ? (
            <div style={{ margin: "0 16px 16px 0" }}>
              <Spin indicator={antIcon} />
            </div>
          ) : quantity < 1 ? (
            <ItemButton>Item Not Available</ItemButton>
          ) : (
            <QuantityBtn>
              <span onClick={handleDecrement}>-</span>
              <span>{total}</span>
              <span onClick={handleIncrement}>+</span>
            </QuantityBtn>
          )}
        </div>
        <div className="d-md-block d-none">
          {quantity < 1 ? (
            <IoMdClose />
          ) : (
            <ProductPriceContainer>
              <p>{`${currency_symbol}${discount}`}</p>
              {discount !== price && (
                <span>{`${currency_symbol}${price}`}</span>
              )}
            </ProductPriceContainer>
          )}
        </div>
      </QuantityAndPriceContainer>
      <div
        className="d-md-none d-flex justify-content-end align-items-center"
        style={{ width: "100%" }}
      >
        <div>
          {quantity < 1 ? (
            <ItemButton>Item Not Available</ItemButton>
          ) : (
            <QuantityBtn>
              <span onClick={handleDecrement}>-</span>
              <span>{total}</span>
              <span onClick={handleIncrement}>+</span>
            </QuantityBtn>
          )}
        </div>
        <div>
          {quantity < 1 ? (
            <IoMdClose />
          ) : (
            <ProductPrice>
              <p>{`${currency_symbol}${discount}`}</p>
              <span>{`${currency_symbol}${price}`}</span>
            </ProductPrice>
          )}
        </div>
      </div>
    </Card>
  );
};

export default BasketCard;
