import React, { useEffect, useState } from "react";
import { Button, Form, message } from "antd";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { ModalStyle, SubmitBtn } from "../styles";
import { InputLabel, LoginArea, SignInButtons } from "./styles";
import {
  getALLData,
  getCartItems,
  login,
  userLoaded,
} from "redux/action/appActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { TOGGLE_AUTH_LOADING } from "redux/Constants";
import { customerService } from "helpers/baseURL";
import setAuthToken from "helpers/setAuthToken";

const LoginModal = ({
  isModalOpen,
  setIsModalOpen,
  // userPhoneNumber,
  setUserNumber,
  setLoginResponse,
  setVerificationModal,
  callback,
}) => {
  const user = useSelector((state) => state.app.data);
  const authLoading = useSelector((state) => state.app.authLoading);
  const [isLoading, setLoading] = useState(false);
  const [refType, setRefType] = useState({});
  const dispatch = useDispatch();
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const handleOnChange = (value) => {
    setUserPhoneNumber(value);
  };

  const navigate = useNavigate();

  const onFinish = async () => {
    setLoading(true);

    const number = formatPhoneNumberIntl(userPhoneNumber).split(" ");
    console.log("the number is", number);

    let countryCode, mobile;

    if (number.length === 3) {
      // 10-digit number
      countryCode = number[0];
      mobile = `${number[1]}${number[2]}`;
    } else if (number.length === 4) {
      // 9-digit number
      countryCode = number[0];
      mobile = `${number[1]}${number[2]}${number[3]}`;
    } else {
      console.error("Invalid phone number format");
    }

    const data = {
      mobile: mobile,
      country_code: countryCode,
      ref: user.ref,
    };

    const res = await login(data);

    if (res.status) {
      setLoginResponse(res);
      setUserNumber(data);
      setVerificationModal(true);
      setLoading(false);
      setIsModalOpen(false);
    } else {
      message.error("Please enter valid phone number");
      setLoading(false);
    }
  };

  const sendGoogleDataToBackendAPI = async (googleLoginData) => {
    try {
      const response = await customerService.post(
        "/api/v1/auth/customer/social-login",
        JSON.stringify(googleLoginData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.status) {
        localStorage.setItem("token", response?.data?.token);
        setAuthToken(response?.data?.token);
        localStorage.setItem(
          googleLoginData?.first_name,
          JSON.stringify(response?.data?.data)
        );
        const token = response?.data?.token;

        const data = {
          isGuest: 0,
          domain:
            window.location.origin === "http://localhost:3000"
              ? "https://web.grocbay.com"
              : window.location.origin,
          screen_type: "home_screen",
        };
        dispatch(userLoaded(data, token));
        dispatch(getALLData(data));
        dispatch(getCartItems(setLoading, data));
      } else {
        console.error(
          "Failed to send data to the backend API. Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error sending data to the backend API:", error);
    }
  };

  const handleGoogleLoginSuccess = async (provider, response) => {
    const { email, given_name, family_name, locationId, picture, sub } =
      response;

    const googleLoginData = {
      email: email,
      mobile: "",
      first_name: given_name,
      last_name: family_name,
      ref: localStorage.getItem("ref"),
      channel: "website",
      referral: "",
      country: "India",
      longitude: "",
      latitude: "",
      country_code: "",
      ip: "",
      location_id: locationId,
    };

    await sendGoogleDataToBackendAPI(googleLoginData);
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failure:", error);
  };

  //-----------------------------Facebook----------------------------//

  const sendFacebookDataToBackendAPI = async (facebookLoginData) => {
    try {
      const response = await customerService.post(
        "/api/v1/auth/customer/social-login",
        JSON.stringify(facebookLoginData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.status) {
        localStorage.setItem("token", response?.data?.token);

        localStorage.setItem(
          facebookLoginData?.first_name,
          JSON.stringify(response?.data?.data)
        );
        const token = response?.data?.token;

        const data = {
          isGuest: 0,
          domain:
            window.location.origin === "http://localhost:3000"
              ? "https://web.grocbay.com"
              : window.location.origin,
          screen_type: "home_screen",
        };
        dispatch(userLoaded(data, token));
        dispatch(getALLData(data));
        dispatch(getCartItems());
        setIsModalOpen(false);
      } else {
        console.error(
          "Failed to send data to the backend API. Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error sending data to the backend API:", error);
    }
  };

  const handleFacebookLoginSuccess = async (response) => {
    const { email, first_name, last_name, locationId, picture, name, sub } =
      response;

    const facebookLoginData = {
      email: email,
      mobile: "",
      name: name,
      first_name: first_name,
      last_name: last_name,
      ref: localStorage.getItem("ref"),
      channel: "website",
      referral: "",
      country: "India",
      longitude: "",
      latitude: "",
      country_code: "",
      ip: "",
      location_id: locationId,
    };

    await sendFacebookDataToBackendAPI(facebookLoginData);
  };

  const handleFacebookLoginFailure = (error) => {
    console.error("Facebook login failure:", error);
  };

  useEffect(() => {
    if (isModalOpen) {
      let refData = localStorage.getItem("refType");
      if (refData) {
        setRefType(JSON.parse(refData));
      }
    }
  }, [isModalOpen]);

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={608}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
    >
      <LoginArea>
        <h4>Login / Sign up</h4>
        <h6>Enter your phone number to Login / Sign up</h6>
        <Form name="login-form" layout="vertical">
          <Form.Item
            label={<InputLabel>Phone number</InputLabel>}
            name="number"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input your number!",
            //   },
            // ]}
          >
            <PhoneInput
              international
              // countryCallingCodeEditable={false}
              defaultCountry="IN"
              value={userPhoneNumber}
              onChange={handleOnChange}
              countries={user?.country?.map((item) => item.iso)}
            />
            <SubmitBtn>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinish}
                disabled={isLoading}
              >
                Continue
              </Button>
            </SubmitBtn>
            <p style={{ marginTop: "2em" }}>
              By using {refType?.business_name}, you agree to the{" "}
              <strong
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsModalOpen(false);
                  navigate("/e-page");
                }}
              >
                Terms & Conditions
              </strong>
            </p>
            {/* <h6>Or continue with</h6> */}
          </Form.Item>
        </Form>
        {/* <SignInButtons> */}
        {/* <GoogleLogin
              clientId="67283784529-ud9fk041h37cabfhorcpkcj8895hcp15.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle />
                  Google
                </Button> */}
        {/* <LoginSocialGoogle
            client_id={
              "277236241016-0avfh5qlkk14re5feba71hjni7mgafcs.apps.googleusercontent.com"
            }
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={({ provider, data }) => {
              handleGoogleLoginSuccess(provider, data);
            }}
            onReject={(err) => {
              dispatch({ type: TOGGLE_AUTH_LOADING });
            }}
          >
            <Button onClick={() => dispatch({ type: TOGGLE_AUTH_LOADING })}>
              <FcGoogle />
              Google
            </Button>
          </LoginSocialGoogle> */}
        {/* facebook log in */}
        {/* <LoginSocialFacebook
            appId="1101072413905811" // id
            onResolve={(response) => {
              handleFacebookLoginSuccess(response.data);
            }}
            onReject={(error) => {
              dispatch({ type: TOGGLE_AUTH_LOADING });
            }}
            onCancel={() => {
              dispatch({ type: TOGGLE_AUTH_LOADING });
            }}
          >
            <Button onClick={() => dispatch({ type: TOGGLE_AUTH_LOADING })}>
              <BsFacebook />
              FacebooK
            </Button>
          </LoginSocialFacebook> */}
        {/* </SignInButtons> */}
      </LoginArea>
      {authLoading && (
        <div
          style={{
            position: "fixed",
            zIndex: "1031",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 120,
              color: "var(--primary)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 120,
              height: 120,
            }}
            spin
          />
        </div>
      )}
    </ModalStyle>
  );
};

export default LoginModal;
