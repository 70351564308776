import { useCallback, useEffect } from "react";

export const useDebounce = (
  effect = () => {},
  dependencies = [],
  delay = 0,
  callBackReturn
) => {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => {
      clearTimeout(timeout);
      callBackReturn && callBackReturn();
    };
  }, [callback, delay]);
};

export function throttler(callback, delay) {
  let timeout = undefined;
  return function (...args) {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => callback(...args), delay);
  };
}

export const getOptionsText = (length) => {
  return  `${length} ${length === 1 ? "option" : "options"}`;
};
