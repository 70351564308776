import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CodeInput } from "react-code-verification-input";
import { BiMessage } from "react-icons/bi";
import { TbPhone } from "react-icons/tb";
import { ModalStyle } from "../styles";
import { ActionButtons, VerifyArea } from "./styles";
import {
  resendOTP,
  sendOTP,
  callme,
  getCartItems,
} from "redux/action/appActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import setAuthToken from "helpers/setAuthToken";

const VerificationModal = ({
  isModalOpen,
  setIsModalOpen,
  userPhoneNumber,
  setLoginModal,
  setItem,
  itemValue,
  loginResponse,
  setLoginResponse,
  callback,
}) => {
  const user = useSelector((state) => state.app.data);
  const navigate = useNavigate();
  const [count, setCount] = useState(30); // Set your initial count value here
  const [code, setCode] = useState("");
  const handleResend = async () => {
    const res = await resendOTP(userPhoneNumber);
    if (res.status) {
      setLoginResponse(res);
    }
  };
  const handleCall = async () => {
    const res = await callme(userPhoneNumber);
  };

  const handleSend = async () => {
    if (code.length === 4) {
      const res = await sendOTP({
        hashed_otp: loginResponse.otp,
        otp: code,
        user_type: loginResponse.data.user_type,
        customer_token: loginResponse.token,
        ref: user.ref,
      });
      if (res.status) {
        if (loginResponse.data.user_type !== "NEW") {
          setAuthToken(loginResponse.token);
        }
        // localStorage.setItem("token", loginResponse.token);
        if (loginResponse.data.user_type === "EXIST") {
          callback();
        } else {
          setIsModalOpen(false);
          setItem(itemValue);
        }
        // navigate("/");
      } else {
        message.error("you have enter wrong otp!");
      }
    }
  };
  useEffect(() => {
    handleSend();
  }, [code]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, [count]);

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={608}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
    >
      <VerifyArea>
        <h4>OTP Verification</h4>
        <h5>We have sent a verification code to</h5>
        <h5>
          {userPhoneNumber?.country_code} {userPhoneNumber?.mobile}
          <img
            onClick={() => {
              setIsModalOpen(false);
              // setUserPhoneNumber(null);
              setLoginModal(true);
            }}
            src="/images/edit-logo.svg"
            alt="click here"
          />
        </h5>
        <CodeInput
          autoFocus={true}
          length={4}
          onChange={(value) => {
            setCode(value);
          }}
          value={code}
        />
        <p>Didn't receive the OTP verification code?</p>
        <ActionButtons>
          <Button disabled={count === 0 ? false : true} onClick={handleResend}>
            <BiMessage />
            Resend {count !== 0 && <>in {count} Sec.</>}
          </Button>
          <Button disabled={count === 0 ? false : true} onClick={handleCall}>
            <TbPhone />
            Call {count !== 0 && <>in {count} Sec.</>}
          </Button>
        </ActionButtons>
      </VerifyArea>
    </ModalStyle>
  );
};

export default VerificationModal;
