import React, { useState, useEffect, useCallback } from "react";
import { Button } from "antd";
import { ModalStyle, ChangeLocation, SubmitBtn } from "../styles";
import MapBox from "components/mapBox";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { CustomAutocomplete } from "../AddAddressModal/styles";
import { addLocation } from "redux/action/appActions";
import { useSelector, useDispatch } from "react-redux";

const ConfirmLocationModal = ({
  isModalOpen,
  setIsModalOpen,
  setCurrentLocation,
  setLocationModal,
  center,
  setCenter,
}) => {
  const guestToken = useSelector((state) => state.app.data.guestToken || "");
  const [fullAddress, setFullAddress] = useState();
  const [showAddress, setShowAddress] = useState(false);
  const user = useSelector((state) => state.app.data);
  const dispatch = useDispatch();

  const callback = () => {
    setIsModalOpen(false);
    setLocationModal(false);
    setCurrentLocation(false);
  };

  const handleSubmit = async (locaAddr) => {
    let data = null;
    let res = null;
    if (!locaAddr) {
      data = {
        //   name: name,
        //   address_type: addressType,
        //   address: address,
        //   pincode: pinCode,
        //   mobile: mobile,
        //   city: city,
        //   country: country,
        //   latitude: latitude,
        //   longitude: longitude,
        //   is_default: isDefault,
        //   reach_address: reachAddress,
        //   location_address: locationAddress,
        //   location_id: locationId,
        latitude: fullAddress.latLng.lat.toString(),
        longitude: fullAddress.latLng.lng.toString(),
        type: "exact",
        location_name: fullAddress.locality,
        location_addresses: fullAddress.formatted_address,
      };

      if (data) {
        res = dispatch(addLocation(data, callback));
      }
    }

    if (res?.status) {
      if (data) {
        localStorage.setItem("user-address", JSON.stringify(data));
      } else {
        localStorage.setItem("user-address", JSON.stringify(locaAddr));
      }
    }
  };

  const handleSelect = async (address) => {
    if (!address || !address.formatted_address || !address.place_id) {
      console.error("Invalid address object:", address);
      return;
    }

    const results = await geocodeByAddress(address?.formatted_address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(address.place_id);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const city =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      )?.long_name || "";
    const country =
      place.address_components.find((c) => c.types.includes("country"))
        ?.long_name || "";
    const locality =
      place.address_components.find((c) => c.types.includes("locality"))
        ?.long_name || "";
    setCenter(latLng);
    const data = {
      postalCode: postalCode,
      latLng: latLng,
      city: city,
      country: country,
      formatted_address: address.formatted_address,
      locality: locality,
    };
    setFullAddress(data);
    setShowAddress(false);
  };

  const onMarker = useCallback((prop) => {
    const { position } = prop;
    if (!position) {
      const { lat, lng } = prop;
      setCenter({
        lat: lat,
        lng: lng,
      });
      getAddressFromLatLng(lat, lng);
    } else {
      setCenter({
        lat: position?.lat(),
        lng: position?.lng(),
      });
      getAddressFromLatLng(position?.lat(), position?.lng());
      prop.setMap(null);
    }
  }, []);

  const getAddressFromLatLng = async (lat, lng) => {
    const apiKey = process.env.REACT_APP_MAPBOX_TOKEN;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      handleSelect(data.results[0]);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    let localAddr = localStorage.getItem("user-address");

    if (!localAddr) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        getAddressFromLatLng(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    } else {
      try {
        localAddr = localAddr ? JSON.parse(localAddr) : localAddr;

        handleSubmit(localAddr);
      } catch (err) {}
    }
  }, [guestToken]);

  useEffect(() => {
    if (center && !fullAddress) {
      getAddressFromLatLng(center.lat, center.lng);
    }
  }, [center]);

  return (
    <ModalStyle
      title={false}
      footer={false}
      centered
      width={670}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
    >
      <div>
        <MapBox center={center} onMarker={onMarker} />

        <div style={{ height: "10px" }}></div>
        {showAddress ? (
          <CustomAutocomplete
            apiKey={process.env.REACT_APP_MAPBOX_TOKEN}
            onPlaceSelected={handleSelect}
            placeholder="Search place..."
          />
        ) : (
          <ChangeLocation>
            <div>
              <img src="/images/address-card-logo-1.svg" alt="click here" />
              <div>
                <h5>{fullAddress?.locality}</h5>
                <p>{fullAddress?.formatted_address}</p>
              </div>
            </div>
            <span onClick={() => setShowAddress(true)}>change</span>
          </ChangeLocation>
        )}

        <SubmitBtn>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue
          </Button>
        </SubmitBtn>
      </div>
    </ModalStyle>
  );
};

export default ConfirmLocationModal;
